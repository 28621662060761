var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-headers full-width" }, [
    _c(
      "div",
      {
        staticClass: "list-header list-header--name list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("title")
          }
        }
      },
      [
        _vm._v("\n    NAME\n    "),
        _vm.orderBy.includes("title")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: { "sort-icon--downward": _vm.orderBy === "title.desc" },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "list-header list-header--tags" }, [
      _vm._v("TAG")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--edited list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("updatedAt")
          }
        }
      },
      [
        _vm._v("\n    LAST EDITED\n    "),
        _vm.orderBy.includes("updatedAt")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: {
                "sort-icon--downward": _vm.orderBy === "updatedAt.desc"
              },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--created list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("createdAt")
          }
        }
      },
      [
        _vm._v("\n    CREATED\n    "),
        _vm.orderBy.includes("createdAt")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: {
                "sort-icon--downward": _vm.orderBy === "createdAt.desc"
              },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }