import { ITEMS_PER_PAGE } from './constants';
import { capitalize } from '../../../helpers/conversions/grammar';

const defaultState = {
  itemsPerPage: ITEMS_PER_PAGE,
  currentListPage: 1,
  lastRefresh: null,
  listItems: [],
  responseNameOptions: [],
  totalCount: 0,
  selectedResponseName: 'all',
};
const getDefaultState = () => {
  return { ...defaultState };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    // =========== Begin Filters
    setSelectedResponseName: (state, name) => {
      state.selectedResponseName = name;
    },
    setResponseNameOptions: (state, options) => {
      const allOption = [{ display: 'All Dialogues', value: 'all' }];
      state.responseNameOptions = allOption.concat(
        options.map((val) => ({
          display: capitalize(val),
          value: val,
        }))
      );
    },
    setItemsPerPage: (state, num) => {
      state.currentListPage = 1;
      state.itemsPerPage = num;
    },
    // =========== End Filters

    resetListState: (state) => {
      state.totalCount = 0;
      state.currentListPage = 1;
    },
    goToFirstPage: (state) => {
      state.currentListPage = 1;
    },
    goToLastPage: (state) => {
      state.currentListPage =
        1 + parseInt(state.totalCount / state.itemsPerPage);
    },
    nextPage: (state) => {
      state.currentListPage = state.currentListPage + 1;
    },
    prevPage: (state) => {
      state.currentListPage = state.currentListPage - 1;
    },
    setTotalCount: (state, count) => {
      state.totalCount = count;
    },
    setListItems: (state, items) => {
      state.listItems = items;
    },
    triggerListRefresh: (state) => {
      state.lastRefresh = new Date();
    },
  },
};
