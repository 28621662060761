var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "edit-row edit-row--link",
      class: { "parent-bg": !_vm.editingItem && _vm.hover && !_vm.childHover },
      on: {
        mouseleave: function($event) {
          _vm.hover = false
        },
        mouseover: function($event) {
          _vm.hover = true
        }
      }
    },
    [
      _c("div", { staticClass: "overline" }, [_vm._v("WEB PAGE")]),
      _vm._v(" "),
      _vm._l(_vm.links, function(link, index) {
        return _c("link-form-field", {
          key: link.link,
          attrs: {
            index: index,
            content: _vm.content,
            cancelItem: _vm.cancelItem,
            editingItem: _vm.editingItem,
            firstLinkIndex: _vm.firstLinkIndex,
            deleteItem: _vm.deleteItem,
            lastLinkIndex: _vm.lastLinkIndex,
            oldLink: link.link,
            oldTitle: link.title,
            oldThumbnail: link.thumbnail,
            oldReadMoreButtonText: link.readMoreButtonText,
            position: _vm.firstLinkIndex + index,
            editingPosition: _vm.editingPosition,
            startEditing: _vm.startEditing,
            saveItem: _vm.saveItem,
            swapItems: _vm.swapItems
          },
          on: { childHover: _vm.handleChildHover }
        })
      }),
      _vm._v(" "),
      !_vm.editingItem && _vm.hover && !_vm.childHover
        ? _c("hover-buttons", {
            staticClass: "hover-links",
            attrs: {
              activeUpArrow: _vm.activeUpArrow,
              activeDownArrow: _vm.activeDownArrow,
              cancelItem: _vm.cancelItem,
              confirmDel: _vm.confirmDel,
              onSwapUp: function() {
                return _vm.moveLinksUp()
              },
              onSwapDown: function() {
                return _vm.moveLinksDown()
              },
              startEditing: function() {},
              handleDel: function() {
                return _vm.confirmDel()
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }