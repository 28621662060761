<template>
  <div class="locale-time">
    <input disabled class="locale-time--input" type="time" :value="time" />
  </div>
</template>
<script>
export default {
  props: {
    time: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.locale-time--input {
  border: 0;
  box-shadow: 0;
  background: transparent;
  padding: 0;
  margin-left: -1px;
}
</style>
