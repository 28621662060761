var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-editor",
      class: { "hover-bg": !_vm.editingItem && !_vm.editingButtons }
    },
    [
      _c("div", { staticClass: "overline" }, [_vm._v("Button")]),
      _vm._v(" "),
      _c("chips-input", {
        attrs: {
          chipset: _vm.currentChippedButtons,
          disabled: !_vm.editingButtons,
          label: "Labels",
          "handle-chip-add": _vm.handleButtonAdd,
          "handle-icon-click": _vm.handleButtonDel,
          "helper-text": "Type button text and press enter to create button"
        }
      }),
      _vm._v(" "),
      _c("save-cancel", {
        attrs: {
          "disable-save": _vm.disableSave,
          "handle-cancel": _vm.handleCancel,
          "handle-update": _vm.handleUpdate,
          "old-val": this.buttons.length,
          "show-save-cancel": _vm.editingButtons,
          primaryActionCopy: this.buttons.length ? "Update" : "Add"
        }
      }),
      _vm._v(" "),
      !_vm.editingButtons && !_vm.editingItem
        ? _c(
            "div",
            { staticClass: "hover-buttons" },
            [
              !_vm.editingItem
                ? _c("icon", {
                    staticClass: "action-icon",
                    attrs: { icon: "edit" },
                    on: {
                      click: function() {
                        return _vm.setEditingButtons(!_vm.editingButtons)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("icon", {
                staticClass: "action-icon",
                attrs: { icon: "delete" },
                on: { click: _vm.confirmDel }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }