var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width full-height content-page" },
    [
      _c("confirm-modal", { attrs: { show: _vm.modal === "CONFIRM" } }),
      _vm._v(" "),
      _c("upload-size-modal", { attrs: { show: _vm.modal === "UPLOAD_SIZE" } }),
      _vm._v(" "),
      _c("error-modal", { attrs: { show: _vm.modal === "ERROR" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-width full-height content" },
        [
          _c(
            "page-header",
            { attrs: { header: "Content Service" } },
            [
              _c(
                "btn",
                {
                  staticClass: "round create",
                  attrs: { icon: "add", raised: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleNewContent()
                    }
                  }
                },
                [_vm._v("\n        Add to Tag\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("list-filters"),
          _vm._v(" "),
          _c("list-view", {
            attrs: {
              items: _vm.listItems,
              "load-more-data": _vm.loadMoreData,
              "waiting-for-data": _vm.waitingForData
            }
          }),
          _vm._v(" "),
          !!_vm.initialContent || _vm.editingContent
            ? _c(
                "side-sheet",
                {
                  staticClass: "side-sheet--content",
                  attrs: {
                    onClose: function() {
                      return _vm.handleCloseSideSheet()
                    }
                  }
                },
                [
                  _vm.editingContent ? _c("content-create") : _vm._e(),
                  _vm._v(" "),
                  !_vm.editingContent && _vm.initialContent
                    ? _c("content-read", {
                        attrs: { info: _vm.initialContent }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("message-preview", {
                    attrs: {
                      buttons: _vm.buttons,
                      buttonsHorizontal: _vm.buttonsHorizontal,
                      content: _vm.content,
                      firstLinkIndex: _vm.firstLinkIndex,
                      links: _vm.links,
                      linksHorizontal: _vm.linksHorizontal,
                      toggleButtonsPreview: _vm.toggleButtonsPreview,
                      toggleLinksPreview: _vm.toggleLinksPreview
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }