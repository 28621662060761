var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-headers full-width" }, [
    _c(
      "div",
      {
        staticClass: "list-header list-header--name list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("displayText")
          }
        }
      },
      [
        _vm._v("\n    NAME\n    "),
        _vm.orderBy.includes("displayText")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: {
                "sort-icon--downward": _vm.orderBy === "displayText.desc"
              },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "list-header list-header--status" }, [
      _vm._v("STATUS")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--next list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("sendDate")
          }
        }
      },
      [
        _vm._v("\n    " + _vm._s(_vm.dateTitle) + "\n    "),
        _vm.orderBy.includes("sendDate")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: { "sort-icon--downward": _vm.orderBy === "sendDate.desc" },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--created list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("createdAt")
          }
        }
      },
      [
        _vm._v("\n    CREATED\n    "),
        _vm.orderBy.includes("createdAt")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: {
                "sort-icon--downward": _vm.orderBy === "createdAt.desc"
              },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }