var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "menu-item" }, [
    _c(
      "div",
      { staticClass: "nav-icon", class: { active: _vm.activeHeader } },
      [
        _vm.iconSrc
          ? _c("icon", {
              staticClass: "menu-icon",
              attrs: { icon: _vm.iconSrc }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }