/* eslint-disable max-lines */
import {
  BROADCAST_SOURCES,
  BROADCAST_STATUSES,
} from '../../../constants/broadcasts';
import { dashToTitleCase } from '../../../helpers/conversions/grammar';
import {
  epochToListView,
  epochToLocaleInput,
  utcToEpoch,
  getScheduleViewable,
} from '../../../helpers/conversions/time/time';

const getBroadcastStatus = (broadcast, source) => {
  if (BROADCAST_SOURCES.BROADCAST === source) {
    return BROADCAST_STATUSES['SENT'];
  } else if (
    broadcast.runData &&
    broadcast.runData.nextRunTime &&
    0 < broadcast.runData.nextRunTime
  ) {
    return BROADCAST_STATUSES['SCHEDULED'];
  } else {
    return BROADCAST_STATUSES['FINISHED'];
  }
};

const getLastRunViewable = (source, broadcast) => {
  if (BROADCAST_SOURCES.BROADCAST === source) {
    const lastRun = {};
    lastRun.date = epochToListView(broadcast.createdAt);
    lastRun.time = epochToLocaleInput(utcToEpoch(broadcast.createdAt)).time;
    return lastRun;
  } else if (
    broadcast.runData &&
    broadcast.runData.lastRun &&
    broadcast.runData.lastRun.timestamp
  ) {
    const lastRunStamp = broadcast.runData.lastRun.timestamp;
    const lastRun = {};
    lastRun.time = lastRunStamp ? epochToLocaleInput(lastRunStamp).time : null;
    lastRun.date = lastRunStamp ? epochToListView(lastRunStamp) : null;

    return lastRun;
  } else {
    return 'N/A';
  }
};

const getNextSendViewable = (source, creator, status, broadcast) => {
  if (BROADCAST_STATUSES.SCHEDULED === status) {
    return {
      date: epochToListView(broadcast.runData.nextRunTime),
      time: epochToLocaleInput(broadcast.runData.nextRunTime).time,
    };

    // If no next send, then show last send
  } else {
    return getLastRunViewable(source, broadcast);
  }
};

const getSendDateEpoch = (status, broadcast) => {
  if (BROADCAST_STATUSES.SCHEDULED === status) {
    return broadcast.runData.nextRunTime;

    // If no next send, then show last send
  } else if (
    broadcast.runData &&
    broadcast.runData.lastRun &&
    broadcast.runData.lastRun.timestamp
  ) {
    const lastRunStamp = broadcast.runData.lastRun.timestamp;
    return lastRunStamp;

    // If schedule is finished or cancelled
  } else {
    return '';
  }
};

export const concatCampaignData = (campaign, app) => {
  const createdAtEpoch = utcToEpoch(campaign.createdAt);
  const createdAtViewable = epochToListView(createdAtEpoch).split(',')[0];
  const title = campaign.name;
  const source = BROADCAST_SOURCES.BROADCAST;
  const status = getBroadcastStatus(campaign, source);
  const subscriptionViewable = dashToTitleCase(campaign.subscription);
  const activeStop = BROADCAST_STATUSES.SCHEDULED === status;
  const activeRestart = false;
  const lastSendEpoch = utcToEpoch(campaign.lastBroadcastTimestamp);
  const lastSendViewable = {
    date: epochToListView(lastSendEpoch),
    time: epochToLocaleInput(lastSendEpoch).time,
  };
  return {
    ...campaign,
    app,
    activeStop,
    activeRestart,
    createdAtEpoch,
    createdAtViewable,
    lastSendViewable,
    status,
    subscriptionViewable,
    source,
    title,
  };
};

export const concatScheduleData = (broadcast) => {
  const appTitleCase = dashToTitleCase(broadcast.meta.app);
  const createdAtEpoch = utcToEpoch(broadcast.createdAt);
  const createdAtViewable = epochToListView(createdAtEpoch).split(',')[0];
  const title = broadcast.postBody.displayText;
  const updatedAtEpoch = utcToEpoch(broadcast.updatedAt);
  const updatedAtViewable = epochToListView(updatedAtEpoch).split(',')[0];
  const scheduleViewable = getScheduleViewable(broadcast);
  const source = BROADCAST_SOURCES['SCHEDULE'];
  const status = getBroadcastStatus(broadcast, source);
  const activeStop = BROADCAST_STATUSES['SCHEDULED'] === status;
  const activeRestart = false;
  const nextSendViewable = getNextSendViewable(
    source,
    broadcast.creator,
    status,
    broadcast
  );
  const sendDateEpoch = getSendDateEpoch(status, broadcast);
  const subscriptionViewable = dashToTitleCase(broadcast.postBody.subscription);
  return {
    ...broadcast,
    app: broadcast.meta.app,
    appTitleCase,
    activeStop,
    activeRestart,
    broadcastId: broadcast.id,
    createdAtEpoch,
    createdAtViewable,
    nextSendViewable,
    updatedAtEpoch,
    updatedAtViewable,
    sendDateEpoch,
    scheduleViewable,
    status,
    subscription: broadcast.postBody.subscription,
    subscriptionViewable,
    source,
    title,
  };
};
