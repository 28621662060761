<!-- eslint-disable max-lines -->
<template>
  <article class="media-uploader">
    <uploader
      :allow-preview="true"
      :editingSelf="true"
      :initialLink="''"
      :initialThumbnail="''"
      :initialType="''"
      @itemUpdated="uploaderUpdatedItem"
      @uploaderPreviewRemoved="previewLoaded = false"
      @uploaderPreviewLoaded="previewLoaded = true"
    />
    <thumbnail-options
      v-if="thumbnailOptions && !!thumbnailOptions.length && !uploading"
      helper="Applicable only when video does not autoplay"
      label="Select Thumbnail"
      :thumbnail-choices="thumbnailOptions"
      :value.sync="itemData.thumbnail"
      @selectOption="handleThumbnailSelect"
    />
    <text-field
      v-show="showTitle"
      class="title"
      :label="'Title'"
      :textarea="true"
      :disabled="false"
      :value.sync="title"
      @keyup="() => updateFormData('title')"
      filled
    />
  </article>
</template>
<script>
// import MediaFilters from '../media-filters.vue'
import TextField from 'gameon-components/src/components/text-field/text-field.vue';
import ThumbnailOptions from 'gameon-components/src/components/thumbnail-options/thumbnail-options.vue';
import Uploader from '../../../../uploader/uploader.vue';

import { FILTER_BY_OPTIONS } from '../../../../../state/content/constants';
// import { validateByFilter, filterMediaListBySize } from '../../../../../state/content/helpers'
// import { addContent, getContent, getContentMeta } from '../../../../../api'
// import { schemaFinder } from '../../../../../helpers/content/content'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { SNACK_TYPES, SNACK_CLASSES } from '../../../../../constants/snackbar';

export default {
  components: {
    // MediaFilters,
    TextField,
    ThumbnailOptions,
    Uploader,
  },
  data() {
    const excludeTagFilters = 4;
    return {
      endOfList: false,
      itemData: this.initialItem || '',
      filterBy: FILTER_BY_OPTIONS[0].value,
      filterByOptions: FILTER_BY_OPTIONS.slice(0, excludeTagFilters),
      preloadSelected: '',
      previewLoaded: false,
      preloadedContent: [],
      showTitle: false,
      uploaded: false,
      waitingForData: false,
      title: '',
    };
  },
  props: {
    updateFormData: {
      required: false,
      type: Function,
    },
  },
  computed: {
    ...mapGetters('uploader', ['uploading', 'file']),
    ...mapGetters('contentForm', ['editingItem']),
    ...mapState('uploader', ['file']),
    ...mapState('content', ['botTagOptions', 'contentBotsMeta']),
    bot() {
      return this.contentBotsMeta[0].displayName;
    },
    fileName() {
      if (this.file && this.file.name) {
        return this.file.name;
      }
      return '';
    },
    thumbnailOptions() {
      const MAX_THUMB_OPTIONS = 5;
      const thumbnailOptions = [];
      if (
        this.itemData &&
        this.itemData.thumbnail &&
        this.itemData.type.includes('video')
      ) {
        for (let i = 0; i < MAX_THUMB_OPTIONS; i = i + 1) {
          const nextThumb = this.itemData.thumbnail.replace(
            /(?:thumbnail\/)([0-9]+)/,
            `thumbnail/${i}`
          );
          thumbnailOptions.push(nextThumb);
        }
      }
      return thumbnailOptions;
    },
    // preloaded content
    // filteredContentOptions() {
    //   const CONTENT_OPTIONS = 6
    //   const selectedFilter = validateByFilter(this.filterBy)
    //   const filteredContent = this.preloadedContent.filter(contentItem => selectedFilter(contentItem))
    //   const sizeLimited = filterMediaListBySize(filteredContent)
    //   return sizeLimited.slice(0, CONTENT_OPTIONS)
    // },
    // showPreloadedContent() {
    //   const previousContent = !!(this.editingSelf && this.preloadedContent.length)
    //   return previousContent && !this.uploading && !this.uploaded
    // },
  },
  methods: {
    ...mapActions('broadcastPage', ['cancelItem', 'defocusEditor']),
    ...mapActions('uploader', ['handleCancel']),
    ...mapMutations('broadcastPage', ['setItem']),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('uploader', ['clearStates']),
    handleUpdate() {
      this.clearStates();
      this.previewLoaded = false;
      const entry = { ...this.itemData };
      this.setItem(entry);
      this.defocusEditor();
      const text = 'All updates saved';
      this.openSnack({
        customClass: SNACK_CLASSES.CUSTOM_SIDESHEET,
        type: SNACK_TYPES.SUCCESS,
        text,
      });
    },
    cancelUpload() {
      this.handleCancel();
      this.cancelItem();
    },
    gatherContentData(data, metaData, schema) {
      const content = {};
      content.createdAt = Date.now();
      content.link = data.link;
      content.size = metaData.size;
      content.mimeType = metaData.mimeType;
      content.width = metaData.width;
      content.height = metaData.height;

      if (schema.includes('video')) {
        content.duration = metaData.duration;
        content.thumbnail = data.thumbnail;
      }
      return content;
    },
    handleThumbnailSelect(selectedUrl) {
      this.selectedThumbnail = selectedUrl;
      this.updateFormData('thumbnail', selectedUrl);
    },
    async uploaderUpdatedItem(data) {
      this.uploaded = true;
      this.itemData = { ...this.itemData, ...data };
      this.updateFormData('link', data.link);
      this.updateFormData('thumbnail', data.thumbnail);
      this.updateFormData('type', data.type);
      this.showTitle = true;
      this.clearStates();
    },

    // preloaded content
    // addUploadToPreload(data) {
    //   const unique = 0 === this.preloadedContent.filter(c => c.body.link === data.link).length
    //   if (unique) {
    //     const preloadData = {
    //       source: 'recentUpload',
    //       body: {
    //         mimeType: data.type,
    //         link: data.link,
    //         thumbnail: data.thumbnail,
    //       },
    //     }
    //     const preloadDataUpdated = [...this.preloadedContent]
    //     preloadDataUpdated.unshift(preloadData)
    //     this.preloadedContent = preloadDataUpdated
    //   }
    // },
    // async getPreloadedContent() {
    //   const CONTENT_OPTIONS = 6
    //   const RECURSIVE = true
    //   const nextContent = await getContent(
    //     this.libraryBotsMeta[0].publishId,
    //     CONTENT_OPTIONS,
    //     RECURSIVE,
    //     this.preloadedContent.length,
    //   )
    //   if (CONTENT_OPTIONS > nextContent.length) this.endOfList = true
    //   this.preloadedContent.push(...nextContent)
    // },
    // setFilterBy(option) {
    //   this.filterBy = option
    // },
    // handlePreloadSelect(selected) {
    //   this.itemData = {
    //     type: selected.body.mimeType,
    //     link: selected.body.link,
    //     thumbnail: selected.body.thumbnail || selected.body.link,
    //   }
    //   this.previewLoaded = true
    // },
    // async waitForData(reset) {
    //   if (this.libraryBotsMeta.length) {
    //     this.waitingForData = true
    //     await this.getPreloadedContent(reset)
    //     this.waitingForData = false
    //   }
    // },
  },
  // async filteredContentOptions() {
  //   if (this.endOfList) return

  //   const minimum = 6
  //   if (minimum > this.filteredContentOptions.length) await this.waitForData()
  // },
  // async libraryBotsMeta() {
  //   if (this.loggedIn) {
  //     await this.waitForData(true)
  //   }
  // },
  // uploading() {
  //   this.setFilterBy(FILTER_BY_OPTIONS[0].value)
  // },
  // },
  // async mounted() {
  //   this.waitForData()
  // },
};
</script>
<style lang="scss" scoped>
@import './media-upload.scss';
</style>
