var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "content-read" },
    [
      _c("icon-btn", {
        staticClass: "edit-btn form-icon",
        attrs: { icon: "edit" },
        on: { click: _vm.handleEdit }
      }),
      _c(
        "more-menu",
        { attrs: { button: true } },
        [_c("more-content-options", { staticClass: "option-btn" })],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "editable-fields" }, [
        _c("h4", { staticClass: "content-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.info.title || (_vm.info.body && _vm.info.body.title)) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _vm.info.body && _vm.info.body.description
          ? _c("div", { staticClass: "description" }, [
              _vm._v("\n      " + _vm._s(_vm.info.body.description) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tags" },
          _vm._l(_vm.info.tags, function(tag) {
            return _c("span", { key: tag.id }, [_vm._v(_vm._s(tag.name))])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("section", { staticClass: "specs full-width" }, [
        _vm.info.contentDescriptor
          ? _c(
              "div",
              { staticClass: "field-value-pair full-width descriptors" },
              [
                _c("div", { staticClass: "field" }, [_vm._v("Type")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "value" },
                  _vm._l(_vm.descriptorsList, function(descriptor) {
                    return _c("div", { key: descriptor }, [
                      _vm._v("\n          " + _vm._s(descriptor) + "\n        ")
                    ])
                  }),
                  0
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.info.fileType
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              _c("div", { staticClass: "field" }, [_vm._v("Type")]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.fileType.toUpperCase()))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "field-value-pair full-width" }, [
          _c("div", { staticClass: "field" }, [_vm._v("Last Edited")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.updatedAtViewable))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field-value-pair full-width" }, [
          _c("div", { staticClass: "field" }, [_vm._v("Created")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.createdAtViewable))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }