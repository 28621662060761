<template>
  <div class="media-content">
    <img class="invisible-load-detector" :src="src" @load="handleLoad" />
    <div
      v-if="src"
      class="media-preview"
      :style="[
        !!(allowPreview && loaded)
          ? { 'background-image': `url('${src}')` }
          : { background: 'transparent' },
      ]"
    >
      <icon v-if="allowPreview && loaded && video" icon="play_circle_filled" />
    </div>
  </div>
</template>
<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      loaded: false,
    };
  },
  props: {
    allowPreview: {
      required: true,
      type: Boolean,
    },
    src: {
      required: true,
      type: String,
    },
    video: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  methods: {
    handleLoad() {
      this.loaded = true;
      this.$emit('previewLoaded');
    },
  },
};
</script>
<style lang="scss" scoped>
.media-content {
  height: 208px;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.media-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100%);
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .material-icons {
    color: $white-medium-emphasis;
    font-size: 64px;
  }
}
.invisible-load-detector {
  display: none;
}
</style>
