var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "broadcast-row" },
    [
      _c(
        "m-list-item",
        { staticClass: "full-width", on: { click: _vm.handleClick } },
        [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.group.title ? _vm.group.title : "Unnamed broadcast"
                ) +
                "\n      "
            ),
            _c("div", { staticClass: "item-file-type" }, [
              _vm._v("\n        " + _vm._s(_vm.campaignName) + "\n      ")
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-status" },
            [_c("status-chip", { attrs: { status: _vm.group.status } })],
            1
          ),
          _vm._v(" "),
          _vm.group.status === "sent"
            ? _c(
                "div",
                { staticClass: "item-next" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.group.lastSendViewable.date) +
                      "\n      "
                  ),
                  _vm.group.lastSendViewable.time
                    ? _c("locale-time", {
                        attrs: { time: _vm.group.lastSendViewable.time }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "item-next" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.group.nextSendViewable.date || "N/A")
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _vm.group.nextSendViewable.time
                    ? _c("locale-time", {
                        attrs: { time: _vm.group.nextSendViewable.time }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.group.status === "scheduled" &&
                  _vm.group.schedule[0].repeat
                    ? _c(
                        "div",
                        { staticClass: "second-line" },
                        [
                          _c("icon", {
                            staticClass: "repeat-icon",
                            attrs: { icon: "loop" }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.truncateRepeatViewable) +
                              "\n      "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
          _vm._v(" "),
          _c("div", { staticClass: "item-created" }, [
            _vm._v("\n      " + _vm._s(_vm.group.createdAtViewable) + "\n    ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }