import { previewableFormContent } from '../../../../helpers/content/content';
import { CONTENT_TYPES } from '../../../../constants/content';
import { createUUID } from '../../../../helpers/uuid/uuid';

export default {
  getters: {
    chatPreviewData: (state) => {
      const questionMessages = state.questions
        .filter((q) => previewableFormContent(q))
        .map((content, i) => {
          return {
            speakerId: 'endUser',
            content: [content],
            left: true,
            id: createUUID(),
            customClass: i ? 'phrase' : 'question',
          };
        });
      const flatAnswerContent = state.message.filter((q) =>
        previewableFormContent(q)
      );
      const flatAnswerContentModes = flatAnswerContent.map(
        (content) => content.mode
      );
      const hasLinks = 0 <= flatAnswerContentModes.indexOf(CONTENT_TYPES.link);
      const firstLinkIndex = flatAnswerContentModes.indexOf(CONTENT_TYPES.link);
      const lastLinkIndex = flatAnswerContentModes.lastIndexOf(
        CONTENT_TYPES.link
      );
      const preLinks = hasLinks
        ? flatAnswerContent.slice(0, firstLinkIndex)
        : flatAnswerContent;
      const links = hasLinks
        ? [
            {
              mode: 'links',
              links: flatAnswerContent.slice(firstLinkIndex, lastLinkIndex + 1),
            },
          ]
        : [];
      const postLinks = hasLinks
        ? flatAnswerContent.slice(lastLinkIndex + 1)
        : [];
      const buttonsContent = state.buttons.length
        ? [
            {
              mode: 'buttons',
              buttons: state.buttons,
            },
          ]
        : [];
      const answerContent = [
        ...preLinks,
        ...links,
        ...postLinks,
        ...buttonsContent,
      ];
      const answerMessage = [
        {
          speakerId: 'admin',
          content: answerContent,
          left: false,
          id: createUUID(),
        },
      ];
      return [...questionMessages, ...answerMessage];
    },
    participants: (state, getters, rootState) => {
      const brandAvatar = rootState.user.botData.display.avatar;
      const endUserAvatar =
        'https://gameon-technology-public-uploads-dashboard.s3-us-west-2.amazonaws.com/end-user-avatar.png';
      return {
        admin: {
          avatar: brandAvatar,
        },
        bot: {
          avatar: brandAvatar,
        },
        endUser: {
          avatar: endUserAvatar,
        },
      };
    },
  },
};
