var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-view" },
    [
      _c("list-headers"),
      _vm._v(" "),
      _vm.items.length
        ? _c(
            "m-list",
            { staticClass: "full-width" },
            [
              _c(
                "div",
                { staticClass: "list-view__rows" },
                _vm._l(_vm.items, function(response) {
                  return _c(
                    "m-list-item",
                    {
                      key: response.id,
                      staticClass: "full-width",
                      on: {
                        click: function($event) {
                          return _vm.handleRowClick(response)
                        }
                      }
                    },
                    [
                      _c(
                        "section",
                        { staticClass: "answer" },
                        _vm._l(
                          _vm.getContentDescriptors(response.content),
                          function(descriptor) {
                            return _c(
                              "div",
                              {
                                key: descriptor,
                                staticClass: "answer__content-types"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s("" + descriptor) +
                                    "\n          "
                                )
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c("section", { staticClass: "tags" }, [
                        _vm._v(
                          "\n          " + _vm._s(response.name) + "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("section", { staticClass: "edited" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getViewableDate(response.updatedAt)) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("section", { staticClass: "created" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getViewableDate(response.createdAt)) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "scroll-pseudo-padding" }),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        : !_vm.items.length && !_vm.waitingForData
        ? _c("empty-message", {
            attrs: {
              message:
                "The combination of search and sort filters yielded no results. Please try another."
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          waitingForData: _vm.waitingForData,
          rowCountOnCurrentPage: _vm.items.length
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }