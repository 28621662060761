const defaultState = {
  actionButton: '',
  cancelButton: '',
  modal: null,
  modalText: '',
  modalHeader: '',
  pendingAction: () => {},
};

export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    closeModal: (state) => {
      state.modal = null;

      // reset optional fields
      state.modalHeader = '';
      state.modalText = '';
      state.actionButton = '';
      state.cancelButton = '';
    },
    showModal: (
      state,
      {
        actionButton,
        cancelButton,
        modalHeader,
        modalText,
        modalType,
        pendingAction,
      }
    ) => {
      const modalTypes = {
        CONFIRM: 'CONFIRM',
        ERROR: 'ERROR',
        UPLOAD_SIZE: 'UPLOAD_SIZE',
      };
      state.modal = modalTypes[modalType];
      state.actionButton = actionButton;
      state.cancelButton = cancelButton;
      state.modalHeader = modalHeader;
      state.modalText = modalText;
      state.pendingAction = pendingAction;
    },
    resetModalStates: (state) => {
      state = { ...defaultState };
    },
  },
};
