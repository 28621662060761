var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "article",
        {
          staticClass: "broadcast-editor full-height",
          class: { "broadcast-editor--scrolling": _vm.scrolling }
        },
        [
          _c("schedule-modal", {
            attrs: {
              "final-broadcast-title": _vm.finalBroadcastTitle,
              "is-dialog-open": _vm.showSchedule,
              "close-modal": function() {
                return (_vm.showSchedule = false)
              },
              "initial-broadcast": _vm.initialBroadcast,
              "send-now": _vm.sendNow
            },
            on: { close: _vm.handleCloseSchedule }
          }),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "top-menu padding-tiny-around" },
            [
              _c(
                "icon-btn",
                {
                  staticClass: "form-icon close",
                  on: { click: _vm.handleClose }
                },
                [_c("icon", { attrs: { icon: "close" } })],
                1
              ),
              _vm._v(" "),
              _c("section", { staticClass: "broadcast-title" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.broadcastTitle,
                      expression: "broadcastTitle"
                    }
                  ],
                  staticClass: "broadcast-title-input",
                  attrs: { placeholder: _vm.titlePlaceholder, type: "text" },
                  domProps: { value: _vm.broadcastTitle },
                  on: {
                    click: _vm.handleTitleClick,
                    blur: _vm.handleTitleBlur,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.broadcastTitle = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "btn",
                {
                  staticClass: "send-now-btn menu-btn",
                  attrs: { disabled: !_vm.allowSend },
                  on: { click: _vm.handleSendNowClick }
                },
                [
                  _c("icon", { staticClass: "small", attrs: { icon: "send" } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "send-now-text" }, [
                    _vm._v("Send Now")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "btn",
                {
                  staticClass: "schedule-btn menu-btn",
                  attrs: { disabled: !_vm.allowSend },
                  on: { click: _vm.schedule }
                },
                [
                  _c("icon", {
                    staticClass: "small",
                    attrs: { icon: "access_time" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "schedule-text" }, [
                    _vm._v("Schedule")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "creation-wrap" }, [
            _c("div", { staticClass: "hide-top-shadow" }),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "fixed-menu" },
              [
                _c("multi-select", {
                  attrs: {
                    "display-values": _vm.defaultBotDisplay,
                    "handle-primary-val": function() {},
                    "handle-secondary-val": _vm.setAudience,
                    "leading-icon": "search",
                    options: _vm.audienceOptions,
                    "primary-val": _vm.botData.key,
                    "secondary-val": _vm.audience
                  }
                }),
                _vm._v(" "),
                _c("section", { staticClass: "buttons" }, [
                  _c(
                    "div",
                    { staticClass: "add-content" },
                    [
                      _c("fab", {
                        staticClass: "purple-fab",
                        attrs: { disabled: _vm.editingItem, icon: "title" },
                        on: {
                          click: function($event) {
                            return _vm.addAndScroll("text")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "fab-desc" }, [_vm._v("Text")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "add-content" },
                    [
                      _c("fab", {
                        staticClass: "theme-primary",
                        attrs: {
                          disabled: _vm.editingItem,
                          icon: "insert_photo"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addAndScroll("media")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "fab-desc" }, [_vm._v("Media")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "add-content" },
                    [
                      _c("fab", {
                        staticClass: "theme-secondary",
                        attrs: { disabled: _vm.editingItem, icon: "link" },
                        on: {
                          click: function($event) {
                            return _vm.addAndScroll("link")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "fab-desc" }, [_vm._v("Link")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "add-content" },
                    [
                      _c("fab", {
                        staticClass: "orange-fab",
                        attrs: {
                          disabled: _vm.editingItem,
                          icon: "crop_square"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addAndScroll("buttons")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "fab-desc" }, [_vm._v("Button")])
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "content-items",
                on: { scroll: _vm.handleScroll }
              },
              [
                _c(
                  "section",
                  { staticClass: "content-row-wrap" },
                  _vm._l(_vm.content, function(item, index) {
                    return _c("message-form-field", {
                      key: item.text,
                      staticClass: "edit-row",
                      attrs: {
                        id:
                          _vm.editingPosition === index ? "scroll-target" : "",
                        content: _vm.content,
                        cancelItem: _vm.cancelItem,
                        deleteAllLinks: _vm.deleteAllLinks,
                        editingPosition: _vm.editingPosition,
                        finishEditingItem: _vm.finishEditingItem,
                        item: item,
                        deleteItem: _vm.deleteItem,
                        editingItem: _vm.editingItem,
                        links: _vm.links,
                        lastLinkIndex: _vm.lastLinkIndex,
                        firstLinkIndex: _vm.firstLinkIndex,
                        position: index,
                        startEditing: _vm.startEditing,
                        saveItem: _vm.saveItem,
                        swapItems: _vm.swapItems,
                        moveLinksUp: _vm.moveLinksUp,
                        moveLinksDown: _vm.moveLinksDown
                      }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  { staticClass: "edit-row" },
                  [
                    _vm.editingButtons || _vm.buttons.length
                      ? _c("quick-reply-form-field", {
                          attrs: {
                            buttons: _vm.buttons,
                            id: _vm.editingButtons ? "scroll-target" : "",
                            editingButtons: _vm.editingButtons,
                            editingItem: _vm.editingItem,
                            deleteButtons: _vm.deleteButtons,
                            setButtons: _vm.setButtons,
                            setEditingButtons: _vm.setEditingButtons
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("preview", {
            attrs: {
              buttonsPresent: _vm.buttonsPresent,
              buttonsHorizontal: _vm.buttonsHorizontal,
              chatPreviewData: _vm.chatPreviewData,
              linksPresent: _vm.linksPresent,
              linksHorizontal: _vm.linksHorizontal,
              toggleButtonsPreview: _vm.toggleButtonsPreview,
              toggleLinksPreview: _vm.toggleLinksPreview,
              participants: _vm.participants
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }