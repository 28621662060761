var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media-content" }, [
    _c("img", {
      staticClass: "invisible-load-detector",
      attrs: { src: _vm.src },
      on: { load: _vm.handleLoad }
    }),
    _vm._v(" "),
    _vm.src
      ? _c(
          "div",
          {
            staticClass: "media-preview",
            style: [
              !!(_vm.allowPreview && _vm.loaded)
                ? { "background-image": "url('" + _vm.src + "')" }
                : { background: "transparent" }
            ]
          },
          [
            _vm.allowPreview && _vm.loaded && _vm.video
              ? _c("icon", { attrs: { icon: "play_circle_filled" } })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }