import Vue from 'vue';
import Login from './components/login/login.vue';
import Container from './developer/container.vue';

import store from './state';

new Vue({
  store,
  el: '#app',
  render(h) {
    return h('div', [h(Login), h(Container)]);
  },
});
