export default {
  namespaced: true,
  state: {
    editing: false,
    selected: null,
    dateFormat: 'locale',
  },
  mutations: {
    closeSideSheet: (state) => {
      state.editing = false;
      state.selected = null;
    },
    setEditing: (state, payload) => {
      state.editing = payload;
    },
    setSelected: (state, payload) => {
      state.selected = payload;
    },
    setDateFormat: (state, format) => {
      const dateOptions = {
        locale: 'locale',
        GMT: 'GMT',
      };
      if (dateOptions[format]) {
        state.dateFormat = dateOptions[format];
      }
    },
  },
};
