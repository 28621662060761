import { get, post } from './axios-wrapper';

export const getKnowledgeBase = async (app) =>
  (await get(`/v1/qna/${app}`)).data;

export const postAnswers = async (app, question) =>
  (await post('/v1/qna/answers', { app, question })).data;

export const upsertQnaPair = async (app, pair) =>
  (await post('/v1/qna/upsertQnaPair', { app, pair })).data;

export const deleteQnaPair = async (app, id) => {
  return (await post('/v1/qna/deleteQnaPair', { app, id })).data;
};
