<template>
  <div class="full-width pagination">
    <span class="pagination__rows-per-page__label">Rows per page</span>
    <single-select
      class="pagination__rows-per-page__dropdown"
      :handle-choice="setItemsPerPage"
      :options="rowCountOptions"
      :upstream-val="itemsPerPage"
    />
    <div class="pagination__range">
      {{ beginRange }} - {{ endRange }}
      <span class="pagination__range__of">of</span> {{ totalCount }}
    </div>

    <icon
      v-if="currentListPage === 1"
      class="pagination__arrow pagination__arrow--disabled"
      icon="first_page"
    />
    <icon-btn
      v-else
      class="pagination__arrow"
      icon="first_page"
      @click="goToFirstPage"
    />
    <icon
      v-if="currentListPage === 1"
      class="pagination__arrow pagination__arrow--disabled"
      icon="keyboard_arrow_left"
    />
    <icon-btn
      v-else
      class="pagination__arrow"
      icon="keyboard_arrow_left"
      @click="prevPage"
    />
    <icon
      v-if="reachedLastPage"
      class="pagination__arrow pagination__arrow--disabled"
      icon="keyboard_arrow_right"
    />
    <icon-btn
      v-else
      class="pagination__arrow"
      icon="keyboard_arrow_right"
      @click="nextPage"
    />
    <icon-btn
      v-if="!reachedLastPage"
      class="pagination__arrow"
      icon="last_page"
      @click="goToLastPage"
    />
    <icon
      v-else
      class="pagination__arrow pagination__arrow--disabled"
      icon="last_page"
    />
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import IconBtn from 'gameon-components/src/components/icon-btn/icon-btn.vue';
import SingleSelect from 'gameon-components/src/components/single-select/single-select.vue';

export default {
  data() {
    return {
      rowCountOptions: [
        {
          display: 10,
          value: 10,
        },
        {
          display: 25,
          value: 25,
        },
        {
          display: 50,
          value: 50,
        },
      ],
    };
  },
  components: {
    Icon,
    IconBtn,
    SingleSelect,
  },
  props: {
    rowCountOnCurrentPage: {
      type: Number,
      required: true,
    },
    waitingForData: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('responseManagerList', [
      'currentListPage',
      'itemsPerPage',
      'totalCount',
    ]),
    beginRange() {
      return (this.currentListPage - 1) * this.itemsPerPage + 1;
    },
    endRange() {
      return this.beginRange + this.rowCountOnCurrentPage - 1;
    },
    reachedLastPage() {
      if (!this.totalCount) return false;
      return this.totalCount === this.endRange;
    },
  },
  methods: {
    ...mapMutations('responseManagerList', [
      'goToFirstPage',
      'goToLastPage',
      'nextPage',
      'prevPage',
      'setItemsPerPage',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -12px;
  z-index: 2;
  padding-right: 80px;
  background-color: #fafafa;
  color: #333333;

  justify-content: flex-end;
  padding-right: 165px;
}
.pagination__range,
.pagination__rows-per-page__label {
  font-size: 14px;
  line-height: 14px;
  padding: 0 12px;
  position: relative;
}
.pagination__range {
  padding: 0 48px;
  &__of {
    padding: 0 6px;
  }
}
.pagination__arrow {
  opacity: 0.8;
  color: #333333;
}
.pagination__arrow--disabled {
  opacity: 0.38;
  padding: 12px;
  position: relative;
  top: 1px;
}
/deep/ .single-select .shadow {
  bottom: 0;
  flex-direction: column-reverse;
  font-size: 14px;
  top: initial;
}
/deep/ .single-select .option {
  color: #333333;
  font-size: 14px;
}
/deep/ .chosen-value {
  color: #333333;
}
/deep/ .chosen-value-wrap {
  border-radius: 8px;
}
/deep/ .menu-open .chosen-value-wrap {
  border: none;
  border-radius: 0;
}
</style>
