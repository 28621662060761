var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-headers full-width" }, [
    _c(
      "div",
      {
        staticClass: "list-header list-header--name",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("id")
          }
        }
      },
      [_vm._v("\n    USER ID\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--status",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("status")
          }
        }
      },
      [_vm._v("\n    STATUS\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--edited",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("lastModified")
          }
        }
      },
      [_vm._v("\n    LAST REPLIED\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--created",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("submitted")
          }
        }
      },
      [_vm._v("\n    STATUS CHANGE\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }