import dayjs from 'dayjs';

const MILLISECONDS_PER_SECOND = 1000;

export const epochify = (date, time, originalFormat) => {
  switch (originalFormat) {
    case 'GMT':
      const YYYY = 0;
      const MM = 1;
      const DD = 2;
      const hh = 0;
      const mm = 1;
      const splittedDate = date.split('-');
      const splittedTime = time.split(':');
      return Date.UTC(
        splittedDate[YYYY],
        splittedDate[MM] - 1,
        splittedDate[DD],
        splittedTime[hh],
        splittedTime[mm]
      );
    case 'locale':
      const epochDate = Date.parse(`${date.replace(/-/g, '/')} ${time}`);
      return epochDate;
  }
};

export const epochToLocaleInput = (epoch) => {
  const doubleDigits = (num) => {
    const strNum = num.toString();
    const requiredDigits = 2;
    if (requiredDigits > strNum.length) return `0${strNum}`;
    return strNum;
  };

  const dateData = dayjs(epoch);
  const year = doubleDigits(dateData.year());
  const month = doubleDigits(dateData.month() + 1);
  const day = doubleDigits(dateData.date());
  const time = `${doubleDigits(dateData.hour())}:${doubleDigits(
    dateData.minute()
  )}:00`;

  return {
    date: `${year}-${month}-${day}`,
    time,
  };
};

export const epochToUTCInput = (epoch) => {
  const date = new Date(parseInt(epoch));
  const dateTimeGMT = date.toISOString();

  // indices of dateTimeGMT string
  const dateTimeSeparator = 10;
  const endIndex = 19;

  const inputDate = dateTimeGMT.slice(0, dateTimeSeparator);
  const inputTime = dateTimeGMT.slice(dateTimeSeparator + 1, endIndex);
  return {
    date: inputDate,
    time: inputTime,
  };
};

export const epochToInput = (epoch, format) => {
  switch (format) {
    case 'GMT':
      return epochToUTCInput(epoch);
    case 'locale':
      return epochToLocaleInput(epoch);
    default:
      return epochToLocaleInput(epoch);
  }
};

export const epochToLocale = (epoch) => {
  const date = new Date(parseInt(epoch));
  return date.toLocaleString('en-US', { hour12: false });
};

export const epochToUTC = (epoch) => {
  const date = new Date(parseInt(epoch));
  return date.toUTCString();
};

export const utcToEpoch = (utcStr) => {
  const date = new Date(utcStr);
  return Math.floor(date);
};

export const inputToDayOfWeek = (inputDate, inputTime) => {
  const inputType = 'locale';
  const epoch = epochify(inputDate, inputTime, inputType);
  return getDayOfWeek(epoch);
};

export const inputToLocale = (date) => {
  const essentialLength = 16;
  return new Date(date + 'T00:00').toDateString().slice(0, essentialLength);
};

// List View is in locale timezone
export const epochToListView = (epoch) => {
  const dateArr = new Date(epoch).toString().split(' ');
  const POSITION = {
    day: 2,
    month: 1,
    year: 3,
  };
  const finalStr = `${dateArr[POSITION.day]} ${dateArr[POSITION.month]} ${
    dateArr[POSITION.year]
  }`;
  return finalStr;
};

export const secondsToDuration = (givenSeconds) => {
  const digits = 2;
  const dateObj = new Date(givenSeconds * MILLISECONDS_PER_SECOND);
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  const duration =
    minutes.toString().padStart(digits, '0') +
    ':' +
    seconds.toString().padStart(digits, '0');
  return duration;
};

// A job can have multiple schedules
// Send back an array of repeat strings
export const getScheduleViewable = (job) => {
  const updatedJob = { ...job };
  if (updatedJob.schedule && updatedJob.schedule.length) {
    for (let i = 0; i < updatedJob.schedule.length; i = i + 1) {
      const schedule = updatedJob.schedule[i];
      const sentence = getRepeatSentence(schedule);
      schedule.startingDateViewable = epochToListView(schedule.starting);
      schedule.startingTimeViewable = epochToLocaleInput(
        schedule.starting
      ).time;
      schedule.endingDateViewable = schedule.ending
        ? epochToListView(schedule.ending)
        : '';
      schedule.endingTimeViewable = schedule.ending
        ? epochToLocaleInput(schedule.ending).time
        : '';
      schedule.repeatViewable = sentence;
    }
  }
  return updatedJob.schedule;
};

export const getRepeatSentence = (schedule) => {
  if (!schedule.repeat) return '';

  const units = 1 < schedule.quantity ? ` ${schedule.unit}s` : schedule.unit;
  const quantity = 1 < schedule.quantity ? schedule.quantity : '';
  const sentence = `Repeats every ${quantity}${units}`;

  if ('week' === schedule.unit) {
    const weeklySentence = `Repeats every 1wk on ${getDayOfWeek(
      schedule.starting
    )}`;
    return weeklySentence;
  } else {
    return sentence;
  }
};

export const getDayOfWeek = (epoch) => {
  const dayCutOff = 3;
  const date = new Date(epoch);
  const dayOfWeek = date.toString().substr(0, dayCutOff);
  return getFullDay(dayOfWeek);
};

export const getFullDay = (dayAbbreviation) => {
  const days = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
  };
  return days[dayAbbreviation];
};

export const timeFormatAMPM = (date) => {
  const hourCutoff = 12;
  const minuteCorrection = 10;
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= hourCutoff ? 'PM' : 'AM';
  hours = hours % hourCutoff;
  hours = hours || hourCutoff;
  minutes = minutes < minuteCorrection ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};
