var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal", class: { open: !_vm.loggedIn } }, [
    _c("div", { staticClass: "modal-content grid-4-center_md-3_sm-1" }, [
      _c("div", { staticClass: "col" }, [
        _c("form", { staticClass: "grid", on: { submit: _vm.login } }, [
          _c(
            "div",
            { staticClass: "gameon-login" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  label: "Username",
                  value: _vm.userName,
                  valid: !_vm.loginAttemptFailed,
                  "validation-msg": true,
                  helper: "Invalid username or password",
                  filled: ""
                },
                on: {
                  "update:value": function($event) {
                    _vm.userName = $event
                  },
                  change: _vm.removeErrorMessage,
                  keydown: _vm.removeErrorMessage
                }
              }),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  value: _vm.password,
                  filled: "",
                  helper: "Invalid username or password",
                  label: "Password",
                  type: "password",
                  valid: !_vm.loginAttemptFailed,
                  "validation-msg": true
                },
                on: {
                  "update:value": function($event) {
                    _vm.password = $event
                  },
                  change: _vm.removeErrorMessage,
                  keydown: _vm.removeErrorMessage
                }
              }),
              _vm._v(" "),
              _c(
                "btn",
                {
                  staticClass: "create full-width submit-btn",
                  attrs: {
                    raised: "",
                    type: "submit",
                    disabled: !_vm.allowSignIn
                  }
                },
                [_vm._v("\n            Sign In\n          ")]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "modal-title" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", { attrs: { src: require("../../assets/on_logo.svg") } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }