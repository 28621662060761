import cleanDeep from 'clean-deep';
import { checkLinkValidity, opengraphMetadata } from '../../../api';
import { NONE_DETECTED } from './constants';

export default {
  state: {
    links: {
      thumbError: false,
      processingInFlight: false,
      valid: false,
      processed: false,
    },
    loadReady: false,
  },
  getters: {
    allowLinkSave(state) {
      return !!(
        NONE_DETECTED !== state.itemData.thumbnail &&
        NONE_DETECTED !== state.itemData.title &&
        !state.links.thumbError &&
        state.itemData.thumbnail &&
        state.itemData.title &&
        state.links.valid &&
        !state.links.processingInFlight
      );
    },
    linkChanged(state) {
      return !!(
        state.itemData.link && state.itemData.link !== state.prevItem.link
      );
    },
    merged(state) {
      return Object.assign({}, state.prevItem, cleanDeep(state.itemData));
    },
  },
  mutations: {
    setLinkData: (state, updatedData) => {
      state.links = updatedData;
    },
    setItemData: (state, updatedData) => {
      state.itemData = updatedData;
    },
    setLoadReady: (state, bool) => {
      state.loadReady = bool;
    },
  },
  actions: {
    evalAllowLoad: ({ commit, getters, state }) => {
      const updatedData =
        getters.linkChanged && !state.links.processingInFlight;
      commit('setLoadReady', updatedData);
    },
    checkLinkValidity: async ({ commit, state }) => {
      let valid;
      try {
        valid = 'ok' === (await checkLinkValidity(state.itemData.link)).status;
        commit('setLinkData', { ...state.links, valid });
      } catch (err) {
        commit('setLinkData', { ...state.links, valid: false });
      } finally {
        commit('setLinkData', { ...state.links, processed: true });
      }
    },
    // previous item has to be valid to Save
    // so this action sets state of edited item to valid
    setPrevValidity: async ({ commit, state }) => {
      commit('setLinkData', { ...state.links, valid: true, processed: true });
    },
    correctUrl: async ({ commit, state }) => {
      const thumbnailDetected = NONE_DETECTED !== state.itemData.thumbnail;
      const missingHttp = !/^http/.test(state.itemData.thumbnail);
      return new Promise((resolve) => {
        const updatedItemData = { ...state.itemData };
        if (missingHttp && thumbnailDetected) {
          const correctedUrl =
            'http://' + state.itemData.thumbnail.replace(/.*(?=www)/, '');
          updatedItemData.thumbnail = correctedUrl;
          commit('setItemData', updatedItemData);
          resolve();
        } else {
          resolve();
        }
      });
    },
    process: async ({ state, commit, dispatch }) => {
      // Load the link metadata
      commit('setLoadReady', false);
      commit('setLinkData', { ...state.links, processingInFlight: true });
      await dispatch('checkLinkValidity');
      if (!state.links.valid) {
        commit('setLoadReady', true);
        commit('setLinkData', { ...state.links, processingInFlight: false });
        return;
      }
      // Do form validation
      let title;
      let thumbnail;
      try {
        const meta = await opengraphMetadata(state.itemData.link);

        title = meta.ogTitle || NONE_DETECTED;
        thumbnail = (meta.ogImage || {}).url || NONE_DETECTED;
        if (Array.isArray(thumbnail)) {
          thumbnail = thumbnail.shift();
        }
        commit('setItemData', { ...state.itemData, title, thumbnail });
        commit('setLinkData', { ...state.links, processingInFlight: false });

        await dispatch('correctUrl');
      } catch (e) {
        title = NONE_DETECTED;
        thumbnail = NONE_DETECTED;
        commit('setLinkData', { ...state.links, title, thumbnail });
      } finally {
        await dispatch('checkLinkValidity');
      }
    },
  },
};
