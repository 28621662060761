var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width pagination" },
    [
      _c("span", { staticClass: "pagination__rows-per-page__label" }, [
        _vm._v("Rows per page")
      ]),
      _vm._v(" "),
      _c("single-select", {
        staticClass: "pagination__rows-per-page__dropdown",
        attrs: {
          "handle-choice": _vm.setBroadcastsPerPage,
          options: _vm.rowCountOptions,
          "upstream-val": _vm.broadcastsPerPage
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "pagination__range" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.beginRange) +
            " - " +
            _vm._s(_vm.endRange) +
            "\n    "
        ),
        _c("span", { staticClass: "pagination__range__of" }, [_vm._v("of")]),
        _vm._v(" " + _vm._s(_vm.totalCount) + "\n  ")
      ]),
      _vm._v(" "),
      _vm.currentBroadcastListPage === 1
        ? _c("icon", {
            staticClass: "pagination__arrow pagination__arrow--disabled",
            attrs: { icon: "first_page" }
          })
        : _c("icon-btn", {
            staticClass: "pagination__arrow",
            attrs: { icon: "first_page" },
            on: { click: _vm.goToFirstPage }
          }),
      _vm._v(" "),
      _vm.currentBroadcastListPage === 1
        ? _c("icon", {
            staticClass: "pagination__arrow pagination__arrow--disabled",
            attrs: { icon: "keyboard_arrow_left" }
          })
        : _c("icon-btn", {
            staticClass: "pagination__arrow",
            attrs: { icon: "keyboard_arrow_left" },
            on: { click: _vm.handlePrevClick }
          }),
      _vm._v(" "),
      _vm.reachedLastPage
        ? _c("icon", {
            staticClass: "pagination__arrow pagination__arrow--disabled",
            attrs: { icon: "keyboard_arrow_right" }
          })
        : _c("icon-btn", {
            staticClass: "pagination__arrow",
            attrs: { icon: "keyboard_arrow_right" },
            on: { click: _vm.handleNextClick }
          }),
      _vm._v(" "),
      !_vm.reachedLastPage
        ? _c("icon-btn", {
            staticClass: "pagination__arrow",
            attrs: { icon: "last_page" },
            on: { click: _vm.goToLastPage }
          })
        : _c("icon", {
            staticClass: "pagination__arrow pagination__arrow--disabled",
            attrs: { icon: "last_page" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }