<!-- eslint-disable max-lines -->
<template>
  <form-dialog
    v-show="isDialogOpen"
    :value="isDialogOpen"
    header="Schedule broadcast"
    @closed="onDialogClosed"
  >
    <template slot="body" class="form-body">
      <section class="body2 audience-desc">
        Schedule this broadcast to <span class="bolder">{{ audience }}</span>
      </section>
      <text-field
        class="side-by-side date-field"
        :helper="'Date to send'"
        type="date"
        label="Date"
        :persistent="true"
        :required="true"
        trailing-icon="insert_invitation"
        :value.sync="startDate"
        filled
      />
      <text-field
        class="time-field side-by-side"
        type="time"
        :helper="'Current time zone'"
        label="Time"
        :required="true"
        :persistent="true"
        :value.sync="startTime"
        trailing-icon="schedule"
        filled
      />
      <dropdown
        :handle-primary-val="handleRepeatSelect"
        :options="repeatOptions"
        :primary-val="repeatVal"
      />
      <section v-if="'Custom' === repeatVal" class="body2 bolder">
        <span class="bolder">Repeat every</span>
        <text-field
          class="repeat-amount side-by-side"
          type="number"
          :required="true"
          :persistent="true"
          :value.sync="repeatAmount"
          filled
        />
        <dropdown
          class="repeat-unit"
          :handle-primary-val="handleRepeatUnitSelect"
          :options="repeatUnitOptions"
          :primary-val="repeatUnit"
        />
        <section v-if="'week(s)' === repeatUnit" class="body2 repeat-on">
          <span class="bolder">Repeat on</span>
          <div class="days-of-week">
            <section
              v-for="(bool, day) in daysOfWeek"
              :key="day"
              class="day-circle body2"
              :class="{ selected: bool }"
              @click="handleDayClick(day)"
            >
              {{ day[0] }}
            </section>
          </div>
        </section>
        <section class="body2 ends">
          <span class="bolder">Ends</span>
          <section class="radio-wrap">
            <radio :value="false" name="ends" @click="handleRadioClick" />
            <span class="bolder radio-copy">Never</span>
          </section>
          <section class="radio-wrap">
            <radio :value="true" name="ends" @click="handleRadioClick" />
            <span class="bolder radio-copy">On</span>
            <text-field
              class="side-by-side end-date"
              type="date"
              label="Date"
              trailing-icon="insert_invitation"
              :value.sync="endDate"
              filled
            />
          </section>
        </section>
      </section>
      <div class="bottom-row">
        <btn class="action-btn" @click="closeModal"> Cancel </btn>
        <btn
          class="action-btn action-btn--positive"
          :disabled="!allowSchedule"
          @click="scheduleBroadcast"
        >
          Schedule
        </btn>
      </div>
    </template>
  </form-dialog>
</template>
<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import Dropdown from 'gameon-components/src/components/dropdown/dropdown.vue';
import FormDialog from 'gameon-components/src/components/form-dialog/form-dialog.vue';
import TextField from 'gameon-components/src/components/text-field/text-field.vue';
import Radio from 'gameon-components/src/components/radio/radio.vue';

import { SNACK_TYPES } from '../../../constants/snackbar';
import {
  epochify,
  epochToInput,
  inputToDayOfWeek,
} from '../../../helpers/conversions/time/time';
import { scheduleBroadcast, updateBroadcast } from '../../../api';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { FILTER_BY_VALUES } from '../../../constants/broadcasts';

export default {
  components: {
    Btn,
    Dropdown,
    FormDialog,
    Radio,
    TextField,
  },
  data() {
    const scheduledItem =
      (this.initialBroadcast &&
        this.initialBroadcast.schedule &&
        this.initialBroadcast.schedule[0]) ||
      '';
    const startDateTime =
      (scheduledItem &&
        scheduledItem.starting &&
        epochToInput(scheduledItem.starting, this.dateFormat)) ||
      '';
    const endDateTime =
      (scheduledItem &&
        scheduledItem.ending &&
        epochToInput(scheduledItem.ending, this.dateFormat)) ||
      '';
    const repeatUnit =
      (scheduledItem && scheduledItem.repeat && scheduledItem.unit) || '';
    let repeatVal = 'Does not repeat';
    if (scheduledItem && repeatUnit && repeatUnit.includes('week')) {
      repeatVal = `Weekly on ${inputToDayOfWeek(
        startDateTime.date,
        startDateTime.time
      )}s`;
    } else if (scheduledItem && repeatUnit && repeatUnit.includes('day')) {
      repeatVal = 'Daily';
    }
    return {
      daysOfWeek: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      ends: endDateTime.date ? true : '',
      endDate: endDateTime.date || '',
      repeatVal: repeatVal,
      repeatAmount: 1,
      repeatUnit: repeatUnit || '',
      repeatUnitOptions: ['week(s)', 'month(s)'],
      startDate: startDateTime.date || '',
      startTime: startDateTime.time || '',
    };
  },
  props: {
    finalBroadcastTitle: {
      required: true,
      type: String,
    },
    closeModal: {
      required: true,
      type: Function,
    },
    isDialogOpen: {
      default: false,
      type: Boolean,
      required: false,
    },
    initialBroadcast: {
      default: null,
      type: Object,
      required: false,
    },
    sendNow: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters('broadcastForm', ['sendable']),
    ...mapState('broadcastForm', ['audience', 'campaign', 'group']),
    ...mapState('user', ['botData']),
    allowSchedule() {
      const hasRequiredFields = !!this.startDate && !!this.startTime;
      return hasRequiredFields;
    },
    weeklyOption() {
      if (this.startDate && this.startTime) {
        return `Weekly on ${inputToDayOfWeek(this.startDate, this.startTime)}s`;
      }
      return 'Weekly';
    },
    repeatOptions() {
      return ['Does not repeat', 'Daily', this.weeklyOption];
    },
  },
  methods: {
    ...mapMutations('snackbar', ['openSnack']),
    ...mapActions('broadcastForm', ['createGroup']),
    ...mapMutations('broadcastPage', ['setFilterBy', 'triggerListRefresh']),
    getSchedules() {
      const repeatVal = this.repeatVal.toLowerCase();
      const starting = epochify(this.startDate, this.startTime, 'locale');

      if ('does not repeat' === repeatVal) {
        return [{ repeat: false, starting }];
      } else if (repeatVal.includes('weekly')) {
        const WEEKLY_UNIT = 'week';
        const WEEKLY_QUANTITY = 1;
        return [
          {
            starting,
            repeat: true,
            quantity: WEEKLY_QUANTITY,
            unit: WEEKLY_UNIT,
          },
        ];
      } else if ('daily' === repeatVal) {
        const DAILY_UNIT = 'day';
        const DAILY_QUANTITY = 1;
        return [
          {
            starting,
            repeat: true,
            quantity: DAILY_QUANTITY,
            unit: DAILY_UNIT,
          },
        ];
      } else {
        return [];
      }
    },
    handleDayClick(day) {
      this.daysOfWeek[day] = !this.daysOfWeek[day];
    },
    handleRadioClick(val) {
      this.ends = val;
    },
    handleRepeatSelect(val) {
      this.repeatVal = val;
    },
    handleRepeatUnitSelect(val) {
      this.repeatUnit = val;
    },
    async onDialogClosed(value) {
      const action = value.action;
      switch (action) {
        case 'OK':
          await this.scheduleBroadcast(action);
          break;
        default:
          this.$emit('close', action);
          break;
      }
    },
    async getGroupId() {
      if (
        this.initialBroadcast &&
        this.initialBroadcast.postBody &&
        this.initialBroadcast.postBody.groupId
      ) {
        return this.initialBroadcast.postBody.groupId;
      } else {
        await this.createGroup(this.finalBroadcastTitle);
        return this.group.id;
      }
    },
    async scheduleBroadcast() {
      try {
        const schedule = this.getSchedules();
        const meta = {
          group: 'engineering',
          app: this.botData.key,
          name: this.finalBroadcastTitle,
          platform: ['broadcast'],
        };
        const postBody = {
          schema: 'compound-multipart-content',
          content: this.sendable,
          botName: this.botData.key,
          subscription: this.audience,
          displayText: this.finalBroadcastTitle,
          campaignId: this.campaign.id,
        };

        postBody.groupId = await this.getGroupId();

        let id;
        let SUCCESS_TEXT;
        if (this.initialBroadcast && this.initialBroadcast.id) {
          SUCCESS_TEXT = 'Broadcast successfully updated';
          id = this.initialBroadcast.id;
          await updateBroadcast(id, meta, schedule, postBody);
        } else {
          SUCCESS_TEXT = 'Broadcast successfully scheduled';
          await scheduleBroadcast(meta, schedule, postBody);
        }
        this.$emit('close', 'SAVE');
        this.openSnack({ type: SNACK_TYPES.SUCCESS, text: SUCCESS_TEXT });
        const delay = 1000;
        setTimeout(() => {
          this.setFilterBy(FILTER_BY_VALUES.SCHEDULED);
        }, delay);
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './schedule-modal.scss';
</style>
