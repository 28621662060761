var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "message-content" },
    _vm._l(_vm.messageContents, function(content, index) {
      return _c(
        "section",
        { key: index, staticClass: "message-content__content" },
        [
          content.mode === "text"
            ? _c("text-preview", {
                attrs: { left: _vm.message.id, text: content.text }
              })
            : content.mode === "links"
            ? _c("links-preview", {
                key: index,
                attrs: {
                  links: content.links,
                  linksHorizontal: _vm.linksHorizontal
                }
              })
            : content.mode === "media"
            ? _c("media-preview", {
                attrs: {
                  link: content.link,
                  thumbnail: content.thumbnail,
                  type: content.type
                }
              })
            : content.mode === "buttons"
            ? _c("buttons-preview", {
                attrs: {
                  buttons: content.buttons,
                  buttonsHorizontal: _vm.buttonsHorizontal
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }