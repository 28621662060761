import { CONTENT_TYPES, PREVIEW_MODES } from '../../constants';

export default {
  mutations: {
    toggleLinksPreview: (state) => {
      if (PREVIEW_MODES.HORIZONTAL === state.previewConfig.links.mode) {
        state.previewConfig.links.mode = PREVIEW_MODES.VERTICAL;
      } else {
        state.previewConfig.links.mode = PREVIEW_MODES.HORIZONTAL;
      }
    },
    deleteAllLinks: (state) => {
      const withoutLinks = state.message.filter((item) => {
        return item.mode !== CONTENT_TYPES.link;
      });
      state.message = [...withoutLinks];
    },
  },
  getters: {
    firstLinkIndex: (state) => {
      for (let i = 0; i < state.message.length; i = i + 1) {
        if (CONTENT_TYPES.link === state.message[i].mode) {
          return i;
        }
      }
      return -1;
    },
    lastLinkIndex: (state) => {
      const lastLink = state.message.reduce(
        (lastLinkIndex, currentItem, currentIndex) => {
          // eslint-disable-next-line
          if (CONTENT_TYPES.link === currentItem.mode) return currentIndex;
          return lastLinkIndex;
        },
        -1
      );
      return lastLink;
    },
    linksHorizontal: (state) => {
      return PREVIEW_MODES.HORIZONTAL === state.previewConfig.links.mode;
    },
    links: (state) => {
      return state.message.filter((item) => {
        return CONTENT_TYPES.link === item.mode;
      });
    },
    linksPresent: (state, getters) => {
      return !!getters.links.length;
    },
    linksRenderable: (state, getters) => {
      if (null !== getters.linksRow && state.message[getters.linksRow].length) {
        return getters.links.filter((link) => !!link.link);
      }
      return [];
    },
  },
  actions: {
    moveLinksDown({ commit, getters, state }) {
      const skipSwappedItem = 1;
      const beforeLinks = state.message.slice(0, getters.firstLinkIndex);
      const afterLinks = state.message.slice(
        getters.lastLinkIndex + 1 + skipSwappedItem,
        state.message.length
      );
      const swappedItem = state.message[getters.lastLinkIndex + 1];
      const message = [
        ...beforeLinks,
        swappedItem,
        ...getters.links,
        ...afterLinks,
      ];
      commit('setContent', message);
    },
    moveLinksUp({ commit, getters, state }) {
      const beforeLinks = state.message.slice(0, getters.firstLinkIndex - 1);
      const afterLinks = state.message.slice(
        getters.lastLinkIndex + 1,
        state.message.length
      );
      const swappedItem = state.message[getters.firstLinkIndex - 1];
      const message = [
        ...beforeLinks,
        ...getters.links,
        swappedItem,
        ...afterLinks,
      ];
      commit('setContent', message);
    },
  },
};
