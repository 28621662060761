export const textListToSentence = (phrases) => {
  const TWO = 2;
  const numberOfPhrases = phrases.length;

  let sentence;
  if (0 === numberOfPhrases) {
    sentence = '';
  } else if (1 === numberOfPhrases) {
    sentence = `${phrases[0]}.`;
  } else if (TWO === numberOfPhrases) {
    sentence = `${phrases[0]}, and ${phrases[1]}.`;
  } else {
    sentence = `${phrases
      .slice(0, phrases.length - 1)
      .join(', ')}, and ${phrases.slice(-1)}.`;
  }

  return capitalize(sentence);
};

export const capitalize = (str) => {
  if (str) return `${str[0].toUpperCase()}${str.substr(1)}`;
  return '';
};

export const dashToTitleCase = (dashStr) => {
  if (dashStr) {
    const frags = dashStr.split('-');
    for (let i = 0; i < frags.length; i = i + 1) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  return '';
};

export const truncate = (str, maxLen) => {
  if (str.length > maxLen) {
    return str.slice(0, maxLen).trim().concat('...');
  }
  return str;
};
