import { DEFAULT_ITEMS_PER_PAGE } from './constants';
// import { ORDER_BY_OPTIONS } from '../constants'

const defaultState = {
  itemsPerLoad: DEFAULT_ITEMS_PER_PAGE,
  currentListPage: 1,
  lastRefresh: null,
  totalItems: [],
};
const getDefaultState = () => {
  return { ...defaultState };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    // BEGIN LIST
    // ===============
    resetListState: (state) => {
      state.totalItems = [];
      state.lastRefresh = null;
      state.currentListPage = 1;
    },
    setTotalItems: (state, items) => {
      state.totalItems = items;
    },
    // ===============
    // END LIST

    // ===============
    // BEGIN FILTERS
    goToFirstPage: (state) => {
      state.currentListPage = 1;
    },
    goToLastPage: (state) => {
      state.currentListPage =
        1 + parseInt(state.totalItems.length / state.itemsPerLoad);
    },
    nextPage: (state) => {
      state.currentListPage = state.currentListPage + 1;
    },
    prevPage: (state) => {
      state.currentListPage = state.currentListPage - 1;
    },
    setItemsPerPage: (state, num) => {
      state.itemsPerLoad = num;
    },
    triggerListRefresh: (state) => {
      state.lastRefresh = new Date();
    },
    // END FILTERS
    // ===============
  },
  getters: {
    firstRowIndex: (state) => {
      return (state.currentListPage - 1) * state.itemsPerLoad;
    },
    lastRowIndex: (state, getters) => {
      return (
        getters.firstRowIndex + state.currentListPage * state.itemsPerLoad - 1
      );
    },
    reachedLastPage: (state, getters) => {
      return getters.lastRowIndex >= getters.totalCount - 1;
    },
    totalCount: (state, getters) => {
      return state.totalItems.length;
    },
  },
};
