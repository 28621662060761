<template>
  <div class="broadcast-row">
    <m-list-item @click="handleClick" class="full-width">
      <div class="item-title">
        {{ group.title ? group.title : 'Unnamed broadcast' }}
        <div class="item-file-type">
          {{ campaignName }}
        </div>
      </div>
      <div class="item-status">
        <status-chip :status="group.status" />
      </div>
      <div v-if="group.status === 'sent'" class="item-next">
        {{ group.lastSendViewable.date }}
        <locale-time
          v-if="group.lastSendViewable.time"
          :time="group.lastSendViewable.time"
        />
      </div>
      <div v-else class="item-next">
        {{ group.nextSendViewable.date || 'N/A' }}<br />
        <locale-time
          v-if="group.nextSendViewable.time"
          :time="group.nextSendViewable.time"
        />
        <div
          v-if="group.status === 'scheduled' && group.schedule[0].repeat"
          class="second-line"
        >
          <icon class="repeat-icon" icon="loop" /> {{ truncateRepeatViewable }}
        </div>
      </div>
      <div class="item-created">
        {{ group.createdAtViewable }}
      </div>
    </m-list-item>
  </div>
</template>
<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';
import List from 'material-components-vue/dist/list';
import LocaleTime from '../../../../components/locale-time/locale-time.vue';
import StatusChip from '../../../../components/status-chip/status-chip.vue';
import { capitalize } from '../../../../helpers/conversions/grammar';
import { BROADCAST_SOURCES } from '../../../../constants/broadcasts';
import { getGroupMessages } from '../../helpers/past-broadcast-helpers';

import { mapMutations, mapState } from 'vuex';
import { getGroupBroadcasts } from '../../../../api/broadcast';

import Vue from 'vue';
Vue.use(List);

export default {
  components: {
    Icon,
    LocaleTime,
    StatusChip,
  },
  props: {
    group: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState('user', ['botData']),
    ...mapState('broadcastForm', ['campaignsData']),
    campaignName() {
      try {
        const campaignId =
          this.group.campaignId || this.group.postBody.campaignId;
        return this.campaignsData[campaignId].name;
      } catch {
        return '';
      }
    },
    truncateRepeatViewable() {
      if (this.group.schedule[0].repeat) {
        const partialSentence = this.group.schedule[0].repeatViewable
          .split('on')[0]
          .split('Repeats')[1]
          .trim();
        return capitalize(partialSentence);
      }
      return '';
    },
  },
  methods: {
    ...mapMutations('broadcastPage', [
      'setSelectedBroadcast',
      'setWaitingForData',
    ]),
    ...mapMutations('broadcastForm', ['setInitialBroadcast']),
    async handleClick() {
      if (this.group.source === BROADCAST_SOURCES.BROADCAST) {
        this.setWaitingForData(true);
        const campaignName = this.campaignsData[this.group.campaignId].name;
        const rawBroadcasts = await getGroupBroadcasts(this.group);
        const groupMessages = getGroupMessages(rawBroadcasts);
        const broadcastCampaign = {
          ...this.group,
          groupMessages,
          campaignName,
        };
        if (rawBroadcasts.length)
          broadcastCampaign.subscription = rawBroadcasts[0].subscription;
        this.setSelectedBroadcast(broadcastCampaign);
        this.setWaitingForData(false);
      } else {
        this.setSelectedBroadcast(this.group);
        this.setInitialBroadcast(this.group);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../list-view.scss';
@import './broadcast-row.scss';
</style>
