import { DEFAULT_CONTENT } from '../../constants';

export default {
  state: {
    questions: [{ ...DEFAULT_CONTENT.text }],
    editingQuestionPos: -1,
  },
  mutations: {
    // init
    resetQuestions: (state) => {
      state.questions = [{ ...DEFAULT_CONTENT.text }];
      state.editingQuestionPos = -1;
    },
    setQuestion: (state, { position, data }) => {
      const updatedQuestions = [...state.questions];
      updatedQuestions[position] = data;
      state.questions = updatedQuestions;
      state.editingQuestionPos = -1;
    },

    // CRUD questions
    createQuestion(state, { type }) {
      const newContent = { ...DEFAULT_CONTENT[type] };
      state.questions.push(newContent);
      state.editingQuestionPos = state.questions.length - 1;
    },
    finishEditingQuestion(state) {
      state.editingQuestionPos = -1;
    },
    destroyQuestion(state, position) {
      state.questions.splice(position, 1);
    },
    updateQuestion(state, { position, data }) {
      const updated = [...state.questions];
      updated[position] = data;
      state.questions = updated;
    },
    setEditingQuestionPos(state, index) {
      state.editingQuestionPos = index;
    },
    swapPhrases(state, { ind, ind2 }) {
      const questions = [...state.questions];
      questions[ind] = state.questions[ind2];
      questions[ind2] = state.questions[ind];
      state.questions = questions;
    },
  },
  getters: {
    currentQuestion(state) {
      return state.questions[state.editingQuestionPos];
    },
    currentQuestionEmpty(state, getters) {
      return !getters.currentQuestion.text;
    },
  },
  actions: {
    addQuestion({ commit, state, getters }, type) {
      if (getters.editingItem) return;
      commit('createQuestion', { type });
      commit('setEditingPosition', state.questions.length - 1);
    },
    cancelQuestion({ commit, dispatch, getters, state }) {
      const phrase = 0 !== state.editingQuestionPos;
      if (getters.currentQuestionEmpty && phrase) {
        dispatch('deleteQuestion', state.editingQuestionPos);
      } else {
        commit('finishEditingQuestion');
      }
    },
    saveQuestion({ commit }, { data, position }) {
      commit('updateQuestion', { data, position });
      commit('finishEditingQuestion');
    },
    deleteQuestion({ commit }, position) {
      commit('destroyQuestion', position);
      commit('finishEditingQuestion');
    },
  },
};
