// Job data comes From different services
// Past broadcasts are from broadcast service
// Future broadcasts are jobs from schedule service
export const BROADCAST_SOURCES = {
  BROADCAST: 'broadcast',
  SCHEDULE: 'schedule',
};

export const ORDER_BY_OPTIONS = [
  {
    display: 'Recently Edited',
    value: 'updatedAt.asc',
  },
  {
    display: 'Recently Edited desc',
    value: 'updatedAt.desc',
  },
  {
    display: 'Recently Created',
    value: 'createdAt.asc',
  },
  {
    display: 'Recently Created desc',
    value: 'createdAt.desc',
  },
  {
    display: 'A - Z by Name',
    value: 'displayText.asc',
  },
  {
    display: 'Z - A by Name',
    value: 'displayText.desc',
  },
  {
    display: 'By Audience',
    value: 'audience.asc',
  },
];

export const FILTER_BY_OPTIONS = [
  {
    display: 'Finished',
    value: 'finished',
  },
  {
    display: 'Scheduled',
    value: 'scheduled',
  },
  {
    display: 'Sent',
    value: 'sent',
  },
];

// String value is UI status copy
export const BROADCAST_STATUSES = {
  ERROR: 'error',
  FINISHED: 'finished',
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  SENT: 'sent',
};

export const FILTER_BY_VALUES = {
  FINISHED: 'finished',
  SCHEDULED: 'scheduled',
  SENT: 'sent',
};

export const ORDER_BY_VALUES = {
  UPDATED_AT_ASC: 'updatedAt.asc',
  UPDATED_AT_DESC: 'updatedAt.desc',
  CREATED_AT_ASC: 'createdAt.asc',
  CREATED_AT_DESC: 'createdAt.desc',
  DISPLAY_TEXT_ASC: 'displayText.asc',
  DISPLAY_TEXT_DESC: 'displayText.desc',
  AUDIENCE_ASC: 'audience.asc',
  SEND_DATE_ASC: 'sendDate.asc',
  SEND_DATE_DESC: 'sendDate.desc',
};
