var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "sidesheet" },
    [
      _c(
        "div",
        { staticClass: "close", on: { click: _vm.onClose } },
        [
          _c("icon-btn", {
            staticClass: "close-icon form-icon",
            attrs: { icon: "close" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }