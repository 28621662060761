export const CONTENT_TYPES = {
  text: 'text',
  media: 'media',
  link: 'link',
  quickReply: 'quickReply',
};
export const PREVIEW_CONTENT_TYPES = {
  text: 'text',
  media: 'media',
  links: 'links',
  buttons: 'buttons',
};
