const defaultState = {
  lastMenuClick: null,
};

export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    triggerCloseAllMenus: (state, dateTime) => {
      state.lastMenuClick = dateTime;
    },
  },
};
