// ============================
// BEGIN POST BODY TO FORM

import { CONTENT_TYPES, PREVIEW_CONTENT_TYPES } from '../../constants/content';

// ============================
const contentTextToForm = (text) => {
  return {
    text: text,
    mode: 'text',
  };
};
const contentMediaToForm = (media) => {
  return {
    ...media,
    mode: 'media',
  };
};
const contentLinksToForm = (links) => {
  return links.map((link) => {
    const transformedLink = {
      link: link.link,
      title: link.title,
      thumbnail: link.image,
      mode: 'link',
    };
    if (link.readMoreButtonText)
      transformedLink.readMoreButtonText = link.readMoreButtonText;
    return transformedLink;
  });
};

const contentLinksArrayToForm = (links) => {
  const previewLinks = links.map((link) => {
    const transformedLink = {
      link: link.link,
      title: link.title,
      thumbnail: link.image,
      mode: 'link',
    };
    if (link.readMoreButtonText)
      transformedLink.readMoreButtonText = link.readMoreButtonText;
    return transformedLink;
  });
  return {
    mode: PREVIEW_CONTENT_TYPES.links,
    links: previewLinks,
  };
};

const contentButtonsToForm = (buttons) => {
  return buttons.map((button) => {
    return {
      mode: 'button',
      title: button.title,
    };
  });
};

// Only supports compound-multipart-content schema
export const contentToForm = (messageBody) => {
  if (!messageBody) return [];
  const messageContents = {
    buttons: [],
    content: [],
  };
  for (let i = 0; i < messageBody.length; i = i + 1) {
    const messageItem = messageBody[i];

    if (messageItem.text) {
      messageContents.content.push(contentTextToForm(messageItem.text));
    }
    if (messageItem.media) {
      messageContents.content.push(contentMediaToForm(messageItem.media[0]));
    }
    if (messageItem.attachments && messageItem.attachments.length) {
      const links = contentLinksToForm(messageItem.attachments);
      messageContents.content = [...messageContents.content, ...links];
    }
    if (messageItem.choices && messageItem.choices.length) {
      messageContents.buttons = contentButtonsToForm(messageItem.choices);
    }
  }
  return messageContents;
};
// Campaign preview handles buttons as content items without need to edit
export const contentToCampaignPreview = (messageBody) => {
  if (!messageBody) return [];
  const messageContents = [];
  for (let i = 0; i < messageBody.length; i = i + 1) {
    const messageItem = messageBody[i];

    if (messageItem.text) {
      messageContents.push(contentTextToForm(messageItem.text));
    }
    if (messageItem.media) {
      messageContents.push(contentMediaToForm(messageItem.media[0]));
    }
    if (messageItem.attachments && messageItem.attachments.length) {
      const links = contentLinksArrayToForm(messageItem.attachments);
      messageContents.push(links);
    }
    if (messageItem.choices && messageItem.choices.length) {
      messageContents.push({
        buttons: contentButtonsToForm(messageItem.choices),
        mode: 'buttons',
      });
    }
  }
  return messageContents;
};
// ==========================
// END POST BODY TO FORM
// ==========================

export const schemaFinder = (mimeType) => {
  if (mimeType.includes('gif')) {
    return 'general-gif';
  }

  const generalType = mimeType.split('/')[0];
  switch (generalType) {
    case 'video':
      return 'general-video';
    case 'image':
      return 'general-image';
    default:
      return null;
  }
};

// Convert form content data to preview data
export const previewableFormContent = (content) => {
  const previewableText = (component) => {
    return !!component.text;
  };
  const previewableMedia = (component) => {
    return !!component.link;
  };
  const previewableLink = (component) => {
    return !!component.link && !!component.thumbnail && !!component.title;
  };

  switch (content.mode) {
    case CONTENT_TYPES.text:
      return previewableText(content);
    case CONTENT_TYPES.media:
      return previewableMedia(content);
    case CONTENT_TYPES.link:
      return previewableLink(content);
  }
};
