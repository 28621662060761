<template>
  <section class="preview">
    <div class="preview__list">
      <div class="preview__list__messages">
        <section
          v-for="(message, index) in chatPreviewData"
          :key="index"
          :class="messageClasses(message)"
        >
          <div class="preview__message-group__avatar">
            <img
              v-if="message.left"
              class="preview__message-group__avatar__img"
              :src="participants[message.speakerId].avatar"
            />
          </div>
          <div class="preview__message-group__contents">
            <message
              :message="message"
              :buttonsHorizontal="buttonsHorizontal"
              :linksHorizontal="linksHorizontal"
            />
            <div
              v-if="message.timestamp"
              class="preview__message-group__timestamp"
            >
              {{ timestampFormatted(message.timestamp) }}
            </div>
          </div>
        </section>
      </div>
    </div>
    <toggle-layout
      :buttonsHorizontal="buttonsHorizontal"
      :buttonsPresent="buttonsPresent"
      :linksHorizontal="linksHorizontal"
      :linksPresent="linksPresent"
      :toggleButtonsPreview="toggleButtonsPreview"
      :toggleLinksPreview="toggleLinksPreview"
    />
  </section>
</template>
<script>
import Message from './message/message.vue';
import ToggleLayout from './toggle-layout/toggle-layout.vue';
import { timeFormatAMPM } from '../../helpers/conversions/time/time';
export default {
  components: {
    Message,
    ToggleLayout,
  },
  props: {
    buttonsPresent: {
      type: Boolean,
      required: true,
    },
    chatPreviewData: {
      type: Array,
      required: true,
    },
    buttonsHorizontal: {
      required: true,
      type: Boolean,
    },
    linksPresent: {
      required: true,
      type: Boolean,
    },
    linksHorizontal: {
      required: true,
      type: Boolean,
    },
    participants: {
      required: true,
      type: Object,
    },
    toggleButtonsPreview: {
      required: true,
      type: Function,
    },
    toggleLinksPreview: {
      required: true,
      type: Function,
    },
  },
  methods: {
    timestampFormatted(timestamp) {
      if (timestamp) {
        const monthIndex = {
          end: 7,
          start: 4,
        };
        const dayIndex = {
          end: 10,
          start: 8,
        };
        const dateTime = new Date(timestamp);
        const year = dateTime.getFullYear();
        const month = dateTime
          .toString()
          .slice(monthIndex.start, monthIndex.end);
        const day = parseInt(
          dateTime.toString().slice(dayIndex.start, dayIndex.end)
        );
        const time = timeFormatAMPM(dateTime);

        const thisYear = new Date().getFullYear();
        if (dateTime.toString().includes(thisYear)) {
          return `${month} ${day}, ${year} ${time}`;
        } else {
          return `${month} ${day}, ${time}`;
        }
      }
      return '';
    },
    messageClasses(message) {
      const classes = {
        'preview__message-group': true,
        bubble: true,
      };
      if (message.left) classes['preview__message-group--left'] = true;
      if (!message.left) classes['preview__message-group--right'] = true;
      if (message.customClass) classes[message.customClass] = true;
      return classes;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './message-preview.scss';
</style>
