import { addContent, deleteContent, updateContent } from '../../../api';
import buttonsState from './buttons/buttons';
import linksState from './links/links';
import {
  CONTENT_TYPES,
  DEFAULT_CONTENT,
  PREVIEW_CONFIG_DEFAULT,
} from '../../../constants/messages';
import { contentToForm } from '../../../helpers/content/content';

export default {
  namespaced: true,
  state: {
    ...buttonsState.state,
    ...linksState.state,

    // form data
    content: [],
    buttons: [],

    // sidesheet info
    initialContent: null,
    editingContent: false,
    previewConfig: PREVIEW_CONFIG_DEFAULT,

    // Item creating and editing
    editingPosition: -1,
    selectedTag: null,
    title: '',
  },
  mutations: {
    ...buttonsState.mutations,
    ...linksState.mutations,

    // init content
    resetContent: (state, content) => {
      state.content = [];
      state.buttons = [];
      state.title = '';
      state.editingPosition = -1;
    },
    setContent: (state, content) => {
      state.content = content;
    },
    setInitialContent: (state, content) => {
      if (!content) {
        state.initialContent = content;
        state.content = [];
        state.buttons = [];
        state.title = '';
        state.selectedTag = null;
      } else if ('compound-multipart-content' === content.schema) {
        state.initialContent = { ...content };
        const messageContents = contentToForm(content.body.content);
        state.content = messageContents.content;
        state.buttons = messageContents.buttons;
        state.title = content.body.title || content.title;
        state.selectedTag = content.tags[0].name;
      }
    },
    setEditing: (state, bool) => {
      if (!bool) state.editingButtons = false;
      state.editingContent = bool;
    },
    setTag: (state, tag) => {
      state.selectedTag = tag;
    },
    setTitle: (state, e) => {
      if (e && e.target && e.target.value) {
        state.title = e.target.value;
      } else {
        state.title = '';
      }
    },

    // CRUD content
    createItem(state, { type, position }) {
      const newContent = { ...DEFAULT_CONTENT[type] };
      if (position) {
        const before = state.content.slice(0, position);
        const after = state.content.slice(position, state.content.length);
        state.content = [...before, newContent, ...after];
      } else {
        state.content.push(newContent);
      }
    },
    finishEditingItem(state) {
      state.editingPosition = -1;
    },
    destroyItem(state, position) {
      const itemType = state.content[position].mode;
      switch (itemType) {
        default:
          state.content.splice(position, 1);
      }
    },
    updateItem(state, { position, data }) {
      const updated = [...state.content];
      updated[position] = data;
      state.content = updated;
    },
    setEditingPosition(state, index) {
      state.editingPosition = index;
    },
    startEditing(state, editingPosition) {
      state.editingPosition = editingPosition;
    },
    swapIndices: (state, { ind, ind2 }) => {
      const newContent = [...state.content];
      newContent[ind] = state.content[ind2];
      newContent[ind2] = state.content[ind];
      state.content = newContent;
    },
  },
  getters: {
    ...buttonsState.getters,
    ...linksState.getters,

    contentReady(state, getters) {
      if (state.content.length && !getters.editingItem) return true;
      return false;
    },
    currentItem(state) {
      return state.content[state.editingPosition];
    },
    currentItemEmpty(state, getters) {
      switch (getters.editingMode) {
        case CONTENT_TYPES.text:
          return !getters.currentItem.text;
        case CONTENT_TYPES.link:
          return (
            !getters.currentItem.link &&
            !getters.currentItem.thumbnail &&
            !getters.currentItem.title
          );
        case CONTENT_TYPES.media:
          return !getters.currentItem.link && !getters.currentItem.title;
      }
    },
    editingItem(state) {
      return -1 < state.editingPosition || state.editingButtons;
    },
    editingMode(state, getters) {
      if (getters.editingItem) return getters.currentItem.mode;
      return '';
    },
    finalTitle(state, getters) {
      const untitled = 'Untitled Content';
      const title = state.title || untitled;
      return title;
    },
    getContentBody(state, getters) {
      const exportMessageBody = (data, item, index, content) => {
        let dataUpdate;
        switch (item.mode) {
          case 'text':
            dataUpdate = [...data, { text: item.text }];
            break;
          case 'link':
            const alreadyAdded =
              0 < index && CONTENT_TYPES.link === content[index - 1].mode;
            if (alreadyAdded) {
              return data;
            } else {
              dataUpdate = [
                ...data,
                {
                  attachments: getters.links.map((link) => {
                    return {
                      title: link.title,
                      image: link.thumbnail,
                      link: link.link,
                      readMoreButtonText: link.readMoreButtonText,
                    };
                  }),
                },
              ];
            }
            break;
          case 'media':
            const mediaData = {
              link: item.link,
              type: item.type,
            };
            if (item.title) mediaData.title = item.title;
            if (item.thumbnail) mediaData.thumbnail = item.thumbnail;
            dataUpdate = [...data, { media: [mediaData] }];
            break;
        }
        return dataUpdate;
      };

      const body = {
        content: state.content.reduce(
          (data, item, index, content) =>
            exportMessageBody(data, item, index, content),
          []
        ),
      };
      if (state.buttons.length && state.content.length) {
        body.content[body.content.length - 1].choices = state.buttons.map(
          (item) => {
            return {
              title: item.title,
              returnValue: item.returnValue || item.title,
            };
          }
        );
      }
      return body;
    },
    getSelectedTagId(state, getters, rootState, rootGetters) {
      const tagOptions = rootGetters['content/tagOptions'];
      if (!state.selectedTag) return tagOptions[1].value;
      return rootGetters['content/tagOptions'].filter((tag) => {
        return tag.display.includes(state.selectedTag);
      })[0].value;
    },
  },
  actions: {
    ...buttonsState.actions,
    ...linksState.actions,

    addItem({ commit, state, getters }, type) {
      if (getters.editingItem) return;

      if (CONTENT_TYPES.link === type && getters.linksPresent) {
        const insertPosition =
          0 < getters.lastLinkIndex ? getters.lastLinkIndex + 1 : 0;
        commit('createItem', { type, position: insertPosition });
        commit('setEditingPosition', getters.lastLinkIndex);
      } else {
        commit('createItem', { type });
        commit('setEditingPosition', state.content.length - 1);
      }
    },
    cancelItem({ commit, dispatch, getters, state }) {
      if (getters.currentItemEmpty) {
        dispatch('deleteItem', state.editingPosition);
      }
      commit('finishEditingItem');
    },
    async deleteInitialContent({ state }) {
      await deleteContent(state.initialContent.originalContent);
    },
    saveItem({ commit }, { data, position }) {
      commit('updateItem', { data, position });
      commit('finishEditingItem');
    },
    deleteItem({ commit }, position) {
      commit('destroyItem', position);
      commit('finishEditingItem');
    },
    async submit({ state, getters }) {
      const tagId = getters.getSelectedTagId;
      const schema = 'compound-multipart-content';
      const body = getters.getContentBody;
      if (state.initialContent) {
        body.title = getters.finalTitle;
        await updateContent({
          ...state.initialContent.originalContent,
          body,
        });
      } else {
        await addContent(schema, body, [[tagId]], getters.finalTitle);
      }
    },
    swapItems({ commit, dispatch, state }, { ind, ind2 }) {
      if (state.content[ind2].mode === CONTENT_TYPES.link && ind > ind2) {
        dispatch('moveLinksDown');
      } else if (
        state.content[ind2].mode === CONTENT_TYPES.link &&
        ind < ind2
      ) {
        dispatch('moveLinksUp');
      } else {
        commit('swapIndices', { ind, ind2 });
      }
    },
    handleCloseSideSheet({ commit }) {
      commit('setEditing', false);
      commit('setInitialContent', null);
    },
  },
};
