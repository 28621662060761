export const verifyImage = async (url) => {
  const imgPromise = new Promise(function imgPromise(resolve, reject) {
    const imgElement = new Image();

    imgElement.addEventListener('load', function imgOnLoad() {
      resolve();
    });

    imgElement.addEventListener('error', function imgOnError() {
      reject(new Error('No image found at given Image URL'));
    });

    // Assign URL to element and the event handlers will verify image
    imgElement.src = url;
  });

  return imgPromise;
};
