<template>
  <div class="full-width full-height content-page">
    <confirm-modal :show="modal === 'CONFIRM'" />
    <upload-size-modal :show="modal === 'UPLOAD_SIZE'" />
    <error-modal :show="modal === 'ERROR'" />
    <div class="full-width full-height content">
      <page-header header="Response Manager">
        <btn
          class="round create"
          @click="setSidesheetOpen(true)"
          icon="add"
          raised
        >
          Response
        </btn>
      </page-header>
      <list-filters />
      <list-view
        :items="listItems"
        :load-more-data="loadMoreData"
        :waiting-for-data="waitingForData"
      >
      </list-view>
      <side-sheet
        class="side-sheet--content"
        v-if="sidesheetOpen"
        :onClose="() => handleCloseSideSheet()"
      >
        <response-form />
        <preview
          :buttonsPresent="buttonsPresent"
          :buttonsHorizontal="buttonsHorizontal"
          :chatPreviewData="chatPreviewData"
          :linksPresent="linksPresent"
          :linksHorizontal="linksHorizontal"
          :toggleButtonsPreview="toggleButtonsPreview"
          :toggleLinksPreview="toggleLinksPreview"
          :participants="participants"
        />
      </side-sheet>
    </div>
    <div class="page-loading" v-if="waitingForData">
      <indeterminate-circle />
    </div>
  </div>
</template>

<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import IndeterminateCircle from '../../components/indeterminate-circle/indeterminate-circle.vue';
import ListFilters from './list-view/list-filters/list-filters.vue';
import ListView from './list-view/list-view.vue';
import PageHeader from '../../components/page-header/page-header.vue';
import Preview from '../../components/preview/message-preview.vue';
import responseForm from './response/response-form/response-form.vue';
import SideSheet from '../../components/side-sheet/side-sheet.vue';

// Modals
import ConfirmModal from '../../components/modal/confirm-modal.vue';
import ErrorModal from '../../components/modal/error-modal.vue';
import UploadSizeModal from '../../components/modal/library/upload-size-modal.vue';

import { getResponses, getTotalResponses } from '../../api/response-manager';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    Btn,
    IndeterminateCircle,
    ListFilters,
    ListView,
    PageHeader,
    Preview,
    SideSheet,
    responseForm,

    // Modals
    ConfirmModal,
    ErrorModal,
    UploadSizeModal,
  },
  computed: {
    ...mapGetters('responseManager', ['responseManagerAvailable']),
    ...mapState('responseManager', ['sidesheetOpen', 'orderBy']),
    ...mapGetters('responseManagerForm', [
      'buttons',
      'buttonsPresent',
      'buttonsHorizontal',
      'chatPreviewData',
      'linksHorizontal',
      'linksPresent',
      'firstLinkIndex',
      'lastLinkIndex',
      'links',
      'participants',
    ]),
    ...mapState('responseManagerList', [
      'currentListPage',
      'itemsPerPage',
      'lastRefresh',
      'listItems',
      'responseNameOptions',
      'selectedResponseName',
    ]),
    ...mapState('modal', ['modal']),
    ...mapState('uploader', [
      'continueUpload',
      'itemLink',
      'itemThumbnail',
      'itemType',
    ]),
    ...mapGetters('uploader', ['uploaderHasError', 'uploading']),
    ...mapState('user', ['loggedIn', 'botData']),
  },
  data() {
    return {
      gettingMetaData: false,
      fileName: '',
      previewLoaded: false,
      uploader: {
        done: false,
        error: false,
      },
      waitingForData: true,
    };
  },
  methods: {
    ...mapMutations('responseManager', ['setSidesheetOpen']),
    ...mapMutations('responseManagerForm', [
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapActions('responseManagerForm', ['handleCloseSideSheet']),

    ...mapMutations('responseManagerList', [
      'resetListState',
      'setListItems',
      'setTotalCount',
    ]),
    ...mapMutations('uploader', ['clearStates']),
    ...mapMutations('modal', ['resetModalStates', 'showModal']),
    ...mapMutations('snackbar', ['openSnack']),

    // List view methods
    determineSkip(reset) {
      if (reset) {
        return 0;
      } else {
        return (this.currentListPage - 1) * this.itemsPerPage;
      }
    },
    async loadMoreData() {
      if (this.waitingForData) return;
      await this.waitForData();
    },
    async getListViewData(reset = false) {
      if (!this.loggedIn) {
        return [];
      }
      const options = {
        count: this.itemsPerPage,
        offset: this.determineSkip(reset),
        sort: this.orderBy,
        name: this.selectedResponseName,
      };
      const pageItems = await getResponses(this.botData.key, options);
      const count = await getTotalResponses(
        this.botData.key,
        this.selectedResponseName
      );
      this.setListItems(pageItems);
      this.setTotalCount(count);
    },
    async resetAll() {
      this.resetListState();
      this.resetModalStates();
      this.clearStates();
      this.getListViewData(true);
    },
    async waitForData(reset = false) {
      if (reset) {
        this.resetListState();
      }
      if (this.botData) {
        this.waitingForData = true;
        await this.getListViewData(reset);
        this.waitingForData = false;
      } else if (!this.botData) {
        this.waitingForData = false;
      }
    },
  },
  watch: {
    currentListPage() {
      this.waitForData();
    },
    itemsPerPage() {
      this.waitForData();
    },
    lastRefresh() {
      this.resetAll();
    },
    async responseManagerAvailable() {
      if (this.loggedIn) {
        await this.waitForData(true);
      }
    },
    async orderBy() {
      await this.waitForData(true);
    },
    async selectedResponseName() {
      this.resetListState();
      await this.waitForData(true);
    },
  },
  activated() {
    this.resetAll();
    this.waitForData(true);
  },
};
</script>
<style scoped lang="scss">
@import './response-manager.scss';
@import '../../components/indeterminate-circle/page-loading.scss';
</style>
