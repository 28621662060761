<!-- eslint-disable max-lines -->
<template>
  <div class="schedule-editor" :class="{ 'update-mode': updateMode }">
    <editor-header editor="Job" :mode="headerMode" />
    <text-field
      class="form-spacing"
      label="Job name"
      :value.sync="name"
      required
      filled
    />
    <div class="form-spacing--switch">
      <m-switch id="customer-switch" v-model="showInList"></m-switch>
      <label class="form-spacing" for="customer-switch"
        >Show in customer broadcast list?</label
      >
    </div>
    <div class="form-spacing--switch">
      <m-switch id="enable-switch" v-model="enabled"></m-switch>
      <label class="form-spacing" for="enable-switch">Enable? *</label>
    </div>
    <div class="dropdown form-spacing">
      <div class="select-label">App *</div>
      <single-select
        :handle-choice="handleSelectApp"
        :options="scheduleAppOptions"
        :upstream-val="app"
      />
    </div>
    <text-field
      class="form-spacing"
      label="URL"
      :value.sync="uri"
      required
      filled
    />
    <label
      class="form-spacing pseudo-v-label"
      :class="{ invalid: !postBodyValid }"
      for="#post-body"
      >Post Body</label
    >
    <div>
      <editor
        id="post-body"
        class="full-width post-body"
        v-model="postBody"
        @init="() => {}"
        lang="json"
        height="150"
      />
    </div>
    <label class="form-spacing pseudo-v-label padding-tiny-bottom full-width">
      When (currently {{ this.dateFormat }} mode)
    </label>
    <div class="full-width date-time form-spacing">
      <text-field
        class="padding-small"
        label="Start date"
        type="date"
        :value.sync="startDate"
        :required="recurring"
        trailing-icon="today"
        @update="handleNewDate"
        filled
      />
      <text-field
        class="padding-small"
        label="Start time"
        type="time"
        :value.sync="startTime"
        :required="recurring"
        trailing-icon="access_time"
        @update="handleNewTime"
        filled
      />
    </div>
    <div class="form-spacing">
      <div class="recurring-wrap">
        <m-checkbox v-model="recurring" />
        <label class="vertical-center-label">Recurring</label>
      </div>
      <div class="full-width" v-if="recurring">
        <label
          class="pseudo-v-label padding-small padding-tiny-bottom full-width"
          >every</label
        >
        <div class="padding-small full-width">
          <text-field
            class="padding-small half-width quantity"
            label="Quantity"
            type="number"
            :value.sync="quantity"
            :required="recurring"
            filled
          />
          <div class="dropdown half-width">
            <div class="select-label">Units*</div>
            <single-select
              class="dropdown"
              :handle-choice="handleUnitChoice"
              :options="unitOptions"
              :upstream-val="unit"
            />
          </div>
        </div>
        <label class="pseudo-v-label until">until</label>
        <div class="padding-small full-width date-time">
          <text-field
            class="padding-small"
            label="End date"
            type="date"
            :value.sync="endDate"
            :required="recurring"
            filled
          />
          <text-field
            class="padding-small"
            label="End time"
            type="time"
            :value.sync="endTime"
            :required="recurring"
            filled
          />
        </div>
      </div>
    </div>
    <chips-input
      :chipset="tagChips"
      :disabled="false"
      label="Tags"
      :handle-chip-add="handleTagAdd"
      :handle-icon-click="handleTagDel"
      helper-text="Type tag and press enter..."
    />
    <div class="full-width grid row-wrap--right">
      <btn
        type="button"
        class="btn-color--secondary"
        @click="run"
        raised
        :disabled="!allowRun || loading"
      >
        {{
          pendingAction === jobPendingActions.running
            ? 'Saving...'
            : 'Save and Run'
        }}
      </btn>
      <btn
        type="button"
        v-if="!updateMode"
        class="btn-color--secondary"
        raised
        @click="schedule"
        :disabled="!allowSchedule || loading"
      >
        {{
          pendingAction === jobPendingActions.scheduling
            ? 'Scheduling...'
            : 'Schedule'
        }}
      </btn>
      <btn
        type="button"
        class="btn-color--primary"
        v-if="updateMode"
        raised
        @click="update"
        :disabled="!allowSchedule || loading"
      >
        {{
          pendingAction === jobPendingActions.updating
            ? 'Updating...'
            : 'Update'
        }}
      </btn>
      <btn
        type="button"
        v-if="updateMode"
        class="btn-color--destructive"
        raised
        @click="destroy"
        :disabled="loading"
      >
        {{
          pendingAction === jobPendingActions.DELETING
            ? 'Deleting...'
            : 'Delete'
        }}
      </btn>
    </div>
  </div>
</template>

<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import ChipsInput from 'gameon-components/src/components/chips-input/chips-input.vue';
import Editor from 'vue2-ace-editor';
import EditorHeader from './editor-header.vue';
import '../../../assets/icon-global-message-editor.svg';
import MSwitch from 'material-components-vue/dist/switch';
import MCheckbox from 'material-components-vue/dist/checkbox';
import SingleSelect from 'gameon-components/src/components/single-select/single-select.vue';
import TextField from 'gameon-components/src/components/text-field/text-field.vue';

import { mapGetters, mapMutations } from 'vuex';

import { setJob, runJob, updateJob, deleteJob } from '../../../api';
import { epochify, epochToInput } from '../../../helpers/conversions/time/time';
import { JOB_PENDING_ACTIONS } from './constants';

import Vue from 'vue';

Vue.use(MSwitch);
Vue.use(MCheckbox);

const INDENTATION = 2;
export default {
  data() {
    const previous = this.previousData || {};

    const unitOptions = ['second', 'minute', 'hour', 'day', 'week'].map(
      (unit) => {
        return { display: unit, value: unit };
      }
    );
    const interfaceData = {
      headerMode: previous.meta ? 'Update Job' : 'Create Job',
      jobPendingActions: JOB_PENDING_ACTIONS,
      pendingAction: JOB_PENDING_ACTIONS.none,
      tag: '',
      button: null,
      successText: '',
      showSuccess: false,
      showError: false,
      errorText: {
        type: 'commonErr',
        commonErr: 'DEFAULT',
      },
      unitOptions,
    };

    const scheduledItem = (previous.schedule && previous.schedule[0]) || '';
    const startDateTime =
      (scheduledItem &&
        scheduledItem.starting &&
        epochToInput(scheduledItem.starting, this.dateFormat)) ||
      '';
    const endDateTime =
      (scheduledItem &&
        scheduledItem.ending &&
        epochToInput(scheduledItem.ending, this.dateFormat)) ||
      '';
    const form = {
      enabled: undefined === previous.enabled ? true : previous.enabled,
      autoDelete: previous.autoDelete || false,
      group: 'engineering',
      app: (previous.meta && previous.meta.app) || 'content-generators',
      name: (previous.meta && previous.meta.name) || '',
      showInList:
        previous.meta &&
        previous.meta.platform &&
        previous.meta.platform.includes('broadcast'),
      uri: previous.uri || '',
      postBody: JSON.stringify(previous.postBody, null, INDENTATION) || '',
      recurring:
        (previous.schedule &&
          previous.schedule[0] &&
          previous.schedule[0]['repeat']) ||
        false,
      startDate: startDateTime.date || '',
      startTime: startDateTime.time || '',
      endDate: endDateTime.date || '',
      endTime: endDateTime.time || '',
      quantity:
        (scheduledItem && scheduledItem.repeat && scheduledItem.quantity) || '',
      unit: (scheduledItem && scheduledItem.repeat && scheduledItem.unit) || '',
      tags: (previous.meta && previous.meta.tags) || [],
      updateMode: !!previous.meta || false,
    };
    return {
      ...form,
      ...interfaceData,
    };
  },
  props: ['dateFormat', 'previousData'],
  components: {
    Btn,
    ChipsInput,
    Editor,
    EditorHeader,
    SingleSelect,
    TextField,
  },
  computed: {
    ...mapGetters('user', ['scheduleAppOptions']),
    tagChips() {
      return this.tagsToChips(this.tags);
    },
    validForm() {
      return (
        !!this.uri &&
        !!this.name &&
        !!this.group &&
        !!this.app &&
        this.postBodyValid
      );
    },
    validRecur() {
      return (
        !!this.recurring &&
        !!this.quantity &&
        !!this.unit &&
        !!this.startDate &&
        !!this.startTime
      );
    },
    allowSchedule() {
      return (
        (this.validForm && this.startDate && this.startTime) ||
        (this.validForm && this.validRecur)
      );
    },
    allowRun() {
      return this.validForm && !!this.enabled;
    },
    loading() {
      return this.pendingAction !== this.jobPendingActions.none;
    },
    postBodyValid() {
      if (this.postBody) {
        try {
          JSON.parse(this.postBody);
        } catch (e) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapMutations('schedule', ['closeSideSheet', 'setDateFormat']),
    handleTagAdd(text) {
      if (text in this.tags) return;
      this.tags.push(text);
    },
    handleTagDel(chipSelected) {
      this.tags = this.tags.filter((tag) => {
        return chipSelected.name !== tag;
      });
    },
    handleNewDate(val) {
      this.startDate = val;
    },
    handleNewTime(val) {
      this.startTime = val;
    },
    tagsToChips(tags = []) {
      return tags.map((tag) => {
        return {
          name: tag,
          icon: {
            slot: 'trailingIcon',
            icon: 'cancel',
          },
        };
      });
    },
    async run(e) {
      e.preventDefault();
      if (!this.allowRun) return;
      try {
        this.pendingAction = this.jobPendingActions.running;
        let id;
        if (this.updateMode) {
          await updateJob(this.gatherForm(), this.previousData.id);
          id = this.previousData.id;
        } else {
          const res = await setJob(this.gatherForm());
          id = res.id;
        }
        if (id) {
          await runJob(id);
          this.successText = 'Job is now running';
          this.showSuccess = true;
        }
      } catch (error) {
        this.showError = true;
        this.errorText = { type: 'DEFAULT' };
      } finally {
        this.pendingAction = this.jobPendingActions.none;
        this.closeSideSheet();
      }
    },
    async schedule(e) {
      e.preventDefault();
      if (!this.allowSchedule) return;

      try {
        this.pendingAction = this.jobPendingActions.scheduling;
        await setJob(this.gatherForm());
        this.successText = 'Job successfully scheduled';
        this.showSuccess = true;
        this.closeSideSheet();
      } catch (er) {
        // eslint-disable-next-line
        console.log('er', er);
        this.showError = true;
        this.errorText = { type: 'DEFAULT' };
      } finally {
        this.pendingAction = this.jobPendingActions.none;
      }
    },
    async update(e) {
      e.preventDefault();
      try {
        this.pendingAction = this.jobPendingActions.updating;
        await updateJob(this.gatherForm(), this.previousData.id);
        this.successText = 'Job successfully updated';
        this.successText = true;
        this.closeSideSheet();
      } catch (err) {
        this.showError = true;
        this.errorText = { type: 'DEFAULT' };
      } finally {
        this.pendingAction = this.jobPendingActions.none;
      }
    },
    async destroy(e) {
      e.preventDefault();

      try {
        this.pendingAction = this.jobPendingActions.deleting;
        await deleteJob(this.previousData.id);
        this.successText = 'Job successfully deleted';
        this.showSuccess = true;
        this.closeSideSheet();
      } catch (err) {
        this.showError = true;
        this.errorText = { type: 'DEFAULT' };
      } finally {
        this.pendingAction = this.jobPendingActions.none;
      }
    },
    gatherForm() {
      // Required data
      const formData = {
        uri: this.uri,
        schedule: [],
        enabled: this.enabled,
        autoDelete: this.autoDelete,
        meta: {
          group: this.group,
          app: this.app,
          name: this.name,
        },
      };

      // Optional data
      const platformHasData = !!(
        this.previousData &&
        this.previousData.meta &&
        this.previousData.meta.platform &&
        this.previousData.meta.platform.length
      );
      const platform = platformHasData
        ? [...this.previousData.meta.platform]
        : [];
      if (this.showInList && !platform.includes('broadcast')) {
        platform.unshift('broadcast');
      } else if (!this.showInList) {
        platform.splice(platform.indexOf('broadcast'), 1);
      }
      formData.meta.platform = platform;
      if (this.tags.length) {
        formData.meta.tags = this.tags;
      }
      if (this.startTime && this.startDate) {
        formData.schedule = [
          {
            repeat: this.recurring,
            starting: epochify(this.startDate, this.startTime, this.dateFormat),
          },
        ];
      }
      if (this.recurring) {
        formData.schedule[0]['quantity'] = parseInt(this.quantity, 10);
        formData.schedule[0]['unit'] = this.unit;
        if (this.endDate && this.endTime) {
          formData.schedule[0]['ending'] = epochify(
            this.endDate,
            this.endTime,
            this.dateFormat
          );
        }
      }
      if (this.postBody && this.postBody.length) {
        formData.postBody = JSON.parse(this.postBody);
      }
      return formData;
    },
    handleSelectApp(incoming) {
      this.app = incoming;
    },
    handleUnitChoice(incoming) {
      this.unit = incoming;
    },
  },
};
</script>
<style lang="scss">
@import 'material-components-vue/dist/switch/styles';
@import 'material-components-vue/dist/checkbox/styles';
.v-input--checkbox label {
  margin-bottom: 0;
}
.post-body {
  margin-top: 4px;
  margin-bottom: 16px;
}
.pseudo-v-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.until {
  margin: 16px;
}
.date-time .v-input {
  max-width: 48%;
}
.recurring-wrap {
  margin-top: 24px;
  margin-bottom: 24px;
}
.vertical-center-label {
  line-height: 40px;
}
.schedule-editor .dropdown label {
  margin: 12px 0;
}
.schedule-editor .form-spacing {
  margin: 16px 0;
}
.schedule-editor .form-spacing--switch {
  margin: 24px 0;
  label {
    padding-left: 12px;
  }
}
/deep/ .select-wrap {
  width: 200px;
}
.select-wrap {
  width: 200px;
}
.select-label {
  font-size: 12px;
  padding: 10px 0 0 0;
}
.schedule-editor .options {
  z-index: 6;
}
.ace_gutter {
  z-index: 0;
}
</style>
