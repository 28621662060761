import { getBotTag, getTag, getTags } from '../../api';
import { ORDER_BY_OPTIONS } from './constants';

export default {
  namespaced: true,
  state: {
    botTagOptions: null,
    dateFormat: 'locale',
    editing: false,
    contentBotsMeta: [],
    listItems: [],
    selectedContent: null,
    showAssetModal: false,
    showUploadSideSheet: false,

    // list filters
    selectedTag: '',
    orderBy: ORDER_BY_OPTIONS[0].value,
    orderByOptions: ORDER_BY_OPTIONS,
  },
  mutations: {
    setBotTagOptions: (state, nestedOptions) => {
      state.botTagOptions = nestedOptions;
    },
    setDateFormat: (state, format) => {
      const dateOptions = {
        locale: 'locale',
        GMT: 'GMT',
      };
      if (dateOptions[format]) {
        state.dateFormat = dateOptions[format];
      }
    },
    setEditing: (state, payload) => {
      state.editing = payload;
    },
    setSelectedTag: (state, choice) => {
      state.selectedTag = choice;
    },
    setContentBotsMeta: (state, botsData) => {
      state.contentBotsMeta = botsData;
    },
    setListItems: (state, items) => {
      state.listItems = items;
    },
    setOrderBy: (state, choice) => {
      state.orderBy = choice;
    },
    setSelectedContent: (state, payload) => {
      state.selectedContent = payload;
    },
    setShowAssetModal: (state, bool) => {
      state.showAssetModal = bool;
    },
    setShowUploadSideSheet: (state, bool) => {
      state.showUploadSideSheet = bool;
    },
  },
  getters: {
    botTagOptionsPresent(state, getters) {
      if (!getters.botTagDropdownOptions) return false;
      return !!Object.keys(getters.botTagDropdownOptions).length;
    },
    botTagDropdownOptions(state) {
      try {
        const result = {};
        for (const bot in state.botTagOptions) {
          result[bot] = state.botTagOptions[bot].map((tag) => tag.name);
        }
        return result;
      } catch (e) {
        return null;
      }
    },
    contentAvailable(state, getters, rootState) {
      try {
        return !!Object.keys(rootState.user.data.apps['content']);
      } catch (e) {
        return false;
      }
    },
    tagOptions(state, getters) {
      if (getters.botTagOptionsPresent && state.contentBotsMeta.length) {
        const parentTag = {
          display: 'All Tags',
          value: state.contentBotsMeta[0].publishId,
        };
        const bot = Object.keys(state.botTagOptions)[0];
        const childTags = state.botTagOptions[bot].map((tag) => {
          return {
            display: `${tag.name}`,
            value: tag.id,
          };
        });
        return [parentTag].concat(childTags);
      }
      return [];
    },
    validateByFilter(state) {
      switch (state.selectedTag) {
        case 'allMedia':
          return (item) => item;
        case 'videos':
          return (item) =>
            !!item.body.mimeType && !!item.body.mimeType.includes('video');
        case 'gifs':
          return (item) =>
            !!item.body.mimeType && !!item.body.mimeType.includes('gif');
        case 'images':
          return (item) =>
            !!item.body.mimeType && !!item.body.mimeType.includes('image');
        case 'tags':
          return (item) => item.tags.length;
        case 'noTags':
          return (item) => !item.tags || !item.tags.length;
        default:
          return (item) => item;
      }
    },
  },
  actions: {
    // Tag methods
    async gatherTagsFromBot({ commit, dispatch, state, rootState }) {
      let botTags = [];
      try {
        const getParentOrChildTags = async (tagResult) => {
          const childResults = await getTags(
            rootState.user.botData.key,
            tagResult.name
          );
          const childTags = childResults.map((t) => {
            t.name = `${tagResult.name.toUpperCase()} - ${t.name}`;
            return t;
          });
          if (childResults.length) {
            // returns array of tag obj
            return childTags;
          } else {
            // returns tag obj
            return [tagResult];
          }
        };
        const dashpublishTags = await getTags(
          rootState.user.botData.key,
          'dashpublish'
        );
        for (const tagResult of dashpublishTags) {
          const result = await getParentOrChildTags(tagResult);
          botTags = [...botTags, ...result];
        }
      } catch (e) {
        botTags = [];
      }
      return botTags;
    },
    async initBotTagOptions({ commit, dispatch, rootState }) {
      const botsWithTags = {};
      const tags = await dispatch(
        'gatherTagsFromBot',
        rootState.user.botData.key
      );
      botsWithTags[rootState.user.botData.key] = tags;
      commit('setBotTagOptions', botsWithTags);
    },
    // Updates state to include metadata of all bots
    async initBotMeta({ commit, rootState }) {
      const result = [];
      try {
        const botMeta = await getBotTag(rootState.user.botData.key);
        const context = `${botMeta.name},dashpublish`;
        const botDashPublishMeta = await getTag(context);
        result.push({
          displayName: rootState.user.botData.key,
          tagName: botMeta.name,
          id: botMeta.id,
          publishId: botDashPublishMeta.id,
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log(
          'Content support missing for bot',
          rootState.user.botData.key
        );
      }
      if (result.length) {
        commit('setContentBotsMeta', result);
      } else {
        commit('setContentBotsMeta', null);
      }
    },
    // End Tag methods
  },
};
