var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "preview" },
    [
      _c("div", { staticClass: "preview__list" }, [
        _c(
          "div",
          { staticClass: "preview__list__messages" },
          _vm._l(_vm.chatPreviewData, function(message, index) {
            return _c(
              "section",
              { key: index, class: _vm.messageClasses(message) },
              [
                _c("div", { staticClass: "preview__message-group__avatar" }, [
                  message.left
                    ? _c("img", {
                        staticClass: "preview__message-group__avatar__img",
                        attrs: {
                          src: _vm.participants[message.speakerId].avatar
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "preview__message-group__contents" },
                  [
                    _c("message", {
                      attrs: {
                        message: message,
                        buttonsHorizontal: _vm.buttonsHorizontal,
                        linksHorizontal: _vm.linksHorizontal
                      }
                    }),
                    _vm._v(" "),
                    message.timestamp
                      ? _c(
                          "div",
                          { staticClass: "preview__message-group__timestamp" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.timestampFormatted(message.timestamp)
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("toggle-layout", {
        attrs: {
          buttonsHorizontal: _vm.buttonsHorizontal,
          buttonsPresent: _vm.buttonsPresent,
          linksHorizontal: _vm.linksHorizontal,
          linksPresent: _vm.linksPresent,
          toggleButtonsPreview: _vm.toggleButtonsPreview,
          toggleLinksPreview: _vm.toggleLinksPreview
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }