<template>
  <div class="full-width full-height content-page">
    <confirm-modal :show="modal === 'CONFIRM'" />
    <upload-size-modal :show="modal === 'UPLOAD_SIZE'" />
    <error-modal :show="modal === 'ERROR'" />
    <div
      :class="[
        { 'show-overlay': editingPair },
        'full-width',
        'content',
        'full-height',
        'overlay',
      ]"
    >
      <page-header header="Questions and Answers (Q&A)">
        <btn class="round create" @click="createNewPair" icon="add" raised>
          Q&A Pair
        </btn>
      </page-header>
      <list-filters />
      <list-view
        :items="listItems"
        :load-more-data="loadMoreData"
        :waiting-for-data="waitingForData"
      >
      </list-view>
      <side-sheet
        class="side-sheet--content"
        v-if="editingPair"
        :onClose="() => handleCloseSideSheet()"
      >
        <qna-pair-form />
        <preview
          :buttonsPresent="buttonsPresent"
          :buttonsHorizontal="buttonsHorizontal"
          :chatPreviewData="chatPreviewData"
          :linksPresent="linksPresent"
          :linksHorizontal="linksHorizontal"
          :toggleButtonsPreview="toggleButtonsPreview"
          :toggleLinksPreview="toggleLinksPreview"
          :participants="participants"
        />
      </side-sheet>
    </div>
    <div class="page-loading" v-if="waitingForData">
      <indeterminate-circle />
    </div>
  </div>
</template>

<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import IndeterminateCircle from '../../components/indeterminate-circle/indeterminate-circle.vue';
import ListFilters from './list-view/list-filters/list-filters.vue';
import ListView from './list-view/list-view.vue';
import PageHeader from '../../components/page-header/page-header.vue';
import Preview from '../../components/preview/message-preview.vue';
import QnaPairForm from './qna-pair/qna-pair-form/qna-pair-form.vue';
import SideSheet from '../../components/side-sheet/side-sheet.vue';

// Modals
import ConfirmModal from '../../components/modal/confirm-modal.vue';
import ErrorModal from '../../components/modal/error-modal.vue';
import UploadSizeModal from '../../components/modal/library/upload-size-modal.vue';

import { getKnowledgeBase } from '../../api/qna';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    Btn,
    IndeterminateCircle,
    ListFilters,
    ListView,
    PageHeader,
    Preview,
    SideSheet,
    QnaPairForm,

    // Modals
    ConfirmModal,
    ErrorModal,
    UploadSizeModal,
  },
  computed: {
    ...mapGetters('qna', ['qnaAvailable']),
    ...mapState('user', ['botData', 'loggedIn']),
    ...mapState('qna', ['orderBy', 'selectedTag', 'selectedPair']),
    ...mapGetters('qnaForm', [
      'buttons',
      'buttonsPresent',
      'buttonsHorizontal',
      'chatPreviewData',
      'linksHorizontal',
      'linksPresent',
      'firstLinkIndex',
      'lastLinkIndex',
      'links',
      'participants',
    ]),
    ...mapState('qnaForm', ['editingPair']),
    ...mapState('qnaList', ['lastRefresh', 'tagOptions']),
    ...mapGetters('qnaList', ['listItems']),
    ...mapState('modal', ['modal']),
    ...mapState('uploader', [
      'continueUpload',
      'itemLink',
      'itemThumbnail',
      'itemType',
    ]),
    ...mapGetters('uploader', ['uploaderHasError', 'uploading']),
  },
  data() {
    return {
      gettingMetaData: false,
      fileName: '',
      offset: 0,
      previewLoaded: false,
      uploader: {
        done: false,
        error: false,
      },
      waitingForData: true,
    };
  },
  methods: {
    ...mapMutations('qnaForm', [
      'createNewPair',
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapActions('qnaForm', ['handleCloseSideSheet']),

    ...mapMutations('qnaList', ['resetListState', 'setTotalItems']),
    ...mapMutations('uploader', ['clearStates']),
    ...mapMutations('modal', ['resetModalStates', 'showModal']),
    ...mapMutations('snackbar', ['openSnack']),

    // List view methods
    async loadMoreData() {
      if (this.waitingForData) return;
      await this.waitForData();
    },
    async getListViewData(reset = false) {
      if (!this.loggedIn) {
        return [];
      }
      const questionAnswerPairs = (await getKnowledgeBase(this.botData.key))
        .questionAnswerPairs;
      this.setTotalItems(questionAnswerPairs);
    },
    async resetAll() {
      this.resetListState();
      this.resetModalStates();
      this.clearStates();
      this.getListViewData(true);
    },
    async waitForData(reset = false) {
      if (reset) {
        this.resetListState();
      }
      if (this.botData) {
        this.waitingForData = true;
        await this.getListViewData(reset);
        this.waitingForData = false;
      } else if (!this.botData) {
        this.waitingForData = false;
      }
    },
  },
  watch: {
    lastRefresh() {
      this.resetAll();
    },
    async qnaAvailable() {
      if (this.loggedIn) {
        await this.waitForData(true);
      }
    },
    async orderBy() {
      await this.waitForData(true);
    },
    async selectedTag() {
      this.resetListState();
      await this.waitForData(true);
    },
    async tagOptions() {
      if (this.tagOptions.length && !this.selectedTag) {
        this.setSelectedChannel(this.channelOptions[0].value);
      }
    },
  },
  activated() {
    this.resetAll();
    this.waitForData(true);
  },
};
</script>
<style scoped lang="scss">
@import './qna.scss';
@import '../../components/indeterminate-circle/page-loading.scss';
</style>
