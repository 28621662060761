export const getVideoDuration = async (file) => {
  const vid = document.createElement('video');
  vid.src = URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    const GIVE_UP = 3000;
    const timeout = setTimeout(() => {
      reject(new Error('No video duration found'));
    }, GIVE_UP);
    vid.ondurationchange = function () {
      if (this.duration) {
        resolve(this.duration);
        clearTimeout(timeout);
      }
    };
  });
};
