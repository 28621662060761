/* eslint-disable max-lines */
import {
  FILTER_BY_VALUES,
  ORDER_BY_VALUES,
  FILTER_BY_OPTIONS,
  ORDER_BY_OPTIONS,
} from '../../../constants/broadcasts';

import { PREVIEW_CONFIG_DEFAULT } from '../../../constants/messages';
import { MODES_META_DATA } from './constants';

export default {
  namespaced: true,
  state: {
    editingBroadcast: false,
    lastFocus: null,
    lastRefresh: null,
    modesMetaData: MODES_META_DATA,
    selectedBroadcast: null,
    previewConfig: PREVIEW_CONFIG_DEFAULT,
    waitingForData: true,

    // filter dropdowns
    filterBy: FILTER_BY_VALUES.SENT,
    filterByOptions: FILTER_BY_OPTIONS,
    groupSearchInput: '',
    orderBy: ORDER_BY_VALUES.SEND_DATE_DESC,
    orderByOptions: ORDER_BY_OPTIONS,
    selectedCampaigns: [],
  },
  mutations: {
    setEditing: (state, bool) => {
      state.orderBy = ORDER_BY_VALUES.UPDATED_AT_DESC;
      state.editingBroadcast = bool;
    },
    setWaitingForData: (state, bool) => {
      state.waitingForData = bool;
    },

    // List interactions
    setFilterBy: (state, filterBy) => {
      state.filterBy = filterBy;
      state.lastRefresh = new Date();
    },
    setGroupSearchInput: (state, search) => {
      state.groupSearchInput = search;
    },
    setOrderBy: (state, orderBy) => {
      state.orderBy = orderBy;
    },
    setSelectedBroadcast: (state, broadcast) => {
      if (state.editingBroadcast) return;
      state.selectedBroadcast = broadcast;
    },
    triggerListRefresh: (state) => {
      state.lastRefresh = new Date();
    },
    setSelectedCampaigns: (state, campaignSelections) => {
      state.selectedCampaigns = campaignSelections;
    },
  },
  getters: {
    campaignsOptions: (state, getters, rootState) => {
      if (state.selectedCampaigns.length) {
        return state.selectedCampaigns;
      } else {
        const campaignsData = rootState.broadcastForm.campaignsData;
        return Object.keys(campaignsData).map((campaignId) => {
          return {
            display: campaignsData[campaignId].name,
            value: campaignId,
            checked: true,
          };
        });
      }
    },
    selectedCampaignIds: (state, getters, rootState) => {
      const allIds = Object.keys(rootState.broadcastForm.campaignsData);
      const selectedIds = allIds.filter((campaignId, i) => {
        return state.selectedCampaigns[i] && state.selectedCampaigns[i].checked;
      });
      if (selectedIds.length) {
        return selectedIds.join(',');
      } else {
        return allIds.join(',');
      }
    },
  },
  actions: {
    async handleSearchInput({ commit }, search) {
      if (search && !!search.trim() && 1 < search.trim().length) {
        commit('setGroupSearchInput', search);
      } else {
        commit('setGroupSearchInput', '');
      }
    },
  },
};
