var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width full-height content-page" },
    [
      _c("confirm-modal", { attrs: { show: _vm.modal === "CONFIRM" } }),
      _vm._v(" "),
      _c("upload-size-modal", { attrs: { show: _vm.modal === "UPLOAD_SIZE" } }),
      _vm._v(" "),
      _c("error-modal", { attrs: { show: _vm.modal === "ERROR" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            { "show-overlay": _vm.editingPair },
            "full-width",
            "content",
            "full-height",
            "overlay"
          ]
        },
        [
          _c(
            "page-header",
            { attrs: { header: "Questions and Answers (Q&A)" } },
            [
              _c(
                "btn",
                {
                  staticClass: "round create",
                  attrs: { icon: "add", raised: "" },
                  on: { click: _vm.createNewPair }
                },
                [_vm._v("\n        Q&A Pair\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("list-filters"),
          _vm._v(" "),
          _c("list-view", {
            attrs: {
              items: _vm.listItems,
              "load-more-data": _vm.loadMoreData,
              "waiting-for-data": _vm.waitingForData
            }
          }),
          _vm._v(" "),
          _vm.editingPair
            ? _c(
                "side-sheet",
                {
                  staticClass: "side-sheet--content",
                  attrs: {
                    onClose: function() {
                      return _vm.handleCloseSideSheet()
                    }
                  }
                },
                [
                  _c("qna-pair-form"),
                  _vm._v(" "),
                  _c("preview", {
                    attrs: {
                      buttonsPresent: _vm.buttonsPresent,
                      buttonsHorizontal: _vm.buttonsHorizontal,
                      chatPreviewData: _vm.chatPreviewData,
                      linksPresent: _vm.linksPresent,
                      linksHorizontal: _vm.linksHorizontal,
                      toggleButtonsPreview: _vm.toggleButtonsPreview,
                      toggleLinksPreview: _vm.toggleLinksPreview,
                      participants: _vm.participants
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.waitingForData
        ? _c(
            "div",
            { staticClass: "page-loading" },
            [_c("indeterminate-circle")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }