<!-- eslint-disable max-lines -->
<template>
  <article
    class="message-editor full-height"
    :class="{ 'message-editor--scrolling': scrolling }"
  >
    <section class="top-menu padding-tiny-around">
      <section class="broadcast-title">
        <input
          :value.sync="title"
          @keyup="setTitle"
          :placeholder="titlePlaceholder"
          class="broadcast-title-input"
          type="text"
        />
      </section>
      <btn class="submit-btn" :disabled="!allowSubmit" @click="submitContent">
        <span class="submit-text">Save</span>
      </btn>
    </section>
    <div class="creation-wrap">
      <div class="hide-top-shadow" />
      <section class="fixed-menu">
        <div v-if="initialContent" class="dropdown__disabled" />
        <dropdown
          leading-icon="search"
          :handle-primary-val="setTag"
          :options="tagOptions"
          :primary-val="dropdownVal"
          :disabled="!!initialContent"
        />
        <section class="buttons">
          <div class="add-content">
            <fab
              :disabled="editingItem"
              class="purple-fab"
              icon="title"
              @click="addAndScroll('text')"
            />
            <div class="fab-desc">Text</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-primary"
              :disabled="editingItem"
              icon="insert_photo"
              @click="addAndScroll('media')"
            />
            <div class="fab-desc">Media</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-secondary"
              :disabled="editingItem"
              icon="link"
              @click="addAndScroll('link')"
            />
            <div class="fab-desc">Web page</div>
          </div>
          <div class="add-content">
            <fab
              class="orange-fab"
              :disabled="editingItem"
              icon="crop_square"
              @click="addAndScroll('buttons')"
            />
            <div class="fab-desc">Buttons</div>
          </div>
        </section>
      </section>
      <section @scroll="handleScroll" class="content-items">
        <section class="content-row-wrap">
          <message-form-field
            v-for="(item, index) in content"
            class="edit-row"
            :id="editingPosition === index ? 'scroll-target' : ''"
            :key="item.text"
            :content="content"
            :cancelItem="cancelItem"
            :deleteAllLinks="deleteAllLinks"
            :editingPosition="editingPosition"
            :finishEditingItem="finishEditingItem"
            :item="item"
            :deleteItem="deleteItem"
            :editingItem="editingItem"
            :links="links"
            :lastLinkIndex="lastLinkIndex"
            :firstLinkIndex="firstLinkIndex"
            :position="index"
            :startEditing="startEditing"
            :saveItem="saveItem"
            :swapItems="swapItems"
            :moveLinksUp="moveLinksUp"
            :moveLinksDown="moveLinksDown"
          />
        </section>
        <section class="edit-row">
          <quick-reply-form-field
            v-if="editingButtons || buttons.length"
            :buttons="buttons"
            :id="editingButtons ? 'scroll-target' : ''"
            :editingButtons="editingButtons"
            :editingItem="editingItem"
            :deleteButtons="deleteButtons"
            :setButtons="setButtons"
            :setEditingButtons="setEditingButtons"
          />
        </section>
      </section>
    </div>
  </article>
</template>
<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import Dropdown from 'gameon-components/src/components/dropdown/dropdown.vue';
import Fab from 'gameon-components/src/components/fab/fab.vue';
import MessageFormField from '../../../../../components/message-form-field/message-form-field.vue';
import QuickReplyFormField from '../../../../../components/message-form-field/quick-reply-form-field/quick-reply-form-field.vue';

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { SNACK_TYPES } from '../../../../../constants/snackbar';

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);
const animationTime = 800;

export default {
  data() {
    const defaultTitle = 'Enter a name for this Content Service reference';
    return {
      submitting: false,
      scrolling: false,
      titlePlaceholder: defaultTitle,
    };
  },
  components: {
    Btn,
    Dropdown,
    Fab,
    MessageFormField,
    QuickReplyFormField,
  },
  computed: {
    ...mapGetters('user', ['bots']),
    ...mapGetters('contentForm', [
      'buttonsHorizontal',
      'contentReady',
      'editingItem',
      'firstLinkIndex',
      'lastLinkIndex',
      'links',
      'linksHorizontal',
    ]),
    ...mapState('contentForm', [
      'buttons',
      'content',
      'editingButtons',
      'editingPosition',
      'initialContent',
      'selectedTag',
      'title',
    ]),
    ...mapState('content', ['botTagOptions']),
    allowSubmit() {
      return this.contentReady && !this.submitting;
    },
    dropdownVal() {
      if (this.selectedTag) return this.selectedTag;
      if (this.tagOptions && this.tagOptions.length) return this.tagOptions[0];
      return '';
    },
    successText() {
      const addSuccess = 'Content successfully added to Content Service';
      const updateSuccess = 'Content successfully updated';
      return this.initialContent ? updateSuccess : addSuccess;
    },
    tagOptions() {
      if (this.botTagOptions) {
        const bot = Object.keys(this.botTagOptions)[0];
        return this.botTagOptions[bot].map((tag) => tag.name);
      }
      return [];
    },
  },
  methods: {
    ...mapMutations('contentList', ['triggerListRefresh']),
    ...mapActions('contentForm', [
      'addItem',
      'cancelItem',
      'clear',
      'deleteItem',
      'editButtons',
      'moveLinksDown',
      'moveLinksUp',
      'saveItem',
      'selectTag',
      'submit',
      'swapItems',
    ]),
    ...mapMutations('contentForm', [
      'deleteAllLinks',
      'deleteButtons',
      'finishEditingItem',
      'resetContent',
      'setButtons',
      'setTag',
      'setTitle',
      'setEditing',
      'setEditingButtons',
      'startEditing',
      'setInitialContent',
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('uploader', ['clearStates']),
    addAndScroll(mode) {
      switch (mode) {
        case 'buttons':
          this.editButtons();
          break;
        default:
          this.addItem(mode);
          break;
      }
      this.$nextTick(() => {
        this.scrollTo();
      });
    },
    resetForm() {
      this.setTag('');
      this.setEditing(false);
      this.setTitle('');
      this.resetContent();
      this.setInitialContent(null);
    },
    handleScroll(e) {
      this.scrolling = !!e.target.scrollTop;
    },
    scrollTo() {
      VueScrollTo.scrollTo('#scroll-target', animationTime, {
        container: '.content-items',
      });
    },
    async submitContent() {
      try {
        await this.submit();
        this.resetForm();
        const delaySnackTime = 3000;
        setTimeout(() => {
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: this.successText });
        }, delaySnackTime);
        this.$nextTick(() => {
          this.triggerListRefresh();
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      } finally {
        this.submitting = false;
      }
    },
  },
  deactivated() {
    this.clearStates();
  },
  beforeDestroy() {
    this.clearStates();
    this.resetForm();
  },
};
</script>
<style lang="scss" scoped>
@import './content-create.scss';
</style>
