var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width pagination" },
    [
      _vm.currentListPage === 1
        ? _c("icon", {
            staticClass: "pagination__arrow pagination__arrow--disabled",
            attrs: { icon: "keyboard_arrow_left" }
          })
        : _c("icon-btn", {
            staticClass: "pagination__arrow",
            attrs: { icon: "keyboard_arrow_left" },
            on: { click: _vm.handlePrevClick }
          }),
      _vm._v(" "),
      _c("div", { staticClass: "pagination__range" }, [
        _vm._v(_vm._s(_vm.beginRange) + " - " + _vm._s(_vm.endRange))
      ]),
      _vm._v(" "),
      _vm.reachedLastPage || _vm.waitingForData
        ? _c("icon", {
            staticClass: "pagination__arrow pagination__arrow--disabled",
            attrs: { icon: "keyboard_arrow_right" }
          })
        : _c("icon-btn", {
            staticClass: "pagination__arrow",
            attrs: { icon: "keyboard_arrow_right" },
            on: { click: _vm.handleNextClick }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }