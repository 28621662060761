<template>
  <m-list class="full-width options-container">
    <m-list-item class="full-width option" @click="handleClick">
      Delete
    </m-list-item>
  </m-list>
</template>
<script>
import { deleteQnaPair } from '../../../../../api/qna';
import { mapActions, mapMutations, mapState } from 'vuex';
import { SNACK_TYPES } from '../../../../../constants/snackbar';

import Vue from 'vue';
import List from 'material-components-vue/dist/list';
Vue.use(List);

export default {
  computed: {
    ...mapState('user', ['botData']),
    ...mapState('qnaForm', ['selectedPair']),
  },
  methods: {
    ...mapMutations('modal', ['showModal']),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('qnaList', ['triggerListRefresh']),
    ...mapActions('qnaForm', ['handleCloseSideSheet']),
    async handleClick(e) {
      // eslint-disable-next-line max-len
      const text =
        'Are you sure you want to delete this Question and Answer pair?';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        modalHeader: 'Delete QNA Pair',
        actionButton: 'Delete',
        cancelButton: 'Cancel',
        pendingAction: this.handleDelete,
      });
    },
    async handleDelete() {
      try {
        await deleteQnaPair(this.botData.key, this.selectedPair.id);

        this.handleCloseSideSheet();
        this.triggerListRefresh();
        this.$nextTick(() => {
          const SUCCESS_TEXT = 'QNA Pair successfully deleted';
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: SUCCESS_TEXT });
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log('error deleting qna pair', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'material-components-vue/dist/list/styles';
.options-container {
  max-height: 63px;
  overflow: hidden;
}
.option {
  cursor: default;

  &.active {
    cursor: pointer;
  }
}
</style>
