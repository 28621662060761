var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-view" },
    [
      _c("list-headers"),
      _vm._v(" "),
      _vm.currentPageItems.length
        ? _c(
            "m-list",
            { staticClass: "full-width" },
            [
              _c(
                "div",
                { staticClass: "list-view__rows" },
                _vm._l(_vm.currentPageItems, function(content) {
                  return _c(
                    "m-list-item",
                    {
                      key: content.id,
                      staticClass: "full-width",
                      on: {
                        click: function($event) {
                          return _vm.setInitialContent(content)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-title" },
                        [
                          content.thumbnailType.includes("text")
                            ? _c("fab-static", { attrs: { type: "text" } }, [
                                _vm._v("\n            T\n          ")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "thumbnail" },
                            [
                              content.fileType &&
                              content.fileType.includes("gif")
                                ? _c("static-gif", {
                                    attrs: {
                                      gif: content.body && content.body.link,
                                      title: content.body && content.body.title
                                    }
                                  })
                                : content.body &&
                                  content.body.mimeType &&
                                  content.body.mimeType.includes("video")
                                ? _c("img", {
                                    staticClass: "thumbnail__img",
                                    attrs: { src: content.body.thumbnail }
                                  })
                                : content.body &&
                                  content.body.mimeType &&
                                  content.body.mimeType.includes("image")
                                ? _c("img", {
                                    staticClass: "thumbnail__img",
                                    attrs: { src: content.body.link }
                                  })
                                : "links" === content.thumbnailType
                                ? _c("img", {
                                    staticClass: "thumbnail__img",
                                    attrs: { src: _vm.getLinkThumb(content) }
                                  })
                                : "media" === content.thumbnailType
                                ? _c("img", {
                                    staticClass: "thumbnail__img",
                                    attrs: { src: _vm.getMediaThumb(content) }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(content.titleTrimmed))]),
                          _vm._v(" "),
                          content.mimeTypeViewable
                            ? _c("span", { staticClass: "content-file-type" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(content.mimeTypeViewable) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.contentDescriptor
                            ? _c("span", { staticClass: "content-file-type" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.getContentDescriptorTrimmed(
                                        content.contentDescriptor
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !!content.durationViewable
                            ? _c("span", { staticClass: "center-dot" }, [
                                _vm._v("•")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.durationViewable
                            ? _c("span", { staticClass: "content-file-info" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(content.durationViewable) +
                                    " mins\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !!content.sizeViewable
                            ? _c("span", { staticClass: "center-dot" }, [
                                _vm._v("•")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.sizeViewable
                            ? _c("span", { staticClass: "content-file-info" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(content.sizeViewable) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.body.height && content.body.width
                            ? _c("span", { staticClass: "center-dot" }, [
                                _vm._v("\n            •\n          ")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.body.height && content.body.width
                            ? _c("span", { staticClass: "content-file-info" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(content.body.width) +
                                    " x " +
                                    _vm._s(content.body.height) +
                                    "\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content-tags" },
                        _vm._l(content.tags, function(tag) {
                          return _c("span", { key: tag.id }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(tag.name) +
                                "\n          "
                            )
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-edited" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(content.updatedAtViewable) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-created" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(content.createdAtViewable) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "scroll-pseudo-padding" }),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.waitingForData && !_vm.items.length
        ? _c("empty-list-loading")
        : !_vm.items.length && !_vm.waitingForData
        ? _c("empty-message", {
            attrs: {
              message:
                "The combination of search and sort filters yielded no results. Please try another."
            }
          })
        : _vm.waitingForData
        ? _c(
            "div",
            { staticClass: "broadcasts-loading" },
            [_c("indeterminate-circle")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          loadMoreData: _vm.loadMoreData,
          reachedLastPage: _vm.reachedLastPage,
          waitingForData: _vm.waitingForData,
          rowCountOnCurrentPage: _vm.rowCountOnCurrentPage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }