<template>
  <div class="list-view">
    <list-headers />
    <m-list v-if="items.length" class="full-width">
      <div class="list-view__rows">
        <m-list-item
          v-for="response in items"
          @click="handleRowClick(response)"
          class="full-width"
          :key="response.id"
        >
          <section class="answer">
            <div
              v-for="descriptor in getContentDescriptors(response.content)"
              :key="descriptor"
              class="answer__content-types"
            >
              {{ `${descriptor}` }}
            </div>
          </section>
          <section class="tags">
            {{ response.name }}
          </section>
          <section class="edited">
            {{ getViewableDate(response.updatedAt) }}
          </section>
          <section class="created">
            {{ getViewableDate(response.createdAt) }}
          </section>
          <!--<section class="score">
          {{response.score ? truncateScore(response.score) : ''}}
        </section>-->
        </m-list-item>
      </div>
      <div class="scroll-pseudo-padding" />
      <slot />
    </m-list>
    <empty-message
      v-else-if="!items.length && !waitingForData"
      message="The combination of search and sort filters yielded no results. Please try another."
    />
    <pagination
      :waitingForData="waitingForData"
      :rowCountOnCurrentPage="items.length"
    />
  </div>
</template>
<script>
import EmptyMessage from '../../../components/empty-message/empty-message.vue';
import List from 'material-components-vue/dist/list';
import ListHeaders from './list-headers.vue';
import Pagination from './pagination/pagination.vue';

import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  epochToListView,
  utcToEpoch,
} from '../../../helpers/conversions/time/time';
import { contentToForm } from '../../../helpers/content/content';
import { CONTENT_TYPES } from '../../../constants/content';
import { getResponseNames } from '../../../api/response-manager';

import Vue from 'vue';
Vue.use(List);

export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    loadMoreData: {
      required: true,
      type: Function,
    },
    waitingForData: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  components: {
    EmptyMessage,
    ListHeaders,
    Pagination,
  },
  computed: {
    ...mapState('responseManagerList', [
      'itemsPerPage',
      'currentListPage',
      'endOfList',
    ]),
    ...mapGetters('responseManagerList', [
      'firstRowIndex',
      'lastRowIndex',
      'reachedLastPage',
    ]),
    ...mapState('user', ['botData']),
  },
  methods: {
    ...mapMutations('responseManager', ['setSidesheetOpen']),
    ...mapMutations('responseManagerForm', ['setInitialRow']),
    ...mapMutations('responseManagerList', ['setResponseNameOptions']),
    getViewableDate(date) {
      return epochToListView(utcToEpoch(date));
    },
    truncateScore(score) {
      const percentage = 100;
      const nearestWholePercentage = 0;
      return (percentage * score).toFixed(nearestWholePercentage);
    },
    getContentDescriptors(content) {
      const contentFormData = contentToForm(content);
      const messageBody = contentFormData.content;
      const buttonsPresent = contentFormData.buttons.length;
      const messageBodyDescriptors = messageBody.map((messageItem) => {
        if (messageItem.mode === CONTENT_TYPES.text) return messageItem.text;
        if (messageItem.mode === CONTENT_TYPES.media) return '<media>';
        if (messageItem.mode === CONTENT_TYPES.link) return '<web pages>';
      });

      if (buttonsPresent) {
        return [...new Set(messageBodyDescriptors), '<buttons>'];
      } else {
        return [...new Set(messageBodyDescriptors)];
      }
    },
    handleRowClick(row) {
      this.setInitialRow(row);
      this.setSidesheetOpen(true);
    },
  },
  async mounted() {
    const responseNames = await getResponseNames(this.botData.key);
    this.setResponseNameOptions(responseNames);
  },
};
</script>
<style lang="scss" scoped>
@import 'material-components-vue/dist/list/styles';
@import '../../../components/list-view/list-view.scss';
@import './list-view.scss';
</style>
