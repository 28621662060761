<template>
  <g-dialog
    :value="show"
    :body="modalText"
    actionButton="Continue"
    header="Large File Size Warning"
    cancelButton="Cancel"
    @closed="handleChoice"
  />
</template>

<script>
import GDialog from 'gameon-components/src/components/dialog/dialog.vue';

import { DIALOG_ACTIONS } from '../constants';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  components: {
    GDialog,
  },
  props: ['show'],
  computed: {
    ...mapState('modal', ['modalText']),
  },
  methods: {
    ...mapActions('uploader', ['handleCancel']),
    ...mapMutations('modal', ['closeModal']),
    ...mapMutations('uploader', ['triggerUpload']),
    cancel() {
      this.handleCancel();
      this.closeModal();
    },
    continueUpload() {
      this.closeModal();
      this.triggerUpload();
    },
    handleChoice(result) {
      switch (result.action) {
        case DIALOG_ACTIONS.ACTION:
          this.continueUpload();
          break;
        default:
          this.cancel();
          break;
      }
    },
  },
};
</script>
