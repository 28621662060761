var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "media-uploader" },
    [
      _c("uploader", {
        attrs: {
          "allow-preview": true,
          editingSelf: true,
          initialLink: "",
          initialThumbnail: "",
          initialType: ""
        },
        on: {
          itemUpdated: _vm.uploaderUpdatedItem,
          uploaderPreviewRemoved: function($event) {
            _vm.previewLoaded = false
          },
          uploaderPreviewLoaded: function($event) {
            _vm.previewLoaded = true
          }
        }
      }),
      _vm._v(" "),
      _vm.thumbnailOptions && !!_vm.thumbnailOptions.length && !_vm.uploading
        ? _c("thumbnail-options", {
            attrs: {
              helper: "Applicable only when video does not autoplay",
              label: "Select Thumbnail",
              "thumbnail-choices": _vm.thumbnailOptions,
              value: _vm.itemData.thumbnail
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.itemData, "thumbnail", $event)
              },
              selectOption: _vm.handleThumbnailSelect
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("text-field", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTitle,
            expression: "showTitle"
          }
        ],
        staticClass: "title",
        attrs: {
          label: "Title",
          textarea: true,
          disabled: false,
          value: _vm.title,
          filled: ""
        },
        on: {
          "update:value": function($event) {
            _vm.title = $event
          },
          keyup: function() {
            return _vm.updateFormData("title")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }