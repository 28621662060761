var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header-text" }, [
      _c("img", {
        attrs: { src: "/dist/assets/icon-global-message-editor.svg" }
      }),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.editor) + " editor\n      "),
        _c("span", { staticStyle: { margin: "0 4px" } }, [_vm._v(">")]),
        _vm._v(" "),
        _c("span", { staticClass: "header-text--black" }, [
          _vm._v(_vm._s(_vm.mode))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }