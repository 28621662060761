const JOB_FREQUENCY = 60000;

const setup = (store) => {
  store.dispatch('user/getUser');
};

const runJobs = (store) => {
  store.dispatch('user/checkToken');
};

module.exports = (store) => {
  setup(store);
  runJobs(store);

  setInterval(() => runJobs(store), JOB_FREQUENCY);
};
