<template>
  <div class="list-filters full-width">
    <search-filter
      :handleSearchInput="handleSearchInput"
      placeholder="Search Questions And Phrasings"
    />
    <single-select
      v-if="tagOptions"
      class="filter-by list-filter"
      :handle-choice="setSelectedTag"
      :options="tagOptions"
      :upstream-val="selectedTag"
    />
  </div>
</template>
<script>
import SearchFilter from '../../../../components/search-filter/search-filter.vue';
import SingleSelect from 'gameon-components/src/components/single-select/single-select.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    SearchFilter,
    SingleSelect,
  },
  computed: {
    ...mapState('user', ['botData']),
    ...mapState('qnaList', ['selectedTag', 'tagOptions']),
    ...mapGetters('qnaList', ['tagOptions']),
  },
  methods: {
    ...mapMutations('qnaList', ['setSelectedTag']),
    ...mapActions('qnaList', ['handleSearchInput']),
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../components/list-filters/list-filters.scss';
.filter-by {
  margin-right: 12px;
  margin-left: 32px;
  height: 48px;
  display: flex;
  align-items: center;
}
</style>
