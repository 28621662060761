const assert = require('assert');
const { authenticate, getBotConfig, user, tokenCheck } = require('../../api');

export default {
  namespaced: true,
  state: {
    botData: null,
    loggedIn: false,
    attemptingLogin: false,
    loginAttemptFailed: false,
    data: {},
    lastClear: '',
  },
  mutations: {
    authFailed: (state) => {
      state.loggedIn = false;
    },
    authSucceeded: (state) => {
      state.loggedIn = true;
      state.loginAttemptFailed = false;
    },
    clearLogin: (state) => {
      state.lastClear = new Date();
    },
    loginAttemptFailed: (state) => {
      state.loginAttemptFailed = true;
    },
    setBot: (state, botObj) => {
      state.botData = botObj;
    },
    setUser: (state, payload) => {
      state.data = payload;
    },
  },
  actions: {
    login: async ({ commit, dispatch }, { userName, password }) => {
      const success = await authenticate(userName, password);

      if (success) {
        commit('authSucceeded');
        dispatch('getUser');
        commit('clearLogin');
      } else {
        commit('authFailed');
        commit('loginAttemptFailed');
      }
    },
    logout: async ({ commit }) => {
      commit('authFailed');
      await fetch('/logout', { method: 'POST' });
      window.location.reload();
    },
    getUser: async ({ commit }) => {
      let botSaved = false;
      const data = await user();
      if (data.apps) {
        const pages = Object.keys(data.apps);
        for (let i = 0; i < pages.length; i = i + 1) {
          const bots = data.apps[pages[i]].bots;
          if (!botSaved && bots && bots.length && 'schedule' !== pages[i]) {
            const botKey = data.apps[pages[i]].bots[0].name;
            const botData = await getBotConfig(botKey);
            commit('setBot', botData);
            botSaved = true;
          }
        }
        commit('setUser', data);
      }
    },
    checkToken: async ({ commit }) => {
      try {
        const ok = await tokenCheck();
        assert(ok);
        commit('authSucceeded');
      } catch (e) {
        commit('authFailed');
      }
    },
  },
  getters: {
    apps: (state) => {
      return (state.data || {}).apps;
    },
    bots: (state) => {
      try {
        return state.data.apps.publish.bots;
      } catch (e) {
        return [];
      }
    },
    bot: (state) => {
      try {
      } catch {
        return null;
      }
    },
    configurableApps: (state) => {
      try {
        return state.data.apps['config-manager'].bots;
      } catch (e) {
        return [];
      }
    },
    scheduleApps: (state) => {
      try {
        return state.data.apps.schedule.bots.map((bot) => bot.name);
      } catch (e) {
        return [];
      }
    },
    scheduleAppOptions: (state) => {
      try {
        return state.data.apps.schedule.bots.map((bot) => {
          return {
            display: bot.name,
            value: bot.name,
          };
        });
      } catch (e) {
        return [];
      }
    },
  },
};
