<template>
  <g-dialog
    :value="show"
    :body="modalText"
    :header="modalHeader || 'Error'"
    :actionButton="actionButton || 'Okay'"
    @closed="close"
  />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import GDialog from 'gameon-components/src/components/dialog/dialog.vue';

export default {
  components: {
    GDialog,
  },
  props: ['show'],
  computed: {
    ...mapState('modal', ['modalHeader', 'modalText', 'actionButton']),
  },
  methods: {
    ...mapMutations('modal', ['closeModal']),
    close() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped></style>
