var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobs-table full-width" },
    [
      _c("div", { staticClass: "title-toolbar full-width" }, [
        _c(
          "section",
          { staticClass: "toolbar" },
          [
            _c("text-field", {
              attrs: {
                value: _vm.search,
                type: "text",
                label: "Search",
                filled: ""
              },
              on: {
                "update:value": function($event) {
                  _vm.search = $event
                }
              }
            }),
            _vm._v(" "),
            _c(
              "btn",
              {
                staticClass: "round create",
                attrs: { disabled: _vm.editing, icon: "add", raised: "" },
                on: { click: _vm.makeJob }
              },
              [_vm._v("\n        New\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dropdowns full-width" }, [
        _c(
          "div",
          { staticClass: "dropdown" },
          [
            _c("div", { staticClass: "select-label" }, [_vm._v("App")]),
            _vm._v(" "),
            _c("single-select", {
              attrs: {
                "handle-choice": _vm.handleSelectApp,
                options: _vm.scheduleAppOptions,
                "upstream-val": _vm.app
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dropdown" },
          [
            _c("div", { staticClass: "select-label" }, [_vm._v("Date Format")]),
            _vm._v(" "),
            _c("single-select", {
              attrs: {
                "handle-choice": _vm.changeDateFormat,
                options: _vm.dateOptions,
                "upstream-val": _vm.dateFormat
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("vue-good-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.filteredJobs,
          "pagination-options": {
            enabled: true,
            mode: "records",
            perPage: 10,
            position: "top",
            perPageDropdown: [5, 10, 20, 50],
            dropdownAllowAll: true,
            setCurrentPage: 1,
            nextLabel: "next",
            prevLabel: "prev",
            rowsPerPageLabel: "Rows per page",
            ofLabel: "of",
            pageLabel: "page",
            allLabel: "All"
          }
        },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function(props) {
              return [
                props.column.field == "schedule"
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.editSelected(props.row)
                          }
                        }
                      },
                      _vm._l(props.row.schedule, function(schedule) {
                        return _c("div", { key: schedule.starting }, [
                          _vm._v(
                            "\n          Starting: " +
                              _vm._s(_vm.toHumanReadable(schedule.starting)) +
                              "\n          "
                          ),
                          schedule.ending
                            ? _c("div", [
                                _vm._v(
                                  "\n            Ending: " +
                                    _vm._s(
                                      _vm.toHumanReadable(schedule.ending)
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          schedule.repeat
                            ? _c("div", [
                                _vm._v(
                                  "\n            Every " +
                                    _vm._s(schedule.quantity) +
                                    " " +
                                    _vm._s(schedule.unit) +
                                    "(s)\n          "
                                )
                              ])
                            : _vm._e()
                        ])
                      }),
                      0
                    )
                  : props.column.field === "#"
                  ? _c("span", [
                      _vm._v("\n        " + _vm._s(props.index) + "\n      ")
                    ])
                  : props.column.field === "edit"
                  ? _c("span", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.editSelected(props.row)
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n      "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }