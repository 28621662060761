<template>
  <div
    class="button-container"
    ref="container"
    @scroll="updateCarouselProperties"
  >
    <transition name="fade">
      <section v-show="showLeftButton" class="icon-bg icon--left" @click="left">
        <icon class="arrow" icon="keyboard_arrow_left" />
      </section>
    </transition>
    <div class="spacer">&nbsp;</div>
    <single-button
      v-for="button in buttons"
      :key="button.title"
      :text="button.title"
      class="button"
    />
    <div class="spacer">&nbsp;</div>
    <transition name="fade">
      <section
        v-show="showRightButton"
        class="icon-bg icon--right"
        @click="right"
      >
        <icon class="arrow" icon="keyboard_arrow_right" />
      </section>
    </transition>
  </div>
</template>

<script>
import SingleButton from '../single-button/single-button.vue';
import Icon from 'gameon-components/src/components/icon/icon.vue';

export default {
  components: {
    Icon,
    SingleButton,
  },
  data() {
    return {
      scrollLeft: 0,
      totalScrollWidth: 0,
    };
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
  computed: {
    showLeftButton() {
      try {
        return 0 < this.scrollLeft;
      } catch (e) {
        return false;
      }
    },
    showRightButton() {
      try {
        const prettyMuchTheEnd = 4;
        const containerWidth = 436;
        const endOfScroll =
          this.totalScrollWidth - this.scrollLeft - containerWidth <
          prettyMuchTheEnd;
        const minScrollWidth = 310;
        return this.totalScrollWidth > minScrollWidth && !endOfScroll;
      } catch (e) {
        return false;
      }
    },
  },
  methods: {
    left() {
      this.$refs.container.scrollLeft -= 200;
      this.updateCarouselProperties();
    },
    right() {
      this.$refs.container.scrollLeft += 200;
      this.updateCarouselProperties();
    },
    updateCarouselProperties() {
      this.$nextTick(() => {
        if (this.$refs.container) {
          this.scrollLeft = this.$refs.container.scrollLeft;
          this.totalScrollWidth = this.$refs.container.scrollWidth;
        }
      });
    },
  },
  watch: {
    buttons: {
      deep: true,
      handler() {
        this.updateCarouselProperties();
      },
    },
  },
  mounted() {
    this.updateCarouselProperties();
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 34px;
  white-space: nowrap;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  &.example {
    -ms-overflow-style: none;
  }
}

.single-button {
  display: inline-flex;
}

.spacer {
  display: inline-flex;
  width: 98px;
}

.icon-bg {
  position: absolute;
  z-index: 9;
  background-color: #f3f3f3;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 30px);
  cursor: pointer;

  &:hover .arrow {
    color: $black-high-emphasis;
  }
}

.icon--left {
  left: 16px;
}

.icon--right {
  right: 16px;
}

.arrow {
  color: $black-inactive;
  font-size: 36px;
}
.fade-enter {
  transition-delay: 0.5s;
}
.fade-enter-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
