/* eslint-disable max-lines */
import axios from 'axios';
import { capitalize } from '../../helpers/conversions/grammar';
import { getVideoDuration } from '../../helpers/videos/videos';
import { bytesViewable } from '../../helpers/conversions/meta-data/meta-data';
import {
  FEATURE_TYPES,
  FILE_LIMITATIONS,
  ORDERED_UPLOADER_STEPS,
  PROGRESS,
  UPLOAD_ERRORS,
} from './constants';

export default {
  namespaced: true,
  state: {
    continueUpload: null,
    errors: {
      fullsize: false,
      thumbnail: false,
      network: false,
      other: false,
    },
    file: null,
    validationProgress: 0,
    itemLink: '',
    itemThumbnail: '',
    itemType: '',
    previousItemState: {},
    thumbnailLoaded: false,
    uploadProgress: 0,
    uploadXhr: null,
    currentUploadingStep: '',
    videoProgressUrl: '',
  },
  mutations: {
    setRefreshInterval: (state, func) => {
      state.refreshInterval = func;
    },
    clearError: (state, type) => {
      state.errors[UPLOAD_ERRORS[type]] = false;
    },
    clearPreviousItem: (state) => {
      state.previousItemState = {};
    },
    clearStates: (state) => {
      const initialItem = { ...state.previousItemState };
      state.errors = {
        fullsize: false,
        thumbnail: false,
        network: false,
        other: false,
      };
      state.validationProgress = 0;
      state.file = null;
      state.itemThumbnail = initialItem.thumbnail || '';
      state.itemLink = initialItem.link || '';
      state.itemType = initialItem.type || '';
      state.previousItemState = { ...initialItem };
      state.thumbOption = 0;
      state.thumbnailLoaded = false;
      state.uploadProgress = 0;
      state.videoProgressUrl = '';
      state.currentUploadingStep = '';

      if (state.uploadXhr) {
        state.uploadXhr.abort();
        state.uploadXhr = undefined;
      }
    },
    setError: (state, type = 'OTHER') => {
      state.errors[UPLOAD_ERRORS[type]] = true;
    },
    setInitialItem: (state, initialItem) => {
      state.previousItemState = { ...initialItem };
      state.itemThumbnail = initialItem.thumbnail || '';
      state.itemLink = initialItem.link || '';
      state.itemType = initialItem.type || '';
    },
    // Make sure state var exists and val is same type
    setUploaderState: (state, { varName, val }) => {
      const varExists = varName in state;
      const typesMatch = typeof val === typeof state[varName];

      if (Array.isArray(state[varName])) {
        // Prevent dev from assigning object to an array variable
        if (Array.isArray(val) && varExists) {
          state[varName] = val;
        }
      } else if (varExists && typesMatch) {
        switch (typeof state[varName]) {
          case 'object':
            // Prevent dev from assigning array to an object variable
            if (!Array.isArray(val)) {
              state[varName] = val;
            }
            break;
          default:
            state[varName] = val;
            break;
        }
      }
    },
    setCurrentUploadingStep: (state, step) => {
      if ('uploadCancelled' === state.currentUploadingStep) return;
      if (ORDERED_UPLOADER_STEPS.includes(step))
        state.currentUploadingStep = step;
    },
    setUploadProgress: (state, xhrEvent) => {
      if (xhrEvent.loaded / xhrEvent.total > state.uploadProgress) {
        state.uploadProgress = xhrEvent.loaded / xhrEvent.total;
      }
    },
    setThumbnailLoaded: (state) => {
      state.thumbnailLoaded = true;
    },
    triggerUpload: (state) => {
      state.continueUpload = new Date();
    },
    updateItem: (state, item) => {
      state.itemLink = item.link;
      state.itemThumbnail = item.thumb;
      state.itemType = item.type;
      state.previousItemState = {
        link: item.link,
        thumbnail: item.thumb,
        type: item.type,
      };
    },
  },
  actions: {
    checkFile: async (
      { commit, dispatch, getters, state },
      { feature, file }
    ) => {
      try {
        commit('clearStates');
        commit('setCurrentUploadingStep', 'uploadStarted');
        commit('setUploaderState', { varName: 'file', val: file });
        const maxSize = FILE_LIMITATIONS.MAX_VIDEO_SIZE_BYTES[feature];
        const warnSize = FILE_LIMITATIONS.WARN_VIDEO_SIZE_BYTES[feature];

        let vidDurationExceeded = false;
        if (
          'video' === getters.fileType &&
          FEATURE_TYPES.BROADCAST === feature
        ) {
          const vidDuration = await getVideoDuration(state.file);
          vidDurationExceeded =
            vidDuration > FILE_LIMITATIONS.MAX_VIDEO_DURATION;
        }
        const largeFile = state.file.size > warnSize;
        const fileSizeExceeded = state.file.size > maxSize;

        if (fileSizeExceeded && vidDurationExceeded) {
          commit('setCurrentUploadingStep', 'uploadCancelled');
          const allExceedText = `Video duration cannot be larger than ${bytesViewable(
            maxSize
          )}
             and longer than ${FILE_LIMITATIONS.MAX_MIN} mins. 
             Please try again with another video.`;
          commit(
            'modal/showModal',
            {
              modalHeader: 'Change Needed',
              modalType: 'ERROR',
              modalText: allExceedText,
            },
            { root: true }
          );
          commit('clearStates');
        } else if (vidDurationExceeded) {
          commit('setCurrentUploadingStep', 'uploadCancelled');
          const durationText = `Video duration cannot be longer than ${FILE_LIMITATIONS.MAX_MIN} mins. 
             Please try again with a shorter video.`;
          commit(
            'modal/showModal',
            {
              modalHeader: 'Change Needed',
              modalType: 'ERROR',
              modalText: durationText,
            },
            { root: true }
          );
          commit('clearStates');
        } else if (fileSizeExceeded) {
          commit('setCurrentUploadingStep', 'uploadCancelled');
          const fileType = getters.fileType.slice();
          const fileSizeText = `${capitalize(
            fileType
          )} size cannot be larger than ${bytesViewable(maxSize)}.
            Please try again with a smaller ${fileType}.`;
          commit(
            'modal/showModal',
            {
              modalHeader: 'Change Needed',
              modalType: 'ERROR',
              modalText: fileSizeText,
            },
            { root: true }
          );
          commit('clearStates');
        } else if (largeFile) {
          const largeFileText =
            'The selected file is large. Processing may take some time.';
          commit(
            'modal/showModal',
            {
              modalType: 'UPLOAD_SIZE',
              modalText: largeFileText,
            },
            { root: true }
          );
        } else {
          dispatch('upload');
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log('file upload error', err);
        commit('setCurrentUploadingStep', 'uploadCancelled');
        const readText = `There were issues with reading your file.
           Please check your file or contact your administrator if the issue persists.`;
        commit(
          'modal/showModal',
          { modalType: 'ERROR', modalText: readText },
          { root: true }
        );
        commit('clearStates');
      }
    },
    errorModal: ({ commit, state }) => {
      let errorModalText;
      if (state.errors.fullsize) {
        errorModalText =
          'The uploaded file appears to be corrupted. Please refresh and upload a new file.';
      } else if (state.errors.network) {
        errorModalText =
          'A network error occurred uploading the file. Please refresh and try again.';
      } else {
        errorModalText = 'An unknown error occurred. Please try again later.';
      }
      commit(
        'modal/showModal',
        { modalType: 'ERROR', modalText: errorModalText },
        { root: true }
      );
      commit('clearStates');
    },
    finishUploading: ({ commit }, item) => {
      commit('setUploaderState', { varName: 'currentUploadingStep', val: '' });
      commit('updateItem', item);
    },
    handleCancel: ({ commit, state }) => {
      commit('setCurrentUploadingStep', 'uploadCancelled');
      clearInterval(state.refreshInterval);
      commit('clearStates');
    },
    handleError: ({ commit, dispatch }, type) => {
      dispatch('removeFile');
      commit('clearStates');
      dispatch('handleCancel');
      commit('setError', type);
      dispatch('errorModal');
    },
    removeFile: ({ commit }) => {
      commit('setCurrentUploadingStep', 'uploadCancelled');
      commit('setUploaderState', { varName: 'file', val: null });
    },
    selectThumbnailOption: ({ commit, state }, option) => {
      const updatedThumb = state.itemThumbnail.replace(
        /(?:thumbnail\/)([0-9]+)/,
        `thumbnail/${option}`
      );
      commit('setUploaderState', {
        varName: 'itemThumbnail',
        val: updatedThumb,
      });
    },
    tryToFetch: async ({ commit, dispatch, state, getters }, item) => {
      try {
        await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = () => {
            resolve();
          };
          xhr.onerror = (e) => {
            reject(e);
          };
          xhr.open('GET', item.link, true);
          xhr.send();
        });
        // prime all thumbnails
        if (getters.fileType.includes('video')) {
          const totalThumbnails = 5;
          const thumbnailOptions = [];
          for (let i = 0; i < totalThumbnails; i = i + 1) {
            const thumbnail = item.thumb.replace(
              /(?:thumbnail\/)([0-9]+)/,
              `thumbnail/${i}`
            );
            thumbnailOptions.push(thumbnail);
          }
          await Promise.all(
            thumbnailOptions.map(async (thumbnailUrl) => {
              return fetch(thumbnailUrl);
            })
          );
        } else {
          await fetch(item.thumb);
        }
        // if only one uploading state, remove fullSizeReady everywhere
        dispatch('finishUploading', item);
        commit('clearError', 'FULLSIZE');
      } catch (e) {
        if (!getters.uploading) {
          dispatch('handleCancel');
          return;
        }
        // eslint-disable-next-line
        console.log('e', e);
        dispatch('handleError', 'FULLSIZE');
        dispatch('removeFile');
      }
    },
    upload: async ({ commit, dispatch, getters, state }) => {
      if (!getters.uploading) {
        dispatch('handleCancel');
        return;
      }
      const formData = new FormData();
      formData.append('feature', window.location.pathname.split('/app/')[1]);
      formData.append('file', state.file);

      const xhr = new XMLHttpRequest();
      commit('setUploaderState', { varName: 'uploadXhr', val: xhr });
      commit('setCurrentUploadingStep', 'savingToServer');
      xhr.upload.onprogress = (xhrEvent) => {
        commit('setUploadProgress', {
          loaded: xhrEvent.loaded,
          total: xhrEvent.total,
        });
      };
      xhr.onload = (xhrEvent) => {
        if (!getters.uploading) {
          dispatch('handleCancel');
          return;
        }
        try {
          const respData = JSON.parse(xhr.response);

          if ('ok' !== respData.status) {
            dispatch('handleError');
            return;
          }

          commit('setUploaderState', {
            varName: 'videoProgressUrl',
            val: respData.progressUrl,
          });
          const item = {
            thumb: respData.thumbnail || state.itemThumbnail,
            link: respData.resizedUrl || state.itemLink,
            type: respData.type || state.itemType,
          };
          commit('clearError', 'NETWORK');
          dispatch('validateAndPrimeUpload', item);
        } catch (networkError) {
          dispatch('handleError', 'NETWORK');
        }
      };
      try {
        xhr.open('POST', '/v1/upload');
        xhr.send(formData);
      } catch (networkError) {
        dispatch('handleError', 'NETWORK');
      }
    },
    // Fetching link of the uploaded asset once
    // Validates link and caches the asset in CDN
    validateAndPrimeUpload: async (
      { commit, dispatch, getters, state },
      item
    ) => {
      if (!getters.uploading) {
        dispatch('handleCancel');
        return;
      }
      if (!state.videoProgressUrl) {
        dispatch('tryToFetch', item);
        return;
      }
      commit('setCurrentUploadingStep', 'validating');
      try {
        commit(
          'setRefreshInterval',
          setInterval(async () => {
            const result = (await axios.get(state.videoProgressUrl)).data;
            if (result.error) {
              clearInterval(state.refreshInterval);
              dispatch('handleError', 'FULLSIZE');
              return;
            }

            const progress = Math.floor(result.percent || 0) / PROGRESS.DONE;
            if (1 === progress) {
              clearInterval(state.refreshInterval);
              dispatch('tryToFetch', item);
            } else if (progress > state.validationProgress) {
              commit('setUploaderState', {
                varName: 'validationProgress',
                val: progress,
              });
            }
          }, PROGRESS.REFRESH_INTERVAL)
        );
      } catch (progressErr) {
        clearInterval(state.refreshInterval);
        dispatch('handleError', 'FULLSIZE');
      }
    },
  },
  getters: {
    fileType: (state) => {
      if (state.file && state.file.type) {
        return state.file.type.split('/')[0];
      } else if (state.previousItemState && state.previousItemState.type) {
        return state.previousItemState.type.split('/')[0];
      }
      return null;
    },
    linkUnchanged: (state) => {
      return state.previousItemState.link === state.itemLink;
    },
    showDefaultImg: (state) => {
      const thumbnailPending =
        'savingToServer' === state.currentUploadingStep ||
        'validating' === state.currentUploadingStep;
      return (
        thumbnailPending || !!(state.errors.thumbnail || !state.itemThumbnail)
      );
    },
    thumbUnchanged: (state) => {
      return state.previousItemState.thumbnail === state.itemThumbnail;
    },
    totalUploadProgress: (state, getters) => {
      if (getters.fileType && getters.fileType.includes('video')) {
        const uploadRatio = 0.3;
        const validationRatio = 0.7;
        return (
          state.validationProgress * validationRatio +
          state.uploadProgress * uploadRatio
        );
      }
      return state.uploadProgress;
    },
    // Upload validation and thumbnail loading happen independently but both need to be ready before
    // readily content is available in intents
    uploading: (state) => {
      const positiveUploadingStates = [
        'savingToServer',
        'validating',
        'uploadValidated',
      ];
      if (positiveUploadingStates.includes(state.currentUploadingStep)) {
        return true;
      } else {
        return (
          !!state.currentUploadingStep &&
          'uploadValidated' !== state.currentUploadingStep &&
          !state.thumbnailLoaded &&
          !!('uploadCancelled' !== state.currentUploadingStep)
        );
      }
    },
    uploaderHasError: (state) => {
      return (
        !!state.errors.thumbnail ||
        !!state.errors.fullsize ||
        !!state.errors.network ||
        !!state.errors.other
      );
    },
  },
};
