<template>
  <article class="message-editor full-height">
    <section class="top-menu padding-tiny-around">
      <section class="sidesheet-title">
        <span v-if="!selectedPair">Add new Question and Answer pairing</span>
      </section>
      <div class="top-menu__options">
        <btn class="submit-btn" :disabled="!allowSubmit" @click="submitContent">
          <span class="submit-text">Save</span>
        </btn>
        <more-menu v-if="selectedPair" :button="true">
          <more-qna-options class="option-btn" />
        </more-menu>
      </div>
    </section>
    <div class="creation-wrap">
      <div class="hide-top-shadow" />
      <section class="fixed-menu">
        <questions />

        <label class="overline">ANSWER</label>
        <suggest-select
          label="Tag"
          leading-icon="search"
          :handle-primary-val="setSelectedTag"
          :options="flattenedTagOptions"
          :primary-val="selectedTag"
        />
        <section class="buttons">
          <div class="add-content">
            <fab
              :disabled="editingItem"
              class="purple-fab"
              icon="title"
              @click="addAndScroll('text')"
            />
            <div class="fab-desc">Text</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-primary"
              :disabled="editingItem"
              icon="insert_photo"
              @click="addAndScroll('media')"
            />
            <div class="fab-desc">Media</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-secondary"
              :disabled="editingItem"
              icon="link"
              @click="addAndScroll('link')"
            />
            <div class="fab-desc">Web page</div>
          </div>
          <div class="add-content">
            <fab
              class="orange-fab"
              :disabled="editingItem"
              icon="crop_square"
              @click="addAndScroll('buttons')"
            />
            <div class="fab-desc">Buttons</div>
          </div>
        </section>
      </section>
      <section class="content-items">
        <section class="content-row-wrap">
          <message-form-field
            v-for="(item, index) in message"
            class="edit-row"
            :id="editingPosition === index ? 'scroll-target' : ''"
            :key="item.text"
            :content="message"
            :cancelItem="cancelItem"
            :deleteAllLinks="deleteAllLinks"
            :editingPosition="editingPosition"
            :finishEditingItem="finishEditingItem"
            :item="item"
            :deleteItem="deleteItem"
            :editingItem="editingItem"
            :links="links"
            :lastLinkIndex="lastLinkIndex"
            :firstLinkIndex="firstLinkIndex"
            :position="index"
            :startEditing="startEditing"
            :saveItem="saveItem"
            :swapItems="swapItems"
            :moveLinksUp="moveLinksUp"
            :moveLinksDown="moveLinksDown"
          />
        </section>
        <section class="edit-row">
          <quick-reply-form-field
            v-if="editingButtons || buttons.length"
            :buttons="buttons"
            :id="editingButtons ? 'scroll-target' : ''"
            :editingButtons="editingButtons"
            :editingItem="editingItem"
            :deleteButtons="deleteButtons"
            :setButtons="setButtons"
            :setEditingButtons="setEditingButtons"
          />
        </section>
      </section>
    </div>
  </article>
</template>
<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import SuggestSelect from 'gameon-components/src/components/suggest-select/suggest-select.vue';
import Fab from 'gameon-components/src/components/fab/fab.vue';
import MessageFormField from '../../../../components/message-form-field/message-form-field.vue';
import MoreMenu from '../../../../components/more-menu/more-menu.vue';
import MoreQnaOptions from './more-qna-options/more-qna-options.vue';
import Questions from './qna-questions/qna-questions.vue';
import QuickReplyFormField from '../../../../components/message-form-field/quick-reply-form-field/quick-reply-form-field.vue';

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { SNACK_TYPES } from '../../../../constants/snackbar';

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);
const animationTime = 800;

export default {
  data() {
    return {
      submitting: false,
    };
  },
  components: {
    Btn,
    Fab,
    MessageFormField,
    MoreMenu,
    MoreQnaOptions,
    Questions,
    QuickReplyFormField,
    SuggestSelect,
  },
  computed: {
    ...mapGetters('user', ['bots']),
    ...mapGetters('qnaForm', [
      'buttonsHorizontal',
      'messageReady',
      'editingItem',
      'firstLinkIndex',
      'formChanged',
      'lastLinkIndex',
      'links',
      'linksHorizontal',
    ]),
    ...mapState('qnaForm', [
      'questions',
      'buttons',
      'message',
      'editingButtons',
      'editingPosition',
      'selectedTag',
      'selectedPair',
      'title',
    ]),
    ...mapGetters('qnaList', ['tagOptions']),
    allowSubmit() {
      if (this.selectedPair) {
        return this.messageReady && !this.submitting && this.formChanged;
      } else {
        return this.messageReady && !this.submitting;
      }
    },
    dropdownVal() {
      if (this.selectedTag) return this.selectedTag;
      if (this.tagOptions && this.tagOptions.length) return this.tagOptions[0];
      return '';
    },
    successText() {
      const updateSuccess = 'Questions and Answers successfully updated';
      return updateSuccess;
    },
    flattenedTagOptions() {
      if (this.tagOptions) {
        return this.tagOptions
          .map((tag) => tag.value)
          .slice(1, this.tagOptions.length);
      }
      return [];
    },
  },
  methods: {
    ...mapMutations('qnaList', ['triggerListRefresh']),
    ...mapActions('qnaForm', [
      'addItem',
      'cancelItem',
      'clear',
      'deleteItem',
      'editButtons',
      'moveLinksDown',
      'moveLinksUp',
      'saveItem',
      'selectTag',
      'submit',
      'swapItems',
    ]),
    ...mapMutations('qnaForm', [
      'deleteAllLinks',
      'deleteButtons',
      'finishEditingItem',
      'resetForm',
      'setButtons',
      'setSelectedTag',
      'setEditing',
      'setEditingButtons',
      'startEditing',
      'setInitialPair',
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('uploader', ['clearStates']),
    addAndScroll(mode) {
      switch (mode) {
        case 'buttons':
          this.editButtons();
          break;
        default:
          this.addItem(mode);
          break;
      }
      this.$nextTick(() => {
        this.scrollTo();
      });
    },
    resetSidesheet() {
      this.resetForm();
      this.setInitialPair(null);
      this.setEditing(false);
    },
    scrollTo() {
      VueScrollTo.scrollTo('#scroll-target', animationTime, {
        container: '.creation-wrap',
      });
    },
    async submitContent() {
      try {
        await this.submit();
        this.resetSidesheet();
        const delaySnackTime = 3000;
        setTimeout(() => {
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: this.successText });
        }, delaySnackTime);
        this.$nextTick(() => {
          this.triggerListRefresh();
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      } finally {
        this.submitting = false;
      }
    },
  },
  deactivated() {
    this.clearStates();
    this.resetSidesheet();
  },
  beforeDestroy() {
    this.clearStates();
    this.resetSidesheet();
  },
};
</script>
<style lang="scss" scoped>
@import './qna-pair-form.scss';
</style>
