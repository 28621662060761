var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-filters full-width" },
    [
      _c("search-filter", {
        attrs: {
          handleSearchInput: _vm.handleSearchInput,
          placeholder: "placeholder"
        }
      }),
      _vm._v(" "),
      _c("icon", { staticClass: "search-icon", attrs: { icon: "search" } }),
      _vm._v(" "),
      _vm.showCampaignsFilter &&
      _vm.campaignsOptions &&
      _vm.campaignsOptions.length
        ? _c("checklist-dropdown", {
            staticClass: "campaign-dropdown list-filter",
            attrs: {
              options: _vm.campaignsOptions,
              "handle-choice": _vm.setSelectedCampaigns,
              title: "Campaigns"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("single-select", {
        staticClass: "filter-by list-filter",
        attrs: {
          "handle-choice": _vm.setFilterBy,
          options: _vm.filterByOptions,
          "upstream-val": _vm.filterBy
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }