<template>
  <section
    class="edit-row edit-row--link"
    :class="{ 'parent-bg': !editingItem && hover && !childHover }"
    @mouseleave="hover = false"
    @mouseover="hover = true"
  >
    <div class="overline">WEB PAGE</div>
    <link-form-field
      @childHover="handleChildHover"
      v-for="(link, index) in links"
      :key="link.link"
      :index="index"
      :content="content"
      :cancelItem="cancelItem"
      :editingItem="editingItem"
      :firstLinkIndex="firstLinkIndex"
      :deleteItem="deleteItem"
      :lastLinkIndex="lastLinkIndex"
      :oldLink="link.link"
      :oldTitle="link.title"
      :oldThumbnail="link.thumbnail"
      :oldReadMoreButtonText="link.readMoreButtonText"
      :position="firstLinkIndex + index"
      :editingPosition="editingPosition"
      :startEditing="startEditing"
      :saveItem="saveItem"
      :swapItems="swapItems"
    />
    <hover-buttons
      v-if="!editingItem && hover && !childHover"
      class="hover-links"
      :activeUpArrow="activeUpArrow"
      :activeDownArrow="activeDownArrow"
      :cancelItem="cancelItem"
      :confirmDel="confirmDel"
      :onSwapUp="() => moveLinksUp()"
      :onSwapDown="() => moveLinksDown()"
      :startEditing="() => {}"
      :handleDel="() => confirmDel()"
    />
  </section>
</template>
<script>
import HoverButtons from '../../hover-buttons/hover-buttons.vue';
import LinkFormField from './link-form-field.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    HoverButtons,
    LinkFormField,
  },
  props: {
    cancelItem: {
      required: true,
      type: Function,
    },
    content: {
      required: true,
      type: Array,
    },
    deleteItem: {
      required: true,
      type: Function,
    },
    deleteAllLinks: {
      required: true,
      type: Function,
    },
    editingItem: {
      required: true,
      type: Boolean,
    },
    editingPosition: {
      required: true,
      type: Number,
    },
    firstLinkIndex: {
      required: true,
      type: Number,
    },
    lastLinkIndex: {
      required: true,
      type: Number,
    },
    links: {
      required: true,
      type: Array,
    },
    position: {
      required: true,
      type: Number,
    },
    startEditing: {
      required: true,
      type: Function,
    },
    moveLinksDown: {
      required: true,
      type: Function,
    },
    moveLinksUp: {
      required: true,
      type: Function,
    },
    saveItem: {
      required: true,
      type: Function,
    },
    swapItems: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      childHover: false,
      hover: false,
    };
  },
  computed: {
    ...mapState('modal', ['modal']),
    activeDownArrow() {
      const lastItemIndex = this.content.length - 1;
      const itemsAfterCarousel = this.lastLinkIndex < lastItemIndex;
      return itemsAfterCarousel;
    },
    activeUpArrow() {
      return 0 < this.firstLinkIndex;
    },
  },
  methods: {
    ...mapMutations('modal', ['showModal']),
    confirmDel() {
      const text =
        'Are you sure you want to delete the entire web page carousel?';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        cancelButton: 'Cancel',
        actionButton: 'Delete',
        pendingAction: () => this.deleteAllLinks(),
      });
    },
    handleChildHover(bool) {
      this.childHover = bool;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'gameon-components/src/universal/vars';

.edit-row--link {
  position: relative;
  padding: 16px 8px 0;
  border-radius: 4px;
}
.overline {
  margin-bottom: 4px;
}
// Hide carousel parent edit button
/deep/ .hover-links .edit {
  display: none;
}
</style>
