var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-headers full-width" }, [
    _c("div", { staticClass: "list-header list-header--answer" }, [
      _vm._v("RESPONSE")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--tag list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("name")
          }
        }
      },
      [
        _vm._v("\n    DIALOGUE\n    "),
        _vm.orderBy.includes("name")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: { "sort-icon--downward": _vm.orderBy === "name.desc" },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--edited list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("updatedAt")
          }
        }
      },
      [
        _vm._v("\n    LAST EDITED\n    "),
        _vm.orderBy.includes("updatedAt")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: {
                "sort-icon--downward": _vm.orderBy === "updatedAt.desc"
              },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--created list-header--sortable",
        on: {
          click: function($event) {
            return _vm.handleHeaderClick("createdAt")
          }
        }
      },
      [
        _vm._v("\n    CREATED\n    "),
        _vm.orderBy.includes("createdAt")
          ? _c("icon", {
              staticClass: "sort-icon",
              class: {
                "sort-icon--downward": _vm.orderBy === "createdAt.desc"
              },
              attrs: { icon: "arrow_upward" }
            })
          : _c("icon", {
              staticClass: "sort-icon sort-icon--hover",
              attrs: { icon: "arrow_upward" }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }