<template>
  <div class="hover-buttons">
    <icon
      v-show="!hideEdit"
      class="action-icon edit"
      icon="edit"
      @click="startEditing"
    />
    <icon
      v-if="activeDownArrow"
      icon="arrow_downward"
      class="action icon"
      @click="onSwapDown"
    />
    <icon
      v-if="!activeDownArrow"
      icon="arrow_downward"
      class="action-icon--disabled"
    />
    <icon
      v-if="activeUpArrow"
      icon="arrow_upward"
      class="action-icon"
      @click="onSwapUp"
    />
    <icon
      v-if="!activeUpArrow"
      class="action-icon--disabled"
      icon="arrow_upward"
    />
    <icon class="action-icon" icon="delete" @click.stop.prevent="handleDel" />
  </div>
</template>
<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    activeDownArrow: {
      required: true,
      type: Boolean,
    },
    activeUpArrow: {
      required: true,
      type: Boolean,
    },
    handleDel: {
      required: true,
      type: Function,
    },
    hideEdit: {
      default: false,
      required: false,
      type: Boolean,
    },
    startEditing: {
      default: () => {},
      required: false,
      type: Function,
    },
    onSwapDown: {
      required: true,
      type: Function,
    },
    onSwapUp: {
      required: true,
      type: Function,
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'gameon-components/src/universal/vars';

// Hover actions
.hover-buttons {
  position: absolute;
  right: 8px;
  top: 12px;
}
.hover-buttons .material-icons {
  font-size: 18px;
  color: $black-medium-emphasis;
  cursor: pointer;

  &.action-icon--disabled {
    opacity: 0.38;
    cursor: default;
  }
}

// carousel parent hover buttons
.edit-row--link.edit-row .hover-buttons {
  top: 12px;
  right: 8px;
}
// carousel children hover buttons
.edit-row--link.edit-row .hover-buttons.child-hover-buttons {
  top: 4px;
  right: 8px;
}
</style>
