<template>
  <pre class="text-preview" v-html="text"></pre>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.text-preview {
  max-width: 232px;
  padding: 8px 12px;
  border-radius: $border-radius-chat-render;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.08);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  white-space: pre-wrap;
  font-family: 'Roboto', sans-serif;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
