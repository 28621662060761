import { DEFAULT_ITEMS_PER_PAGE } from './constants';
import { postAnswers } from '../../../api/qna';

const defaultState = {
  itemsPerLoad: DEFAULT_ITEMS_PER_PAGE,
  totalItems: [],
  currentListPage: 1,
  lastRefresh: null,
  selectedTag: 'all',
  searchResults: [],
};
const getDefaultState = () => {
  return { ...defaultState };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    // =============
    // BEGIN LIST
    resetListState: (state) => {
      state.totalItems = [];
      state.currentListPage = 1;
      state.endOfList = false;
      state.lastRefresh = null;
      state.selectedTag = 'all';
      state.searchResults = [];
    },
    declareEndOfList: (state) => {
      state.endOfList = true;
    },
    setSearchResults: (state, answers) => {
      state.selectedTag = defaultState.selectedTag;
      state.searchResults = answers;
    },
    triggerListRefresh: (state) => {
      state.lastRefresh = new Date();
    },
    setTotalItems: (state, items) => {
      state.totalItems = items.sort((a, b) => {
        return a.lastUpdated - b.lastUpdated;
      });
    },
    // END LIST
    // =============

    // =============
    // BEGIN FILTERS
    goToFirstPage: (state) => {
      state.currentListPage = 1;
    },
    goToLastPage: (state) => {
      state.currentListPage =
        1 + parseInt(state.totalItems.length / state.itemsPerLoad);
    },
    nextPage: (state) => {
      state.currentListPage = state.currentListPage + 1;
    },
    prevPage: (state) => {
      state.currentListPage = state.currentListPage - 1;
    },
    removeSearchResults: (state) => {
      state.searchResults = [];
    },
    setListPage: (state, page) => {
      state.currentListPage = page;
    },
    setItemsPerPage: (state, num) => {
      state.itemsPerLoad = num;
    },
    resetSelectedTag: (state) => {
      state.selectedTag = defaultState.selectedTag;
      state.currentListPage = 1;
    },
    setSelectedTag: (state, payload) => {
      state.selectedTag = payload;
      state.currentListPage = 1;
    },
    // END FILTERS
    // =============
  },
  getters: {
    filteredItems: (state) => {
      return state.totalItems
        .filter((qnaGroup) => {
          if ('all' === state.selectedTag) {
            return true;
          } else if (!qnaGroup.tags.length) {
            return false;
          }
          return (
            state.selectedTag.toLowerCase() === qnaGroup.tags[0].toLowerCase()
          );
        })
        .reverse();
    },
    listItems: (state, getters) => {
      // Use filtered scored items if search input present
      if (state.searchResults.length) {
        return state.totalItems.reduce((result, pair) => {
          const score = getters.scoresById[pair.id];
          if (score) {
            const scoreSorted = [...result, { ...pair, score }].sort((a, b) =>
              a.score > b.score ? -1 : 1
            );
            return scoreSorted;
          } else {
            return result;
          }
        }, []);
        // Otherwise show all
      } else {
        return getters.filteredItems;
      }
    },
    firstRowIndex: (state) => {
      return (state.currentListPage - 1) * state.itemsPerLoad;
    },
    lastRowIndex: (state) => {
      return state.currentListPage * state.itemsPerLoad - 1;
    },
    tagOptions: (state, getters, rootState) => {
      const allTags = state.totalItems.reduce((tags, curr) => {
        if (curr.tags.length && !tags.includes(curr.tags[0])) {
          return [...tags, curr.tags[0]];
        }
        return tags;
      }, []);
      const tagOptions = allTags.map((value) => {
        const display = `${value[0].toUpperCase()}${value.substr(
          1,
          value.length
        )}`;
        return { display, value };
      });
      return [{ display: 'All Tags', value: 'all' }, ...tagOptions];
    },
    scoresById: (state) => {
      const results = {};
      for (const pair of state.searchResults) {
        results[pair.id] = pair.score;
      }
      return results;
    },
    totalCount: (state, getters) => {
      return getters.listItems.length;
    },
    reachedLastPage: (state, getters) => {
      return getters.lastRowIndex >= getters.totalCount - 1;
    },
  },
  actions: {
    async handleSearchInput({ commit, rootState }, search) {
      if (search && !!search.trim()) {
        const results = await postAnswers(rootState.user.botData.key, search);
        commit('setListPage', 1);
        commit('setSearchResults', results);
      } else {
        commit('removeSearchResults');
      }
    },
  },
};
