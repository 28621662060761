<!-- eslint-disable max-lines -->
<template>
  <article class="message-editor full-height">
    <section class="top-menu padding-tiny-around">
      <section class="title">
        <input
          v-model="title"
          @click="handleTitleClick"
          @blur="handleTitleBlur"
          :placeholder="titlePlaceholder"
          class="title__input"
          type="text"
        />
      </section>
      <div class="top-menu__options">
        <btn class="submit-btn" :disabled="!allowSubmit" @click="submitForm">
          <span class="submit-text">Save</span>
        </btn>
        <more-menu v-if="initialRow" :button="true">
          <more-response-options class="option-btn" />
        </more-menu>
      </div>
    </section>
    <div class="creation-wrap">
      <div class="hide-top-shadow" />
      <section class="fixed-menu">
        <suggest-select
          label="Dialogues"
          leading-icon="search"
          :handle-primary-val="setSelectedResponseName"
          :options="flattenedNameOptions"
          :primary-val="selectedResponseName"
        />
        <section class="buttons">
          <div class="add-content">
            <fab
              :disabled="editingItem"
              class="purple-fab"
              icon="title"
              @click="addAndScroll('text')"
            />
            <div class="fab-desc">Text</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-primary"
              :disabled="editingItem"
              icon="insert_photo"
              @click="addAndScroll('media')"
            />
            <div class="fab-desc">Media</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-secondary"
              :disabled="editingItem"
              icon="link"
              @click="addAndScroll('link')"
            />
            <div class="fab-desc">Web page</div>
          </div>
          <div class="add-content">
            <fab
              class="orange-fab"
              :disabled="editingItem"
              icon="crop_square"
              @click="addAndScroll('buttons')"
            />
            <div class="fab-desc">Buttons</div>
          </div>
        </section>
      </section>
      <section class="content-items">
        <section class="content-row-wrap">
          <message-form-field
            v-for="(item, index) in content"
            class="edit-row"
            :id="editingPosition === index ? 'scroll-target' : ''"
            :key="item.text"
            :content="content"
            :cancelItem="cancelItem"
            :deleteAllLinks="deleteAllLinks"
            :editingPosition="editingPosition"
            :finishEditingItem="finishEditingItem"
            :item="item"
            :deleteItem="deleteItem"
            :editingItem="editingItem"
            :links="links"
            :lastLinkIndex="lastLinkIndex"
            :firstLinkIndex="firstLinkIndex"
            :position="index"
            :startEditing="startEditing"
            :saveItem="saveItem"
            :swapItems="swapItems"
            :moveLinksUp="moveLinksUp"
            :moveLinksDown="moveLinksDown"
          />
        </section>
        <section class="edit-row">
          <quick-reply-form-field
            v-if="editingButtons || buttons.length"
            :buttons="buttons"
            :id="editingButtons ? 'scroll-target' : ''"
            :editingButtons="editingButtons"
            :editingItem="editingItem"
            :deleteButtons="deleteButtons"
            :setButtons="setButtons"
            :setEditingButtons="setEditingButtons"
          />
        </section>
      </section>
    </div>
  </article>
</template>
<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import SuggestSelect from 'gameon-components/src/components/suggest-select/suggest-select.vue';
import Fab from 'gameon-components/src/components/fab/fab.vue';
import MessageFormField from '../../../../components/message-form-field/message-form-field.vue';
import MoreMenu from '../../../../components/more-menu/more-menu.vue';
import MoreResponseOptions from './more-response-options/more-response-options.vue';
import QuickReplyFormField from '../../../../components/message-form-field/quick-reply-form-field/quick-reply-form-field.vue';

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { SNACK_TYPES } from '../../../../constants/snackbar';
import { upsertResponse } from '../../../../api/response-manager';

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);
const animationTime = 800;
const defaultTitle = 'Greeting Response 1 (Optional Title)';

export default {
  components: {
    Btn,
    Fab,
    MessageFormField,
    MoreMenu,
    MoreResponseOptions,
    QuickReplyFormField,
    SuggestSelect,
  },
  computed: {
    ...mapState('user', ['botData']),
    ...mapGetters('responseManagerForm', [
      'buttonsHorizontal',
      'messageReady',
      'editingItem',
      'firstLinkIndex',
      'flattenedNameOptions',
      'formChanged',
      'lastLinkIndex',
      'links',
      'linksHorizontal',
      'sendable',
      'selectedResponseNameValid',
    ]),
    ...mapState('responseManagerForm', [
      'buttons',
      'content',
      'editingButtons',
      'editingPosition',
      'initialRow',
      'selectedResponseName',
    ]),
    ...mapState('responseManagerList', ['responseNameOptions']),
    allowSubmit() {
      if (this.initialRow) {
        const changesMade = this.titleChanged || this.formChanged;
        return (
          this.messageReady &&
          this.selectedResponseNameValid &&
          !this.submitting &&
          changesMade
        );
      } else {
        return (
          this.messageReady &&
          !this.submitting &&
          this.selectedResponseNameValid
        );
      }
    },
    dropdownVal() {
      if (this.selectedResponseName) return this.selectedResponseName;
      if (this.responseNameOptions && this.responseNameOptions.length)
        return this.responseNameOptions[0];
      return '';
    },
    successText() {
      const updateSuccess = 'Response successfully updated';
      return updateSuccess;
    },
    titleChanged() {
      if (this.initialRow && this.initialRow.title) {
        return this.title !== this.initialRow.title;
      } else {
        return this.title.length;
      }
    },
  },
  data() {
    return {
      submitting: false,
      title: this.initialRow ? this.initialRow.title : '',
      titlePlaceholder: defaultTitle,
    };
  },
  methods: {
    ...mapMutations('responseManager', ['setSidesheetOpen']),
    ...mapMutations('responseManagerList', ['triggerListRefresh']),
    ...mapActions('responseManagerForm', [
      'addItem',
      'cancelItem',
      'clear',
      'deleteItem',
      'editButtons',
      'moveLinksDown',
      'moveLinksUp',
      'saveItem',
      'swapItems',
    ]),
    ...mapMutations('responseManagerForm', [
      'deleteAllLinks',
      'deleteButtons',
      'finishEditingItem',
      'resetForm',
      'setButtons',
      'setSelectedResponseName',
      'setEditingButtons',
      'startEditing',
      'setInitialRow',
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('uploader', ['clearStates']),

    // ====== Begin Title methods
    handleTitleBlur() {
      if (!this.title) this.titlePlaceholder = defaultTitle;
    },
    handleTitleClick() {
      if (!this.title && this.titlePlaceholder) this.titlePlaceholder = '';
    },
    // ====== End Title methods

    addAndScroll(mode) {
      switch (mode) {
        case 'buttons':
          this.editButtons();
          break;
        default:
          this.addItem(mode);
          break;
      }
      this.$nextTick(() => {
        this.scrollTo();
      });
    },
    resetSidesheet() {
      this.title = '';
      this.resetForm();
      this.setInitialRow(null);
      this.setSidesheetOpen(false);
    },
    scrollTo() {
      VueScrollTo.scrollTo('#scroll-target', animationTime, {
        container: '.creation-wrap',
      });
    },
    async submitForm() {
      try {
        await this.submit();
        this.resetSidesheet();
        const delaySnackTime = 3000;
        setTimeout(() => {
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: this.successText });
        }, delaySnackTime);
        this.$nextTick(() => {
          this.triggerListRefresh();
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      } finally {
        this.submitting = false;
      }
    },
    async submit() {
      this.submitting = true;
      const postData = {
        response: {
          name: this.selectedResponseName,
          content: this.sendable,
          app: this.botData.key,
          title: this.title,
        },
      };
      if (this.initialRow) {
        postData.response.id = this.initialRow.id;
      }
      await upsertResponse(postData);
    },
  },
  mounted() {
    this.title = this.initialRow ? this.initialRow.title : '';
  },
  deactivated() {
    this.clearStates();
    this.resetSidesheet();
  },
  beforeDestroy() {
    this.clearStates();
    this.resetSidesheet();
  },
  watch: {
    initialRow() {
      this.title = this.initialRow.title;
      this.setSelectedResponseName(this.initialRow.name);
    },
  },
};
</script>
<style lang="scss" scoped>
@import './response-form.scss';
</style>
