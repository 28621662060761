var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-dialog", {
    attrs: {
      value: _vm.show,
      body: _vm.modalText,
      cancelButton: _vm.cancelButton || "Cancel",
      header: _vm.modalHeader || "Warning",
      actionButton: _vm.actionButton
    },
    on: { closed: _vm.onDialogClosed }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }