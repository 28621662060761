<template>
  <section
    class="buttons-preview"
    :class="{ 'buttons-preview--vertical': !buttonsHorizontal }"
  >
    <carousel :buttons="buttons" v-if="buttonsHorizontal">
      <single-button
        v-for="button in buttons"
        :key="button.title"
        :text="button.title"
        class="button"
      />
    </carousel>
    <single-button
      v-else
      class="single-button--vertical"
      v-for="button in buttons"
      :key="button.title"
      :text="button.title"
    />
  </section>
</template>

<script>
import SingleButton from './single-button/single-button.vue';
import Carousel from '../../../../../components/carousel/carousel.vue';

export default {
  components: {
    Carousel,
    SingleButton,
  },
  props: {
    buttons: {
      required: true,
      type: Array,
    },
    buttonsHorizontal: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-preview {
  margin-top: 8px;
  position: relative;
  margin-bottom: 8px;
  max-width: 360px;
}
/deep/ .go-carousel--no-scroll {
  margin-top: -8px;
  margin-bottom: -8px;
}
.buttons-preview--vertical {
  align-items: flex-end;
  flex-direction: column;
  margin: 0;
}
.single-button--vertical.single-button {
  margin-left: 80px;
  margin-top: 2px;
  margin-bottom: 2px;
  width: fit-content;
}
</style>
