export const CONTENT_TYPES = {
  text: 'text',
  media: 'media',
  link: 'link',
  quickReply: 'quickReply',
};

export const DEFAULT_CONTENT = {
  text: {
    mode: 'text',
    text: '',
  },
  media: {
    mode: 'media',
    thumbnail: '',
    link: '',
    type: '',
    title: '',
  },
  link: {
    mode: 'link',
    title: '',
    link: '',
    thumbnail: '',
  },
  buttons: {
    mode: 'button',
    title: '',
  },
};
export const PREVIEW_MODES = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const PREVIEW_CONFIG_DEFAULT = {
  links: {
    mode: PREVIEW_MODES.HORIZONTAL,
  },
  buttons: {
    mode: PREVIEW_MODES.HORIZONTAL,
  },
};
