import {
  getHumanHandoffConvo,
  sendHumanHandoffMessage,
} from '../../../api/human-handoff';

export default {
  namespaced: true,
  state: {
    selectedUser: null,
    conversationLoaded: false,
  },
  mutations: {
    setSelectedUser: (state, user) => {
      state.selectedUser = user;
    },
    setLoaded: (state, bool) => {
      state.conversationLoaded = bool;
    },
    resetConversation: (state) => {
      state.conversationLoaded = false;
    },
  },
  getters: {
    participants: (state, getters, rootState, rootGetters) => {
      let brandAvatar;
      try {
        brandAvatar = rootState.user.botData.display.avatar;
      } catch (e) {
        // eslint-disable-next-line
        console.log('Error retrieving bot display data: ', e);
        brandAvatar = '';
      }
      const avatars = {
        USER: 'https://gameon-technology-public-uploads-dashboard.s3-us-west-2.amazonaws.com/end-user-avatar.png',
        AGENT: brandAvatar,
        BOT: brandAvatar,
      };
      const participants = [
        {
          displayName: '',
          id: getters.speakerId,
          avatar: avatars.AGENT,
        },
        {
          displayName: '',
          id: getters.userId,
          avatar: avatars.USER,
        },
        {
          displayName: '',
          id: rootState.user.botData.key,
          avatar: avatars.BOT,
        },
      ];
      return participants;
    },
    speakerId(state, getters, rootState) {
      return rootState.user.data.username;
    },
    userId(state) {
      if (state.selectedUser) return state.selectedUser.id;
      return null;
    },
  },
  actions: {
    handleCloseSideSheet({ commit }) {
      commit('setSelectedUser', null);
    },
    getConversationHistory: async (
      { commit, state, getters },
      historyParams
    ) => {
      const { offset, count, timeStart, timeEnd } = historyParams;
      return new Promise(async (resolve, reject) => {
        try {
          const result = await getHumanHandoffConvo(
            getters.userId,
            count,
            offset,
            timeStart,
            timeEnd
          );
          if (!state.conversationLoaded) commit('setLoaded', true);
          resolve(result);
        } catch (e) {
          // eslint-disable-next-line
          reject(e);
        }
      });
    },
    sendMessage: async ({ getters, rootState }, messageData) => {
      return new Promise(async (resolve, reject) => {
        const speakerId = rootState.user.data.username;
        try {
          const result = await sendHumanHandoffMessage(
            rootState.user.botData.key,
            messageData,
            getters.userId,
            speakerId
          );
          resolve(result);
        } catch (e) {
          // eslint-disable-next-line
          console.log('Error sending HH message: ', e);
          reject(e);
        }
      });
    },
  },
};
