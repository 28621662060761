var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "message-editor full-height" }, [
    _c(
      "section",
      { staticClass: "top-menu" },
      [
        _vm.selectedUser.stateViewable === "pending"
          ? _c(
              "btn",
              {
                staticClass: "resolve-btn",
                attrs: { disabled: _vm.submitting },
                on: { click: _vm.confirmResolve }
              },
              [
                _c("span", { staticClass: "resolve-btn__text" }, [
                  _vm._v("Resolve")
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedUser.stateViewable === "pending"
          ? _c(
              "btn",
              {
                staticClass: "activate-btn",
                attrs: { disabled: _vm.submitting, unelevated: "" },
                on: { click: _vm.activate }
              },
              [
                _c("span", { staticClass: "activate-btn__text" }, [
                  _vm._v("Take Over Chat")
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedUser.stateViewable === "active"
          ? _c(
              "btn",
              {
                staticClass: "deactivate-btn",
                attrs: { disabled: _vm.submitting, unelevated: "" },
                on: { click: _vm.deactivate }
              },
              [
                _c("span", { staticClass: "deactivate-btn__text" }, [
                  _vm._v("Leave Chat")
                ])
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }