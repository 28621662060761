<template lang="html">
  <div class="list-headers full-width">
    <div class="list-header list-header--questions">QUESTIONS</div>
    <div class="list-header list-header--answer">ANSWER</div>
    <div class="list-header list-header--tag">TAG</div>
    <div class="list-header list-header--edited">LAST EDITED</div>
    <div
      class="list-header list-header--score"
      :class="{ 'list-header--disabled': !searchResults.length }"
    >
      SCORE
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('qnaList', ['searchResults']),
  },
};
</script>

<style lang="scss" scoped>
@import '../../../components/list-headers/list-headers.scss';

.list-headers {
  padding: 0 16px 0;
}
.list-header--tags {
  position: relative;
}
.list-headers .list-header--edited {
  position: relative;
  left: 0;
}

.list-header {
  &--questions,
  &--answer {
    width: 32%;
  }
  &--score,
  &--tag,
  &--edited {
    width: 12%;
  }
}
</style>
