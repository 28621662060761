<template>
  <m-list class="full-width">
    <m-list-item
      class="full-width option"
      :disabled="!activeStop"
      @click="handleStop"
    >
      Stop
    </m-list-item>
  </m-list>
</template>
<script>
import { updateJob } from '../../../../api';
import { mapMutations } from 'vuex';
import { SNACK_TYPES } from '../../../../constants/snackbar';
import { FILTER_BY_VALUES } from '../../../../constants/broadcasts';

import Vue from 'vue';
import List from 'material-components-vue/dist/list';
Vue.use(List);

export default {
  props: {
    activeStop: {
      required: true,
      type: Boolean,
    },
    activeRestart: {
      required: true,
      type: Boolean,
    },
    broadcast: {
      required: true,
      type: Object,
    },
  },
  methods: {
    ...mapMutations('broadcastPage', ['setFilterBy']),
    ...mapMutations('modal', ['showModal']),
    ...mapMutations('snackbar', ['openSnack']),
    gatherJob() {
      return {
        uri: this.broadcast.uri,
        enabled: this.broadcast.enabled,
        autoDelete: this.broadcast.autoDelete,
        schedule: this.broadcast.schedule,
        meta: this.broadcast.meta,
        postBody: this.broadcast.postBody,
        broadcastId: this.broadcast.id,
      };
    },
    async handleStop(e) {
      if (this.activeStop) {
        // eslint-disable-next-line max-len
        const text =
          'By stopping the Scheduled Broadcast, no future Broadcasts will be sent to the targeted audience. Are you sure you want to stop this schedule?';
        this.showModal({
          modalType: 'CONFIRM',
          modalText: text,
          modalHeader: 'Stop Scheduled Broadcast',
          actionButton: 'Stop',
          cancelButton: 'Cancel',
          pendingAction: this.stopScheduledBroadcast,
        });
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    async stopScheduledBroadcast() {
      const job = this.gatherJob();
      job.schedule = [];
      try {
        await updateJob(job, job.broadcastId);
        this.$nextTick(() => {
          this.$emit('stopped');
          const SUCCESS_TEXT = 'Broadcast successfully stopped';
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: SUCCESS_TEXT });
        });
        const delay = 1000;
        setTimeout(() => {
          this.setFilterBy(FILTER_BY_VALUES.FINISHED);
        }, delay);
      } catch (e) {
        // eslint-disable-next-line
        console.log('error stopping broadcast', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'material-components-vue/dist/list/styles';
.option {
  cursor: default;

  &.active {
    cursor: pointer;
  }
}
</style>
