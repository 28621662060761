import { get, post } from './axios-wrapper';

export const updateConversationState = async (app, id, state) => {
  return (
    await post('/v1/human-handoff/resolve', {
      data: {
        stateObj: {
          state: state,
        },
        app,
        primaryId: id,
      },
    })
  ).data;
};

export const getHumanHandoffConvo = async (id, count, offset, start, end) => {
  const idStr = `userId=${id}`;
  const countStr = `&count=${count}`;
  const offsetStr = offset ? `&offset=${offset}` : '';
  const startStr = start ? `&start_epoch=${start}` : '';
  const endStr = end ? `&end_epoch=${end}` : '';
  const queriesStr = `${idStr}${countStr}${offsetStr}${startStr}${endStr}`;
  return (await get(`/v1/human-handoff/conversation?${queriesStr}`)).data;
};

export const getHumanHandoffUsers = async (
  app,
  channel,
  state,
  count,
  offset
) => {
  const appStr = `app=${app}`;
  const channelStr = channel && 'all' !== channel ? `&channel=${channel}` : '';
  const stateStr = `&state=${state}`;
  const countStr = count ? `&count=${count}` : '';
  const offsetStr = offset ? `&offset=${offset}` : '';
  const queriesStr = `${appStr}${channelStr}${stateStr}${countStr}${offsetStr}`;
  return (await get(`/v1/human-handoff/users?${queriesStr}`)).data;
};

export const sendHumanHandoffMessage = async (
  app,
  messageData,
  userId,
  speakerId
) => {
  return (
    await post('/v1/human-handoff/send-message', {
      app,
      user: userId,
      content: messageData.content,
      speakerId,
      messageId: messageData.messageId,
    })
  ).data;
};
