<template>
  <div class="broadcast-read">
    <section v-if="showOptions" class="option-buttons">
      <icon-btn
        v-if="allowEdit"
        class="option-btn form-icon"
        @click="handleEdit"
        icon="edit"
      /><more-menu :button="true">
        <more-broadcast-options
          class="option-btn"
          :broadcast="broadcast"
          :active-stop="broadcast.activeStop"
          :active-restart="broadcast.activeRestart"
          @stopped="stopped"
        />
      </more-menu>
    </section>
    <h4 class="content-title">
      {{ broadcast.title || broadcast.displayText || 'Unnamed broadcast' }}
    </h4>
    <div class="bot">
      <div v-if="botData.display && botData.display.avatar" class="bot__avatar">
        <img class="bot__avatar__img" :src="botData.display.avatar" />
      </div>
      <div class="bot__send-info">
        <h5 class="bot__send-info__name">
          {{ botData.display.name }}
        </h5>
        <div class="body2">
          {{ broadcast.campaignName }}
        </div>
      </div>
    </div>
    <hr v-if="hasMetrics" class="hr hr--above" />
    <transition name="expand">
      <inline-metrics v-if="hasMetrics" :metrics="groupMetrics" />
    </transition>
    <hr v-if="hasMetrics" class="hr hr--below" />
    <hr v-else class="hr hr--no-metrics" />
    <div class="specs full-width">
      <div
        v-if="broadcast.status"
        class="field-value-pair full-width status-pair"
      >
        <div class="field">Status</div>
        <div class="value">
          <status-chip :status="broadcast.status" />
        </div>
      </div>
      <div
        v-if="broadcast.schedule && broadcast.schedule.length"
        class="field-value-pair full-width"
      >
        <div class="field">Schedule</div>
        <div class="value-rows">
          <div
            v-for="(schedule, i) in broadcast.scheduleViewable"
            :key="i"
            class="value caption"
          >
            <div class="date-time">
              <div class="schedule--starting">
                <div class="schedule--date date">
                  {{ schedule.startingDateViewable }}
                </div>
                <div class="schedule--time">
                  <locale-time :time="schedule.startingTimeViewable" />
                </div>
              </div>
              <div v-if="schedule.endingDateViewable" class="arrow">&rarr;</div>
              <div v-if="schedule.endingDateViewable" class="schedule--ending">
                <div class="schedule--date">
                  {{ schedule.endingDateViewable }}
                </div>
                <div class="schedule--time">
                  <locale-time :time="schedule.endingTimeViewable" />
                </div>
              </div>
            </div>
            <div class="repeat">
              {{ schedule.repeatViewable }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.lastRun" class="field-value-pair full-width">
        <div v-if="'schedule' === broadcast.source" class="field">
          Last Sent
        </div>
        <div v-else class="field">Sent</div>
        <div class="value">
          <div class="date-time">
            <div class="schedule--starting">
              <div class="schedule--date">{{ lastRunDate }}</div>
              <div class="schedule--time">
                <locale-time :time="lastRunTime" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="broadcast.source === BROADCAST_SOURCES.BROADCAST && audience"
        class="field-value-pair full-width"
      >
        <div class="field">Audience</div>
        <div class="value">
          {{ audience }}
        </div>
      </div>
      <div
        v-if="channels && broadcast.source === BROADCAST_SOURCES.BROADCAST"
        class="field-value-pair full-width"
      >
        <div class="field">Channels</div>
        <div class="value">
          <div v-for="channel in channels" :key="channel" class="capitalize">
            {{ channel }}
          </div>
        </div>
      </div>
      <div
        v-if="broadcast.source === BROADCAST_SOURCES.BROADCAST"
        class="field-value-pair full-width"
      >
        <div class="field">Last Sent</div>
        <div class="value date">
          {{ broadcast.lastSendViewable.date }}
          <div class="schedule--time">
            <locale-time :time="broadcast.lastSendViewable.time" />
          </div>
        </div>
      </div>
      <div v-if="updatedAtViewable" class="field-value-pair full-width">
        <div class="field">Last Edited</div>
        <div class="value date">{{ updatedAtViewable }}</div>
      </div>
      <div
        v-if="broadcast.createdAtViewable"
        class="field-value-pair full-width"
      >
        <div class="field">Created</div>
        <div class="value date">{{ broadcast.createdAtViewable }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import IconBtn from 'gameon-components/src/components/icon-btn/icon-btn.vue';
import InlineMetrics from './inline-metrics/inline-metrics.vue';
import LocaleTime from '../../../../components/locale-time/locale-time.vue';
import MoreBroadcastOptions from './more-broadcast-options.vue';
import MoreMenu from '../../../../components/more-menu/more-menu.vue';
import StatusChip from '../../../../components/status-chip/status-chip.vue';
import {
  epochToListView,
  epochToLocaleInput,
} from '../../../../helpers/conversions/time/time';
import {
  BROADCAST_SOURCES,
  BROADCAST_STATUSES,
} from '../../../../constants/broadcasts';
import { getGroupMetrics } from '../../../../api/broadcast';
import { getBroadcastIds } from '../../helpers/past-broadcast-helpers';

import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    IconBtn,
    InlineMetrics,
    LocaleTime,
    MoreMenu,
    MoreBroadcastOptions,
    StatusChip,
  },
  props: {
    originalBroadcast: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      broadcast: this.originalBroadcast,
      BROADCAST_STATUSES: BROADCAST_STATUSES,
      BROADCAST_SOURCES: BROADCAST_SOURCES,
      groupMetrics: null,
    };
  },
  computed: {
    ...mapState('user', ['botData']),
    audience() {
      if (this.broadcast.subscription) {
        return this.broadcast.subscription;
      } else if (this.hasMetrics && this.groupMetrics[0].audience) {
        return this.groupMetrics[0].audience;
      } else {
        return null;
      }
    },
    allowEdit() {
      const scheduled =
        this.originalBroadcast.status === BROADCAST_STATUSES.SCHEDULED;
      return scheduled;
    },
    channels() {
      if (this.hasMetrics && this.groupMetrics[0].channels) {
        return this.groupMetrics[0].channels.split(',');
      } else {
        return null;
      }
    },
    hasMetrics() {
      return this.groupMetrics && this.groupMetrics.length;
    },
    lastRun() {
      if (BROADCAST_SOURCES.BROADCAST === this.broadcast.source)
        return this.broadcast.updatedAt;
      if (
        this.broadcast.runData &&
        this.broadcast.runData.lastRun &&
        this.broadcast.runData.lastRun.timestamp
      ) {
        return this.broadcast.runData.lastRun.timestamp;
      }
      return null;
    },
    lastRunDate() {
      return this.lastRun ? epochToListView(this.lastRun) : null;
    },
    lastRunTime() {
      return this.lastRun ? epochToLocaleInput(this.lastRun).time : null;
    },
    showOptions() {
      return !(BROADCAST_SOURCES.BROADCAST === this.broadcast.source);
    },
    updatedAtViewable() {
      switch (this.broadcast.status) {
        case BROADCAST_STATUSES.SENT:
          return null;
        default:
          return this.broadcast.updatedAtViewable;
      }
    },
  },
  methods: {
    ...mapMutations('broadcastForm', ['setInitialBroadcast', 'setContent']),
    ...mapMutations('broadcastPage', ['setEditing', 'setSelectedBroadcast']),
    handleEdit() {
      this.setEditing(true);
      this.setInitialBroadcast(this.originalBroadcast);
      this.setSelectedBroadcast(null);
    },
    stopped() {
      const updated = { ...this.broadcast };
      updated.schedule = [];
      updated.status = BROADCAST_STATUSES.FINISHED;
      updated.activeStop = false;
      this.broadcast = updated;
    },
    async refreshMetrics() {
      this.groupMetrics = null;
      if (this.broadcast.groupMessages && this.broadcast.groupMessages.length) {
        const messages = this.broadcast.groupMessages;
        const broadcastIds = getBroadcastIds(messages).join(',');
        const end = messages[messages.length - 1].timestamp;
        const groupMetrics = await getGroupMetrics(
          this.botData.key,
          broadcastIds,
          end
        );
        this.groupMetrics = groupMetrics;
      }
    },
  },
  async mounted() {
    this.setInitialBroadcast(this.broadcast);
    this.refreshMetrics();
  },
  watch: {
    originalBroadcast() {
      this.broadcast = this.originalBroadcast;
      this.setInitialBroadcast(this.broadcast);
      this.refreshMetrics();
    },
  },
};
</script>
<style lang="scss" scoped>
@import './broadcast-read.scss';
</style>
