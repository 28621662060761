export default {
  namespaced: true,
  state: {
    dateFormat: 'locale',
    selectedPair: null,
    showUploadSideSheet: false,
  },
  getters: {
    qnaAvailable(state, getters, rootState) {
      try {
        return !!Object.keys(rootState.user.data.apps['qna']);
      } catch (e) {
        return false;
      }
    },
  },
};
