<template>
  <div class="conversation">
    <chat-render
      :participants="participants"
      :inputSubmitFunction="inputSubmitFunction"
      :getConversationHistoryFunction="getConversationHistory"
      :onScrolledToTop="onScrolledToTop"
      :settings="settings"
    />
    <indeterminate-circle v-if="!conversationLoaded" />
  </div>
</template>
<script>
import ChatRender from '@gameontechnology/chat-rendering';
import IndeterminateCircle from '../../../../components/indeterminate-circle/indeterminate-circle.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      settings: {
        buttons: {
          'read-only': true,
        },
      },
    };
  },
  components: {
    ChatRender,
    IndeterminateCircle,
  },
  computed: {
    ...mapState('humanHandoffConversation', ['conversationLoaded']),
    ...mapGetters('humanHandoffConversation', ['participants']),
  },
  methods: {
    ...mapActions('humanHandoffConversation', [
      'getConversationHistory',
      'sendMessage',
    ]),
    ...mapMutations('humanHandoffConversation', ['resetConversation']),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('uploader', ['clearStates']),
    async inputSubmitFunction(inputMessageData) {
      await this.sendMessage(inputMessageData);
    },
    onScrolledToTop() {
      // TO DO: get prev messages starting at timestamp, counting backwards
      // eslint-disable-next-line
      console.log('scrollback function tbd');
    },
  },
  beforeDestroy() {
    this.resetConversation();
  },
};
</script>
<style lang="scss" scoped>
@import './chat-ui-conversation.scss';
@import './chat-render-fixes.scss';

/deep/ .mdc-circular-progress {
  position: absolute;
  top: calc(50% - 64px);
  right: calc(50% - 64px);
}
</style>
