<template>
  <article class="content-read">
    <!-- css fix: do not add newline -->
    <icon-btn
      class="edit-btn form-icon"
      @click="handleEdit"
      icon="edit"
    /><more-menu :button="true">
      <more-content-options class="option-btn" />
    </more-menu>
    <section class="editable-fields">
      <h4 class="content-title">
        {{ info.title || (info.body && info.body.title) }}
      </h4>
      <div v-if="info.body && info.body.description" class="description">
        {{ info.body.description }}
      </div>
      <div class="tags">
        <span v-for="tag in info.tags" :key="tag.id">{{ tag.name }}</span>
      </div>
    </section>

    <hr />
    <section class="specs full-width">
      <div
        v-if="info.contentDescriptor"
        class="field-value-pair full-width descriptors"
      >
        <div class="field">Type</div>
        <div class="value">
          <div v-for="descriptor in descriptorsList" :key="descriptor">
            {{ descriptor }}
          </div>
        </div>
      </div>
      <div v-if="info.fileType" class="field-value-pair full-width">
        <div class="field">Type</div>
        <div class="value">{{ info.fileType.toUpperCase() }}</div>
      </div>
      <div class="field-value-pair full-width">
        <div class="field">Last Edited</div>
        <div class="value">{{ info.updatedAtViewable }}</div>
      </div>
      <div class="field-value-pair full-width">
        <div class="field">Created</div>
        <div class="value">{{ info.createdAtViewable }}</div>
      </div>
    </section>
  </article>
</template>
<script>
import IconBtn from 'gameon-components/src/components/icon-btn/icon-btn.vue';
import MoreContentOptions from './more-content-options.vue';
import MoreMenu from '../../../../../components/more-menu/more-menu.vue';
import { mapMutations } from 'vuex';

export default {
  components: {
    IconBtn,
    MoreMenu,
    MoreContentOptions,
  },
  props: {
    info: {
      required: true,
      type: Object,
    },
  },
  computed: {
    descriptorsList() {
      return this.info.contentDescriptor.split(',');
    },
  },
  methods: {
    ...mapMutations('contentForm', ['setEditing']),
    handleEdit() {
      this.setEditing(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import './content-read.scss';
</style>
