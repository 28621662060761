var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-list",
    { staticClass: "full-width more-options" },
    [
      _c(
        "m-list-item",
        { staticClass: "full-width option", on: { click: _vm.confirmDel } },
        [_vm._v("\n    Delete\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }