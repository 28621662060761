<template>
  <section class="btn-wrap" v-if="showSaveCancel">
    <btn
      class="form-action save"
      :disabled="disableSave"
      :unelevated="true"
      @click="handleUpdate"
    >
      {{ primaryActionCopy }}
    </btn>
    <btn class="form-action cancel" @click="handleCancel"> Cancel </btn>
  </section>
</template>
<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';

export default {
  components: {
    Btn,
  },
  props: {
    disableSave: {
      required: true,
      type: Boolean,
    },
    handleCancel: {
      required: true,
      type: Function,
    },
    handleUpdate: {
      required: true,
      type: Function,
    },
    oldVal: {
      required: true,
    },
    primaryActionCopy: {
      default: 'Add',
      required: false,
      type: String,
    },
    showSaveCancel: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-wrap {
  --mdc-theme-on-primary: white;
  margin: 12px 0 32px;
}
.form-action {
  height: 32px;
  margin: 0 8px 0 0;
  text-transform: capitalize;
}
</style>
