var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "button-container",
      on: { scroll: _vm.updateCarouselProperties }
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showLeftButton,
                expression: "showLeftButton"
              }
            ],
            staticClass: "icon-bg icon--left",
            on: { click: _vm.left }
          },
          [
            _c("icon", {
              staticClass: "arrow",
              attrs: { icon: "keyboard_arrow_left" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "spacer" }, [_vm._v(" ")]),
      _vm._v(" "),
      _vm._l(_vm.buttons, function(button) {
        return _c("single-button", {
          key: button.title,
          staticClass: "button",
          attrs: { text: button.title }
        })
      }),
      _vm._v(" "),
      _c("div", { staticClass: "spacer" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showRightButton,
                expression: "showRightButton"
              }
            ],
            staticClass: "icon-bg icon--right",
            on: { click: _vm.right }
          },
          [
            _c("icon", {
              staticClass: "arrow",
              attrs: { icon: "keyboard_arrow_right" }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }