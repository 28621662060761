export const FILTER_BY_OPTIONS = [
  {
    display: 'All Media',
    value: 'allMedia',
  },
  {
    display: 'Images Only',
    value: 'images',
  },
  {
    display: 'Videos Only',
    value: 'videos',
  },
  {
    display: 'GIFs Only',
    value: 'gifs',
  },
  {
    display: 'Has Tags',
    value: 'tags',
  },
  {
    display: 'No Tags Only',
    value: 'noTags',
  },
];

export const ORDER_BY_OPTIONS = [
  {
    display: 'Recently Edited',
    value: 'updatedAt.desc',
  },
  {
    display: 'Recently Created',
    value: 'createdAt.desc',
  },
  {
    display: 'A - Z by Name',
    value: 'title.asc',
  },
  {
    display: 'Z - A by Name',
    value: 'title.desc',
  },
];

export const ORDER_BY_VALUES = {
  UPDATED_AT_ASC: 'updatedAt.asc',
  UPDATED_AT_DESC: 'updatedAt.desc',
  CREATED_AT_ASC: 'createdAt.asc',
  CREATED_AT_DESC: 'createdAt.desc',
  DISPLAY_TEXT_ASC: 'title.asc',
  DISPLAY_TEXT_DESC: 'title.desc',
};
