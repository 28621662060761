var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "message-editor full-height",
      class: { "message-editor--scrolling": _vm.scrolling }
    },
    [
      _c(
        "section",
        { staticClass: "top-menu padding-tiny-around" },
        [
          _c("section", { staticClass: "broadcast-title" }, [
            _c("input", {
              staticClass: "broadcast-title-input",
              attrs: { placeholder: _vm.titlePlaceholder, type: "text" },
              domProps: { value: _vm.title },
              on: {
                "update:value": function($event) {
                  _vm.title = $event
                },
                keyup: _vm.setTitle
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "btn",
            {
              staticClass: "submit-btn",
              attrs: { disabled: !_vm.allowSubmit },
              on: { click: _vm.submitContent }
            },
            [_c("span", { staticClass: "submit-text" }, [_vm._v("Save")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "creation-wrap" }, [
        _c("div", { staticClass: "hide-top-shadow" }),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "fixed-menu" },
          [
            _vm.initialContent
              ? _c("div", { staticClass: "dropdown__disabled" })
              : _vm._e(),
            _vm._v(" "),
            _c("dropdown", {
              attrs: {
                "leading-icon": "search",
                "handle-primary-val": _vm.setTag,
                options: _vm.tagOptions,
                "primary-val": _vm.dropdownVal,
                disabled: !!_vm.initialContent
              }
            }),
            _vm._v(" "),
            _c("section", { staticClass: "buttons" }, [
              _c(
                "div",
                { staticClass: "add-content" },
                [
                  _c("fab", {
                    staticClass: "purple-fab",
                    attrs: { disabled: _vm.editingItem, icon: "title" },
                    on: {
                      click: function($event) {
                        return _vm.addAndScroll("text")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "fab-desc" }, [_vm._v("Text")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "add-content" },
                [
                  _c("fab", {
                    staticClass: "theme-primary",
                    attrs: { disabled: _vm.editingItem, icon: "insert_photo" },
                    on: {
                      click: function($event) {
                        return _vm.addAndScroll("media")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "fab-desc" }, [_vm._v("Media")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "add-content" },
                [
                  _c("fab", {
                    staticClass: "theme-secondary",
                    attrs: { disabled: _vm.editingItem, icon: "link" },
                    on: {
                      click: function($event) {
                        return _vm.addAndScroll("link")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "fab-desc" }, [_vm._v("Web page")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "add-content" },
                [
                  _c("fab", {
                    staticClass: "orange-fab",
                    attrs: { disabled: _vm.editingItem, icon: "crop_square" },
                    on: {
                      click: function($event) {
                        return _vm.addAndScroll("buttons")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "fab-desc" }, [_vm._v("Buttons")])
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "content-items", on: { scroll: _vm.handleScroll } },
          [
            _c(
              "section",
              { staticClass: "content-row-wrap" },
              _vm._l(_vm.content, function(item, index) {
                return _c("message-form-field", {
                  key: item.text,
                  staticClass: "edit-row",
                  attrs: {
                    id: _vm.editingPosition === index ? "scroll-target" : "",
                    content: _vm.content,
                    cancelItem: _vm.cancelItem,
                    deleteAllLinks: _vm.deleteAllLinks,
                    editingPosition: _vm.editingPosition,
                    finishEditingItem: _vm.finishEditingItem,
                    item: item,
                    deleteItem: _vm.deleteItem,
                    editingItem: _vm.editingItem,
                    links: _vm.links,
                    lastLinkIndex: _vm.lastLinkIndex,
                    firstLinkIndex: _vm.firstLinkIndex,
                    position: index,
                    startEditing: _vm.startEditing,
                    saveItem: _vm.saveItem,
                    swapItems: _vm.swapItems,
                    moveLinksUp: _vm.moveLinksUp,
                    moveLinksDown: _vm.moveLinksDown
                  }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "edit-row" },
              [
                _vm.editingButtons || _vm.buttons.length
                  ? _c("quick-reply-form-field", {
                      attrs: {
                        buttons: _vm.buttons,
                        id: _vm.editingButtons ? "scroll-target" : "",
                        editingButtons: _vm.editingButtons,
                        editingItem: _vm.editingItem,
                        deleteButtons: _vm.deleteButtons,
                        setButtons: _vm.setButtons,
                        setEditingButtons: _vm.setEditingButtons
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }