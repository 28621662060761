<template>
  <div class="jobs-table full-width">
    <div class="title-toolbar full-width">
      <section class="toolbar">
        <text-field :value.sync="search" type="text" label="Search" filled />
        <btn
          class="round create"
          @click="makeJob"
          :disabled="editing"
          icon="add"
          raised
        >
          New
        </btn>
      </section>
    </div>
    <div class="dropdowns full-width">
      <div class="dropdown">
        <div class="select-label">App</div>
        <single-select
          :handle-choice="handleSelectApp"
          :options="scheduleAppOptions"
          :upstream-val="app"
        />
      </div>
      <div class="dropdown">
        <div class="select-label">Date Format</div>
        <single-select
          :handle-choice="changeDateFormat"
          :options="dateOptions"
          :upstream-val="dateFormat"
        />
      </div>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="filteredJobs"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'top',
        perPageDropdown: [5, 10, 20, 50],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span
          @click="editSelected(props.row)"
          v-if="props.column.field == 'schedule'"
        >
          <div :key="schedule.starting" v-for="schedule in props.row.schedule">
            Starting: {{ toHumanReadable(schedule.starting) }}
            <div v-if="schedule.ending">
              Ending: {{ toHumanReadable(schedule.ending) }}
            </div>
            <div v-if="schedule.repeat">
              Every {{ schedule.quantity }} {{ schedule.unit }}(s)
            </div>
          </div>
        </span>
        <span v-else-if="props.column.field === '#'">
          {{ props.index }}
        </span>
        <span v-else-if="props.column.field === 'edit'">
          <a @click="editSelected(props.row)">Edit</a>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import Btn from 'gameon-components/src/components/btn/btn.vue';
import SingleSelect from 'gameon-components/src/components/single-select/single-select.vue';
import TextField from 'gameon-components/src/components/text-field/text-field.vue';

import { mapGetters, mapMutations, mapState } from 'vuex';
import { getJobs } from '../../../api';
import {
  epochToLocale,
  epochToUTC,
} from '../../../helpers/conversions/time/time';
import 'vue-good-table/dist/vue-good-table.css';
export default {
  data() {
    const dateOptions = [
      { display: 'locale', value: 'locale' },
      { value: 'GMT', display: 'GMT' },
    ];
    return {
      app: 'content-generators',
      dateOptions,
      group: 'engineering',
      jobs: [],
      search: '',
      asc: true,
      columns: [
        {
          label: '#',
          field: '#',
          type: 'text',
        },
        {
          label: 'Name',
          field: 'meta.name',
          type: 'text',
        },
        {
          label: 'App',
          field: 'meta.app',
          type: 'text',
        },
        {
          label: 'Enabled',
          field: 'enabled',
          type: 'text',
        },
        {
          label: 'Platform',
          field: (rowObj) => this.listToStr(rowObj.meta.platform),
        },
        {
          label: 'Tags',
          field: (rowObj) => this.listToStr(rowObj.meta.tags),
          type: 'text',
        },
        {
          label: 'Schedule',
          field: 'schedule',
        },
        {
          label: 'Next run',
          field: this.nextRunTime,
          type: 'text',
        },
        {
          label: 'Edit',
          field: 'edit',
          type: 'text',
        },
      ],
    };
  },
  components: { Btn, TextField, SingleSelect, VueGoodTable },
  computed: {
    ...mapGetters('user', ['scheduleAppOptions']),
    ...mapState('schedule', ['editing', 'dateFormat']),
    ...mapState('user', ['loggedIn']),
    filteredJobs() {
      if (!this.jobs) return [];
      if (!this.search) return this.jobs;
      return this.jobs.filter((job) => {
        if (job.uri.includes(this.search)) return true;

        const metaFields = ['app', 'group', 'name', 'tags'];
        for (const field of metaFields) {
          if (job['meta'][field] && job['meta'][field].includes(this.search)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    ...mapMutations('schedule', ['setSelected', 'setEditing', 'setDateFormat']),
    makeJob() {
      this.setSelected({});
      this.setEditing(true);
    },
    async getJobs() {
      if (!this.group || !this.loggedIn) return [];
      this.jobs = (await getJobs(this.group, this.app)).data;
    },
    handleSelectApp(incoming) {
      this.app = incoming;
    },
    handleSelectDateFormat(incoming) {
      this.dateFormat = incoming;
    },
    editSelected(job) {
      this.setSelected(job);
      this.setEditing(true);
    },
    listToStr(arr) {
      if (!arr) return '';
      return arr.join(', ');
    },
    toHumanReadable(epoch) {
      if (!epoch) return '';
      switch (this.dateFormat) {
        case 'GMT':
          return epochToUTC(epoch);
        case 'locale':
          return epochToLocale(epoch);
        default:
          return '';
      }
    },
    changeDateFormat(option) {
      this.setDateFormat(option);
    },
    nextRunTime(rowObj) {
      const nextRunTime = rowObj.runData.nextRunTime;
      if (-1 === nextRunTime) return '';
      return this.toHumanReadable(rowObj.runData.nextRunTime);
    },
  },
  watch: {
    app() {
      this.getJobs();
    },
    editing() {
      this.getJobs();
    },
    loggedIn() {
      this.getJobs();
    },
  },
  async mounted() {
    await this.getJobs();
  },
};
</script>

<style lang="scss" scoped>
.disabled-text {
  color: $color-grey-medium;
}
.dropdowns {
  margin: 12px 0 12px;
}
.dropdown {
  width: 50%;
}
.select-label {
  padding-left: 12px;
  font-size: 12px;
}
/deep/ .select-wrap {
  width: 200px;
}
.toolbar {
  width: 80%;
  margin: 12px 12px 24px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: $width-tablet) {
  .full-width {
    justify-content: space-between;
  }

  .search {
    width: 300px;
  }

  /deep/ .v-table {
    width: 100%;
  }
}
</style>
