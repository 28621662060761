export const CONTENT_TYPES = {
  text: 'text',
  media: 'media',
  link: 'link',
  quickReply: 'quickReply',
};

export const DEFAULT_CONTENT = {
  text: {
    mode: 'text',
    text: '',
  },
  media: {
    mode: 'media',
    thumbnail: '',
    link: '',
    type: '',
    title: '',
  },
  link: {
    mode: 'link',
    title: '',
    link: '',
    thumbnail: '',
  },
  buttons: {
    mode: 'button',
    title: '',
  },
};

export const DISPLAY_CHANNELS = {
  all: 'All Channels',
  facebook: 'Facebook',
  gbm: 'Google Business Messaging',
  slack: 'Slack',
  skype: 'Skype',
  twiliosms: 'SMS',
  rcs: 'RCS',
};

export const ORDER_BY_OPTIONS = [
  {
    display: 'Recently Edited',
    value: 'updatedAt.desc',
  },
  {
    display: 'Recently Created',
    value: 'createdAt.desc',
  },
  {
    display: 'A - Z by Name',
    value: 'title.asc',
  },
  {
    display: 'Z - A by Name',
    value: 'title.desc',
  },
];

export const ORDER_BY_VALUES = {
  UPDATED_AT_ASC: 'updatedAt.asc',
  UPDATED_AT_DESC: 'updatedAt.desc',
  CREATED_AT_ASC: 'createdAt.asc',
  CREATED_AT_DESC: 'createdAt.desc',
  DISPLAY_TEXT_ASC: 'title.asc',
  DISPLAY_TEXT_DESC: 'title.desc',
};

export const PREVIEW_MODES = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const PREVIEW_CONFIG_DEFAULT = {
  links: {
    mode: PREVIEW_MODES.HORIZONTAL,
  },
  buttons: {
    mode: PREVIEW_MODES.HORIZONTAL,
  },
};

export const MESSAGES_PER_LOAD = 50;

export const SPEAKER_TYPES = {
  AGENT: 'AGENT',
  BOT: 'BOT',
  USER: 'USER',
};
