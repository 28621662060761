<!-- eslint-disable max-lines -->
<template>
  <article
    @mouseover.stop="handleChildHover"
    @mouseleave="handleChildHoverStop"
    class="links-editor"
    :class="{
      'link-hover': !editingItem && hover,
      'editing-link': editingSelf,
    }"
    :id="editingPosition === position ? 'scroll-target' : ''"
  >
    <section class="hover-bg">
      <div class="overline overline--subheader">ARTICLE {{ position + 1 }}</div>
      <hover-buttons
        class="child-hover-buttons"
        v-show="!editingItem && hover"
        :activeUpArrow="activeUpArrow"
        :activeDownArrow="activeDownArrow"
        :handleDel="() => confirmDel(position)"
        :onSwapUp="() => swapItems({ ind: position, ind2: position - 1 })"
        :onSwapDown="() => swapItems({ ind: position, ind2: position + 1 })"
        :startEditing="() => startEditing(position)"
      />
      <text-field
        class="link-field"
        :disabled="!editingSelf"
        :helper="'Paste or type link URL'"
        label="Link URL"
        :valid="validLink"
        :value.sync="link"
        filled
      />
      <linear-progress :open="'loading' === formState" />
      <text-field
        v-if="'linkChecked' === formState && editingSelf"
        class="link-title"
        :disabled="!editingSelf"
        :helper="'Customize link title'"
        label="Title"
        :textarea="true"
        :valid="validTitle"
        :value.sync="linkTitle"
        filled
      />
      <text-field
        v-if="editingSelf && !!('linkChecked' === formState)"
        class="link-image"
        :disabled="!editingSelf"
        :helper="'Customize link image'"
        label="Image URL"
        :valid="validImage"
        :value.sync="linkImage"
        filled
      />
      <text-field
        v-if="editingSelf && !!('linkChecked' === formState)"
        class="link-button"
        :helper="'Customize link button'"
        label="Button Label"
        :textarea="true"
        :valid="validButton"
        :value.sync="readMoreButtonText"
        filled
      />
    </section>
    <save-cancel
      v-if="'linkChecked' !== formState"
      :disable-save="!allowSave"
      :handle-cancel="handleCancel"
      :handle-update="processUrl"
      :old-val="oldLink"
      :show-save-cancel="editingSelf"
      :primaryActionCopy="oldLink ? 'Update' : 'Add'"
    />
    <save-cancel
      v-else
      :disable-save="!allowSave"
      :handle-cancel="handleCancel"
      :handle-update="checkMetaData"
      :old-val="oldLink"
      :show-save-cancel="editingSelf"
      :primaryActionCopy="oldLink ? 'Update' : 'Add'"
    />
  </article>
</template>
<script>
import HoverButtons from '../../hover-buttons/hover-buttons.vue';
import LinearProgress from 'gameon-components/src/components/linear-progress/linear-progress.vue';
import TextField from 'gameon-components/src/components/text-field/text-field.vue';
import SaveCancel from '../../../save-cancel/save-cancel.vue';
import { mapMutations } from 'vuex';
import { checkLinkValidity, opengraphMetadata } from '../../../../api';
import { verifyImage } from '../../../../helpers/images/images';
import { LINK_STATES } from '../../constants';
import { CONTENT_TYPES } from '../../../../constants/messages';
import { SNACK_TYPES, SNACK_CLASSES } from '../../../../constants/snackbar';

export default {
  components: {
    HoverButtons,
    LinearProgress,
    SaveCancel,
    TextField,
  },
  data() {
    return {
      formState: this.oldLink
        ? LINK_STATES['linkChecked']
        : LINK_STATES['newLink'],
      hover: false,
      imageWorks: false,
      link: this.oldLink || '',
      readMoreButtonText: this.oldReadMoreButtonText
        ? this.oldReadMoreButtonText
        : 'Read Now',
      linkImage: this.oldThumbnail || '',
      linkTitle: this.oldTitle || '',
      linkValidated: false,
      validLink: true,
    };
  },
  props: {
    cancelItem: {
      required: true,
      type: Function,
    },
    content: {
      required: true,
      type: Array,
    },
    deleteItem: {
      required: true,
      type: Function,
    },
    editingItem: {
      required: true,
      type: Boolean,
    },
    editingPosition: {
      required: true,
      type: Number,
    },
    firstLinkIndex: {
      required: true,
      type: Number,
    },
    lastLinkIndex: {
      required: true,
      type: Number,
    },
    oldReadMoreButtonText: {
      required: false,
      type: String,
    },
    oldLink: {
      required: false,
      type: String,
    },
    oldThumbnail: {
      required: false,
      type: String,
    },
    oldTitle: {
      required: false,
      type: String,
    },
    position: {
      required: true,
      type: Number,
    },
    saveItem: {
      required: true,
      type: Function,
    },
    startEditing: {
      required: true,
      type: Function,
    },
    swapItems: {
      required: true,
      type: Function,
    },
  },
  computed: {
    activeUpArrow() {
      // if not zero and not first in links array
      return !!this.position && this.position !== this.firstLinkIndex;
    },
    activeDownArrow() {
      return (
        this.position < this.content.length - 1 &&
        this.position !== this.lastLinkIndex
      );
    },
    allowSave() {
      if (LINK_STATES['newLink'] === this.formState) {
        return this.link !== this.oldLink;
      } else {
        const changesMade =
          this.link !== this.oldLink ||
          this.oldTitle !== this.linkTitle ||
          this.oldThumbnail !== this.linkImage ||
          this.oldReadMoreButtonText !== this.readMoreButtonText;
        return (
          changesMade &&
          this.validLink &&
          this.validTitle &&
          this.allowImageInput
        );
      }
    },
    editingSelf() {
      return this.editingPosition === this.position;
    },
    validTitle() {
      return !!this.linkTitle && 'none detected' !== this.linkTitle;
    },
    validImage() {
      return (
        !!this.linkImage &&
        'none detected' !== this.linkImage &&
        this.imageWorks
      );
    },
    validButton() {
      const FB_LIMIT = 15;
      return (
        FB_LIMIT >= this.readMoreButtonText.length &&
        0 < this.readMoreButtonText.length
      );
    },
    allowImageInput() {
      return !!this.linkImage && 'none detected' !== this.linkImage;
    },
  },
  methods: {
    ...mapMutations('modal', ['showModal']),
    ...mapMutations('snackbar', ['openSnack']),
    async checkMetaData() {
      await this.correctUrl();
      this.imageWorks = await verifyImage(this.linkImage)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      if (
        this.validLink &&
        this.validTitle &&
        this.validImage &&
        this.validButton
      ) {
        this.handleUpdate();
      }
    },
    confirmDel() {
      const text = 'Are you sure you want to delete the selected web page?';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        actionButton: 'Delete',
        cancelButton: 'Cancel',
        pendingAction: () => this.deleteItem(this.position),
      });
    },
    async correctUrl() {
      const thumbnailDetected = 'none detected' !== this.linkImage;
      const missingHttp = !/^http/.test(this.linkImage);
      return new Promise((resolve) => {
        if (missingHttp && thumbnailDetected) {
          const correctedUrl =
            'http://' + this.linkImage.replace(/.*(?=www)/, '');
          this.linkImage = correctedUrl;
          resolve();
        } else {
          resolve();
        }
      });
    },
    async fillMetaData() {
      try {
        const getMetaData = async () => {
          const meta = await opengraphMetadata(this.link);
          this.linkTitle = meta.ogTitle || 'none detected';
          this.linkImage = (meta.ogImage || {}).url || 'none detected';
          if (Array.isArray(this.linkImage)) {
            this.linkImage = this.linkImage.shift();
          }

          await this.checkMetaData();
          this.formState = LINK_STATES['linkChecked'];
        };
        const MINIMUM_LOAD_TIME = 1500;
        await new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              await getMetaData();
              resolve();
            } catch (e) {
              reject(e);
            }
          }, MINIMUM_LOAD_TIME);
        });
      } catch (e) {
        this.linkTitle = 'none detected';
        this.linkImage = 'none detected';
        this.formState = LINK_STATES['linkChecked'];
      }
    },
    handleCancel() {
      this.cancelItem();
      this.link = this.oldLink;
      this.linkTitle = this.oldTitle;
      this.linkImage = this.oldThumbnail;
    },
    handleChildHover() {
      this.hover = true;
      this.$emit('childHover', true);
    },
    handleChildHoverStop() {
      this.hover = false;
      this.$emit('childHover', false);
    },
    handleKeyup(e) {
      const ENTER = 13;
      if (ENTER === e.keyCode) {
        this.processUrl();
      }
    },
    handleUpdate() {
      const mode = CONTENT_TYPES.link;
      const updatedLink = {
        mode,
        link: this.link,
        title: this.linkTitle,
        thumbnail: this.linkImage,
        readMoreButtonText: this.readMoreButtonText,
      };
      this.saveItem({ data: updatedLink, position: this.position });
      const text = 'All updates saved';
      this.openSnack({
        customClass: SNACK_CLASSES.CUSTOM_SIDESHEET,
        type: SNACK_TYPES.SUCCESS,
        text,
      });
    },
    async processUrl() {
      try {
        this.formState = LINK_STATES['loading'];
        this.validLink = 'ok' === (await checkLinkValidity(this.link)).status;
      } catch (e) {
        this.formState = LINK_STATES['newLink'];
        this.validLink = false;
      }

      if (this.validLink) await this.fillMetaData();
    },
  },
  watch: {
    editingSelf() {
      this.imageWorks = true;
    },
    oldLink() {
      this.link = this.oldLink;
    },
    oldTitle() {
      this.linkTitle = this.oldTitle;
    },
    oldThumbnail() {
      this.linkImage = this.oldThumbnail;
    },
  },
};
</script>
<style lang="scss" scoped>
.links-editor {
  position: relative;
}
.overline.overline--subheader {
  margin-left: 8px;
  color: $black-medium-emphasis;
}
.hover-bg {
  padding: 8px 8px 16px;
}
.editing-link {
  .hover-bg {
    padding-bottom: 0;
    .link-image {
      padding-bottom: 4;
    }
  }
}
.link-hover .hover-bg {
  border-radius: 4px;
}

// text fields
/deep/ .resizable-textarea {
  margin: 0 0 12px;
}
/deep/ .edit-row article:last-child {
  margin: 0;
}
/deep/ .edit-row article:first-child {
  margin: 0;
}

.hover-buttons {
  position: absolute;
  top: 8px;
  right: 8px;
}

/deep/ .mdc-linear-progress {
  position: absolute;
  bottom: 62px;
  z-index: 3;
  width: calc(100% - 16px);
}

/deep/ .btn-wrap {
  margin: 12px 0 32px 8px;
}
</style>
