<template>
  <div>
    <div class="header-text">
      <img src="/dist/assets/icon-global-message-editor.svg" />
      <p>
        {{ editor }} editor
        <span style="margin: 0 4px">></span>
        <span class="header-text--black">{{ mode }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import '../../../assets/icon-global-message-editor.svg';
export default {
  props: ['mode', 'editor'],
};
</script>
