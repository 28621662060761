var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "text-field-editor" },
    [
      _c(
        "section",
        { staticClass: "bg-wrap", class: { "hover-bg": !_vm.editingItem } },
        [
          !_vm.editingItem
            ? _c("hover-buttons", {
                attrs: {
                  activeUpArrow: _vm.activeUpArrow,
                  activeDownArrow: _vm.activeDownArrow,
                  handleDel: function() {
                    return _vm.confirmDel(_vm.position)
                  },
                  startEditing: function() {
                    return _vm.startEditing(_vm.position)
                  },
                  onSwapUp: function() {
                    return _vm.swapItems({
                      ind: _vm.position,
                      ind2: _vm.position - 1
                    })
                  },
                  onSwapDown: function() {
                    return _vm.swapItems({
                      ind: _vm.position,
                      ind2: _vm.position + 1
                    })
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "overline" }, [_vm._v(_vm._s(_vm.header))]),
          _vm._v(" "),
          _c("text-field", {
            staticClass: "text-field",
            attrs: {
              allowNewline: true,
              disabled: !_vm.editingSelf,
              helper: "Enter your message.",
              label: _vm.label,
              textarea: true,
              value: _vm.currentText,
              filled: ""
            },
            on: {
              "update:value": function($event) {
                _vm.currentText = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("save-cancel", {
        attrs: {
          "disable-save": _vm.disableSave,
          "handle-cancel": _vm.handleCancel,
          "handle-update": _vm.handleUpdate,
          "old-val": _vm.oldText,
          "show-save-cancel": _vm.editingSelf,
          primaryActionCopy: _vm.oldText ? "Update" : "Add"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }