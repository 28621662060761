var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-view" },
    [
      _c("list-headers"),
      _vm._v(" "),
      _vm.currentPageItems.length
        ? _c(
            "m-list",
            { staticClass: "full-width" },
            [
              _c(
                "div",
                { staticClass: "list-view__rows" },
                _vm._l(_vm.currentPageItems, function(user) {
                  return _c(
                    "m-list-item",
                    {
                      key: user.id,
                      staticClass: "full-width",
                      on: {
                        click: function($event) {
                          return _vm.setSelectedUser(user)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "title" },
                        [
                          _c(
                            "fab-static",
                            { attrs: { type: "disabled" } },
                            [_c("icon", { attrs: { icon: "person" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(_vm.trimId(user.id)))]),
                          _vm._v(" "),
                          _c("span", { staticClass: "file-type" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(user.channelViewable) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "status" },
                        [
                          _c("status-chip", {
                            staticClass: "status-chip--paused",
                            attrs: { status: user.stateViewable }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "edited" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(user.lastReplyDateViewable) +
                              "\n          "
                          ),
                          _c("locale-time", {
                            attrs: { time: user.lastReplyTimeViewable }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "created" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(user.submittedDateViewable) +
                              "\n          "
                          ),
                          _c("locale-time", {
                            attrs: { time: user.submittedTimeViewable }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "scroll-pseudo-padding" }),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        : !_vm.items.length && !_vm.waitingForData
        ? _c("empty-message", {
            attrs: {
              message:
                "The combination of search and sort filters yielded no results. Please try another."
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          loadMoreData: _vm.loadMoreData,
          reachedLastPage: _vm.reachedLastPage,
          waitingForData: _vm.waitingForData,
          rowCountOnCurrentPage: _vm.rowCountOnCurrentPage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }