var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-dialog", {
    attrs: {
      value: _vm.show,
      body: _vm.modalText,
      actionButton: "Continue",
      header: "Large File Size Warning",
      cancelButton: "Cancel"
    },
    on: { closed: _vm.handleChoice }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }