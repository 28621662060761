<template>
  <img class="main-icon" src="/dist/assets/icon-analytics-coming-soon.svg" />
</template>

<script>
import '../assets/icon-analytics-coming-soon.svg';

export default {};
</script>

<style scoped>
.main-icon {
  height: 160px;
  max-width: 160px;
  margin: auto;
}
</style>
