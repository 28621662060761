<template>
  <g-dialog
    :value="show"
    :body="modalText"
    :cancelButton="cancelButton || 'Cancel'"
    :header="modalHeader || 'Warning'"
    :actionButton="actionButton"
    @closed="onDialogClosed"
  />
</template>

<script>
import GDialog from 'gameon-components/src/components/dialog/dialog.vue';

import { DIALOG_ACTIONS } from './constants';
import { mapMutations, mapState } from 'vuex';
export default {
  components: {
    GDialog,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    show: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState('modal', [
      'actionButton',
      'cancelButton',
      'modalText',
      'modalHeader',
      'pendingAction',
    ]),
  },
  methods: {
    ...mapMutations('modal', ['closeModal', 'clearPendingAction']),
    async performAction() {
      this.pendingAction();
      this.closeModal();
    },
    onDialogClosed(click) {
      switch (click.action) {
        case DIALOG_ACTIONS.ACTION:
          this.performAction();
          break;
        default:
          this.closeModal();
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import 'gameon-components/src/universal/vars';
</style>
