<template lang="html">
  <div class="list-headers full-width">
    <div @click="handleHeaderClick('id')" class="list-header list-header--name">
      USER ID
    </div>
    <div
      @click="handleHeaderClick('status')"
      class="list-header list-header--status"
    >
      STATUS
    </div>
    <div
      @click="handleHeaderClick('lastModified')"
      class="list-header list-header--edited"
    >
      LAST REPLIED
    </div>
    <div
      @click="handleHeaderClick('submitted')"
      class="list-header list-header--created"
    >
      STATUS CHANGE
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import { ORDER_BY_VALUES } from '../../../state/content/constants';
export default {
  computed: {
    ...mapState('humanHandoff', ['orderBy']),
  },
  methods: {
    ...mapMutations('humanHandoff', ['setOrderBy']),
    handleHeaderClick(updateOrderBy) {
      const prevOrderBy = this.orderBy;
      switch (updateOrderBy) {
        case 'updatedAt':
          if (ORDER_BY_VALUES.UPDATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_ASC);
          }
          break;
        case 'createdAt':
          if (ORDER_BY_VALUES.CREATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_ASC);
          }
          break;
        case 'title':
          if (ORDER_BY_VALUES.DISPLAY_TEXT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.DISPLAY_TEXT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.DISPLAY_TEXT_ASC);
          }
          break;
        default:
          // eslint-disable-next-line
          console.log('invalid column order value');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../components/list-headers/list-headers.scss';

.list-header--tags {
  left: -40px;
  position: relative;
}
.list-header--edited {
  position: relative;
  left: -50px;
}
.list-header--created {
  position: relative;
  left: -60px;
}
.list-header--name {
  padding-left: 56px;
  position: relative;
}

// match column width to row content width
.list-header--name {
  width: 42%;
}
.list-header--status {
  width: 18%;
  position: relative;
  left: -38px;
}
.list-header--created,
.list-header--edited {
  width: 12%;
  color: $black-high-emphasis;
}
</style>
