<template>
  <div class="empty-loading">
    <indeterminate-circle />
  </div>
</template>
<script>
import IndeterminateCircle from '../../components/indeterminate-circle/indeterminate-circle.vue';

export default {
  components: {
    IndeterminateCircle,
  },
};
</script>
<style lang="scss" scoped>
.empty-loading {
  height: calc(100vh - 276px);
  width: 100%;
  padding-right: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
  padding-right: 80px;
}
</style>
