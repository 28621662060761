/* eslint-disable max-lines */
import { ORDER_BY_VALUES } from '../../../constants/response-manager';

import { PREVIEW_CONFIG_DEFAULT } from '../../../constants/messages';
import { MODES_META_DATA } from './constants';

export default {
  namespaced: true,
  state: {
    lastFocus: null,
    modesMetaData: MODES_META_DATA,
    previewConfig: PREVIEW_CONFIG_DEFAULT,
    sidesheetOpen: false,
    waitingForData: true,

    // filter dropdowns
    groupSearchInput: '',
    orderBy: ORDER_BY_VALUES.UPDATED_AT_DESC,
  },
  mutations: {
    setSidesheetOpen: (state, bool) => {
      state.orderBy = ORDER_BY_VALUES.UPDATED_AT_DESC;
      state.sidesheetOpen = bool;
    },
    setWaitingForData: (state, bool) => {
      state.waitingForData = bool;
    },

    // List interactions
    setGroupSearchInput: (state, search) => {
      state.groupSearchInput = search;
    },
    setOrderBy: (state, orderBy) => {
      state.orderBy = orderBy;
    },
  },
  getters: {
    responseManagerAvailable(state, getters, rootState) {
      try {
        return !!Object.keys(rootState.user.data.apps['responseManager']);
      } catch (e) {
        return false;
      }
    },
  },
  actions: {},
};
