import { get, post } from './axios-wrapper';

const DEFAULT_END_HOURS = 24;

export const sendBroadcast = async (data) => {
  const {
    schema,
    content,
    botName,
    subscription,
    displayText,
    groupId,
    campaignId,
  } = data;
  return (
    await post('/v1/broadcast/direct', {
      schema,
      content,
      botName,
      subscription,
      displayText,
      groupId,
      campaignId,
    })
  ).data;
};

export const getBroadcast = async (id, startDate) =>
  (await get(`/v1/broadcast/${id}?start=${startDate}`)).data;

export const getGroups = async (chatApps, options) => {
  const { count, skip, sort, subscription, campaignIds } = options;
  const chatAppsCommas = chatApps
    .filter((b) => 'content-generators' !== b)
    .join(',');
  const countStr = count ? `count=${count}` : '';
  const skipStr = skip ? `&skip=${skip}` : '';
  const subStr = subscription ? `subscription=${subscription}` : '';
  const sortStr = sort ? sort.split('.') : '';
  const fieldStr = sort ? `&field=${sortStr[0]}` : '';
  const orderStr = sort ? `&order=${sortStr[1]}` : '';
  const campaignsStr = `&campaignIds=${campaignIds}`;
  const queriesStr = `${countStr}${skipStr}${fieldStr}${orderStr}${subStr}${campaignsStr}`;
  const url = `/v1/broadcast/groups/${chatAppsCommas}?${queriesStr}`;
  const result = (await get(url)).data;
  return result;
};
export const getTotalGroupCount = async (chatApps, options) => {
  const { sort, subscription, campaignIds } = options;
  const chatAppsCommas = chatApps
    .filter((b) => 'content-generators' !== b)
    .join(',');
  const subStr = subscription ? `subscription=${subscription}` : '';
  const sortStr = sort ? sort.split('.') : '';
  const fieldStr = sort ? `&field=${sortStr[0]}` : '';
  const orderStr = sort ? `&order=${sortStr[1]}` : '';
  const campaignsStr = `&campaignIds=${campaignIds}`;
  const queriesStr = `${fieldStr}${orderStr}${subStr}${campaignsStr}`;
  const url = `/v1/broadcast/groupCount/${chatAppsCommas}?${queriesStr}`;
  return (await get(url)).data.body.count;
};

export const getCampaigns = async (app) => {
  const all = 500;
  const queriesStr = `count=${all}`;
  const url = `/v1/broadcast/campaigns/${app}?${queriesStr}`;
  const result = (await get(url)).data;
  return result;
};

export const getGroupBroadcasts = async (group) => {
  const campaignId = group.campaignId;
  const groupId = group.id;
  const createdAt = group.createdAt;
  const lastBroadcastTime = group.lastBroadcastTimestamp;

  if (!lastBroadcastTime) {
    return [];
  }

  const queryStart = new Date(lastBroadcastTime);
  queryStart.setHours(queryStart.getHours() + DEFAULT_END_HOURS);
  const queryStartTime = queryStart.toISOString();
  const queryEndTime = createdAt;

  const queries = `campaignId=${campaignId}&groupId=${groupId}&start=${queryStartTime}&end=${queryEndTime}`;
  const broadcasts = (await get(`/v1/broadcast/groupBroadcasts?${queries}`))
    .data.broadcasts;
  return broadcasts;
};

export const getGroupMetrics = async (app, broadcastIds, end) => {
  const queries = `app=${app}&broadcastId=${broadcastIds}&end=${end}`;
  const broadcasts = (await get(`/v1/broadcast/metrics?${queries}`)).data
    .metrics;
  return broadcasts;
};

export const getSubs = async (app) => {
  return (await get(`/v1/broadcast/subscriptions/${app}`)).data;
};

export const getManualCampaign = async (app) => {
  return (await get(`/v1/broadcast/manualCampaign/${app}`)).data;
};
export const createManualCampaign = async (app) => {
  return (await post(`/v1/broadcast/manualCampaign/${app}`)).data;
};

export const createGroup = async (app, campaignId, name) => {
  const referenceId = `portal_${new Date().toISOString()}_${name}`;
  const queriesStr = `app=${app}&name=${name}&campaignId=${campaignId}&referenceId=${referenceId}`;
  return (await post(`/v1/broadcast/group?${queriesStr}`)).data;
};

// ----------------------------------------------------------------
// Scheduled Broadcasts
// ----------------------------------------------------------------
export const getBroadcastsScheduled = async (chatApps, options) => {
  const { count, skip, sort, subset, status } = options;
  const chatAppsCommas = chatApps.join(',');
  const countStr = count ? `count=${count}` : '';
  const skipStr = skip ? `&skip=${skip}` : '';
  const subsetStr = subset ? `&subset=${subset}` : '';
  const sortStr = sort ? `&sort=${sort}` : '';
  const statusStr = sort ? `&status=${status}` : '';
  const queriesStr = `${countStr}${skipStr}${subsetStr}${sortStr}${statusStr}`;
  const url = `/v1/schedule/jobs/engineering?app=${chatAppsCommas}&platform=broadcast&${queriesStr}`;
  return (await get(url)).data.data;
};

export const getTotalScheduledBroadcastCount = async (chatApps, options) => {
  const { sort, subset, status } = options;
  const chatAppsCommas = chatApps.join(',');
  const subsetStr = subset ? `&subset=${subset}` : '';
  const sortStr = sort ? `&sort=${sort}` : '';
  const statusStr = sort ? `&status=${status}` : '';
  const queriesStr = `${subsetStr}${sortStr}${statusStr}`;
  const url = `/v1/schedule/getTotalScheduledBroadcastCount/engineering?app=${chatAppsCommas}&platform=broadcast&${queriesStr}`;
  return (await get(url)).data.data.count;
};
// End Scheduled Broadcasts
// ----------------------------------------------------------------
