import Vue from 'vue';
import Vuex from 'vuex';

import broadcastList from './broadcast/broadcast-list/broadcast-list';
import broadcastForm from './broadcast/broadcast-form/broadcast-form';
import broadcastPage from './broadcast/broadcast-page';
import content from './content/content';
import contentForm from './content/content-form/content-form';
import contentList from './content/content-list/content-list';
import humanHandoffConversation from './human-handoff/human-handoff-conversation/human-handoff-conversation';
import humanHandoffForm from './human-handoff/human-handoff-form/human-handoff-form';
import humanHandoffList from './human-handoff/human-handoff-list/human-handoff-list';
import humanHandoff from './human-handoff/human-handoff';
import menu from './menu/menu';
import messageItem from './message-item';
import modal from './modal/modal';
import qna from './qna/qna';
import qnaForm from './qna/qna-form/qna-form';
import qnaList from './qna/qna-list/qna-list';
import responseManagerList from './response-manager/response-manager-list/response-manager-list';
import responseManagerForm from './response-manager/response-manager-form/response-manager-form';
import responseManager from './response-manager/response-manager';
import schedule from './schedule/schedule';
import snackbar from './snackbar/snackbar';
import uploader from './uploader/uploader';
import user from './user';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    pipeOk: true,
    currentApp: {},
  },
  mutations: {
    pipeOk: (state) => {
      state.pipeOk = true;
    },
    pipeFailed: (state) => {
      state.pipeOk = false;
    },
    setApp: (state, app) => {
      window.history.pushState({}, app.name, app.route);
      state.currentApp = app;
    },
  },
  modules: {
    broadcastForm,
    broadcastList,
    broadcastPage,
    content,
    contentForm,
    contentList,
    humanHandoff,
    humanHandoffConversation,
    humanHandoffForm,
    humanHandoffList,
    menu,
    messageItem,
    modal,
    qna,
    qnaForm,
    qnaList,
    responseManager,
    responseManagerForm,
    responseManagerList,
    schedule,
    snackbar,
    uploader,
    user,
  },
});

export default store;
