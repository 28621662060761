<template>
  <canvas class="static-gif" ref="canvas" alt="title"> </canvas>
</template>
<script>
export default {
  props: {
    gif: {
      default: '',
      required: true,
      type: String,
    },
    title: {
      default: 'gif thumbnail',
      required: false,
      type: String,
    },
  },
  methods: {
    drawGif() {
      const canvas = this.$refs.canvas;
      canvas.width = '40';
      canvas.height = '40';
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
      image.src = this.gif;
    },
  },
  mounted() {
    this.drawGif();
  },
};
</script>
<style lang="scss" scoped></style>
