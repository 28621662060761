<template>
  <article class="text-field-editor">
    <section class="bg-wrap" :class="{ 'hover-bg': !editingItem }">
      <hover-buttons
        v-if="!editingItem"
        :activeUpArrow="activeUpArrow"
        :activeDownArrow="activeDownArrow"
        :handleDel="() => confirmDel(position)"
        :startEditing="() => startEditing(position)"
        :onSwapUp="() => swapItems({ ind: position, ind2: position - 1 })"
        :onSwapDown="() => swapItems({ ind: position, ind2: position + 1 })"
      />
      <div class="overline">{{ header }}</div>
      <text-field
        class="text-field"
        :allowNewline="true"
        :disabled="!editingSelf"
        :helper="'Enter your message.'"
        :label="label"
        :textarea="true"
        :value.sync="currentText"
        filled
      />
    </section>
    <save-cancel
      :disable-save="disableSave"
      :handle-cancel="handleCancel"
      :handle-update="handleUpdate"
      :old-val="oldText"
      :show-save-cancel="editingSelf"
      :primaryActionCopy="oldText ? 'Update' : 'Add'"
    />
  </article>
</template>
<script>
import HoverButtons from '../hover-buttons/hover-buttons.vue';
import TextField from 'gameon-components/src/components/text-field/text-field.vue';
import SaveCancel from '../../save-cancel/save-cancel.vue';
import { mapMutations } from 'vuex';
import { SNACK_TYPES, SNACK_CLASSES } from '../../../constants/snackbar';

export default {
  components: {
    HoverButtons,
    SaveCancel,
    TextField,
  },
  data() {
    return {
      currentText: this.initialText,
      oldText: this.initialText,
    };
  },
  props: {
    cancelItem: {
      required: true,
      type: Function,
    },
    editingItem: {
      required: true,
      type: Boolean,
    },
    initialText: {
      required: true,
      type: String,
    },
    item: {
      required: true,
      type: Object,
    },
    header: {
      required: false,
      type: String,
      default: 'TEXT',
    },
    label: {
      required: false,
      type: String,
      default: 'Message',
    },
    position: {
      required: true,
      type: Number,
    },
    saveItem: {
      required: true,
      type: Function,
    },
    startEditing: {
      required: true,
      type: Function,
    },

    // hover buttons
    activeDownArrow: {
      type: Boolean,
      required: true,
    },
    activeUpArrow: {
      type: Boolean,
      required: true,
    },
    handleDel: {
      type: Function,
      required: true,
    },
    editingSelf: {
      required: true,
      type: Boolean,
    },
    swapItems: {
      required: true,
      type: Function,
    },
  },
  computed: {
    disableSave() {
      if (!this.oldText && this.currentText) return false;
      return this.currentText === this.oldText;
    },
  },
  methods: {
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('modal', ['showModal']),
    confirmDel() {
      const text = 'Are you sure you want to delete the selected text?';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        actionButton: 'Delete',
        pendingAction: () => this.handleDel(this.position),
      });
    },
    handleUpdate() {
      this.oldText = this.currentText;
      const updatedItem = { ...this.item };
      updatedItem.text = this.currentText;
      this.saveItem({ data: updatedItem, position: this.position });
      const text = 'All updates saved';
      this.openSnack({
        customClass: SNACK_CLASSES.CUSTOM_SIDESHEET,
        type: SNACK_TYPES.SUCCESS,
        text,
      });
    },
    handleCancel() {
      this.cancelItem();
      this.currentText = this.oldText;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-field-editor {
  position: relative;
  .hover-buttons {
    display: none;
  }
}
.text-field-editor:hover {
  .hover-buttons {
    display: block;
  }
}
.bg-wrap {
  padding: 16px 8px 0;
  border-radius: 4px;
}
.text-field {
  width: 100%;
}
/deep/ .mdc-text-field {
  width: 100%;
}
/deep/ .resizable-textarea .hide-icon:after {
  bottom: 20.7px;
}
/deep/ .btn-wrap {
  margin: 12px 0 32px 8px;
}
</style>
