var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "search-filter" },
    [
      _c("icon", {
        staticClass: "search-filter__icon search-filter__icon__search",
        attrs: { icon: "search" }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        ref: "input",
        staticClass: "search-filter__input",
        attrs: { type: "search", placeholder: _vm.placeholder },
        domProps: { value: _vm.search },
        on: {
          keyup: _vm.handleInputChange,
          search: _vm.handleSearch,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }