<template>
  <article class="sidesheet">
    <div class="close" @click="onClose">
      <icon-btn class="close-icon form-icon" icon="close" />
    </div>
    <slot />
  </article>
</template>
<script>
import IconBtn from 'gameon-components/src/components/icon-btn/icon-btn.vue';

export default {
  components: {
    IconBtn,
  },
  props: {
    onClose: {
      required: true,
      type: Function,
    },
  },
};
</script>
<style lang="scss" scoped>
.sidesheet {
  display: flex;
  height: 100vh;
  width: calc(100vw - 96px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;

  background-color: white;
  box-shadow: 0px 22px 30px rgba(0, 0, 0, 0.1),
    0px 18px 92px rgba(0, 0, 0, 0.06), 0px 48px 76px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  &--scrolling .fixed-menu {
    box-shadow: 4px 5px 7px -2px lightgrey;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.06),
      0px 2px 4px rgba(0, 0, 0, 0.08);
  }
}

.side-sheet--broadcast,
.side-sheet--content {
  width: 870px;
}

.side-sheet--human-handoff {
  width: 448px;
}

.side-sheet--schedule {
  width: 600px;
  overflow: scroll;

  .close {
    position: fixed;
    right: 546px;
    top: 4px;
    z-index: 11;
  }
}
.close {
  position: absolute;
  margin: 4px;
  z-index: 11;
}
/deep/ .form-icon {
  color: $black-medium-emphasis;
  fill: $black-medium-emphasis;
  &:hover {
    color: $black-high-emphasis;
  }
}
/deep/ .form-icon:hover svg {
  fill: $black-high-emphasis;
}

/deep/ .mdc-text-field--disabled,
/deep/ .mdc-text-field--textarea.mdc-text-field--disabled,
/deep/ .chip-input--disabled {
  background-color: transparent;
  .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37);
  }
}
</style>
