// Takes array of objects and dedupes by unique key
// Returns array of unique objects
export const dedupeArr = (arr, uniqueKey) => {
  return arr.reduce(
    (accumulator, currentVal) => {
      const id = currentVal[uniqueKey];

      // include value if uniqueKey not already present
      if (-1 === accumulator.temp.indexOf(id)) {
        accumulator.out.push(currentVal);
        accumulator.temp.push(id);
      }
      return accumulator;

      // return the deduped array
    },
    { temp: [], out: [] }
  ).out;
};

// Takes array of objects and compares by key value
// Returns reordered array
export const sortArrByKey = (arr, compareKey) => {
  return arr.sort(function (a, b) {
    if (a[compareKey] < b[compareKey]) return -1;
    if (a[compareKey] > b[compareKey]) return 1;
    return -1;
  });
};
