var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-width full-height settings" }, [
    _c(
      "div",
      { staticClass: "full-width full-height content" },
      [
        _c("page-header", { attrs: { header: "User Settings" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "margin-small" },
          [
            _c("tabs", { attrs: { tabs: _vm.tabs } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "full-width card-wrap" },
              [
                _c(
                  "card",
                  { attrs: { outline: true } },
                  [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v("Password")
                    ]),
                    _vm._v(" "),
                    _c("text-field", {
                      attrs: {
                        value: _vm.currentPassword,
                        helper: _vm.currentPasswordHelper,
                        valid: _vm.validCurrentPassword,
                        persistent: !_vm.validCurrentPassword,
                        type: "password",
                        label: "Current Password",
                        filled: ""
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.currentPassword = $event
                        },
                        "update:valid": function($event) {
                          _vm.validCurrentPassword = $event
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("text-field", {
                      attrs: {
                        value: _vm.newPassword,
                        valid: _vm.strongPassword,
                        helper: _vm.newPasswordHelper,
                        persistent: !_vm.strongPassword,
                        type: "password",
                        label: "New Password",
                        filled: ""
                      },
                      on: {
                        blur: _vm.checkStrongPassword,
                        "update:value": function($event) {
                          _vm.newPassword = $event
                        },
                        "update:valid": function($event) {
                          _vm.strongPassword = $event
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("text-field", {
                      attrs: {
                        value: _vm.confirm,
                        valid: _vm.passwordsMatch,
                        helper: _vm.passwordsMatchHelper,
                        persistent: !_vm.passwordsMatch,
                        type: "password",
                        label: "Confirm New Password",
                        filled: ""
                      },
                      on: {
                        "update:value": function($event) {
                          _vm.confirm = $event
                        },
                        "update:valid": function($event) {
                          _vm.passwordsMatch = $event
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("save-cancel", {
                      attrs: {
                        "disable-save": !_vm.allFieldsFilled,
                        "handle-cancel": _vm.handleCancel,
                        "handle-update": _vm.changePassword,
                        "old-val": this.newPassword && this.confirm,
                        primaryActionCopy: "Reset",
                        "show-save-cancel": _vm.showSaveCancel
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }