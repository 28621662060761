<template>
  <section class="link-slide" @click="openLink">
    <div class="image" :style="{ 'background-image': `url('${thumbnail}')` }" />
    <div class="gray-background">
      <div class="title-wrap">
        <div class="title">{{ titleViewable }}</div>
      </div>
      <div class="url-wrap">
        <div class="url">{{ urlViewable }}</div>
      </div>
      <div class="read-more-button-text">
        <div class="read-more-button-text">{{ readMoreButtonText }}</div>
      </div>
    </div>
  </section>
</template>
<script>
import { truncate } from '../../../../../helpers/conversions/grammar';

const MAX_LINK_TITLE = 50;
const MAX_LINK_URL = 25;

export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    readMoreButtonText: {
      type: String,
      required: false,
      default: 'Read More',
    },
  },
  computed: {
    titleViewable() {
      return truncate(this.title, MAX_LINK_TITLE);
    },
    urlViewable() {
      return truncate(this.link, MAX_LINK_URL);
    },
  },
  methods: {
    openLink() {
      const link = this.link.includes('http')
        ? this.link
        : `http://${this.link}`;
      const newTab = window.open(link, '_blank');
      newTab.focus();
    },
    truncate(str, maxLen) {
      return truncate(str, maxLen);
    },
  },
};
</script>
<style lang="scss" scoped>
.link-slide {
  display: inline-flex;
  flex-direction: column;
  border-radius: $border-radius-chat-render;
  overflow: hidden;
  width: 232px;
  margin-right: 2px;
  cursor: pointer;
}
.image {
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.gray-background,
.title-wrap,
.url-wrap,
.image {
  width: 100%;
}
.gray-background {
  height: 124px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.08);
  font-size: 14px;
}
.title-wrap,
.url-wrap {
  width: 208px;
  padding: 0 4px;
  word-break: break-word;
}
.title-wrap,
.title {
  height: 40px;
  overflow: hidden;
}
.title,
.url {
  line-height: 20px;
  letter-spacing: 0.25px;
}
.title {
  color: $black-high-emphasis;
  font-weight: 400;
  white-space: normal;
}
.url-wrap {
  margin-bottom: 8px;
}
.url {
  margin-top: 4px;
  color: $black-medium-emphasis;
}
.read-more-button-text {
  background: #ffffff;
  border-radius: 24px;
  padding: 4px 0;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
</style>
