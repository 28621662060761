<template>
  <div class="list-filters full-width">
    <search-filter
      :handleSearchInput="handleSearchInput"
      placeholder="Search Responses"
    />
    <single-select
      v-if="responseNameOptions && selectedResponseName"
      class="filter-by list-filter"
      :handle-choice="setSelectedResponseName"
      :options="responseNameOptions"
      :upstream-val="selectedResponseName"
    />
  </div>
</template>
<script>
import SearchFilter from '../../../../components/search-filter/search-filter.vue';
import SingleSelect from 'gameon-components/src/components/single-select/single-select.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  components: {
    SearchFilter,
    SingleSelect,
  },
  computed: {
    ...mapState('user', ['botData']),
    ...mapState('responseManagerList', [
      'selectedResponseName',
      'responseNameOptions',
    ]),
  },
  methods: {
    ...mapMutations('responseManagerList', ['setSelectedResponseName']),
    ...mapActions('responseManagerList', ['handleSearchInput']),
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../components/list-filters/list-filters.scss';
.search-filter {
  visibility: hidden;
  width: 1px;
}
.filter-by {
  margin-right: 12px;
  height: 48px;
  display: flex;
  align-items: center;
}
</style>
