import { render, staticRenderFns } from "./inline-metrics.vue?vue&type=template&id=3d689ade&scoped=true&"
import script from "./inline-metrics.vue?vue&type=script&lang=js&"
export * from "./inline-metrics.vue?vue&type=script&lang=js&"
import style0 from "./inline-metrics.vue?vue&type=style&index=0&id=3d689ade&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d689ade",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d689ade')) {
      api.createRecord('3d689ade', component.options)
    } else {
      api.reload('3d689ade', component.options)
    }
    module.hot.accept("./inline-metrics.vue?vue&type=template&id=3d689ade&scoped=true&", function () {
      api.rerender('3d689ade', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dashboard/broadcast/broadcast-side-sheet/read/inline-metrics/inline-metrics.vue"
export default component.exports