<template>
  <div class="list-filters full-width">
    <icon class="search-icon" icon="search" />
    <single-select
      class="filter-by list-filter"
      :handle-choice="setSelectedChannel"
      :options="channelOptions"
      :upstream-val="selectedChannel"
    />
  </div>
</template>
<script>
import SingleSelect from 'gameon-components/src/components/single-select/single-select.vue';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    Icon,
    SingleSelect,
  },
  computed: {
    ...mapState('humanHandoff', ['channelOptions', 'selectedChannel']),
  },
  methods: {
    ...mapMutations('humanHandoff', ['setSelectedChannel']),
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../components/list-filters/list-filters.scss';

.filter-by {
  margin-right: 12px;
}
</style>
