<template>
  <section class="edit-row">
    <text-form-field
      v-if="'text' === item.mode"
      v-bind="$props"
      :editingSelf="editingPosition === position"
      :editingItem="editingItem"
      :initialText="item.text"
      :cancelItem="cancelItem"
      :activeUpArrow="activeUpArrow"
      :activeDownArrow="activeDownArrow"
      :handleDel="deleteItem"
      :swapItems="swapItems"
      :position="position"
      :saveItem="saveItem"
      :startEditing="startEditing"
    />
    <media-form-field
      v-else-if="'media' === item.mode"
      v-bind="$props"
      :cancelItem="cancelItem"
      :editingSelf="editingPosition === position"
      :editingItem="editingItem"
      :finishEditingItem="finishEditingItem"
      :link="item.link"
      :thumbnail="item.thumbnail"
      :title="item.title"
      :type="item.type"
      :activeUpArrow="activeUpArrow"
      :activeDownArrow="activeDownArrow"
      :handleDel="deleteItem"
      :swapItems="swapItems"
      :position="position"
      :saveItem="saveItem"
      :startEditing="startEditing"
    />
    <edit-link-group
      v-else-if="'link' === item.mode && position === firstLinkIndex"
      :content="content"
      :cancelItem="cancelItem"
      :deleteAllLinks="deleteAllLinks"
      :editingPosition="editingPosition"
      :editingItem="editingItem"
      :firstLinkIndex="firstLinkIndex"
      :deleteItem="deleteItem"
      :lastLinkIndex="lastLinkIndex"
      :links="links"
      :position="position"
      :startEditing="startEditing"
      :swapItems="swapItems"
      :moveLinksUp="moveLinksUp"
      :moveLinksDown="moveLinksDown"
      :saveItem="saveItem"
    />
  </section>
</template>
<script>
import TextFormField from './message-form-field-types/text-form-field.vue';
import EditLinkGroup from './message-form-field-types/link-form-field/edit-link-group.vue';
import MediaFormField from './message-form-field-types/media-form-field/media-form-field.vue';

export default {
  components: {
    EditLinkGroup,
    MediaFormField,
    TextFormField,
  },
  props: {
    cancelItem: {
      required: true,
      type: Function,
    },
    content: {
      required: true,
      type: Array,
    },
    editingItem: {
      required: true,
      type: Boolean,
    },
    editingPosition: {
      required: true,
      type: Number,
    },
    deleteAllLinks: {
      required: true,
      type: Function,
    },
    deleteItem: {
      required: true,
      type: Function,
    },
    finishEditingItem: {
      required: true,
      type: Function,
    },
    item: {
      required: true,
      type: Object,
    },
    moveLinksDown: {
      required: true,
      type: Function,
    },
    moveLinksUp: {
      required: true,
      type: Function,
    },
    position: {
      required: true,
      type: Number,
    },
    saveItem: {
      required: true,
      type: Function,
    },
    startEditing: {
      required: true,
      type: Function,
    },
    swapItems: {
      required: true,
      type: Function,
    },

    // links
    links: {
      required: true,
      type: Array,
    },
    firstLinkIndex: {
      required: true,
      type: Number,
    },
    lastLinkIndex: {
      required: true,
      type: Number,
    },
  },
  computed: {
    activeUpArrow() {
      return (
        !!this.position &&
        this.content.length &&
        this.position < this.content.length
      );
    },
    activeDownArrow() {
      return !!this.content.length && this.position < this.content.length - 1;
    },
    fileType(item) {
      if (item.type) {
        return item.type.split('/')[0];
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'gameon-components/src/universal/vars';

.edit-row {
  position: relative;
}
/deep/ .edit-row .btn-wrap {
  margin: 12px 0 32px 8px;
}
</style>
