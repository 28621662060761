<template>
  <div class="modal" v-bind:class="{ open: !loggedIn }">
    <div class="modal-content grid-4-center_md-3_sm-1">
      <div class="col">
        <form @submit="login" class="grid">
          <div class="gameon-login">
            <h1 class="modal-title">
              <div class="logo">
                <img src="../../assets/on_logo.svg" />
              </div>
            </h1>
            <text-field
              label="Username"
              :value.sync="userName"
              :valid="!loginAttemptFailed"
              :validation-msg="true"
              :helper="'Invalid username or password'"
              filled
              @change="removeErrorMessage"
              @keydown="removeErrorMessage"
            />
            <text-field
              :value.sync="password"
              filled
              :helper="'Invalid username or password'"
              label="Password"
              type="password"
              :valid="!loginAttemptFailed"
              :validation-msg="true"
              @change="removeErrorMessage"
              @keydown="removeErrorMessage"
            />
            <btn
              raised
              type="submit"
              class="create full-width submit-btn"
              :disabled="!allowSignIn"
            >
              Sign In
            </btn>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import TextField from 'gameon-components/src/components/text-field/text-field.vue';

import { mapState, mapActions } from 'vuex';

import '../../assets/login-logo-gradient.svg';
import '../../assets/icon-indicator-inactive.svg';
import '../../assets/icon-indicator-success.svg';
import '../../assets/icon-indicator-error.svg';

const USERNAME_LEN_MIN = 2;
const PASSWORD_LEN_MIN = 8;

export default {
  components: {
    Btn,
    TextField,
  },
  data() {
    return {
      userName: '',
      password: '',
      errorStillValid: true,
    };
  },
  beforeDestroy() {
    clearInterval(this.pingTimerInterval);
  },
  computed: {
    ...mapState('user', ['lastClear', 'loggedIn', 'loginAttemptFailed']),
    allowSignIn() {
      return this.passwordValid && this.userNameValid;
    },
    passwordValid() {
      return PASSWORD_LEN_MIN <= this.password.length;
    },
    userNameValid() {
      return (
        USERNAME_LEN_MIN <= this.userName.length &&
        this.userName.match(/^[A-Za-z0-9-_]*$/)
      );
    },
  },
  methods: {
    async login(e) {
      e.preventDefault();
      await this.$store.dispatch('user/login', {
        userName: this.userName,
        password: this.password,
      });
      this.errorStillValid = true;
    },
    ...mapActions('user', ['check-token', 'get-user']),
    removeErrorMessage() {
      this.errorStillValid = false;
    },
  },
  watch: {
    lastClear() {
      this.userName = '';
      this.password = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  overflow: hidden; /* Enable scroll if needed */
  &.open {
    display: block;
  }
}

.logo {
  margin-top: 8px;
  margin-bottom: -8px;
  text-align: center;
  img {
    width: 60px;
    height: auto;
    margin: auto;
  }
}

.modal-title {
  text-align: center;
  margin-bottom: 32px;
}

/* Modal Content/Box */
.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #252742;
  background-size: auto auto;
  background: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.gameon-login {
  padding: 32px 16px;
  border-radius: 4px;
  background-color: white;
  width: 100%;
}
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}
input::-webkit-credentials-auto-fill-button {
  left: -10px;
  position: relative;
}
.text-field-resizable {
  margin-bottom: 20px;
}
.submit-btn {
  border-radius: 200px;
  height: 36px;

  margin-bottom: 14px;
  font-size: 14px;
}
</style>
