var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header full-width" },
    [
      _c("div", { staticClass: "header-bar" }, [
        _c("div", [_c("h1", [_vm._v(_vm._s(_vm.header))])])
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }