<template>
  <section class="questions">
    <text-form-field
      v-bind="$props"
      ref="mainQuestion"
      class="questions__main"
      :editingSelf="0 === editingQuestionPos"
      :editingItem="editingItem"
      :initialText="questions[0].text"
      :item="questions[0]"
      header="Question"
      label="Text"
      :cancelItem="cancelQuestion"
      :activeUpArrow="false"
      :activeDownArrow="false"
      :handleDel="() => {}"
      :swapItems="() => {}"
      :position="0"
      :saveItem="setQuestion"
      :startEditing="() => setEditingQuestionPos(0)"
    />
    <section class="buttons">
      <div class="add-content">
        <fab
          :disabled="editingItem"
          class="purple-fab"
          icon="P"
          @click="addAndScroll('text')"
        />
        <div class="fab-desc">Phrase</div>
      </div>
    </section>
    <text-form-field
      v-for="(phrase, index) in phrases"
      v-bind="$props"
      :key="phrase.text + index"
      :id="determineScrollTarget(index)"
      :item="phrase"
      :editingSelf="index + 1 === editingQuestionPos"
      :editingItem="editingItem"
      :initialText="phrase.text"
      header="PHRASING"
      label="Text"
      :cancelItem="cancelQuestion"
      :activeUpArrow="activeUpArrow(index)"
      :activeDownArrow="activeDownArrow(index)"
      :handleDel="deleteQuestion"
      :swapItems="swapPhrases"
      :position="index + 1"
      :saveItem="setQuestion"
      :startEditing="() => setEditingQuestionPos(index + 1)"
    />
  </section>
</template>
<script>
import Fab from 'gameon-components/src/components/fab/fab.vue';
import TextFormField from '../../../../../components/message-form-field/message-form-field-types/text-form-field.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);
const animationTime = 800;

export default {
  components: {
    Fab,
    TextFormField,
  },
  computed: {
    ...mapState('qnaForm', ['questions', 'editingQuestionPos']),
    ...mapGetters('qnaForm', ['editingItem']),
    phrases() {
      if (this.questions.length) return this.questions.slice(1);
      return [];
    },
  },
  methods: {
    ...mapActions('qnaForm', ['cancelQuestion', 'deleteQuestion']),
    ...mapMutations('qnaForm', [
      'createQuestion',
      'setEditingQuestionPos',
      'setQuestion',
      'swapPhrases',
    ]),
    addAndScroll(mode) {
      this.createQuestion({ type: 'text' });
      this.$nextTick(() => {
        this.scrollTo();
      });
    },
    scrollTo() {
      VueScrollTo.scrollTo('#scroll-target', animationTime, {
        container: '.creation-wrap',
      });
    },
    determineScrollTarget(index) {
      const minimumIndex = 1;
      const matchingIndex = this.editingQuestionPos === index + 1;
      if (index > minimumIndex && matchingIndex) {
        return 'scroll-target';
      } else {
        return '';
      }
    },
    activeUpArrow(i) {
      return !!i && 1 < this.phrases.length;
    },
    activeDownArrow(i) {
      return !!this.phrases.length && i < this.phrases.length - 1;
    },
    focusMain() {
      this.$refs.mainQuestion.$el.querySelectorAll('textarea')[0].focus();
    },
  },
  mounted() {
    if (0 === this.editingQuestionPos) this.focusMain();
  },
};
</script>
<style scoped lang="scss">
@import './qna-questions.scss';
</style>
