<template>
  <section
    class="links-preview"
    :class="{ 'links-preview--vertical': !linksHorizontal }"
  >
    <carousel
      v-if="linksHorizontal"
      :onPlus="() => {}"
      :key="carouselChangeDetection"
    >
      <link-slide
        class="invisible-placeholder"
        link=""
        thumbnail=""
        title=""
        readMoreButtonText=""
      />
      <link-slide
        v-for="link in linksRenderable"
        :key="link.link"
        :link="link.link"
        :thumbnail="link.thumbnail"
        :title="link.title"
        :readMoreButtonText="link.readMoreButtonText"
      />
    </carousel>
    <link-slide
      v-else
      v-for="link in links"
      :key="getLinkChangeDetection(link)"
      :link="link.link"
      :thumbnail="link.thumbnail"
      :title="link.title"
      :readMoreButtonText="link.readMoreButtonText"
    />
  </section>
</template>
<script>
import Carousel from '../../../carousel/carousel.vue';
import LinkSlide from './link-slide/link-slide.vue';

export default {
  components: {
    Carousel,
    LinkSlide,
  },
  computed: {
    carouselChangeDetection() {
      return this.links
        .map(
          (link) =>
            `${link.link}${link.thumbnail}${link.title}${link.readMoreButtonText}`
        )
        .join('');
    },
    linksRenderable() {
      return this.links.filter(
        (link) => !!link.link && !!link.thumbnail && !!link.title
      );
    },
  },
  props: {
    links: {
      required: true,
      type: Array,
    },
    linksHorizontal: {
      required: true,
      type: Boolean,
    },
    position: {
      required: true,
      type: Number,
    },
  },
  methods: {
    getLinkChangeDetection(link) {
      return `${link.link}${link.thumbnail}${link.title}${link.readMoreButtonText}`;
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .invisible-placeholder .gray-background {
  background-color: transparent;
  cursor: initial;
}
/deep/ .invisible-placeholder .read-more-button-text {
  background: transparent;
}
.links-preview--vertical .link-slide {
  margin-bottom: 2px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
