<template>
  <div class="status-chip" :class="`status-chip--${status}`">
    {{ text }}
  </div>
</template>
<script>
import { capitalize } from '../../helpers/conversions/grammar';

export default {
  props: {
    status: {
      required: true,
      type: String,
    },
  },
  computed: {
    text() {
      return capitalize(this.status);
    },
  },
};
</script>
<style lang="scss" scoped>
.status-chip {
  width: 90px;
  border-radius: 16px;
  height: 32px;
  line-height: 32px;

  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  text-align: center;
  letter-spacing: 0.15px;
}
// Draft does not currently exist, reusing style for Finished
.status-chip.status-chip--draft {
  background-color: #e1f7f7;
  color: $mdc-theme-secondary;
}
.status-chip.status-chip--error {
  background-color: #fecdd2;
  color: #d32f2f;
}
.status-chip.status-chip--finished {
  background-color: #e1f7f7;
  color: $mdc-theme-secondary;
}
.status-chip.status-chip--pending {
  background-color: #e1f7f7;
  color: #1e8485;
}
.status-chip.status-chip--paused {
  background-color: #fff3e0;
  color: #f57c00;
}
.status-chip.status-chip--sent {
  background-color: #e8f5e9;
  color: #43a047;
}
.status-chip.status-chip--scheduled {
  background-color: #e1f5fe;
  color: #0288d1;
}
</style>
