<template>
  <div class="list-view">
    <list-headers />
    <m-list v-if="currentPageItems.length" class="full-width">
      <div class="list-view__rows">
        <m-list-item
          v-for="content in currentPageItems"
          @click="setInitialContent(content)"
          class="full-width"
          :key="content.id"
        >
          <div class="content-title">
            <fab-static
              v-if="content.thumbnailType.includes('text')"
              type="text"
            >
              T
            </fab-static>
            <!--
            <fab-static
              v-else-if="content.schema.includes('general')"
              type="media">
              <icon icon="insert_photo" />
            </fab-static>
          -->
            <div class="thumbnail">
              <static-gif
                v-if="content.fileType && content.fileType.includes('gif')"
                :gif="content.body && content.body.link"
                :title="content.body && content.body.title"
              />
              <img
                class="thumbnail__img"
                v-else-if="
                  content.body &&
                  content.body.mimeType &&
                  content.body.mimeType.includes('video')
                "
                :src="content.body.thumbnail"
              />
              <img
                class="thumbnail__img"
                v-else-if="
                  content.body &&
                  content.body.mimeType &&
                  content.body.mimeType.includes('image')
                "
                :src="content.body.link"
              />

              <img
                v-else-if="'links' === content.thumbnailType"
                class="thumbnail__img"
                :src="getLinkThumb(content)"
              />
              <img
                v-else-if="'media' === content.thumbnailType"
                class="thumbnail__img"
                :src="getMediaThumb(content)"
              />
            </div>
            <div>{{ content.titleTrimmed }}</div>
            <span v-if="content.mimeTypeViewable" class="content-file-type">
              {{ content.mimeTypeViewable }}
            </span>
            <span v-if="content.contentDescriptor" class="content-file-type">
              {{ getContentDescriptorTrimmed(content.contentDescriptor) }}
            </span>
            <span v-if="!!content.durationViewable" class="center-dot"
              >&bull;</span
            >
            <span v-if="content.durationViewable" class="content-file-info">
              {{ content.durationViewable }} mins
            </span>
            <span v-if="!!content.sizeViewable" class="center-dot">&bull;</span>
            <span v-if="content.sizeViewable" class="content-file-info">
              {{ content.sizeViewable }}
            </span>
            <span
              v-if="content.body.height && content.body.width"
              class="center-dot"
            >
              &bull;
            </span>
            <span
              v-if="content.body.height && content.body.width"
              class="content-file-info"
            >
              {{ content.body.width }} x {{ content.body.height }}
            </span>
          </div>
          <div class="content-tags">
            <span v-for="tag in content.tags" :key="tag.id">
              {{ tag.name }}
            </span>
          </div>
          <div class="content-edited">
            {{ content.updatedAtViewable }}
          </div>
          <div class="content-created">
            {{ content.createdAtViewable }}
          </div>
        </m-list-item>
      </div>
      <div class="scroll-pseudo-padding" />
      <slot />
    </m-list>
    <empty-list-loading v-if="waitingForData && !items.length" />
    <empty-message
      v-else-if="!items.length && !waitingForData"
      message="The combination of search and sort filters yielded no results. Please try another."
    />
    <div v-else-if="waitingForData" class="broadcasts-loading">
      <indeterminate-circle />
    </div>
    <pagination
      :loadMoreData="loadMoreData"
      :reachedLastPage="reachedLastPage"
      :waitingForData="waitingForData"
      :rowCountOnCurrentPage="rowCountOnCurrentPage"
    />
  </div>
</template>
<script>
import EmptyListLoading from '../../../components/empty-list-loading/empty-list-loading.vue';
import EmptyMessage from '../../../components/empty-message/empty-message.vue';
import IndeterminateCircle from '../../../components/indeterminate-circle/indeterminate-circle.vue';
import FabStatic from '../../../components/fab-static/fab-static.vue';
import List from 'material-components-vue/dist/list';
import ListHeaders from './list-headers.vue';
import Pagination from './pagination/pagination.vue';
import StaticGif from './static-gif.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../state/content/content-list/constants';
import { MAX_DESCRIPTOR_LEN } from '../constants';

import Vue from 'vue';
Vue.use(List);

export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    loadMoreData: {
      required: true,
      type: Function,
    },
    waitingForData: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  components: {
    EmptyListLoading,
    EmptyMessage,
    FabStatic,
    // Icon,
    IndeterminateCircle,
    ListHeaders,
    Pagination,
    StaticGif,
  },
  computed: {
    ...mapState('contentList', [
      'itemsPerLoad',
      'currentListPage',
      'endOfContent',
    ]),
    ...mapGetters('contentList', ['firstRowIndex', 'lastRowIndex']),
    currentPageItems() {
      if (!this.items.length) return [];
      const currentPage = this.items.slice(
        this.firstRowIndex,
        this.lastRowIndex + 1
      );
      return currentPage;
    },
    reachedLastPage() {
      const lastRow = !(this.lastRowIndex < this.items.length - 1);
      const currentPage = this.items.slice(
        this.firstRowIndex,
        this.lastRowIndex + 1
      );
      const vacancies = currentPage.length < DEFAULT_ITEMS_PER_PAGE;
      return (this.endOfContent && lastRow) || vacancies;
    },
    rowCountOnCurrentPage() {
      return this.currentPageItems.length;
    },
  },
  methods: {
    ...mapMutations('contentForm', ['setInitialContent']),
    getContentDescriptorTrimmed(descriptor) {
      if (MAX_DESCRIPTOR_LEN < descriptor.length) {
        return descriptor.slice(0, MAX_DESCRIPTOR_LEN).concat('...');
      } else {
        return descriptor;
      }
    },
    getLinkThumb(data) {
      const linkItem = data.body.content.filter(
        (contentItem) => !!contentItem.attachments
      );
      return linkItem[0].attachments[0].image;
    },
    getMediaThumb(data) {
      const mediaItems = data.body.content.filter(
        (contentItem) => !!contentItem.media
      );
      return mediaItems[0].media[0].thumbnail || mediaItems[0].media[0].link;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'material-components-vue/dist/list/styles';
@import '../../../components/list-view/list-view.scss';
@import './list-view.scss';
</style>
