var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { staticClass: "mdc-circular-progress", attrs: { viewBox: "25 25 50 50" } },
    [
      _c("circle", {
        staticClass: "mdc-circular-progress__path",
        attrs: {
          cx: "50",
          cy: "50",
          r: "8",
          fill: "none",
          "stroke-width": "2",
          "stroke-miterlimit": "10"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }