import { DEFAULT_ITEMS_PER_PAGE } from './constants';
import { ORDER_BY_OPTIONS, FILTER_BY_OPTIONS } from '../constants';

const defaultState = {
  itemsPerLoad: DEFAULT_ITEMS_PER_PAGE,
  currentListPage: 1,
  endOfList: false,
  lastRefresh: null,
};
const getDefaultState = () => {
  return { ...defaultState };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetListState: (state) => {
      Object.assign(state, getDefaultState());
    },
    nextPage: (state) => {
      state.currentListPage = state.currentListPage + 1;
    },
    prevPage: (state) => {
      state.currentListPage = state.currentListPage - 1;
    },
    declareEndOfList: (state) => {
      state.endOfList = true;
    },
    triggerListRefresh: (state) => {
      state.filterBy = FILTER_BY_OPTIONS[0].value;
      state.orderBy = ORDER_BY_OPTIONS[0].value;
      state.lastRefresh = new Date();
    },
  },
  getters: {
    firstRowIndex: (state) => {
      return (state.currentListPage - 1) * state.itemsPerLoad;
    },
    lastRowIndex: (state) => {
      return state.currentListPage * state.itemsPerLoad - 1;
    },
  },
};
