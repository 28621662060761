<template>
  <div
    class="button-editor"
    :class="{ 'hover-bg': !editingItem && !editingButtons }"
  >
    <div class="overline">Button</div>
    <chips-input
      :chipset="currentChippedButtons"
      :disabled="!editingButtons"
      label="Labels"
      :handle-chip-add="handleButtonAdd"
      :handle-icon-click="handleButtonDel"
      helper-text="Type button text and press enter to create button"
    />
    <save-cancel
      :disable-save="disableSave"
      :handle-cancel="handleCancel"
      :handle-update="handleUpdate"
      :old-val="this.buttons.length"
      :show-save-cancel="editingButtons"
      :primaryActionCopy="this.buttons.length ? 'Update' : 'Add'"
    />
    <div v-if="!editingButtons && !editingItem" class="hover-buttons">
      <icon
        class="action-icon"
        v-if="!editingItem"
        icon="edit"
        @click="() => setEditingButtons(!editingButtons)"
      />
      <icon class="action-icon" icon="delete" @click="confirmDel" />
    </div>
  </div>
</template>
<script>
import ChipsInput from 'gameon-components/src/components/chips-input/chips-input.vue';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import SaveCancel from '../../save-cancel/save-cancel.vue';
import { mapState, mapMutations } from 'vuex';

import { SNACK_TYPES, SNACK_CLASSES } from '../../../constants/snackbar';

export default {
  components: {
    ChipsInput,
    Icon,
    SaveCancel,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    editingButtons: {
      type: Boolean,
      required: true,
    },
    editingItem: {
      type: Boolean,
      required: true,
    },
    setEditingButtons: {
      type: Function,
      required: true,
    },
    deleteButtons: {
      type: Function,
      required: true,
    },
    setButtons: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('modal', ['modal']),
    currentNames() {
      if (this.currentChippedButtons && this.currentChippedButtons.length) {
        return this.currentChippedButtons.map((chip) => chip.name) || [];
      }
      return [];
    },
    currentButtons() {
      return this.chipsToButtons(this.currentChippedButtons);
    },
    disableSave() {
      return this.unchanged;
    },
    unchanged() {
      // If before and after is different length
      if (this.currentChippedButtons.length !== this.buttons.length) {
        return false;

        // If before and after is same length, check values
      } else if (this.currentChippedButtons.length === this.buttons.length) {
        for (let i = 0; i < this.buttons.length; i += 1) {
          if (this.buttons[i].title !== this.currentChippedButtons[i].name)
            return false;
        }
      }
      return true;
    },
  },
  data() {
    const initialChippedButtons = this.buttonsToChips(this.buttons);
    return {
      currentChippedButtons: initialChippedButtons || [],
    };
  },
  methods: {
    ...mapMutations('modal', ['showModal']),
    ...mapMutations('snackbar', ['openSnack']),
    buttonsToChips(btnArr = []) {
      return btnArr.map((btn) => {
        return {
          name: btn.title,
          icon: {
            slot: 'trailingIcon',
            icon: 'cancel',
          },
        };
      });
    },
    chipsToButtons(chipArr) {
      return chipArr.map((chip) => {
        return {
          title: chip.name,
          mode: 'button',
        };
      });
    },
    confirmDel() {
      const text = 'Are you sure you want to delete all the buttons?';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        actionButton: 'Delete',
        pendingAction: this.deleteButtons,
      });
    },
    handleButtonAdd(text) {
      if (text in this.currentNames) return;
      this.currentChippedButtons.push({
        name: text,
        icon: {
          slot: 'trailingIcon',
          icon: 'cancel',
        },
      });
    },
    handleButtonDel(chipSelected) {
      this.currentChippedButtons = this.currentChippedButtons.filter((chip) => {
        return chipSelected.name !== chip.name;
      });
    },
    handleCancel() {
      this.setEditingButtons(false);
    },
    handleUpdate() {
      const draftButton = this.$children[0].$refs.textInput.value;
      if (draftButton.trim()) {
        this.handleButtonAdd(draftButton);
        this.$children[0].$refs.textInput.value = '';
      }
      const updatedButtons = this.chipsToButtons(this.currentChippedButtons);
      this.setButtons(updatedButtons);
      this.setEditingButtons(false);
      const text = 'All updates saved';
      this.openSnack({
        customClass: SNACK_CLASSES.CUSTOM_SIDESHEET,
        type: SNACK_TYPES.SUCCESS,
        text,
      });
    },
  },
  mounted() {
    this.currentChippedButtons = this.buttonsToChips(this.buttons);
  },
  watch: {
    buttons() {
      this.currentChippedButtons = this.buttonsToChips(this.buttons);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-editor {
  position: relative;
  padding: 16px 8px 0;
  margin-top: 16px;
}
.button-editor:hover.hover-bg {
  border-radius: 4px;
}

// Hover actions
.hover-buttons {
  display: none;
  position: absolute;
  right: 8px;
  top: 12px;
}
.edit-row:hover .hover-buttons {
  display: block;
}
.action-icon {
  cursor: pointer;
}
/deep/ .hover-buttons .material-icons {
  font-size: 18px;
  color: $black-medium-emphasis;
}
</style>
