import { DISPLAY_CHANNELS, ORDER_BY_OPTIONS } from './constants';
import { getBotConfig } from '../../api';

export default {
  namespaced: true,
  state: {
    dateFormat: 'locale',
    editing: false,
    selectedContent: null,
    showUploadSideSheet: false,

    // list filters
    channelOptions: [],
    displayChannels: DISPLAY_CHANNELS,
    selectedChannel: '',
    orderBy: ORDER_BY_OPTIONS[0].value,
    orderByOptions: ORDER_BY_OPTIONS,
  },
  mutations: {
    setChannelOptions: (state, options) => {
      state.channelOptions = options;
    },
    setDateFormat: (state, format) => {
      const dateOptions = {
        locale: 'locale',
        GMT: 'GMT',
      };
      if (dateOptions[format]) {
        state.dateFormat = dateOptions[format];
      }
    },
    setEditing: (state, payload) => {
      state.editing = payload;
    },
    setSelectedChannel: (state, value) => {
      state.selectedChannel = value;
    },
    setOrderBy: (state, choice) => {
      state.orderBy = choice;
    },
    setShowUploadSideSheet: (state, bool) => {
      state.showUploadSideSheet = bool;
    },
  },
  getters: {
    humanHandoffAvailable(state, getters, rootState) {
      try {
        return !!Object.keys(rootState.user.data.apps['human-handoff']);
      } catch (e) {
        return false;
      }
    },
  },
  actions: {
    async updateChannelOptions({ commit, getters, rootState }) {
      if (!getters.humanHandoffAvailable) return;
      const optionifyChannels = (channelValue) => {
        if (DISPLAY_CHANNELS[channelValue]) {
          return {
            display: DISPLAY_CHANNELS[channelValue],
            value: channelValue,
          };
        } else {
          return {
            display: channelValue,
            value: channelValue,
          };
        }
      };
      const botChannels = (await getBotConfig(rootState.user.botData.key))
        .channels;
      const botChannelOptions = botChannels.map((channelVal) =>
        optionifyChannels(channelVal)
      );
      const allChannelsOption = [{ display: 'All Channels', value: 'all' }];
      const channelOptions = allChannelsOption.concat(botChannelOptions);
      commit('setChannelOptions', channelOptions);
    },
  },
};
