var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width full-height content-page" },
    [
      _c("confirm-modal", { attrs: { show: _vm.modal === "CONFIRM" } }),
      _vm._v(" "),
      _c("upload-size-modal", { attrs: { show: _vm.modal === "UPLOAD_SIZE" } }),
      _vm._v(" "),
      _c("error-modal", { attrs: { show: _vm.modal === "ERROR" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-width full-height content" },
        [
          _c("page-header", { attrs: { header: "Human Handoff" } }),
          _vm._v(" "),
          _c("list-filters"),
          _vm._v(" "),
          _c("list-view", {
            attrs: {
              items: _vm.totalItems,
              "load-more-data": _vm.loadMoreData,
              "waiting-for-data": _vm.waitingForData
            }
          }),
          _vm._v(" "),
          _vm.selectedUser
            ? _c(
                "side-sheet",
                {
                  staticClass: "side-sheet--human-handoff",
                  class: {
                    pending: _vm.selectedUser.stateViewable === "pending",
                    active: _vm.selectedUser.stateViewable === "active"
                  },
                  attrs: {
                    onClose: function() {
                      return _vm.handleCloseSideSheet()
                    }
                  }
                },
                [
                  _c("chat-info"),
                  _vm._v(" "),
                  _c("chat-conversation", { key: _vm.selectedUser.id })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.waitingForData
        ? _c(
            "div",
            { staticClass: "page-loading" },
            [_c("indeterminate-circle")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }