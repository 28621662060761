var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "media-form-field",
      class: {
        "media-form-field--editing": _vm.editingSelf
      }
    },
    [
      _c(
        "div",
        { staticClass: "bg-wrap", class: { "hover-bg": !_vm.editingItem } },
        [
          !_vm.editingItem
            ? _c("hover-buttons", {
                attrs: {
                  activeUpArrow: _vm.activeUpArrow,
                  activeDownArrow: _vm.activeDownArrow,
                  handleDel: _vm.confirmDel,
                  startEditing: function() {
                    return _vm.startEditing(_vm.position)
                  },
                  onSwapUp: function() {
                    return _vm.swapItems({
                      ind: _vm.position,
                      ind2: _vm.position - 1
                    })
                  },
                  onSwapDown: function() {
                    return _vm.swapItems({
                      ind: _vm.position,
                      ind2: _vm.position + 1
                    })
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "overline" }, [_vm._v("Media")]),
          _vm._v(" "),
          _vm.mediaMode === _vm.mediaModes.link || _vm.initialItem.link
            ? _c("media-link", {
                attrs: {
                  title: _vm.formData.title,
                  link: _vm.formData.link,
                  thumbnail: _vm.formData.thumbnail,
                  type: _vm.formData.type,
                  editingSelf: _vm.editingSelf,
                  updateFormData: _vm.updateFormData
                },
                on: {
                  "update:title": function($event) {
                    return _vm.$set(_vm.formData, "title", $event)
                  },
                  "update:link": function($event) {
                    return _vm.$set(_vm.formData, "link", $event)
                  },
                  "update:thumbnail": function($event) {
                    return _vm.$set(_vm.formData, "thumbnail", $event)
                  }
                }
              })
            : _c("media-upload", {
                attrs: { updateFormData: _vm.updateFormData }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c("save-cancel", {
        attrs: {
          "disable-save": !_vm.allowSave,
          "handle-cancel": _vm.cancelUpload,
          "handle-update": _vm.handleUpdate,
          "old-val": _vm.initialItem,
          "show-save-cancel": _vm.editingSelf,
          primaryActionCopy: _vm.initialItem.link ? "Update" : "Add"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }