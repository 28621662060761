<template>
  <div class="full-width full-height settings">
    <div class="full-width full-height content">
      <page-header header="User Settings" />
      <div class="margin-small">
        <tabs :tabs="tabs" />
        <div class="full-width card-wrap">
          <card :outline="true">
            <h5 class="card-title">Password</h5>
            <text-field
              :value.sync="currentPassword"
              :helper="currentPasswordHelper"
              :valid.sync="validCurrentPassword"
              :persistent="!validCurrentPassword"
              type="password"
              label="Current Password"
              filled
            />
            <text-field
              @blur="checkStrongPassword"
              :value.sync="newPassword"
              :valid.sync="strongPassword"
              :helper="newPasswordHelper"
              :persistent="!strongPassword"
              type="password"
              label="New Password"
              filled
            />
            <text-field
              :value.sync="confirm"
              :valid.sync="passwordsMatch"
              :helper="passwordsMatchHelper"
              :persistent="!passwordsMatch"
              type="password"
              label="Confirm New Password"
              filled
            />
            <save-cancel
              :disable-save="!allFieldsFilled"
              :handle-cancel="handleCancel"
              :handle-update="changePassword"
              :old-val="this.newPassword && this.confirm"
              primaryActionCopy="Reset"
              :show-save-cancel="showSaveCancel"
            />
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'gameon-components/src/components/card/card.vue';
import PageHeader from '../page-header/page-header.vue';
import SaveCancel from '../save-cancel/save-cancel.vue';
import Tabs from 'gameon-components/src/components/tabs/tabs.vue';
import TextField from 'gameon-components/src/components/text-field/text-field.vue';

import { mapMutations } from 'vuex';
import { setMyPassword, checkPassword } from '../../api';
import { MIN_PASSWORD_LEN } from './constants';
import { SNACK_TYPES } from '../../constants/snackbar';

export default {
  data() {
    return {
      validCurrentPassword: true,
      currentPassword: '',
      newPassword: '',
      confirm: '',
      isBusy: false,
      passwordsMatch: true,
      strongPassword: true,
      successText: 'Password successfully changed',
      tabs: [
        {
          title: 'Password',
        },
      ],
    };
  },
  components: { Card, PageHeader, SaveCancel, Tabs, TextField },
  computed: {
    allFieldsFilled() {
      return !!this.currentPassword && !!this.newPassword && !!this.confirm;
    },
    currentPasswordHelper() {
      return this.validCurrentPassword
        ? ' '
        : 'The current password entered does not match our records';
    },
    newPasswordHelper() {
      return this.strongPassword
        ? '8 character minimum'
        : 'New password must be at least 8 characters';
    },
    passwordsMatchHelper() {
      return this.passwordsMatch
        ? 'Must match New Password field'
        : 'The two passwords do not match';
    },
    showSaveCancel() {
      if (this.isBusy) return false;
      return !!this.currentPassword || !!this.newPassword || !!this.confirm;
    },
    validForm() {
      return (
        !!this.validCurrentPassword &&
        !!this.passwordsMatch &&
        !!this.strongPassword
      );
    },
  },
  methods: {
    ...mapMutations('snackbar', ['openSnack']),
    checkPasswordsMatch() {
      this.passwordsMatch = this.newPassword === this.confirm;
    },
    checkStrongPassword() {
      this.strongPassword = MIN_PASSWORD_LEN <= this.newPassword.length;
    },
    async checkCurrentPassword() {
      try {
        await checkPassword(this.currentPassword);
        this.validCurrentPassword = true;
      } catch (err) {
        // eslint-disable-next-line
        console.log('err', err);
        this.validCurrentPassword = false;
      }
    },
    handleCancel() {
      this.currentPassword = '';
      this.newPassword = '';
      this.confirm = '';
    },
    async setNewPassword() {
      try {
        const res = await setMyPassword(this.newPassword, this.currentPassword);
        if ('ok' === res.status) {
          this.validCurrentPassword = true;
          const successText = 'Password successfully changed';
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: successText });
        }
        this.currentPassword = '';
        this.newPassword = '';
        this.confirm = '';
      } catch (err) {
        // eslint-disable-next-line
        console.log('error changing password', err);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      }
    },
    async changePassword(e) {
      e.preventDefault();
      this.isBusy = true;

      this.checkPasswordsMatch();
      this.checkStrongPassword();
      await this.checkCurrentPassword();

      if (this.validForm) {
        await this.setNewPassword();
      }
      this.isBusy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings .content {
  display: block;
}
.page-header {
  margin-bottom: 24px;
}
.margin-small {
  margin: 0 16px;
}

// custom tab bar styles
.mdc-tab-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mdc-tab-scroller {
  position: relative;
  bottom: -1px;
}

// card
.card-wrap {
  display: flex;
  justify-content: center;
}
.mdc-card {
  width: 460px;
  padding: 16px;
  margin-top: 48px;
}
.card-title {
  margin-bottom: 16px;
}
/deep/ .card-wrap .text-field-resizable {
  position: relative;
  margin: 8px 0;
}
.btn-wrap {
  margin: 12px 0 0 0;
}

/deep/ .save.mdc-button[data-v-6a742ea5]:not(:disabled) {
  color: #22aeb5;
  background-color: white;
}
</style>
