import { DEFAULT_BROADCASTS_PER_PAGE } from './constants';

const defaultState = {
  broadcastsPerPage: DEFAULT_BROADCASTS_PER_PAGE,
  currentBroadcastListPage: 1,
  totalCount: 0,
};
const getDefaultState = () => {
  return { ...defaultState };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetBroadcastListState: (state) => {
      state.totalCount = 0;
      state.currentBroadcastListPage = 1;
    },
    goToFirstPage: (state) => {
      state.currentBroadcastListPage = 1;
    },
    goToLastPage: (state) => {
      state.currentBroadcastListPage =
        1 + parseInt(state.totalCount / state.broadcastsPerPage);
    },
    nextPage: (state) => {
      state.currentBroadcastListPage = state.currentBroadcastListPage + 1;
    },
    prevPage: (state) => {
      state.currentBroadcastListPage = state.currentBroadcastListPage - 1;
    },
    setBroadcastsPerPage: (state, increment) => {
      state.broadcastsPerPage = increment;
    },
    setTotalCount: (state, count) => {
      state.totalCount = count;
    },
  },
};
