var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "broadcast-read" },
    [
      _vm.showOptions
        ? _c(
            "section",
            { staticClass: "option-buttons" },
            [
              _vm.allowEdit
                ? _c("icon-btn", {
                    staticClass: "option-btn form-icon",
                    attrs: { icon: "edit" },
                    on: { click: _vm.handleEdit }
                  })
                : _vm._e(),
              _c(
                "more-menu",
                { attrs: { button: true } },
                [
                  _c("more-broadcast-options", {
                    staticClass: "option-btn",
                    attrs: {
                      broadcast: _vm.broadcast,
                      "active-stop": _vm.broadcast.activeStop,
                      "active-restart": _vm.broadcast.activeRestart
                    },
                    on: { stopped: _vm.stopped }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "content-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.broadcast.title ||
                _vm.broadcast.displayText ||
                "Unnamed broadcast"
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bot" }, [
        _vm.botData.display && _vm.botData.display.avatar
          ? _c("div", { staticClass: "bot__avatar" }, [
              _c("img", {
                staticClass: "bot__avatar__img",
                attrs: { src: _vm.botData.display.avatar }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "bot__send-info" }, [
          _c("h5", { staticClass: "bot__send-info__name" }, [
            _vm._v("\n        " + _vm._s(_vm.botData.display.name) + "\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "body2" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.broadcast.campaignName) + "\n      "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.hasMetrics ? _c("hr", { staticClass: "hr hr--above" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "expand" } },
        [
          _vm.hasMetrics
            ? _c("inline-metrics", { attrs: { metrics: _vm.groupMetrics } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasMetrics
        ? _c("hr", { staticClass: "hr hr--below" })
        : _c("hr", { staticClass: "hr hr--no-metrics" }),
      _vm._v(" "),
      _c("div", { staticClass: "specs full-width" }, [
        _vm.broadcast.status
          ? _c(
              "div",
              { staticClass: "field-value-pair full-width status-pair" },
              [
                _c("div", { staticClass: "field" }, [_vm._v("Status")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c("status-chip", {
                      attrs: { status: _vm.broadcast.status }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.broadcast.schedule && _vm.broadcast.schedule.length
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              _c("div", { staticClass: "field" }, [_vm._v("Schedule")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "value-rows" },
                _vm._l(_vm.broadcast.scheduleViewable, function(schedule, i) {
                  return _c("div", { key: i, staticClass: "value caption" }, [
                    _c("div", { staticClass: "date-time" }, [
                      _c("div", { staticClass: "schedule--starting" }, [
                        _c("div", { staticClass: "schedule--date date" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(schedule.startingDateViewable) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "schedule--time" },
                          [
                            _c("locale-time", {
                              attrs: { time: schedule.startingTimeViewable }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      schedule.endingDateViewable
                        ? _c("div", { staticClass: "arrow" }, [_vm._v("→")])
                        : _vm._e(),
                      _vm._v(" "),
                      schedule.endingDateViewable
                        ? _c("div", { staticClass: "schedule--ending" }, [
                            _c("div", { staticClass: "schedule--date" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(schedule.endingDateViewable) +
                                  "\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "schedule--time" },
                              [
                                _c("locale-time", {
                                  attrs: { time: schedule.endingTimeViewable }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "repeat" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(schedule.repeatViewable) +
                          "\n          "
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        this.lastRun
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              "schedule" === _vm.broadcast.source
                ? _c("div", { staticClass: "field" }, [
                    _vm._v("\n        Last Sent\n      ")
                  ])
                : _c("div", { staticClass: "field" }, [_vm._v("Sent")]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _c("div", { staticClass: "date-time" }, [
                  _c("div", { staticClass: "schedule--starting" }, [
                    _c("div", { staticClass: "schedule--date" }, [
                      _vm._v(_vm._s(_vm.lastRunDate))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "schedule--time" },
                      [_c("locale-time", { attrs: { time: _vm.lastRunTime } })],
                      1
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.broadcast.source === _vm.BROADCAST_SOURCES.BROADCAST && _vm.audience
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              _c("div", { staticClass: "field" }, [_vm._v("Audience")]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v("\n        " + _vm._s(_vm.audience) + "\n      ")
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.channels && _vm.broadcast.source === _vm.BROADCAST_SOURCES.BROADCAST
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              _c("div", { staticClass: "field" }, [_vm._v("Channels")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "value" },
                _vm._l(_vm.channels, function(channel) {
                  return _c(
                    "div",
                    { key: channel, staticClass: "capitalize" },
                    [_vm._v("\n          " + _vm._s(channel) + "\n        ")]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.broadcast.source === _vm.BROADCAST_SOURCES.BROADCAST
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              _c("div", { staticClass: "field" }, [_vm._v("Last Sent")]),
              _vm._v(" "),
              _c("div", { staticClass: "value date" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.broadcast.lastSendViewable.date) +
                    "\n        "
                ),
                _c(
                  "div",
                  { staticClass: "schedule--time" },
                  [
                    _c("locale-time", {
                      attrs: { time: _vm.broadcast.lastSendViewable.time }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.updatedAtViewable
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              _c("div", { staticClass: "field" }, [_vm._v("Last Edited")]),
              _vm._v(" "),
              _c("div", { staticClass: "value date" }, [
                _vm._v(_vm._s(_vm.updatedAtViewable))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.broadcast.createdAtViewable
          ? _c("div", { staticClass: "field-value-pair full-width" }, [
              _c("div", { staticClass: "field" }, [_vm._v("Created")]),
              _vm._v(" "),
              _c("div", { staticClass: "value date" }, [
                _vm._v(_vm._s(_vm.broadcast.createdAtViewable))
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }