var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "empty-msg full-width" }, [
    _c("p", { staticClass: "empty-text" }, [_vm._v(_vm._s(_vm.message))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }