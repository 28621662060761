import assert from 'assert';
import { get, post, put, del } from './axios-wrapper';

// ----------------------------------------------------------------
// Auth and user validation
// ----------------------------------------------------------------
export const tokenCheck = async () => {
  return 'ok' === (await get('/token-check')).data.status;
};
export const authenticate = async (user, password) => {
  assert(user, 'username not provided');
  assert(password, 'password not provided');

  try {
    const status = (
      await post('/token', {
        user,
        password,
      })
    ).data.status;

    return 'ok' === status;
  } catch (e) {
    return false;
  }
};

export const checkLinkValidity = async (url) =>
  (await post('/v1/is-link-valid', { url })).data;

export const user = async () => (await get('/v1/authenticated-user')).data;

export const checkPassword = async (password) =>
  (await post('/v1/check-my-password', { password })).data;
// End auth and validations
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// Upload
// ----------------------------------------------------------------
export const opengraphMetadata = async (url) =>
  (await post('/v1/opengraph-metadata', { url })).data;
// end Upload
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// Content
// ----------------------------------------------------------------
export const addContent = async (schema, content, context, title) => {
  return (await post('/v1/content/add', { schema, content, context, title }))
    .data;
};

export const getContent = async (
  id,
  count,
  recursive = false,
  offset,
  sortBy
) => {
  const countStr = count ? `&count=${count}` : '';
  const sortByStr = sortBy ? `&sortBy=${sortBy}` : '';
  const offsetStr = offset ? `&offset=${offset}` : '';
  const recursiveString = recursive ? '&recursive=1' : '';
  const queriesStr = `id=${id}${countStr}${recursiveString}${offsetStr}${sortByStr}`;
  return (await get(`/v1/content/getContent?${queriesStr}`)).data;
};

export const getContentItemTags = async (tagId) =>
  (await get(`/v1/content/getContentTags?id=${tagId}`)).data;

export const getContentMeta = async (s3Url) =>
  (await get(`/v1/content/getContentMeta?url=${s3Url}`)).data;

export const getBotTag = async (chatApp) =>
  (await get(`/v1/content/getSingleBotTag?app=${chatApp}`)).data;

export const getTag = async (tagName) =>
  (await get(`/v1/content/getSingleTag?context=${tagName}`)).data;

export const getTags = async (chatApp, filter) => {
  const queryFilter = filter ? `?filter=${filter}` : '';
  return (await get(`/v1/content/tags/${chatApp}${queryFilter}`)).data;
};

export const deleteContent = async (content) => {
  const response = (await del('/v1/content/deleteContent', { data: content }))
    .data;
  return response;
};
export const updateContent = async (content) => {
  const response = await put('/v1/content/updateContent', { content });
  return response;
};
// End Content
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// Bot Config
// ----------------------------------------------------------------
export const getBotConfig = async (key) => {
  const botConfig = (await get(`/v1/app-config/${key}`)).data;
  return botConfig;
};
// End Bot Config
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// Admin
// ----------------------------------------------------------------
export const getUsers = async () => (await get('/v1/administrate/users')).data;

export const getUser = async (userName) =>
  (await get(`/v1/administrate/user/${userName}`, { credentials: 'include' }))
    .data;

export const makeUser = async (userName) =>
  (await post('/v1/administrate/new-user', { userName })).data;

export const setApp = async (userName, appName, options) =>
  (await post('/v1/administrate/set-app', { userName, appName, options })).data;

export const deleteApp = async (userName, appName) =>
  (await post('/v1/administrate/delete-app', { userName, appName })).data;

export const deleteUser = async (userName) =>
  (await post('/v1/administrate/delete-user', { userName })).data;

export const setPassword = async (userName, password) =>
  (await post('/v1/administrate/set-password', { userName, password })).data;
// End Admin
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// Schedule
// ----------------------------------------------------------------
export const getJobs = async (group, app) => {
  const optional = app ? `?app=${app}` : '';
  return (await get(`/v1/schedule/jobs/${group}/${optional}`)).data;
};

export const scheduleBroadcast = async (meta, schedule, postBody) =>
  (await post('/v1/schedule/scheduleBroadcast', { meta, schedule, postBody }))
    .data;

export const updateBroadcast = async (id, meta, schedule, postBody) =>
  (
    await put(`/v1/schedule/updateBroadcast/${id}`, {
      meta,
      schedule,
      postBody,
    })
  ).data;

export const setJob = async (job) =>
  (await post('/v1/schedule/job', { job })).data;

export const runJob = async (id) => (await get(`/v1/schedule/run/${id}`)).data;

export const updateJob = async (job, id) =>
  (await put(`/v1/schedule/job/${id}`, { job })).data;

export const deleteJob = async (id) =>
  (await del(`/v1/schedule/job/${id}`)).data;

export const getJob = async (id) => {
  return (await get(`/v1/schedule/job/${id}`)).data.data;
};

// End Schedule
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// Configs
// ----------------------------------------------------------------
export const proxyGet = async (url) => (await get(`/v1/proxy?url=${url}`)).data;

export const getAppConfig = async (app) =>
  (await get(`/v1/app-config/${app}`)).data;

export const setAppConfig = async (app, config) =>
  (await post(`/v1/app-config/${app}`, config)).data;

export const setMyPassword = async (password, oldPassword) =>
  (await post('/v1/set-my-password', { password, oldPassword })).data;
// End Config
// ----------------------------------------------------------------
