<template lang="html">
  <div class="list-headers full-width">
    <div class="list-header list-header--answer">RESPONSE</div>
    <div
      @click="handleHeaderClick('name')"
      class="list-header list-header--tag list-header--sortable"
    >
      DIALOGUE
      <icon
        v-if="orderBy.includes('name')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'name.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
    <div
      @click="handleHeaderClick('updatedAt')"
      class="list-header list-header--edited list-header--sortable"
    >
      LAST EDITED
      <icon
        v-if="orderBy.includes('updatedAt')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'updatedAt.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
    <div
      @click="handleHeaderClick('createdAt')"
      class="list-header list-header--created list-header--sortable"
    >
      CREATED
      <icon
        v-if="orderBy.includes('createdAt')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'createdAt.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
  </div>
</template>

<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';
import { mapMutations, mapState } from 'vuex';
import { ORDER_BY_VALUES } from '../../../constants/response-manager';

export default {
  components: {
    Icon,
  },
  computed: {
    ...mapState('responseManager', ['orderBy']),
    ...mapState('responseManagerList', ['searchResults']),
  },
  methods: {
    ...mapMutations('responseManager', ['setOrderBy']),

    handleHeaderClick(updateOrderBy) {
      const prevOrderBy = this.orderBy;
      switch (updateOrderBy) {
        case 'updatedAt':
          if (ORDER_BY_VALUES.UPDATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_ASC);
          }
          break;
        case 'createdAt':
          if (ORDER_BY_VALUES.CREATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_ASC);
          }
          break;
        case 'name':
          if (ORDER_BY_VALUES.NAME_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.NAME_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.NAME_ASC);
          }
          break;
        default:
          // eslint-disable-next-line
          console.log('invalid column order value');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../components/list-headers/list-headers.scss';

.list-headers {
  padding: 0 16px 0;
}
.list-header--tags {
  position: relative;
}
.list-headers .list-header--created {
  position: relative;
  right: 18px;
}

.list-header {
  &--questions,
  &--answer {
    width: 32%;
  }
  &--created,
  &--tag,
  &--edited {
    width: 12%;
  }
}
</style>
