export const MODES_META_DATA = {
  text: {
    fieldSet: [{ name: 'text' }],
    oneItemPerRow: true,
  },
  media: {
    fieldSet: [
      { name: 'type' },
      { name: 'title' },
      { name: 'link' },
      { name: 'thumbnail' },
    ],
    oneItemPerRow: true,
  },
  links: {
    fieldSet: [
      { name: 'title' },
      { name: 'thumbnail' },
      { name: 'link' },
      { name: 'readMoreButtonText' },
    ],
    limitOnePerMessage: true,
  },
  buttons: {
    fieldSet: [{ name: 'title' }, { name: 'returnValue' }],
    limitOnePerMessage: true,
  },
};

export const PREVIEW_MODES = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const PREVIEW_CONFIG_DEFAULT = {
  links: {
    mode: PREVIEW_MODES.HORIZONTAL,
  },
  buttons: {
    mode: PREVIEW_MODES.HORIZONTAL,
  },
};
