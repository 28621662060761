<template lang="html">
  <div class="list-headers full-width">
    <div
      @click="handleHeaderClick('title')"
      class="list-header list-header--name list-header--sortable"
    >
      NAME
      <icon
        v-if="orderBy.includes('title')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'title.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
    <div class="list-header list-header--tags">TAG</div>
    <div
      @click="handleHeaderClick('updatedAt')"
      class="list-header list-header--edited list-header--sortable"
    >
      LAST EDITED
      <icon
        v-if="orderBy.includes('updatedAt')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'updatedAt.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
    <div
      @click="handleHeaderClick('createdAt')"
      class="list-header list-header--created list-header--sortable"
    >
      CREATED
      <icon
        v-if="orderBy.includes('createdAt')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'createdAt.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
  </div>
</template>

<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';
import { mapMutations, mapState } from 'vuex';

import { ORDER_BY_VALUES } from '../../../state/content/constants';
export default {
  components: {
    Icon,
  },
  computed: {
    ...mapState('content', ['orderBy']),
  },
  methods: {
    ...mapMutations('content', ['setOrderBy']),
    handleHeaderClick(updateOrderBy) {
      const prevOrderBy = this.orderBy;
      switch (updateOrderBy) {
        case 'updatedAt':
          if (ORDER_BY_VALUES.UPDATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_ASC);
          }
          break;
        case 'createdAt':
          if (ORDER_BY_VALUES.CREATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_ASC);
          }
          break;
        case 'title':
          if (ORDER_BY_VALUES.DISPLAY_TEXT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.DISPLAY_TEXT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.DISPLAY_TEXT_ASC);
          }
          break;
        default:
          // eslint-disable-next-line
          console.log('invalid column order value');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../components/list-headers/list-headers.scss';

.list-header--tags {
  left: -40px;
  position: relative;
}
.list-header--edited {
  position: relative;
  left: -54px;
}
.list-header--created {
  position: relative;
  left: -64px;
}
.list-header--name {
  padding-left: 56px;
  position: relative;
}

// match column width to row content width
.content-title,
.list-header--name {
  width: 50%;
}
.content-tags,
.list-header--tags {
  width: 12%;
}
.content-created,
.content-edited,
.list-header--created,
.list-header--edited {
  width: 12%;
  color: $black-high-emphasis;
}
</style>
