<!-- eslint-disable max-lines -->
<template>
  <div class="full-width full-height content-page">
    <confirm-modal :show="modal === 'CONFIRM'" />
    <upload-size-modal :show="modal === 'UPLOAD_SIZE'" />
    <error-modal :show="modal === 'ERROR'" />
    <div class="full-width full-height content">
      <page-header header="Content Service">
        <btn class="round create" @click="handleNewContent()" icon="add" raised>
          Add to Tag
        </btn>
      </page-header>
      <list-filters />
      <list-view
        :items="listItems"
        :load-more-data="loadMoreData"
        :waiting-for-data="waitingForData"
      >
      </list-view>
      <side-sheet
        class="side-sheet--content"
        v-if="!!initialContent || editingContent"
        :onClose="() => handleCloseSideSheet()"
      >
        <content-create v-if="editingContent" />
        <content-read
          v-if="!editingContent && initialContent"
          :info="initialContent"
        />
        <message-preview
          :buttons="buttons"
          :buttonsHorizontal="buttonsHorizontal"
          :content="content"
          :firstLinkIndex="firstLinkIndex"
          :links="links"
          :linksHorizontal="linksHorizontal"
          :toggleButtonsPreview="toggleButtonsPreview"
          :toggleLinksPreview="toggleLinksPreview"
        />
      </side-sheet>
    </div>
  </div>
</template>

<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import ListFilters from './list-view/list-filters/list-filters.vue';
import ListView from './list-view/list-view.vue';
import PageHeader from '../../components/page-header/page-header.vue';
import SideSheet from '../../components/side-sheet/side-sheet.vue';

// Modals
import ConfirmModal from '../../components/modal/confirm-modal.vue';
import ContentCreate from './content-side-sheet/create/content-create/content-create.vue';
import ContentRead from './content-side-sheet/read/content-read/content-read.vue';
import ErrorModal from '../../components/modal/error-modal.vue';
import MessagePreview from '../../components/message-preview/message-preview.vue';
import UploadSizeModal from '../../components/modal/library/upload-size-modal.vue';

import { getContent, getContentItemTags } from '../../api';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { MAX_TITLE_LEN } from './constants';
import { DEFAULT_ITEMS_PER_PAGE } from '../../state/content/content-list/constants';
// import { FEATURE_TYPES } from '../../state/uploader/constants'
import { capitalize } from '../../helpers/conversions/grammar';
import {
  epochToListView,
  secondsToDuration,
} from '../../helpers/conversions/time/time';
import { dedupeArr } from '../../helpers/array/array';
import { bytesViewable } from '../../helpers/conversions/meta-data/meta-data';
import {
  getContentDescriptor,
  getThumbnailType,
} from '../../state/content/helpers/helpers';

export default {
  components: {
    Btn,
    ContentCreate,
    ContentRead,
    ListFilters,
    ListView,
    PageHeader,
    MessagePreview,
    SideSheet,

    // Modals
    ConfirmModal,
    ErrorModal,
    UploadSizeModal,
  },
  computed: {
    ...mapGetters('content', ['tagOptions']),
    ...mapState('content', [
      'botTagOptions',
      'contentBotsMeta',
      'listItems',
      'orderBy',
      'selectedTag',
      'showAssetModal',
    ]),
    ...mapGetters('contentForm', [
      'buttons',
      'buttonsHorizontal',
      'linksHorizontal',
      'firstLinkIndex',
      'lastLinkIndex',
      'links',
    ]),
    ...mapState('contentForm', [
      'editingContent',
      'content',
      'buttons',
      'initialContent',
    ]),
    ...mapState('contentList', ['itemsPerLoad', 'endOfList', 'lastRefresh']),
    ...mapState('modal', ['modal']),
    ...mapState('uploader', [
      'continueUpload',
      'itemLink',
      'itemThumbnail',
      'itemType',
    ]),
    ...mapGetters('uploader', ['uploaderHasError', 'uploading']),
    ...mapState('user', ['loggedIn']),
  },
  data() {
    return {
      gettingMetaData: false,
      metaData: null,
      tag: [],
      fileName: '',
      contentOffset: 0,
      previewLoaded: false,
      uploader: {
        done: false,
        error: false,
      },
      waitingForData: true,
    };
  },
  methods: {
    ...mapActions('contentForm', ['handleCloseSideSheet']),
    ...mapMutations('contentList', ['declareEndOfList', 'resetListState']),
    ...mapMutations('contentForm', [
      'setInitialContent',
      'setEditing',
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapMutations('uploader', ['clearStates']),
    ...mapMutations('modal', ['resetModalStates', 'showModal']),
    ...mapMutations('content', [
      'setListItems',
      'setShowAssetModal',
      'setSelectedTag',
    ]),
    ...mapMutations('snackbar', ['openSnack']),

    // Content form methods
    handleNewContent() {
      this.setEditing(true);
      this.setInitialContent(null);
    },

    // List view methods
    async concatItemData(contentItem) {
      const tags = await getContentItemTags(contentItem.id);
      const finalItem = {
        ...contentItem,
        originalContent: { ...contentItem },
        createdAtViewable: epochToListView(contentItem.createdAt).split(',')[0],
        updatedAtViewable: epochToListView(contentItem.updatedAt).split(',')[0],
        tags,
        thumbnailType: getThumbnailType(contentItem),
        contentDescriptor: getContentDescriptor(contentItem),
      };
      const title = contentItem.body.title || contentItem.title || '';
      if (title && MAX_TITLE_LEN < title.length) {
        finalItem.titleTrimmed = title.slice(0, MAX_TITLE_LEN).concat('...');
      } else {
        finalItem.titleTrimmed = title;
      }
      if (contentItem.body.mimeType) {
        const splitMime = contentItem.body.mimeType.split('/');
        finalItem.fileType = splitMime[splitMime.length - 1];
        finalItem.mimeTypeViewable = `${capitalize(
          splitMime[0]
        )}/${splitMime[1].toUpperCase()}`;
      }
      if (contentItem.body.size)
        finalItem.sizeViewable = bytesViewable(contentItem.body.size);
      if (contentItem.body.duration)
        finalItem.durationViewable = secondsToDuration(
          contentItem.body.duration
        );
      return finalItem;
    },
    getBotOffset(botName) {
      return this.listItems.filter((contentData) => {
        return contentData.bot === botName;
      }).length;
    },
    async loadMoreData() {
      if (this.waitingForData) return;
      await this.waitForData();
    },
    async getListViewData(reset = false) {
      if (!this.loggedIn) {
        return [];
      }
      let content = [];
      const botOffset = reset ? 0 : this.listItems.length;
      const tag = this.selectedTag
        ? this.selectedTag
        : this.tagOptions[0].value;
      content = await getContent(
        tag,
        DEFAULT_ITEMS_PER_PAGE + 1,
        true,
        botOffset,
        this.orderBy
      );
      if (!content.length) {
        this.declareEndOfList();
        return;
      }
      const contentWithFullData = await Promise.all(
        content.map((contentItem) => this.concatItemData(contentItem))
      );
      const updatedContent = reset
        ? contentWithFullData
        : [...this.listItems, ...contentWithFullData];
      const totalItems = dedupeArr(updatedContent, 'id');
      this.setListItems(totalItems);
    },
    async resetAll() {
      this.resetListState();
      this.metaData = null;
      this.resetModalStates();
      this.clearStates();
      this.setListItems([]);
      this.setDefaultTagOption();
    },
    async waitForData(reset = false) {
      if (reset) {
        this.resetListState();
      }
      if (this.contentBotsMeta && this.contentBotsMeta.length) {
        this.waitingForData = true;
        await this.getListViewData(reset);
        this.waitingForData = false;
      } else if (!this.contentBotsMeta) {
        this.waitingForData = false;
      }
    },
    setDefaultTagOption() {
      try {
        // eslint-disable-next-line
        console.log('tag options available, setting default tag');
        this.setSelectedTag(this.tagOptions[0].value);
      } catch (e) {
        // eslint-disable-next-line
        console.log('no tag options available');
      }
    },
  },
  watch: {
    async listItems() {
      if (DEFAULT_ITEMS_PER_PAGE > this.listItems.length && !this.endOfList) {
        await this.waitForData();
      }
    },
    lastRefresh() {
      this.resetAll();
    },
    async contentBotsMeta() {
      if (this.loggedIn) {
        await this.waitForData(true);
      }
    },
    async orderBy() {
      await this.waitForData(true);
    },
    async selectedTag() {
      this.resetListState();
      await this.waitForData(true);
    },
    async tagOptions() {
      if (this.tagOptions.length && !this.selectedTag) {
        this.setSelectedTag(this.tagOptions[0].value);
      }
    },
  },
  activated() {
    this.resetAll();
    this.waitForData(true);
  },
};
</script>
<style scoped lang="scss">
@import './content.scss';
</style>
