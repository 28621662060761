<template>
  <section class="toggle-layout">
    <section
      v-show="buttonsPresent"
      class="toggle-clickable toggle-button"
      @click="toggleButtonsPreview"
    >
      View buttons as
      <icon
        class="icon"
        :class="{ 'icon--vertical': !this.buttonsHorizontal }"
        :icon="buttonsIcon"
      />
    </section>
    <section
      v-show="linksPresent"
      class="toggle-clickable toggle-links"
      @click="toggleLinksPreview"
    >
      View attachments as
      <icon
        :class="{ 'icon--vertical': !this.linksHorizontal }"
        class="icon"
        :icon="linksIcon"
      />
    </section>
  </section>
</template>
<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    buttonsHorizontal: {
      required: true,
      type: Boolean,
    },
    buttonsPresent: {
      required: true,
      type: Boolean,
    },
    linksHorizontal: {
      required: true,
      type: Boolean,
    },
    linksPresent: {
      required: true,
      type: Boolean,
    },
    toggleButtonsPreview: {
      required: true,
      type: Function,
    },
    toggleLinksPreview: {
      required: true,
      type: Function,
    },
  },
  computed: {
    linksIcon() {
      return this.linksHorizontal ? 'view_week' : 'view_stream';
    },
    buttonsIcon() {
      return this.buttonsHorizontal ? 'view_week' : 'view_stream';
    },
  },
};
</script>
<style scoped lang="scss">
.toggle-layout {
  width: 436px;
  height: 40px;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.38);
  background-color: #fafafa;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  padding: 9px 11px;
}

.toggle-clickable {
  margin-left: 32px;
  cursor: pointer;
}
.text-center {
  line-height: 24px;
  height: 24px;
}
.icon {
  font-size: 14px;
  vertical-align: middle;
  font-size: 24px;
  margin-left: 6px;
  &--vertical {
    position: relative;
    top: -1px;
  }
}
</style>
