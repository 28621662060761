<template>
  <section class="info-section">
    <slot />
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.info-section {
  height: 100vh;
  width: 50%;
  background: white;
  padding: 72px;
  position: relative;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
}
</style>
