var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-list",
    { staticClass: "full-width" },
    [
      _c(
        "m-list-item",
        {
          staticClass: "full-width option",
          attrs: { disabled: !_vm.activeStop },
          on: { click: _vm.handleStop }
        },
        [_vm._v("\n    Stop\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }