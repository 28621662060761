<template>
  <div class="page-header full-width">
    <div class="header-bar">
      <div>
        <h1>{{ header }}</h1>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: ['header'],
};
</script>

<style scoped lang="scss">
@import 'gameon-components/src/universal/vars';
.page-header {
  justify-content: space-between;
  margin-bottom: 20px;
  height: 60px;
  margin: 0 80px 20px 16px;
}
.header-bar {
  height: 48px;
  display: inline-flex;
  align-items: center;
  position: relative;

  h1 {
    margin: 0;
    padding: 0;
    padding: 16px 0;
  }
}
</style>
