var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-filters full-width" },
    [
      _c("icon", { staticClass: "search-icon", attrs: { icon: "search" } }),
      _vm._v(" "),
      _c("single-select", {
        staticClass: "filter-by list-filter",
        attrs: {
          "handle-choice": _vm.setSelectedTag,
          options: _vm.tagOptions,
          "upstream-val": _vm.selectedTag
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }