import { BROADCAST_SOURCES } from '../../../constants/broadcasts';
import { contentToCampaignPreview } from '../../../helpers/content/content';

// This function will be deprecated once 'content' field on broadcasts are standardized
// Returns array structure of broadcast content
// If content is not previewing, it is likely the bots are not archiving content in a consistent format
// When bots respect platform format for archiving message content, this helper should no longer be necessary
const getContentFromBroadcast = (broadcast) => {
  const content = broadcast.content;

  // detect nested object, content: {content: []}
  if (content && content.content) {
    // eslint-disable-next-line
    console.log(
      'IMPROPER DATA. Detected double nested content of type content: {"content": {"content": [{content}, {content}]}.'
    );
    // eslint-disable-next-line
    console.log('Here is the offending broadcast data: ', broadcast);

    return broadcast.content.content;

    // detect non-nested object content: {}
  } else if (
    content.attachments ||
    content.choices ||
    content.media ||
    content.text
  ) {
    // eslint-disable-next-line
    console.log(
      'IMPROPER DATA. Detected single content object without array: {content: {content}}.'
    );
    // eslint-disable-next-line
    console.log('Here is the offending broadcast data: ', broadcast);

    // need to turn into Array type content
    const contentArr = [];
    if (content.text) {
      contentArr.push({ text: content.text });
    }
    if (content.attachments) {
      contentArr.push({ attachments: content.attachments });
    }
    if (content.media) {
      contentArr.push({ media: content.media });
    }
    if (content.choices) {
      contentArr.push({ choices: content.choices });
    }
    return contentArr;
  } else if (content.message) {
    // eslint-disable-next-line
    console.log(
      'IMPROPER DATA. Detected content in message field. This is not supported and will cause errors.'
    );
    // eslint-disable-next-line
    console.log('Here is the offending broadcast data: ', broadcast);
  } else if (content && Array.isArray(content)) {
    // eslint-disable-next-line
    console.log('Proper content assumed. See broadcast data here:', broadcast);
    return broadcast.content;
  } else {
    // eslint-disable-next-line
    console.log(
      'IMPROPER DATA. Portal does not recognize this content format and will have errors.'
    );
    // eslint-disable-next-line
    console.log('Here is the offending broadcast data: ', broadcast);
  }
};

export const getGroupMessages = (broadcasts) => {
  const messages = broadcasts.map((broadcast) => {
    const contentData = getContentFromBroadcast(broadcast);
    const content = contentToCampaignPreview(contentData);
    const previewData = {
      ...broadcast,
      source: BROADCAST_SOURCES.BROADCAST,
      content,
      speakerId: 'bot',
      left: false,
    };
    return previewData;
  });
  return messages;
};

export const getBroadcastIds = (broadcasts) => {
  return broadcasts.map((b) => b.id);
};
