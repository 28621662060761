<template>
  <div class="full-height full-width scheduler">
    <div class="content">
      <page-header header="Schedule" />
      <schedule-viewer />
    </div>
    <side-sheet
      class="side-sheet--schedule content"
      v-if="!!selected"
      :onClose="closeSideSheet"
    >
      <schedule-editor :previousData="selected" :dateFormat="dateFormat" />
    </side-sheet>
  </div>
</template>

<style scoped>
@import '/../../styles/form.scss';
@import './schedule.scss';
</style>

<script>
import PageHeader from '../../components/page-header/page-header.vue';
import ScheduleEditor from './schedule-editor/schedule-editor.vue';
import ScheduleViewer from './schedule-viewer/schedule-viewer.vue';
import SideSheet from '../../components/side-sheet/side-sheet.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  components: { PageHeader, ScheduleEditor, ScheduleViewer, SideSheet },
  computed: {
    ...mapState('schedule', ['editing', 'selected', 'dateFormat']),
  },
  methods: {
    ...mapMutations('schedule', ['setEditing', 'closeSideSheet']),
  },
};
</script>
