const BYTE_CONVERSION = 1024;
const BYTES_PER_KB = BYTE_CONVERSION;
const KB_PER_MB = BYTE_CONVERSION;
const MB_PER_GB = BYTE_CONVERSION;

// File restraints
// All file size calculations are binary-based
const secondsPerMin = 60;
const warnVideoSizeMB = 50;
const WARN_VIDEO_SIZE_BYTES = warnVideoSizeMB * BYTES_PER_KB * KB_PER_MB;
const maxVideoSizeMB = 200;
const MAX_VIDEO_SIZE_BYTES_BROADCAST =
  maxVideoSizeMB * KB_PER_MB * BYTES_PER_KB;
const MAX_VIDEO_SIZE_BYTES_LIBRARY = MB_PER_GB * KB_PER_MB * BYTES_PER_KB;
const MAX_VIDEO_DURATION = 360;
const warnImageSizeMB = 10;
const WARN_IMAGE_SIZE_BYTES = warnImageSizeMB * KB_PER_MB * BYTES_PER_KB;
const maxImageSizeMB = 50;
const MAX_IMAGE_SIZE_BYTES = maxImageSizeMB * KB_PER_MB * BYTES_PER_KB;
const MAX_MIN = Math.floor(MAX_VIDEO_DURATION / secondsPerMin);
export const FEATURE_TYPES = {
  BROADCAST: 'BROADCAST',
  LIBRARY: 'LIBRARY',
};

export const FILE_LIMITATIONS = {
  MAX_VIDEO_SIZE_BYTES: {
    BROADCAST: MAX_VIDEO_SIZE_BYTES_BROADCAST,
    LIBRARY: MAX_VIDEO_SIZE_BYTES_LIBRARY,
  },
  WARN_VIDEO_SIZE_BYTES: {
    BROADCAST: WARN_VIDEO_SIZE_BYTES,
    LIBRARY: WARN_VIDEO_SIZE_BYTES,
  },
  MAX_VIDEO_DURATION,
  MAX_IMAGE_SIZE_BYTES,
  WARN_IMAGE_SIZE_BYTES,
  MAX_MIN,
};

export const MAX_PERCENT = 100;

export const ORDERED_UPLOADER_STEPS = [
  'uploadCancelled',
  'uploadStarted',
  'savingToServer',
  'validating',
  'uploadValidated',
];

export const PROGRESS = {
  DONE: 100,
  STALL_COUNT: 500,
  CORRUPTION_THRESHOLD: 0,
  REFRESH_INTERVAL: 1000,
};

export const UPLOAD_ERRORS = {
  FULLSIZE: 'fullsize',
  NETWORK: 'network',
  OTHER: 'other',
  THUMBNAIL: 'thumbnail',
};
