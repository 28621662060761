var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-dialog", {
    attrs: {
      value: _vm.show,
      body: _vm.modalText,
      header: _vm.modalHeader || "Error",
      actionButton: _vm.actionButton || "Okay"
    },
    on: { closed: _vm.close }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }