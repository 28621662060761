var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-height full-width scheduler" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("page-header", { attrs: { header: "Schedule" } }),
          _vm._v(" "),
          _c("schedule-viewer")
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.selected
        ? _c(
            "side-sheet",
            {
              staticClass: "side-sheet--schedule content",
              attrs: { onClose: _vm.closeSideSheet }
            },
            [
              _c("schedule-editor", {
                attrs: {
                  previousData: _vm.selected,
                  dateFormat: _vm.dateFormat
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }