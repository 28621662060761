var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversation" },
    [
      _c("chat-render", {
        attrs: {
          participants: _vm.participants,
          inputSubmitFunction: _vm.inputSubmitFunction,
          getConversationHistoryFunction: _vm.getConversationHistory,
          onScrolledToTop: _vm.onScrolledToTop,
          settings: _vm.settings
        }
      }),
      _vm._v(" "),
      !_vm.conversationLoaded ? _c("indeterminate-circle") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }