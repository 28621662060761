import { render, staticRenderFns } from "./pagination.vue?vue&type=template&id=662b1cc1&scoped=true&"
import script from "./pagination.vue?vue&type=script&lang=js&"
export * from "./pagination.vue?vue&type=script&lang=js&"
import style0 from "./pagination.vue?vue&type=style&index=0&id=662b1cc1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662b1cc1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('662b1cc1')) {
      api.createRecord('662b1cc1', component.options)
    } else {
      api.reload('662b1cc1', component.options)
    }
    module.hot.accept("./pagination.vue?vue&type=template&id=662b1cc1&scoped=true&", function () {
      api.rerender('662b1cc1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dashboard/human-handoff/list-view/pagination/pagination.vue"
export default component.exports