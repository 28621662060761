<!-- eslint-disable max-lines -->
<template>
  <div
    :class="[
      { 'show-overlay': editingBroadcast },
      'full-width',
      'broadcast',
      'full-height',
      'overlay',
    ]"
  >
    <confirm-modal :show="modal === 'CONFIRM'" />
    <div class="content full-width">
      <page-header header="Broadcast">
        <btn
          class="round create"
          @click="handleNewBroadcast()"
          icon="add"
          raised
        >
          Broadcast
        </btn>
      </page-header>
      <list-filters />
      <list-view :waitingForData="waitingForData" :items="viewableBroadcasts">
      </list-view>
      <side-sheet
        class="side-sheet--broadcast"
        v-if="!!selectedBroadcast"
        :selectedBroadcast="selectedBroadcast"
        :onClose="() => setSelectedBroadcast(null)"
      >
        <info>
          <broadcast-read :originalBroadcast="selectedBroadcast" />
        </info>
        <preview
          :buttonsPresent="buttonsPresent"
          :buttonsHorizontal="buttonsHorizontal"
          :chatPreviewData="chatPreviewData"
          :linksPresent="linksPresent"
          :linksHorizontal="linksHorizontal"
          :toggleButtonsPreview="toggleButtonsPreview"
          :toggleLinksPreview="toggleLinksPreview"
          :participants="participants"
        />
      </side-sheet>
      <broadcast-create :show="editingBroadcast" />
    </div>
    <div class="page-loading" v-if="waitingForData">
      <indeterminate-circle />
    </div>
  </div>
</template>
<script>
import BroadcastCreate from './broadcast-side-sheet/create/broadcast-create.vue';
import BroadcastRead from './broadcast-side-sheet/read/broadcast-read.vue';
import Btn from 'gameon-components/src/components/btn/btn.vue';
import ConfirmModal from '../../components/modal/confirm-modal.vue';
import IndeterminateCircle from '../../components/indeterminate-circle/indeterminate-circle.vue';
import Info from '../../components/side-sheet/info/info.vue';
import ListFilters from './list-view/list-filters.vue';
import ListView from './list-view/list-view.vue';
import PageHeader from '../../components/page-header/page-header.vue';
import Preview from '../../components/preview/message-preview.vue';
import SideSheet from '../../components/side-sheet/side-sheet.vue';

import { concatCampaignData, concatScheduleData } from './helpers/helpers';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  getGroups,
  getBroadcastsScheduled,
  getTotalGroupCount,
  getTotalScheduledBroadcastCount,
} from '../../api/broadcast';
import { FILTER_BY_VALUES } from '../../constants/broadcasts';
export default {
  data() {
    return {
      viewableBroadcasts: [],
    };
  },
  components: {
    BroadcastCreate,
    BroadcastRead,
    Btn,
    ConfirmModal,
    IndeterminateCircle,
    Info,
    ListFilters,
    ListView,
    PageHeader,
    Preview,
    SideSheet,
  },
  computed: {
    ...mapState('user', ['botData']),
    ...mapGetters('broadcastForm', [
      'buttonsPresent',
      'buttonsHorizontal',
      'chatPreviewData',
      'linksHorizontal',
      'firstLinkIndex',
      'lastLinkIndex',
      'linksPresent',
      'participants',
    ]),
    ...mapState('broadcastForm', [
      'editingForm',
      'content',
      'buttons',
      'initialBroadcast',
    ]),
    ...mapState('broadcastPage', [
      'editingBroadcast',
      'filterBy',
      'filterByOptions',
      'lastRefresh',
      'orderBy',
      'selectedBroadcast',
      'waitingForData',
    ]),
    ...mapGetters('broadcastPage', ['selectedCampaignIds']),
    ...mapState('modal', ['modal']),
    ...mapState('broadcastList', [
      'broadcastsPerPage',
      'currentBroadcastListPage',
    ]),
  },
  methods: {
    ...mapMutations('broadcastForm', [
      'setInitialBroadcast',
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapMutations('broadcastPage', [
      'setEditing',
      'setSelectedBroadcast',
      'setWaitingForData',
    ]),
    ...mapActions('broadcastForm', ['getAudienceOptions', 'getCampaignsData']),
    ...mapMutations('broadcastList', [
      'resetBroadcastListState',
      'setTotalCount',
    ]),
    determineSkip(reset) {
      if (reset) {
        return 0;
      } else {
        return (this.currentBroadcastListPage - 1) * this.broadcastsPerPage;
      }
    },
    async gatherListView(reset = false, filterBy, orderBy) {
      if (reset) {
        this.resetBroadcastListState();
        this.setTotalListCount(filterBy, orderBy, this.selectedCampaignIds);
      }
      try {
        this.viewableBroadcasts = await this.addQualifyingBroadcastsToList(
          reset,
          filterBy,
          orderBy
        );
      } catch (e) {
        // eslint-disable-next-line
        console.log('e', e);
        this.viewableBroadcasts = [];
      }
    },
    async setTotalListCount(filterBy, orderBy, selectedCampaignIds) {
      const broadcastConfig = this.getBroadcastConfig(filterBy);
      const count = await broadcastConfig.getTotalCount([this.botData.key], {
        sort: orderBy,
        campaignIds: selectedCampaignIds,
        status: filterBy,
      });
      this.setTotalCount(count);
    },
    async addQualifyingBroadcastsToList(reset, filterBy, orderBy) {
      if (this.botData) {
        const broadcastConfig = this.getBroadcastConfig(filterBy);
        const incomingData = await broadcastConfig.getBroadcasts(
          [this.botData.key],
          {
            count: this.broadcastsPerPage,
            skip: this.determineSkip(reset),
            sort: orderBy,
            campaignIds: this.selectedCampaignIds,
            status: this.filterBy,
          }
        );
        return incomingData.map((b) => broadcastConfig.concatData(b));
      }
    },
    getBroadcastConfig(filterBy) {
      const sentFilter = FILTER_BY_VALUES.SENT === filterBy;
      const broadcastService = sentFilter;
      if (broadcastService) {
        return {
          getBroadcasts: getGroups,
          concatData: concatCampaignData,
          getTotalCount: getTotalGroupCount,
        };
      } else {
        return {
          getBroadcasts: getBroadcastsScheduled,
          concatData: concatScheduleData,
          getTotalCount: getTotalScheduledBroadcastCount,
        };
      }
    },
    handleNewBroadcast() {
      this.setInitialBroadcast(null);
      this.setEditing(true);
    },
    async waitForData(reset = false) {
      if (this.botData) {
        this.setWaitingForData(true);
        await this.gatherListView(reset, this.filterBy, this.orderBy);
        this.setWaitingForData(false);
      }
    },
  },
  watch: {
    async botData() {
      await this.getCampaignsData();
      await this.waitForData(true);
    },
    async lastRefresh() {
      await this.waitForData(true);
    },
    async orderBy() {
      await this.waitForData(true);
    },
    async filterBy() {
      await this.waitForData(true);
    },
    async selectedCampaignIds() {
      await this.waitForData(true);
    },
    async currentBroadcastListPage() {
      await this.waitForData();
    },
    async broadcastsPerPage() {
      await this.waitForData();
    },
  },
  async activated() {
    await this.getCampaignsData();
    await this.waitForData(true);
    await this.getAudienceOptions();
  },
  async mounted() {
    await this.getCampaignsData();
    await this.getAudienceOptions();
  },
};
</script>
<style scoped lang="scss">
@import './broadcast.scss';
@import '../../components/indeterminate-circle/page-loading.scss';
</style>
