var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-filters full-width" },
    [
      _c("search-filter", {
        attrs: {
          handleSearchInput: _vm.handleSearchInput,
          placeholder: "Search Questions And Phrasings"
        }
      }),
      _vm._v(" "),
      _vm.tagOptions
        ? _c("single-select", {
            staticClass: "filter-by list-filter",
            attrs: {
              "handle-choice": _vm.setSelectedTag,
              options: _vm.tagOptions,
              "upstream-val": _vm.selectedTag
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }