<template>
  <section class="menu-item">
    <div :class="{ active: activeHeader }" class="nav-icon">
      <icon v-if="iconSrc" class="menu-icon" :icon="iconSrc" />
      <slot />
    </div>
  </section>
</template>
<script>
import { icon } from 'gameon-components';

export default {
  components: { Icon: icon.default },
  props: ['iconSrc', 'title', 'onClick', 'activeHeader'],
};
</script>
<style lang="scss" scoped>
@import 'gameon-components/src/universal/vars';

.menu-item {
  position: relative;
}
.material-icons {
  color: $mdc-theme-white-disabled;
  fill: $mdc-theme-white-disabled;
  transition: color 0.3s ease, fill 0.3s ease;
}

.active .material-icons {
  color: white;
  fill: white;
}
.nav-icon {
  text-align: center;
  line-height: 0;
}
.active.nav-icon::before {
  background-color: #00a86f;
}
.nav-icon::before {
  content: '';
  position: absolute;
  top: -6px;
  left: -16px;
  height: 40px;
  width: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 0px 4px 4px 0px;
}
</style>
