<!-- eslint-disable max-lines -->
<template>
  <article
    v-if="show"
    class="broadcast-editor full-height"
    :class="{ 'broadcast-editor--scrolling': scrolling }"
  >
    <schedule-modal
      :final-broadcast-title="finalBroadcastTitle"
      :is-dialog-open="showSchedule"
      @close="handleCloseSchedule"
      :close-modal="() => (showSchedule = false)"
      :initial-broadcast="initialBroadcast"
      :send-now="sendNow"
    />
    <section class="top-menu padding-tiny-around">
      <icon-btn class="form-icon close" @click="handleClose">
        <icon icon="close" />
      </icon-btn>
      <section class="broadcast-title">
        <input
          v-model="broadcastTitle"
          @click="handleTitleClick"
          @blur="handleTitleBlur"
          :placeholder="titlePlaceholder"
          class="broadcast-title-input"
          type="text"
        />
      </section>
      <btn
        class="send-now-btn menu-btn"
        :disabled="!allowSend"
        @click="handleSendNowClick"
      >
        <icon icon="send" class="small" />
        <span class="send-now-text">Send Now</span>
      </btn>
      <btn
        class="schedule-btn menu-btn"
        :disabled="!allowSend"
        @click="schedule"
      >
        <icon icon="access_time" class="small" />
        <span class="schedule-text">Schedule</span>
      </btn>
    </section>
    <div class="creation-wrap">
      <div class="hide-top-shadow" />
      <section class="fixed-menu">
        <multi-select
          :display-values="defaultBotDisplay"
          :handle-primary-val="() => {}"
          :handle-secondary-val="setAudience"
          leading-icon="search"
          :options="audienceOptions"
          :primary-val="botData.key"
          :secondary-val="audience"
        />
        <section class="buttons">
          <div class="add-content">
            <fab
              :disabled="editingItem"
              class="purple-fab"
              icon="title"
              @click="addAndScroll('text')"
            />
            <div class="fab-desc">Text</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-primary"
              :disabled="editingItem"
              icon="insert_photo"
              @click="addAndScroll('media')"
            />
            <div class="fab-desc">Media</div>
          </div>
          <div class="add-content">
            <fab
              class="theme-secondary"
              :disabled="editingItem"
              icon="link"
              @click="addAndScroll('link')"
            />
            <div class="fab-desc">Link</div>
          </div>
          <div class="add-content">
            <fab
              class="orange-fab"
              :disabled="editingItem"
              icon="crop_square"
              @click="addAndScroll('buttons')"
            />
            <div class="fab-desc">Button</div>
          </div>
        </section>
      </section>
      <section @scroll="handleScroll" class="content-items">
        <section class="content-row-wrap">
          <message-form-field
            v-for="(item, index) in content"
            class="edit-row"
            :id="editingPosition === index ? 'scroll-target' : ''"
            :key="item.text"
            :content="content"
            :cancelItem="cancelItem"
            :deleteAllLinks="deleteAllLinks"
            :editingPosition="editingPosition"
            :finishEditingItem="finishEditingItem"
            :item="item"
            :deleteItem="deleteItem"
            :editingItem="editingItem"
            :links="links"
            :lastLinkIndex="lastLinkIndex"
            :firstLinkIndex="firstLinkIndex"
            :position="index"
            :startEditing="startEditing"
            :saveItem="saveItem"
            :swapItems="swapItems"
            :moveLinksUp="moveLinksUp"
            :moveLinksDown="moveLinksDown"
          />
        </section>
        <section class="edit-row">
          <quick-reply-form-field
            v-if="editingButtons || buttons.length"
            :buttons="buttons"
            :id="editingButtons ? 'scroll-target' : ''"
            :editingButtons="editingButtons"
            :editingItem="editingItem"
            :deleteButtons="deleteButtons"
            :setButtons="setButtons"
            :setEditingButtons="setEditingButtons"
          />
        </section>
      </section>
    </div>
    <preview
      :buttonsPresent="buttonsPresent"
      :buttonsHorizontal="buttonsHorizontal"
      :chatPreviewData="chatPreviewData"
      :linksPresent="linksPresent"
      :linksHorizontal="linksHorizontal"
      :toggleButtonsPreview="toggleButtonsPreview"
      :toggleLinksPreview="toggleLinksPreview"
      :participants="participants"
    />
  </article>
</template>
<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import IconBtn from 'gameon-components/src/components/icon-btn/icon-btn.vue';
import Fab from 'gameon-components/src/components/fab/fab.vue';
import MultiSelect from 'gameon-components/src/components/multi-select/multi-select.vue';
import MessageFormField from '../../../../components/message-form-field/message-form-field.vue';
import QuickReplyFormField from '../../../../components/message-form-field/quick-reply-form-field/quick-reply-form-field.vue';
import Preview from '../../../../components/preview/message-preview.vue';
import ScheduleModal from '../../../../components/modal/schedule-modal/schedule-modal.vue';

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { sendBroadcast } from '../../../../api/broadcast';
import { SNACK_TYPES } from '../../../../constants/snackbar';
import { FILTER_BY_VALUES } from '../../../../constants/broadcasts';

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);
const animationTime = 800;

export default {
  data() {
    const defaultTitle = 'Enter a name for this broadcast';
    return {
      broadcastTitle: '',
      defaultTitle,
      titlePlaceholder: defaultTitle,
      scrolling: false,
      showSchedule: false,
      submitting: false,
      successText: 'Content successfully added to broadcasting queue',
    };
  },
  components: {
    Btn,
    Fab,
    Icon,
    IconBtn,
    MultiSelect,
    MessageFormField,
    Preview,
    QuickReplyFormField,
    ScheduleModal,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapState('user', ['botData']),
    ...mapGetters('broadcastForm', [
      'buttonsHorizontal',
      'buttonsPresent',
      'chatPreviewData',
      'editingItem',
      'linksHorizontal',
      'linksPresent',
      'firstLinkIndex',
      'lastLinkIndex',
      'links',
      'participants',
      'sendable',
    ]),
    ...mapState('broadcastForm', [
      'audience',
      'audienceOptions',
      'buttons',
      'content',
      'editingPosition',
      'initialBroadcast',
      'editingButtons',
      'campaign',
      'group',
    ]),
    allowSend() {
      return (
        !this.editingItem &&
        !!this.content.length &&
        !this.submitting &&
        !!this.audience &&
        !!this.broadcastTitle
      );
    },
    defaultBotDisplay() {
      const result = {};
      result[this.botData.key] = this.botData.display.name;
      return result;
    },
    finalBroadcastTitle() {
      const untitled = 'Untitled Broadcast';
      return this.broadcastTitle ? this.broadcastTitle : untitled;
    },
  },
  methods: {
    ...mapMutations('modal', ['showModal']),
    ...mapActions('broadcastForm', [
      'addItem',
      'cancelItem',
      'clear',
      'createGroup',
      'deleteItem',
      'editButtons',
      'getManualCampaign',
      'moveLinksDown',
      'moveLinksUp',
      'saveItem',
      'selectTag',
      'submit',
      'swapItems',
    ]),
    ...mapMutations('broadcastPage', [
      'setEditing',
      'setFilterBy',
      'setSelectedBroadcast',
      'triggerListRefresh',
    ]),
    ...mapMutations('broadcastForm', [
      'deleteAllLinks',
      'deleteButtons',
      'finishEditingItem',
      'resetForm',
      'startEditing',
      'setAudience',
      'setButtons',
      'setEditingButtons',
      'toggleButtonsPreview',
      'toggleLinksPreview',
    ]),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('uploader', ['clearStates']),
    addAndScroll(mode) {
      switch (mode) {
        case 'buttons':
          this.editButtons();
          break;
        default:
          this.addItem(mode);
          break;
      }
      this.$nextTick(() => {
        this.scrollTo();
      });
    },
    clearForm() {
      this.clear();
      this.showSchedule = false;
      this.setAudience('');
      this.broadcastTitle = '';
      this.setEditing(false);
      this.resetForm();
    },
    handleClose() {
      this.clearForm();
    },
    handleCloseSchedule(action) {
      switch (action) {
        case 'SAVE':
          this.clearForm();
          break;
        default:
          break;
      }
      this.setSelectedBroadcast(null);
      this.showSchedule = false;
    },
    handleScroll(e) {
      this.scrolling = !!e.target.scrollTop;
    },
    handleTitleBlur() {
      if (!this.broadcastTitle) this.titlePlaceholder = this.defaultTitle;
    },
    handleTitleClick() {
      if (!this.broadcastTitle && this.titlePlaceholder)
        this.titlePlaceholder = '';
    },
    scrollTo() {
      VueScrollTo.scrollTo('#scroll-target', animationTime, {
        container: '.content-items',
      });
    },
    async sendNow() {
      this.submitting = true;
      try {
        await this.createGroup(this.finalBroadcastTitle);
        const postData = {
          schema: 'compound-multipart-content',
          content: this.sendable,
          botName: this.botData.key,
          subscription: this.audience,
          displayText: this.finalBroadcastTitle,
          campaignId: this.campaign.id,
          groupId: this.group.id,
        };
        await sendBroadcast(postData);
        this.clearForm();
        const delay = 2000;
        setTimeout(() => {
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: this.successText });
          this.setFilterBy(FILTER_BY_VALUES.SENT);
        }, delay);
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      } finally {
        this.submitting = false;
      }
    },
    schedule() {
      this.showSchedule = true;
    },
    handleSendNowClick() {
      if (!this.allowSend) return;

      // eslint-disable-next-line max-len
      const text = `You are about to send this broadcast to ${this.audience}. Once sent this cannot be undone. Are you sure you want to send?`;
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        modalHeader: 'Send Broadcast Now',
        actionButton: 'Send',
        cancelButton: 'Cancel',
        pendingAction: this.sendNow,
      });
    },
  },
  async mounted() {
    this.getManualCampaign();
  },
  watch: {
    show() {
      if (this.show) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.className = document.body.className.replace(
          'no-scroll',
          ''
        );
      }
    },
    initialBroadcast() {
      this.broadcastTitle = this.initialBroadcast
        ? this.initialBroadcast.title
        : '';
    },
  },
  deactivated() {
    this.clearStates();
  },
};
</script>
<style lang="scss" scoped>
@import './broadcast-create.scss';
</style>
