<template>
  <article class="media-preview">
    <div
      v-if="'video' === assetType"
      class="video media-content"
      :style="{ 'background-image': `url(${src})` }"
      @click="openLink"
    >
      <icon class="play" icon="play_circle_filled" />
    </div>
    <div
      v-else
      class="image media-content"
      :style="{ 'background-image': `url(${src})` }"
      @click="openLink"
    />
  </article>
</template>
<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    link: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    thumbnail: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    assetType() {
      return this.type.split('/')[0];
    },
    src() {
      return this.thumbnail ? this.thumbnail : this.link;
    },
  },
  methods: {
    openLink() {
      const newTab = window.open(this.link, '_blank');
      newTab.focus();
    },
  },
};
</script>
<style lang="scss" scoped>
.media-content {
  height: 156px;
  width: 232px;
  border-radius: $border-radius-chat-render;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.image,
.video {
  cursor: pointer;
}
.video {
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover .play {
    opacity: 0.8;
  }
}
.play {
  color: #dadada;
  opacity: 0.6;
  font-size: 48px;
  transition: opacity 1s ease;
}
</style>
