<template>
  <div class="more-menu" :class="{ 'more-menu--side-sheet': button }">
    <icon-btn
      v-if="button"
      @click.stop="handleClick"
      class="more-icon"
      icon="more_vert"
    />
    <icon
      v-else
      @click.stop="handleClick"
      class="more-icon more-icon--no-background"
      icon="more_vert"
    />
    <m-menu-anchor :anchor-corner="'TOP_RIGHT'">
      <m-menu v-model="showMoreMenu" :default-focus-state="'0'">
        <slot />
      </m-menu>
    </m-menu-anchor>
  </div>
</template>

<script>
import IconBtn from 'gameon-components/src/components/icon-btn/icon-btn.vue';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import Vue from 'vue';
import Menu from 'material-components-vue/dist/menu';

import { mapMutations, mapState } from 'vuex';

Vue.use(Menu);

export default {
  data() {
    return {
      showMoreMenu: false,
    };
  },
  components: {
    Icon,
    IconBtn,
  },
  props: {
    button: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapState('menu', ['lastMenuClick']),
  },
  methods: {
    ...mapMutations('menu', ['triggerCloseAllMenus']),
    handleClick() {
      const preClickState = this.showMoreMenu;

      // close any other open show-more menu
      this.triggerCloseAllMenus(new Date());

      // toggle this menu based on pre-click state
      this.$nextTick(() => {
        this.showMoreMenu = !preClickState;
      });
    },
  },
  watch: {
    lastMenuClick() {
      this.showMoreMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'material-components-vue/dist/menu/styles';

.more-menu {
  cursor: pointer;
  z-index: 11;
}
.more-icon {
  margin: 4px;
  position: absolute;
  top: 0;
  right: 0;
  color: $black-medium-emphasis;
}

.more-menu--side-sheet .mdc-menu-surface--anchor {
  position: fixed;
  top: 40px;
  right: 168px;
  z-index: 2;
}

.more-menu--list-item .mdc-menu-surface--anchor {
  position: absolute;
  top: 22px;
  right: 0px;
}

.more-menu--list-item .more-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 52px;
  right: -24px;
  top: -10px;
}

.more-menu .mdc-menu {
  min-width: 154px;
}

/deep/
  :not(.mdc-list--non-interactive)
  > .mdc-list-item--disabled[data-v-44a5b96e]::before,
:not(.mdc-list--non-interactive)
  > .mdc-list-item--disabled[data-v-44a5b96e]::after {
  background-color: transparent;
}
</style>
