const inspectlet = () => {
  // es6 and linted version of copied inspeclet script
  const goId = 966249170;
  window.__insp = window.__insp || [];
  window.__insp.push(['wid', goId]);
  const ldinsp = function () {
    const conversion = 3600000;
    if ('undefined' !== typeof window.__inspld) return;
    window.__inspld = 1;
    const insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = 'inspsync';
    insp.src =
      ('https:' === document.location.protocol ? 'https' : 'http') +
      '://cdn.inspectlet.com/inspectlet.js?wid=966249170&r=' +
      Math.floor(new Date().getTime() / conversion);
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(insp, x);
  };
  setTimeout(ldinsp, 0);
};

export default inspectlet;
