import { FILE_LIMITATIONS } from '../../uploader/constants';
import { CONTENT_TYPES } from '../../../constants/messages';
import { contentToForm } from '../../../helpers/content/content';

export const validateByFilter = (filter) => {
  switch (filter) {
    case 'allMedia':
      return (contentItem) => contentItem;
    case 'videos':
      return (contentItem) =>
        !!contentItem.body.mimeType &&
        !!contentItem.body.mimeType.includes('video');
    case 'gifs':
      return (contentItem) =>
        !!contentItem.body.mimeType &&
        !!contentItem.body.mimeType.includes('gif');
    case 'images':
      return (contentItem) =>
        !!contentItem.body.mimeType &&
        !!contentItem.body.mimeType.includes('image');
    case 'tags':
      return (contentItem) => !!contentItem.tags.length;
    default:
      // eslint-disable-next-line
      console.log('error: failed to filter media content');
      return (contentItem) => contentItem;
  }
};

// File size restricted by Facebook
// About 25MB post-processed size according Facebook API docs
export const filterMediaListBySize = (arr, size) => {
  return arr.filter((media) => {
    const sizeLimited =
      media.body.size < FILE_LIMITATIONS.MAX_VIDEO_SIZE_BYTES.BROADCAST;
    return sizeLimited;
  });
};

export const getMultipartTypes = (data) => {
  const content = data.body.content;
  const contentTypes = {
    text: false,
    links: false,
    media: false,
    buttons: false,
  };
  content.forEach((item) => {
    if (item.text) {
      contentTypes.text = true;

      // check if multiple links
    } else if (item.attachments) {
      contentTypes.links = true;
    } else if (item.media) {
      contentTypes.media = true;
    }
    if (item.choices) contentTypes.buttons = true;
  });
  return contentTypes;
};

export const getThumbnailType = (data) => {
  const schema = data.schema;
  const legacyMediaType =
    'general-image' === schema ||
    'general-video' === schema ||
    'general-gif' === schema;
  if (legacyMediaType) {
    return 'media';
  } else {
    const contentTypes = getMultipartTypes(data);
    if (contentTypes.media) return 'media';
    if (contentTypes.links) return 'links';
    if (contentTypes.text) return 'text';
  }
};

export const getContentDescriptor = (data) => {
  const schema = data.schema;
  const legacyMediaType =
    'general-image' === schema ||
    'general-video' === schema ||
    'general-gif' === schema;

  if (legacyMediaType) {
    return 'media';
  } else {
    const contentFormData = contentToForm(data.body.content);
    const messageBody = contentFormData.content;
    const buttonsPresent = contentFormData.buttons.length;
    const messageBodyDescriptors = messageBody
      .map((messageItem) => {
        if (messageItem.mode === CONTENT_TYPES.text) return 'Text';
        if (messageItem.mode === CONTENT_TYPES.media) {
          const mimeType = messageItem.type || '';
          return `${mimeType} Media`;
        }
        if (messageItem.mode === CONTENT_TYPES.link) return 'Web Pages';
      })
      .join(', ');

    if (buttonsPresent) {
      return messageBodyDescriptors.concat(', Buttons');
    } else {
      return messageBodyDescriptors;
    }
  }
};
