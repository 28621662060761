import {
  createGroup,
  getCampaigns,
  getManualCampaign,
  createManualCampaign,
} from '../../../../api/broadcast';
export default {
  state: {
    campaign: null,
    campaignsData: {},
    group: null,
  },
  mutations: {
    setCampaign: (state, campaignObj) => {
      state.campaign = campaignObj;
    },
    setGroup: (state, groupObj) => {
      state.group = groupObj;
    },
    setCampaigns: (state, data) => {
      const campaignsObj = {};
      let id;
      for (let i = 0; i < data.length; i = i + 1) {
        id = data[i].id;
        campaignsObj[id] = data[i];
      }
      state.campaignsData = campaignsObj;
    },
  },
  actions: {
    createGroup: async ({ commit, rootState, state }, name) => {
      const group = await createGroup(
        rootState.user.botData.key,
        state.campaign.id,
        name
      );
      commit('setGroup', group);
    },
    createManualCampaign: async ({ commit, rootState }) => {
      const campaign = await createManualCampaign(rootState.user.botData.key);
      commit('setCampaign', campaign);
    },
    getManualCampaign: async ({ commit, dispatch, rootState, state }) => {
      if (state.campaign) return;

      const manualCampaign = await getManualCampaign(
        rootState.user.botData.key
      );
      if (manualCampaign) {
        commit('setCampaign', manualCampaign);
      } else {
        dispatch('createManualCampaign');
      }
    },
    getCampaignsData: async ({ commit, rootState }) => {
      const campaignsData = await getCampaigns(rootState.user.botData.key);
      commit('setCampaigns', campaignsData);
    },
  },
};
