<template lang="html">
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      const classesArr = ['fab-static'];
      if (this.disabled) classesArr.push('fab--disabled');
      if (this.color) classesArr.push(`fab--${this.color}`);
      if (this.type) classesArr.push(`fab--${this.type}`);

      return classesArr.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.fab-static {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  border-radius: 50%;
  font-family: Roboto, sans-serif;

  position: relative;
  top: 2px;
}

.fab--purple,
.fab--text {
  background: $chip-purple-bg;
  .material-icons {
    color: $chip-purple-icon;
  }
}
.fab--secondary,
.fab--web-page {
  background: $mdc-theme-secondary--50;
  .material-icons {
    color: $mdc-theme-secondary;
  }
}
.fab--primary,
.fab--media {
  background: $mdc-theme-primary--100;
  .material-icons {
    color: $mdc-theme-primary--700;
  }
}
.fab--orange {
  background: $chip-orange-bg;
  .material-icons {
    color: $chip-orange-icon;
  }
}
.fab--disabled {
  color: #979797;
  background-color: #dadada;
}
</style>
