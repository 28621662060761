var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "link-slide", on: { click: _vm.openLink } },
    [
      _c("div", {
        staticClass: "image",
        style: { "background-image": "url('" + _vm.thumbnail + "')" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gray-background" }, [
        _c("div", { staticClass: "title-wrap" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.titleViewable))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "url-wrap" }, [
          _c("div", { staticClass: "url" }, [_vm._v(_vm._s(_vm.urlViewable))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "read-more-button-text" }, [
          _c("div", { staticClass: "read-more-button-text" }, [
            _vm._v(_vm._s(_vm.readMoreButtonText))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }