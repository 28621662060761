import ERROR_TEXT from '../../copy/error-text';
import { SNACK_TYPES } from '../../constants/snackbar';

export default {
  namespaced: true,
  state: {
    customClass: '',
    showSnack: false,
    snackText: '',
  },
  mutations: {
    closeSnack(state) {
      state.showSnack = false;
      state.customClass = '';
    },
    openSnack(state, { type, text, customClass }) {
      if (customClass) state.customClass = customClass;
      if (SNACK_TYPES['ERROR'] === type) {
        if (text) {
          state.snackText = text;
        } else {
          state.snackText = ERROR_TEXT['DEFAULT'];
        }
        setTimeout(() => {
          state.showSnack = true;
        }, 0);
      } else if (SNACK_TYPES['SUCCESS'] === type) {
        state.snackText = text;
        setTimeout(() => {
          state.showSnack = true;
        }, 0);
      }
    },
  },
};
