var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hover-buttons" },
    [
      _c("icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideEdit,
            expression: "!hideEdit"
          }
        ],
        staticClass: "action-icon edit",
        attrs: { icon: "edit" },
        on: { click: _vm.startEditing }
      }),
      _vm._v(" "),
      _vm.activeDownArrow
        ? _c("icon", {
            staticClass: "action icon",
            attrs: { icon: "arrow_downward" },
            on: { click: _vm.onSwapDown }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.activeDownArrow
        ? _c("icon", {
            staticClass: "action-icon--disabled",
            attrs: { icon: "arrow_downward" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeUpArrow
        ? _c("icon", {
            staticClass: "action-icon",
            attrs: { icon: "arrow_upward" },
            on: { click: _vm.onSwapUp }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.activeUpArrow
        ? _c("icon", {
            staticClass: "action-icon--disabled",
            attrs: { icon: "arrow_upward" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("icon", {
        staticClass: "action-icon",
        attrs: { icon: "delete" },
        on: {
          click: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.handleDel($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }