var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "media-preview" }, [
    "video" === _vm.assetType
      ? _c(
          "div",
          {
            staticClass: "video media-content",
            style: { "background-image": "url(" + _vm.src + ")" },
            on: { click: _vm.openLink }
          },
          [
            _c("icon", {
              staticClass: "play",
              attrs: { icon: "play_circle_filled" }
            })
          ],
          1
        )
      : _c("div", {
          staticClass: "image media-content",
          style: { "background-image": "url(" + _vm.src + ")" },
          on: { click: _vm.openLink }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }