var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "media-form-field__media-link" },
    [
      _c("text-field", {
        attrs: {
          label: "Media URL*",
          disabled: !_vm.editingSelf,
          value: _vm.mutableLink
        },
        on: {
          "update:value": function($event) {
            _vm.mutableLink = $event
          },
          keyup: function() {
            return _vm.updateFormData("link")
          }
        }
      }),
      _vm._v(" "),
      _vm.editingSelf
        ? _c("text-field", {
            attrs: {
              label: "Thumbnail",
              value: _vm.mutableThumbnail,
              filled: ""
            },
            on: {
              "update:value": function($event) {
                _vm.mutableThumbnail = $event
              },
              keyup: function() {
                return _vm.updateFormData("thumbnail")
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.editingSelf
        ? _c("text-field", {
            attrs: {
              label: "Title",
              textarea: true,
              value: _vm.mutableTitle,
              filled: ""
            },
            on: {
              "update:value": function($event) {
                _vm.mutableTitle = $event
              },
              keyup: function() {
                return _vm.updateFormData("title")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }