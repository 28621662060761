export default [
  'ATPTennisTV',
  'betta_maxostoma',
  'jfl-admin',
  'max-nba',
  'NHL-Social-Team',
  'PGA-TOUR-Admin',
  'sky-sports-admin',
  'SuperSport-Rugby-Admin',
  'Tyson-Ben',

  // pubg
  'david',
  'soonree',
];
