<template>
  <section
    class="links-preview"
    :class="{ 'links-preview--vertical': !linksHorizontal }"
  >
    <carousel
      v-if="linksHorizontal"
      :onPlus="() => {}"
      :key="carouselChangeDetection"
    >
      <link-slide
        v-for="link in links"
        :key="link.link"
        :link="link.link"
        :thumbnail="link.thumbnail"
        :title="link.title"
        :readMoreButtonText="link.readMoreButtonText"
      />
    </carousel>
    <link-slide
      v-else
      v-for="link in links"
      :key="getLinkChangeDetection(link)"
      :link="link.link"
      :thumbnail="link.thumbnail"
      :title="link.title"
      :readMoreButtonText="link.readMoreButtonText"
    />
  </section>
</template>
<script>
import Carousel from '../../../../carousel/carousel.vue';
import LinkSlide from './link-slide/link-slide.vue';

export default {
  components: {
    Carousel,
    LinkSlide,
  },
  computed: {
    carouselChangeDetection() {
      return this.links
        .map(
          (link) =>
            `${link.link}${link.thumbnail}${link.title}${link.readMoreButtonText}`
        )
        .join('');
    },
  },
  props: {
    links: {
      required: true,
      type: Array,
    },
    linksHorizontal: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    getLinkChangeDetection(link) {
      return `${link.link}${link.thumbnail}${link.title}${link.readMoreButtonText}`;
    },
  },
};
</script>
<style scoped lang="scss">
.links-preview {
  display: flex;
  justify-content: flex-end;
  max-width: 360px;
  overflow: hidden;
  position: relative;
}
.links-preview--vertical {
  flex-direction: column;
}
.links-preview--vertical .link-slide {
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
