var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-headers full-width" }, [
    _c("div", { staticClass: "list-header list-header--questions" }, [
      _vm._v("QUESTIONS")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "list-header list-header--answer" }, [
      _vm._v("ANSWER")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "list-header list-header--tag" }, [_vm._v("TAG")]),
    _vm._v(" "),
    _c("div", { staticClass: "list-header list-header--edited" }, [
      _vm._v("LAST EDITED")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "list-header list-header--score",
        class: { "list-header--disabled": !_vm.searchResults.length }
      },
      [_vm._v("\n    SCORE\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }