var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "edit-row" },
    [
      "text" === _vm.item.mode
        ? _c(
            "text-form-field",
            _vm._b(
              {
                attrs: {
                  editingSelf: _vm.editingPosition === _vm.position,
                  editingItem: _vm.editingItem,
                  initialText: _vm.item.text,
                  cancelItem: _vm.cancelItem,
                  activeUpArrow: _vm.activeUpArrow,
                  activeDownArrow: _vm.activeDownArrow,
                  handleDel: _vm.deleteItem,
                  swapItems: _vm.swapItems,
                  position: _vm.position,
                  saveItem: _vm.saveItem,
                  startEditing: _vm.startEditing
                }
              },
              "text-form-field",
              _vm.$props,
              false
            )
          )
        : "media" === _vm.item.mode
        ? _c(
            "media-form-field",
            _vm._b(
              {
                attrs: {
                  cancelItem: _vm.cancelItem,
                  editingSelf: _vm.editingPosition === _vm.position,
                  editingItem: _vm.editingItem,
                  finishEditingItem: _vm.finishEditingItem,
                  link: _vm.item.link,
                  thumbnail: _vm.item.thumbnail,
                  title: _vm.item.title,
                  type: _vm.item.type,
                  activeUpArrow: _vm.activeUpArrow,
                  activeDownArrow: _vm.activeDownArrow,
                  handleDel: _vm.deleteItem,
                  swapItems: _vm.swapItems,
                  position: _vm.position,
                  saveItem: _vm.saveItem,
                  startEditing: _vm.startEditing
                }
              },
              "media-form-field",
              _vm.$props,
              false
            )
          )
        : "link" === _vm.item.mode && _vm.position === _vm.firstLinkIndex
        ? _c("edit-link-group", {
            attrs: {
              content: _vm.content,
              cancelItem: _vm.cancelItem,
              deleteAllLinks: _vm.deleteAllLinks,
              editingPosition: _vm.editingPosition,
              editingItem: _vm.editingItem,
              firstLinkIndex: _vm.firstLinkIndex,
              deleteItem: _vm.deleteItem,
              lastLinkIndex: _vm.lastLinkIndex,
              links: _vm.links,
              position: _vm.position,
              startEditing: _vm.startEditing,
              swapItems: _vm.swapItems,
              moveLinksUp: _vm.moveLinksUp,
              moveLinksDown: _vm.moveLinksDown,
              saveItem: _vm.saveItem
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }