import { del, get, post } from './axios-wrapper';

export const getResponses = async (app, options) => {
  const { count, name, offset, sort } = options;
  const nameStr = name && 'all' !== name ? `&name=${name}` : '';
  const countStr = count ? `count=${count}` : '';
  const skipStr = offset ? `&offset=${offset}` : '';
  const sortStr = sort ? sort.split('.') : '';
  const fieldStr = sort ? `&field=${sortStr[0]}` : '';
  const orderStr = sort ? `&order=${sortStr[1]}` : '';
  const queriesStr = `${countStr}${skipStr}${fieldStr}${orderStr}${nameStr}`;
  const url = `/v1/response-manager/responses/${app}?${queriesStr}`;
  const result = (await get(url)).data;
  return result;
};

export const getTotalResponses = async (app, name) => {
  const nameStr = name && 'all' !== name ? `&name=${name}` : '';
  const url = `/v1/response-manager/getTotalResponses/${app}?${nameStr}`;
  const result = (await get(url)).data;
  return result;
};

export const getResponseNames = async (app) => {
  const url = `/v1/response-manager/names/${app}`;
  const result = (await get(url)).data;
  return result;
};

export const deleteResponse = async (id) =>
  (await del(`/v1/response-manager/response/${id}`)).data;

export const upsertResponse = async (response) =>
  (await post('/v1/response-manager/upsertResponse', response)).data;
