var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        { "show-overlay": _vm.editingBroadcast },
        "full-width",
        "broadcast",
        "full-height",
        "overlay"
      ]
    },
    [
      _c("confirm-modal", { attrs: { show: _vm.modal === "CONFIRM" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content full-width" },
        [
          _c(
            "page-header",
            { attrs: { header: "Broadcast" } },
            [
              _c(
                "btn",
                {
                  staticClass: "round create",
                  attrs: { icon: "add", raised: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleNewBroadcast()
                    }
                  }
                },
                [_vm._v("\n        Broadcast\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("list-filters"),
          _vm._v(" "),
          _c("list-view", {
            attrs: {
              waitingForData: _vm.waitingForData,
              items: _vm.viewableBroadcasts
            }
          }),
          _vm._v(" "),
          !!_vm.selectedBroadcast
            ? _c(
                "side-sheet",
                {
                  staticClass: "side-sheet--broadcast",
                  attrs: {
                    selectedBroadcast: _vm.selectedBroadcast,
                    onClose: function() {
                      return _vm.setSelectedBroadcast(null)
                    }
                  }
                },
                [
                  _c(
                    "info",
                    [
                      _c("broadcast-read", {
                        attrs: { originalBroadcast: _vm.selectedBroadcast }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("preview", {
                    attrs: {
                      buttonsPresent: _vm.buttonsPresent,
                      buttonsHorizontal: _vm.buttonsHorizontal,
                      chatPreviewData: _vm.chatPreviewData,
                      linksPresent: _vm.linksPresent,
                      linksHorizontal: _vm.linksHorizontal,
                      toggleButtonsPreview: _vm.toggleButtonsPreview,
                      toggleLinksPreview: _vm.toggleLinksPreview,
                      participants: _vm.participants
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("broadcast-create", { attrs: { show: _vm.editingBroadcast } })
        ],
        1
      ),
      _vm._v(" "),
      _vm.waitingForData
        ? _c(
            "div",
            { staticClass: "page-loading" },
            [_c("indeterminate-circle")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }