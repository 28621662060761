<template>
  <div class="full-width full-height content-page">
    <confirm-modal :show="modal === 'CONFIRM'" />
    <upload-size-modal :show="modal === 'UPLOAD_SIZE'" />
    <error-modal :show="modal === 'ERROR'" />
    <div class="full-width full-height content">
      <page-header header="Human Handoff">
        <!-- <btn
          class="round create"
          @click="convertUserState()"
          icon="add"
          raised>
          User ID
        </btn> -->
      </page-header>
      <list-filters />
      <list-view
        :items="totalItems"
        :load-more-data="loadMoreData"
        :waiting-for-data="waitingForData"
      >
      </list-view>
      <side-sheet
        class="side-sheet--human-handoff"
        :class="{
          pending: selectedUser.stateViewable === 'pending',
          active: selectedUser.stateViewable === 'active',
        }"
        v-if="selectedUser"
        :onClose="() => handleCloseSideSheet()"
      >
        <chat-info />
        <chat-conversation :key="selectedUser.id" />
      </side-sheet>
    </div>
    <div class="page-loading" v-if="waitingForData">
      <indeterminate-circle />
    </div>
  </div>
</template>

<script>
import IndeterminateCircle from '../../components/indeterminate-circle/indeterminate-circle.vue';
import ListFilters from './list-view/list-filters/list-filters.vue';
import ListView from './list-view/list-view.vue';
import PageHeader from '../../components/page-header/page-header.vue';
import SideSheet from '../../components/side-sheet/side-sheet.vue';

import ConfirmModal from '../../components/modal/confirm-modal.vue';
import ChatConversation from './chat-ui/chat-ui-conversation/chat-ui-conversation.vue';
import ChatInfo from './chat-ui/chat-ui-info/chat-ui-info.vue';
import ErrorModal from '../../components/modal/error-modal.vue';
import UploadSizeModal from '../../components/modal/library/upload-size-modal.vue';

import { getHumanHandoffUsers } from '../../api/human-handoff';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { DISPLAY_CHANNELS } from '../../state/human-handoff/constants';
import {
  epochToListView,
  epochToLocaleInput,
} from '../../helpers/conversions/time/time';

export default {
  components: {
    ChatInfo,
    ChatConversation,
    IndeterminateCircle,
    ListFilters,
    ListView,
    PageHeader,
    SideSheet,

    // Modals
    ConfirmModal,
    ErrorModal,
    UploadSizeModal,
  },
  computed: {
    ...mapGetters('humanHandoff', ['humanHandoffAvailable']),
    ...mapState('user', ['botData', 'loggedIn']),
    ...mapState('humanHandoff', [
      'channelOptions',
      'orderBy',
      'selectedChannel',
    ]),
    ...mapGetters('humanHandoffForm', [
      'buttons',
      'buttonsHorizontal',
      'linksHorizontal',
      'firstLinkIndex',
      'lastLinkIndex',
      'links',
    ]),
    ...mapState('humanHandoffForm', ['editingContent', 'content', 'buttons']),
    ...mapState('humanHandoffConversation', ['selectedUser']),
    ...mapState('humanHandoffList', [
      'itemsPerLoad',
      'endOfList',
      'lastRefresh',
      'totalItems',
    ]),
    ...mapState('modal', ['modal']),
    ...mapState('uploader', [
      'continueUpload',
      'itemLink',
      'itemThumbnail',
      'itemType',
    ]),
    ...mapGetters('uploader', ['uploaderHasError', 'uploading']),
  },
  data() {
    return {
      gettingMetaData: false,
      fileName: '',
      previewLoaded: false,
      uploader: {
        done: false,
        error: false,
      },
      waitingForData: true,
    };
  },
  methods: {
    ...mapActions('humanHandoffConversation', ['handleCloseSideSheet']),
    ...mapMutations('humanHandoffList', ['resetListState', 'setTotalItems']),
    ...mapMutations('humanHandoffConversation', ['setSelectedUser']),
    ...mapMutations('uploader', ['clearStates']),
    ...mapMutations('modal', ['resetModalStates', 'showModal']),
    ...mapMutations('humanHandoff', ['setSelectedChannel']),
    ...mapMutations('snackbar', ['openSnack']),

    // List view methods
    async loadMoreData() {
      if (this.waitingForData) return;
      await this.waitForData();
    },
    getHumanReadable(user) {
      return {
        ...user,
        originalData: { ...user },
        channelViewable:
          DISPLAY_CHANNELS[user.meta.channel.name] || user.meta.channel.name,
        lastReplyDateViewable: epochToListView(user._private.lastMessage).split(
          ','
        )[0],
        lastReplyTimeViewable: epochToLocaleInput(user._private.lastMessage)
          .time,
        stateViewable: user.features.humanHandoff.state,
        submittedDateViewable: epochToListView(
          user.features.humanHandoff.lastModified
        ).split(',')[0],
        submittedTimeViewable: epochToLocaleInput(
          user.features.humanHandoff.lastModified
        ).time,
        id: user.meta.subscriptionInfo.primaryId,
      };
    },
    async getListViewData(reset = false) {
      if (!this.loggedIn || !this.channelOptions.length) {
        return [];
      }
      let humanHandoffUsers = [];
      const COUNT = 1000;
      const channel = this.selectedChannel
        ? this.selectedChannel
        : this.channelOptions[0].value;
      humanHandoffUsers = [
        ...(await getHumanHandoffUsers(
          this.botData.key,
          channel,
          'active',
          COUNT
        )),
        ...(await getHumanHandoffUsers(
          this.botData.key,
          channel,
          'pending',
          COUNT
        )),
      ];
      const usersWithFullData = humanHandoffUsers.map((user) =>
        this.getHumanReadable(user)
      );
      this.setTotalItems(usersWithFullData);
    },
    async resetAll() {
      this.resetListState();
      this.resetModalStates();
      this.clearStates();
      this.setDefaultChannelOption();
      this.setTotalItems([]);
    },
    async waitForData(reset = false) {
      if (reset) {
        this.resetListState();
      }
      if (this.botData) {
        this.waitingForData = true;
        await this.getListViewData(reset);
        this.waitingForData = false;
      } else if (!this.botData) {
        this.waitingForData = false;
      }
    },
    setDefaultChannelOption() {
      try {
        // eslint-disable-next-line
        console.log('channel options available, setting default channel');
        this.setSelectedChannel(this.channelOptions[0].value);
      } catch (e) {
        // eslint-disable-next-line
        console.log('no channel options available');
      }
    },
  },
  watch: {
    lastRefresh() {
      this.resetAll();
      this.waitForData(true);
    },
    async humanHandoffAvailable() {
      if (this.loggedIn) {
        await this.waitForData(true);
      }
    },
    async orderBy() {
      await this.waitForData(true);
    },
    async selectedChannel() {
      this.setTotalItems([]);
      this.resetListState();
      await this.waitForData(true);
    },
    async channelOptions() {
      if (this.channelOptions.length && !this.selectedChannel) {
        this.setSelectedChannel(this.channelOptions[0].value);
      }
    },
  },
  activated() {
    this.resetAll();
    this.waitForData(true);
  },
};
</script>
<style scoped lang="scss">
@import './human-handoff.scss';
@import '../../components/indeterminate-circle/page-loading.scss';
</style>
