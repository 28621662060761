var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "schedule-editor",
      class: { "update-mode": _vm.updateMode }
    },
    [
      _c("editor-header", { attrs: { editor: "Job", mode: _vm.headerMode } }),
      _vm._v(" "),
      _c("text-field", {
        staticClass: "form-spacing",
        attrs: { label: "Job name", value: _vm.name, required: "", filled: "" },
        on: {
          "update:value": function($event) {
            _vm.name = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-spacing--switch" },
        [
          _c("m-switch", {
            attrs: { id: "customer-switch" },
            model: {
              value: _vm.showInList,
              callback: function($$v) {
                _vm.showInList = $$v
              },
              expression: "showInList"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-spacing", attrs: { for: "customer-switch" } },
            [_vm._v("Show in customer broadcast list?")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-spacing--switch" },
        [
          _c("m-switch", {
            attrs: { id: "enable-switch" },
            model: {
              value: _vm.enabled,
              callback: function($$v) {
                _vm.enabled = $$v
              },
              expression: "enabled"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-spacing", attrs: { for: "enable-switch" } },
            [_vm._v("Enable? *")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown form-spacing" },
        [
          _c("div", { staticClass: "select-label" }, [_vm._v("App *")]),
          _vm._v(" "),
          _c("single-select", {
            attrs: {
              "handle-choice": _vm.handleSelectApp,
              options: _vm.scheduleAppOptions,
              "upstream-val": _vm.app
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("text-field", {
        staticClass: "form-spacing",
        attrs: { label: "URL", value: _vm.uri, required: "", filled: "" },
        on: {
          "update:value": function($event) {
            _vm.uri = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form-spacing pseudo-v-label",
          class: { invalid: !_vm.postBodyValid },
          attrs: { for: "#post-body" }
        },
        [_vm._v("Post Body")]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("editor", {
            staticClass: "full-width post-body",
            attrs: { id: "post-body", lang: "json", height: "150" },
            on: { init: function() {} },
            model: {
              value: _vm.postBody,
              callback: function($$v) {
                _vm.postBody = $$v
              },
              expression: "postBody"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass:
            "form-spacing pseudo-v-label padding-tiny-bottom full-width"
        },
        [
          _vm._v(
            "\n    When (currently " + _vm._s(this.dateFormat) + " mode)\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-width date-time form-spacing" },
        [
          _c("text-field", {
            staticClass: "padding-small",
            attrs: {
              label: "Start date",
              type: "date",
              value: _vm.startDate,
              required: _vm.recurring,
              "trailing-icon": "today",
              filled: ""
            },
            on: {
              "update:value": function($event) {
                _vm.startDate = $event
              },
              update: _vm.handleNewDate
            }
          }),
          _vm._v(" "),
          _c("text-field", {
            staticClass: "padding-small",
            attrs: {
              label: "Start time",
              type: "time",
              value: _vm.startTime,
              required: _vm.recurring,
              "trailing-icon": "access_time",
              filled: ""
            },
            on: {
              "update:value": function($event) {
                _vm.startTime = $event
              },
              update: _vm.handleNewTime
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-spacing" }, [
        _c(
          "div",
          { staticClass: "recurring-wrap" },
          [
            _c("m-checkbox", {
              model: {
                value: _vm.recurring,
                callback: function($$v) {
                  _vm.recurring = $$v
                },
                expression: "recurring"
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "vertical-center-label" }, [
              _vm._v("Recurring")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm.recurring
          ? _c("div", { staticClass: "full-width" }, [
              _c(
                "label",
                {
                  staticClass:
                    "pseudo-v-label padding-small padding-tiny-bottom full-width"
                },
                [_vm._v("every")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "padding-small full-width" },
                [
                  _c("text-field", {
                    staticClass: "padding-small half-width quantity",
                    attrs: {
                      label: "Quantity",
                      type: "number",
                      value: _vm.quantity,
                      required: _vm.recurring,
                      filled: ""
                    },
                    on: {
                      "update:value": function($event) {
                        _vm.quantity = $event
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown half-width" },
                    [
                      _c("div", { staticClass: "select-label" }, [
                        _vm._v("Units*")
                      ]),
                      _vm._v(" "),
                      _c("single-select", {
                        staticClass: "dropdown",
                        attrs: {
                          "handle-choice": _vm.handleUnitChoice,
                          options: _vm.unitOptions,
                          "upstream-val": _vm.unit
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("label", { staticClass: "pseudo-v-label until" }, [
                _vm._v("until")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "padding-small full-width date-time" },
                [
                  _c("text-field", {
                    staticClass: "padding-small",
                    attrs: {
                      label: "End date",
                      type: "date",
                      value: _vm.endDate,
                      required: _vm.recurring,
                      filled: ""
                    },
                    on: {
                      "update:value": function($event) {
                        _vm.endDate = $event
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("text-field", {
                    staticClass: "padding-small",
                    attrs: {
                      label: "End time",
                      type: "time",
                      value: _vm.endTime,
                      required: _vm.recurring,
                      filled: ""
                    },
                    on: {
                      "update:value": function($event) {
                        _vm.endTime = $event
                      }
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("chips-input", {
        attrs: {
          chipset: _vm.tagChips,
          disabled: false,
          label: "Tags",
          "handle-chip-add": _vm.handleTagAdd,
          "handle-icon-click": _vm.handleTagDel,
          "helper-text": "Type tag and press enter..."
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-width grid row-wrap--right" },
        [
          _c(
            "btn",
            {
              staticClass: "btn-color--secondary",
              attrs: {
                type: "button",
                raised: "",
                disabled: !_vm.allowRun || _vm.loading
              },
              on: { click: _vm.run }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.pendingAction === _vm.jobPendingActions.running
                      ? "Saving..."
                      : "Save and Run"
                  ) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          !_vm.updateMode
            ? _c(
                "btn",
                {
                  staticClass: "btn-color--secondary",
                  attrs: {
                    type: "button",
                    raised: "",
                    disabled: !_vm.allowSchedule || _vm.loading
                  },
                  on: { click: _vm.schedule }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.pendingAction === _vm.jobPendingActions.scheduling
                          ? "Scheduling..."
                          : "Schedule"
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.updateMode
            ? _c(
                "btn",
                {
                  staticClass: "btn-color--primary",
                  attrs: {
                    type: "button",
                    raised: "",
                    disabled: !_vm.allowSchedule || _vm.loading
                  },
                  on: { click: _vm.update }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.pendingAction === _vm.jobPendingActions.updating
                          ? "Updating..."
                          : "Update"
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.updateMode
            ? _c(
                "btn",
                {
                  staticClass: "btn-color--destructive",
                  attrs: { type: "button", raised: "", disabled: _vm.loading },
                  on: { click: _vm.destroy }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.pendingAction === _vm.jobPendingActions.DELETING
                          ? "Deleting..."
                          : "Delete"
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }