import { upsertQnaPair } from '../../../api/qna';
import buttonsState from './buttons/buttons';
import linksState from './links/links';
import qnaPreviewState from './qna-preview/qna-preview';
import qnaQuestionsState from './qna-questions/qna-questions';
import {
  CONTENT_TYPES,
  DEFAULT_CONTENT,
  PREVIEW_CONFIG_DEFAULT,
} from '../constants';
import { contentToForm } from '../../../helpers/content/content';
import { createUUID } from '../../../helpers/uuid/uuid';

export default {
  namespaced: true,
  state: {
    ...buttonsState.state,
    ...linksState.state,
    ...qnaQuestionsState.state,

    // form data
    message: [],
    buttons: [],

    // sidesheet info
    selectedPair: null,
    editingPair: false,
    previewConfig: PREVIEW_CONFIG_DEFAULT,

    // Item creating and editing
    editingPosition: -1,
    selectedTag: '',
  },
  mutations: {
    ...buttonsState.mutations,
    ...linksState.mutations,
    ...qnaQuestionsState.mutations,

    // init
    createNewPair: (state) => {
      state.editingQuestionPos = 0;
      state.editingPair = true;
    },
    resetForm: (state, message) => {
      state.message = [];
      state.buttons = [];
      state.editingPosition = -1;
    },
    setEditing: (state, bool) => {
      if (!bool) state.editingButtons = false;
      state.editingPair = bool;
    },
    setSelectedPair: (state, data) => {
      state.selectedPair = data;
    },

    // tag
    setSelectedTag: (state, tag) => {
      state.selectedTag = tag;
    },

    // CRUD message
    setMessage(state, message) {
      state.message = message;
    },
    createItem(state, { type, position }) {
      const newContent = { ...DEFAULT_CONTENT[type] };
      if (position) {
        const before = state.message.slice(0, position);
        const after = state.message.slice(position, state.message.length);
        state.message = [...before, newContent, ...after];
      } else {
        state.message.push(newContent);
      }
    },
    finishEditingItem(state) {
      state.editingPosition = -1;
    },
    destroyItem(state, position) {
      const itemType = state.message[position].mode;
      switch (itemType) {
        default:
          state.message.splice(position, 1);
      }
    },
    updateItem(state, { position, data }) {
      const updated = [...state.message];
      updated[position] = data;
      state.message = updated;
    },
    setEditingPosition(state, index) {
      state.editingPosition = index;
    },
    startEditing(state, editingPosition) {
      state.editingPosition = editingPosition;
    },
    swapIndices: (state, { ind, ind2 }) => {
      const newMessage = [...state.message];
      newMessage[ind] = state.message[ind2];
      newMessage[ind2] = state.message[ind];
      state.message = newMessage;
    },
    setInitialPair(state, pair) {
      state.editingPair = true;
      if (pair) {
        state.selectedPair = pair;
        const answer = contentToForm(pair.answerContent);
        state.message = answer.content;
        state.buttons = answer.buttons;
        state.questions = pair.questions.map((q) => ({
          ...DEFAULT_CONTENT.text,
          text: q,
        }));
        state.selectedTag = pair.tags.length ? pair.tags[0] : '';
      } else {
        state.selectedPair = null;
        state.questions = [{ ...DEFAULT_CONTENT.text }];
        state.message = [];
        state.buttons = [];
        state.selectedTag = '';
      }
    },
  },
  getters: {
    ...buttonsState.getters,
    ...linksState.getters,
    ...qnaQuestionsState.getters,
    ...qnaPreviewState.getters,

    messageReady(state, getters) {
      const questionReady = state.questions.length && state.questions[0].text;
      const answerReady = state.message.length;
      if (answerReady && !getters.editingItem && questionReady) return true;
      return false;
    },
    currentItem(state) {
      return state.message[state.editingPosition];
    },
    currentItemEmpty(state, getters) {
      switch (getters.editingMode) {
        case CONTENT_TYPES.text:
          return !getters.currentItem.text;
        case CONTENT_TYPES.link:
          return (
            !getters.currentItem.link &&
            !getters.currentItem.thumbnail &&
            !getters.currentItem.title
          );
        case CONTENT_TYPES.media:
          return !getters.currentItem.link && !getters.currentItem.title;
      }
    },
    editingItem(state) {
      return (
        -1 < state.editingPosition ||
        state.editingButtons ||
        -1 < state.editingQuestionPos
      );
    },
    editingMode(state, getters) {
      if (getters.editingItem) return getters.currentItem.mode;
      return '';
    },
    getAnswerContent(state, getters) {
      const exportMessageBody = (data, item, index, message) => {
        let dataUpdate;
        switch (item.mode) {
          case 'text':
            dataUpdate = [...data, { text: item.text }];
            break;
          case 'link':
            const alreadyAdded =
              0 < index && CONTENT_TYPES.link === message[index - 1].mode;
            if (alreadyAdded) {
              return data;
            } else {
              dataUpdate = [
                ...data,
                {
                  attachments: getters.links.map((link) => {
                    return {
                      title: link.title,
                      image: link.thumbnail,
                      link: link.link,
                      readMoreButtonText: link.readMoreButtonText,
                    };
                  }),
                },
              ];
            }
            break;
          case 'media':
            const mediaData = {
              link: item.link,
              type: item.type,
            };
            if (item.title) mediaData.title = item.title;
            if (item.thumbnail) mediaData.thumbnail = item.thumbnail;
            dataUpdate = [...data, { media: [mediaData] }];
            break;
        }
        return dataUpdate;
      };

      const body = {
        content: state.message.reduce(
          (data, item, index, message) =>
            exportMessageBody(data, item, index, message),
          []
        ),
      };
      if (state.buttons.length && state.message.length) {
        body.content[body.content.length - 1].choices = state.buttons.map(
          (item) => {
            return {
              title: item.title,
              returnValue: item.returnValue || item.title,
            };
          }
        );
      }
      return body.content;
    },
    formChanged(state) {
      const initialPair = state.selectedPair;
      const initialAnswer = contentToForm(initialPair.answerContent);
      const initialQuestions = initialPair.questions.map((q) => ({
        ...DEFAULT_CONTENT.text,
        text: q,
      }));
      const initialTag =
        initialPair.tags.length && initialPair.tags[0]
          ? initialPair.tags[0]
          : '';

      const questionsChanged =
        JSON.stringify(initialQuestions) !== JSON.stringify(state.questions);
      const tagChanged = state.selectedTag !== initialTag;
      const messageChanged =
        JSON.stringify(initialAnswer.content) !== JSON.stringify(state.message);
      const buttonsChanged =
        JSON.stringify(initialAnswer.buttons) !== JSON.stringify(state.buttons);
      const answerChanged = buttonsChanged || messageChanged;

      return tagChanged || questionsChanged || answerChanged;
    },
  },
  actions: {
    ...buttonsState.actions,
    ...linksState.actions,
    ...qnaQuestionsState.actions,

    addItem({ commit, state, getters }, type) {
      if (getters.editingItem) return;

      if (CONTENT_TYPES.link === type && getters.linksPresent) {
        const insertPosition =
          0 < getters.lastLinkIndex ? getters.lastLinkIndex + 1 : 0;
        commit('createItem', { type, position: insertPosition });
        commit('setEditingPosition', getters.lastLinkIndex);
      } else {
        commit('createItem', { type });
        commit('setEditingPosition', state.message.length - 1);
      }
    },
    cancelItem({ commit, dispatch, getters, state }) {
      if (getters.currentItemEmpty) {
        dispatch('deleteItem', state.editingPosition);
      }
      commit('finishEditingItem');
    },
    saveItem({ commit }, { data, position }) {
      commit('updateItem', { data, position });
      commit('finishEditingItem');
    },
    deleteItem({ commit }, position) {
      commit('destroyItem', position);
      commit('finishEditingItem');
    },
    async submit({ state, getters, rootState }) {
      const app = rootState.user.botData.key;
      const body = {
        questions: state.questions.map((textData) => textData.text),
        answerContent: getters.getAnswerContent,
        id: state.selectedPair ? state.selectedPair.id : createUUID(),
        tags: state.selectedTag ? [state.selectedTag] : [],
      };
      await upsertQnaPair(app, body);
    },
    swapItems({ commit, dispatch, state }, { ind, ind2 }) {
      commit('swapIndices', { ind, ind2 });
    },
    handleCloseSideSheet({ commit }) {
      commit('setEditing', false);
    },
  },
};
