<template>
  <section class="buttons-preview">
    <buttons-carousel :buttons="buttons" v-if="buttonsHorizontal" />
    <single-button
      v-else
      class="single-button--vertical"
      v-for="button in buttons"
      :key="button.title"
      :text="button.title"
    />
  </section>
</template>

<script>
import SingleButton from './single-button/single-button.vue';
import ButtonsCarousel from './buttons-carousel/buttons-carousel.vue';

export default {
  components: {
    ButtonsCarousel,
    SingleButton,
  },
  props: {
    buttons: {
      required: true,
      type: Array,
    },
    buttonsHorizontal: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-preview {
  margin-left: -102px;
  margin-top: 16px;
  position: relative;
  margin-bottom: 20px;
  width: 436px;
}
.single-button--vertical.single-button {
  margin-left: 80px;
  margin-top: 2px;
  margin-bottom: 2px;
  width: fit-content;
}
</style>
