import { createUUID } from '../../../../helpers/uuid/uuid';
import { previewableFormContent } from '../../../../helpers/content/content';
import { CONTENT_TYPES } from '../../../../constants/content';

export default {
  getters: {
    chatPreviewData(state, getters, rootState) {
      if (state.content && state.content.length) {
        const flatContent = state.content.filter((c) =>
          previewableFormContent(c)
        );
        const flatContentModes = flatContent.map((content) => content.mode);
        const hasLinks = 0 <= flatContentModes.indexOf(CONTENT_TYPES.link);
        const firstLinkIndex = flatContentModes.indexOf(CONTENT_TYPES.link);
        const lastLinkIndex = flatContentModes.lastIndexOf(CONTENT_TYPES.link);
        const preLinks = hasLinks
          ? flatContent.slice(0, firstLinkIndex)
          : flatContent;
        const links = hasLinks
          ? [
              {
                mode: 'links',
                links: flatContent.slice(firstLinkIndex, lastLinkIndex + 1),
              },
            ]
          : [];
        const postLinks = hasLinks ? flatContent.slice(lastLinkIndex + 1) : [];
        const buttonsContent = state.buttons.length
          ? [
              {
                mode: 'buttons',
                buttons: state.buttons,
              },
            ]
          : [];
        return [
          {
            speakerId: 'bot',
            id: createUUID(),
            left: false,
            content: [...preLinks, ...links, ...postLinks, ...buttonsContent],
          },
        ];
      } else if (rootState.responseManagerForm.selectedRow) {
        return rootState.responseManagerForm.selectedRow.content;
      } else {
        return [];
      }
    },
    participants: (state, getters, rootState) => {
      const brandAvatar = rootState.user.botData.display.avatar;
      const endUserAvatar =
        'https://gameon-technology-public-uploads-dashboard.s3-us-west-2.amazonaws.com/end-user-avatar.png';
      return {
        admin: {
          avatar: brandAvatar,
        },
        bot: {
          avatar: brandAvatar,
        },
        endUser: {
          avatar: endUserAvatar,
        },
      };
    },
  },
};
