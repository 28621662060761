<template>
  <div v-if="editingSelf" class="full-width uploader">
    <upload-size-modal :show="modal === 'UPLOAD_SIZE'" />
    <error-modal :show="modal === 'ERROR'" />
    <div class="full-width">
      <div class="go-input--file browse">
        <label v-if="editingSelf" class="clickable-file-label" for="file">
          <upload-icon />
        </label>
        <input
          type="file"
          id="file"
          @click="nullify"
          @change="handleFileInput"
          accept="image/*,video/*"
          :disabled="uploading"
        />
        <span class="bar"></span>
        <div class="media-wrap">
          <div class="surface media-content">
            <add-photo-alternate-icon v-if="showEmptyState" />
            <div class="tip" v-if="showEmptyState">
              {{ mediaTip }}
            </div>
            <linear-progress
              class="upload-progress"
              :indeterminate="false"
              :open="uploading"
              :progress="viewableProgress"
            />
          </div>
          <div
            :class="{ 'media-clickable': itemThumbnail || initialThumbnail }"
            @click="openAsset"
          >
            <media-content
              class="media-content--uploader"
              v-if="editingSelf && !uploading"
              :src="itemThumbnail || initialThumbnail"
              :allow-preview="true"
              :video="'video' === fileType"
              @previewLoaded="handlePreviewLoaded"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * SPEC
 * Events fired
 * 'itemUpdated' { thumbnail: '<url>', link: '<url>', type: '<type>' }
 */

import AddPhotoAlternateIcon from '../../assets/svg-components/add-photo-alternate.svg';
import ErrorModal from '../../components/modal/error-modal.vue';
import LinearProgress from 'gameon-components/src/components/linear-progress/linear-progress.vue';
import MediaContent from '../media-content/media-content.vue';
import UploadSizeModal from '../../components/modal/library/upload-size-modal.vue';
import UploadIcon from '../../assets/svg-components/upload-icon.svg';

import { bytesViewable } from '../../helpers/conversions/meta-data/meta-data';
import {
  FEATURE_TYPES,
  FILE_LIMITATIONS,
} from '../../state/uploader/constants';

import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

export default {
  components: {
    AddPhotoAlternateIcon,
    ErrorModal,
    LinearProgress,
    MediaContent,
    UploadIcon,
    UploadSizeModal,
  },
  props: [
    'allowPreview',
    'editingSelf',
    'initialLink',
    'initialThumbnail',
    'initialType',
  ],
  data() {
    const mediaTip = `Max file size is ${bytesViewable(
      FILE_LIMITATIONS.MAX_VIDEO_SIZE_BYTES.BROADCAST
    )}, and
       videos must be ${FILE_LIMITATIONS.MAX_MIN} mins or less.`;
    return {
      mediaTip: mediaTip,
      thumbOption: 0,
      viewableProgress: 0,
      lastProgressUpdate: new Date(),
    };
  },
  computed: {
    ...mapGetters('uploader', [
      'fileType',
      'showDefaultImg',
      'uploaderHasError',
      'uploading',
      'thumbUnchanged',
      'totalUploadProgress',
      'videoUploaded',
    ]),
    ...mapState('modal', ['modal']),
    ...mapState('uploader', [
      'continueUpload',
      'currentUploadingStep',
      'errors',
      'file',
      'itemLink',
      'itemThumbnail',
      'itemType',
      'previousItemState',
      'savingToServer',
      'uploadProgress',
      'validationProgress',
      'videoProgressUrl',
    ]),
    initialItem() {
      return {
        link: this.initialLink,
        thumbnail: this.initialThumbnail,
        type: this.initialType,
      };
    },
    showEmptyState() {
      if (this.uploading) return false;
      if (this.itemThumbnail || this.initialThumbnail) return false;
      return true;
    },
  },
  watch: {
    totalUploadProgress() {
      const currentProgressTime = new Date();
      const timeDifference = currentProgressTime - this.lastProgressUpdate;
      const animationDuration = 2000;
      const DONE = 1;
      const unreachableEnd = 0.95;
      if (
        timeDifference > animationDuration ||
        this.totalUploadProgress === DONE
      ) {
        this.lastProgressUpdate = currentProgressTime;
        this.viewableProgress = this.totalUploadProgress * unreachableEnd;
      }
    },
    continueUpload() {
      this.upload();
    },
    editingSelf() {
      this.clearStates();
      this.setInitialItem(this.initialItem);
    },
    // Patch for issue: render happens before data change finishes
    initialThumbnail() {
      this.setInitialItem(this.initialItem);
    },
    itemLink() {
      this.updateParent();
    },
    thumbOption() {
      this.selectThumbnailOption(this.thumbOption);
    },
  },
  methods: {
    ...mapActions('uploader', ['upload']),
    ...mapMutations('modal', ['showModal']),
    ...mapMutations('uploader', [
      'clearError',
      'clearStates',
      'removeFile',
      'setInitialItem',
      'setUploaderState',
      'setUploading',
      'setUploadProgress',
      'setCurrentUploadingStep',
      'setVideoProgressUrl',
      'updateItem',
    ]),
    ...mapActions('uploader', [
      'checkFile',
      'handleCancel',
      'selectThumbnailOption',
    ]),
    // Wipes out old value before inputting new file
    // This allows uploader to run change handler if reuploaded file has the same name
    getThumbOptionLink(option) {
      return this.itemThumbnail.replace(
        /(?:thumbnail\/)([0-9]+)/,
        `thumbnail/${option}`
      );
    },
    handlePreviewLoaded() {
      this.$emit('uploaderPreviewLoaded');
    },
    nullify(e) {
      e.currentTarget.value = null;
    },
    openAsset() {
      if (this.itemLink) {
        const newTab = window.open(this.itemLink, '_blank');
        newTab.focus();
      }
    },
    handleFileInput(e) {
      const file = e.target.files[0];
      this.checkFile({ feature: FEATURE_TYPES.BROADCAST, file });
      this.$emit('uploaderPreviewRemoved');
    },
    thumbLoaded() {
      this.clearError('THUMBNAIL');
      this.previousItemState.thumbnail = this.itemThumbnail;
    },
    thumbErrored(e) {
      if (this.itemThumbnail) {
        this.handleError('THUMBNAIL');
      }
    },
    updateParent() {
      this.$emit('itemUpdated', {
        thumbnail: this.itemThumbnail,
        link: this.itemLink,
        type: this.itemType,
      });
    },
  },
  mount() {
    if (this.initialItem) {
      this.setInitialItem(this.initialItem);
    } else {
      this.clearPreviousItem();
    }
  },
};
</script>

<style lang="scss" scoped>
@import './uploader';
</style>
