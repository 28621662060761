<template>
  <section class="search-filter">
    <icon
      class="search-filter__icon search-filter__icon__search"
      icon="search"
    />
    <input
      v-model="search"
      class="search-filter__input"
      type="search"
      :placeholder="placeholder"
      ref="input"
      @keyup="handleInputChange"
      @search="handleSearch"
    />
  </section>
</template>
<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      search: '',
      timeout: null,
    };
  },
  props: {
    handleSearchInput: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInputChange() {
      clearTimeout(this.timeout);
      const debounceTime = 100;

      // debounce
      this.timeout = setTimeout(async () => {
        await this.handleSearchInput(this.search);
      }, debounceTime);
    },
    async handleSearch() {
      await this.handleSearchInput(this.search);
    },
  },
};
</script>
<style lang="scss" scoped>
@import './search-filter.scss';
</style>
