var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-view" },
    [
      _c("list-headers"),
      _vm._v(" "),
      _vm.items.length
        ? _c(
            "m-list",
            { staticClass: "full-width" },
            [
              _c(
                "div",
                { staticClass: "list-view__rows" },
                _vm._l(_vm.items, function(broadcast) {
                  return _c("broadcast-row", {
                    key: broadcast.broadcastId,
                    staticClass: "item-wrap full-width",
                    attrs: { group: broadcast }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "scroll-pseudo-padding" }),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        : !_vm.items.length && !_vm.waitingForData
        ? _c("empty-message", {
            attrs: {
              message:
                "The combination of search and sort filters yielded no results. Please try another."
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          waitingForData: _vm.waitingForData,
          rowCountOnCurrentPage: _vm.items.length
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }