var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "message-editor full-height" }, [
    _c("section", { staticClass: "top-menu padding-tiny-around" }, [
      _c("section", { staticClass: "title" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title"
            }
          ],
          staticClass: "title__input",
          attrs: { placeholder: _vm.titlePlaceholder, type: "text" },
          domProps: { value: _vm.title },
          on: {
            click: _vm.handleTitleClick,
            blur: _vm.handleTitleBlur,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.title = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "top-menu__options" },
        [
          _c(
            "btn",
            {
              staticClass: "submit-btn",
              attrs: { disabled: !_vm.allowSubmit },
              on: { click: _vm.submitForm }
            },
            [_c("span", { staticClass: "submit-text" }, [_vm._v("Save")])]
          ),
          _vm._v(" "),
          _vm.initialRow
            ? _c(
                "more-menu",
                { attrs: { button: true } },
                [_c("more-response-options", { staticClass: "option-btn" })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "creation-wrap" }, [
      _c("div", { staticClass: "hide-top-shadow" }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "fixed-menu" },
        [
          _c("suggest-select", {
            attrs: {
              label: "Dialogues",
              "leading-icon": "search",
              "handle-primary-val": _vm.setSelectedResponseName,
              options: _vm.flattenedNameOptions,
              "primary-val": _vm.selectedResponseName
            }
          }),
          _vm._v(" "),
          _c("section", { staticClass: "buttons" }, [
            _c(
              "div",
              { staticClass: "add-content" },
              [
                _c("fab", {
                  staticClass: "purple-fab",
                  attrs: { disabled: _vm.editingItem, icon: "title" },
                  on: {
                    click: function($event) {
                      return _vm.addAndScroll("text")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "fab-desc" }, [_vm._v("Text")])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-content" },
              [
                _c("fab", {
                  staticClass: "theme-primary",
                  attrs: { disabled: _vm.editingItem, icon: "insert_photo" },
                  on: {
                    click: function($event) {
                      return _vm.addAndScroll("media")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "fab-desc" }, [_vm._v("Media")])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-content" },
              [
                _c("fab", {
                  staticClass: "theme-secondary",
                  attrs: { disabled: _vm.editingItem, icon: "link" },
                  on: {
                    click: function($event) {
                      return _vm.addAndScroll("link")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "fab-desc" }, [_vm._v("Web page")])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-content" },
              [
                _c("fab", {
                  staticClass: "orange-fab",
                  attrs: { disabled: _vm.editingItem, icon: "crop_square" },
                  on: {
                    click: function($event) {
                      return _vm.addAndScroll("buttons")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "fab-desc" }, [_vm._v("Buttons")])
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "content-items" }, [
        _c(
          "section",
          { staticClass: "content-row-wrap" },
          _vm._l(_vm.content, function(item, index) {
            return _c("message-form-field", {
              key: item.text,
              staticClass: "edit-row",
              attrs: {
                id: _vm.editingPosition === index ? "scroll-target" : "",
                content: _vm.content,
                cancelItem: _vm.cancelItem,
                deleteAllLinks: _vm.deleteAllLinks,
                editingPosition: _vm.editingPosition,
                finishEditingItem: _vm.finishEditingItem,
                item: item,
                deleteItem: _vm.deleteItem,
                editingItem: _vm.editingItem,
                links: _vm.links,
                lastLinkIndex: _vm.lastLinkIndex,
                firstLinkIndex: _vm.firstLinkIndex,
                position: index,
                startEditing: _vm.startEditing,
                saveItem: _vm.saveItem,
                swapItems: _vm.swapItems,
                moveLinksUp: _vm.moveLinksUp,
                moveLinksDown: _vm.moveLinksDown
              }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "edit-row" },
          [
            _vm.editingButtons || _vm.buttons.length
              ? _c("quick-reply-form-field", {
                  attrs: {
                    buttons: _vm.buttons,
                    id: _vm.editingButtons ? "scroll-target" : "",
                    editingButtons: _vm.editingButtons,
                    editingItem: _vm.editingItem,
                    deleteButtons: _vm.deleteButtons,
                    setButtons: _vm.setButtons,
                    setEditingButtons: _vm.setEditingButtons
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }