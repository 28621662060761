<template>
  <article class="message-editor full-height">
    <section class="top-menu">
      <btn
        v-if="selectedUser.stateViewable === 'pending'"
        class="resolve-btn"
        :disabled="submitting"
        @click="confirmResolve"
      >
        <span class="resolve-btn__text">Resolve</span>
      </btn>
      <btn
        v-if="selectedUser.stateViewable === 'pending'"
        class="activate-btn"
        :disabled="submitting"
        unelevated
        @click="activate"
      >
        <span class="activate-btn__text">Take Over Chat</span>
      </btn>
      <btn
        v-if="selectedUser.stateViewable === 'active'"
        class="deactivate-btn"
        :disabled="submitting"
        unelevated
        @click="deactivate"
      >
        <span class="deactivate-btn__text">Leave Chat</span>
      </btn>
    </section>
  </article>
</template>
<script>
import Btn from 'gameon-components/src/components/btn/btn.vue';
import { updateConversationState } from '../../../../api/human-handoff';
import { SNACK_TYPES } from '../../../../constants/snackbar';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  components: {
    Btn,
  },
  data() {
    return {
      submitting: false,
      resolveSuccessText: 'Conversation successfully resolved',
      activateSuccessText: `You've taken over the conversation`,
      deactivateSuccessText: `You've left the conversation`,
    };
  },
  computed: {
    ...mapState('humanHandoffConversation', ['selectedUser']),
    ...mapState('user', ['botData']),
  },
  methods: {
    ...mapMutations('humanHandoffConversation', ['setSelectedUser']),
    ...mapMutations('humanHandoffList', ['triggerListRefresh']),
    ...mapMutations('modal', ['showModal']),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapActions('humanHandoffConversation', ['handleCloseSideSheet']),

    copy(e) {
      const el = document.createElement('textarea');
      el.value = this.selectedUser.id;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    confirmResolve() {
      // eslint-disable-next-line
      const text =
        'Once resolved you won’t be able to message the user until they request Human Handoff again. Are you sure you want to resolve this conversation?';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        actionButton: 'Resolve',
        pendingAction: () => this.resolve(),
      });
    },
    async resolve() {
      this.submitting = true;
      try {
        await updateConversationState(
          this.botData.key,
          this.selectedUser.id,
          ''
        );
        this.triggerListRefresh();
        this.handleCloseSideSheet();
        const delay = 1000;
        setTimeout(() => {
          this.openSnack({
            type: SNACK_TYPES.SUCCESS,
            text: this.resolveSuccessText,
          });
        }, delay);
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      } finally {
        this.submitting = false;
      }
    },
    async activate() {
      this.submitting = true;
      try {
        await updateConversationState(
          this.botData.key,
          this.selectedUser.id,
          'active'
        );
        this.setSelectedUser({
          ...this.selectedUser,
          stateViewable: 'active',
        });
        this.triggerListRefresh();
        const delay = 1000;
        setTimeout(() => {
          this.openSnack({
            type: SNACK_TYPES.SUCCESS,
            text: this.activateSuccessText,
          });
        }, delay);
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      } finally {
        this.submitting = false;
      }
    },
    async deactivate() {
      this.submitting = true;
      try {
        await updateConversationState(
          this.botData.key,
          this.selectedUser.id,
          'pending'
        );
        this.setSelectedUser({
          ...this.selectedUser,
          stateViewable: 'pending',
        });
        this.triggerListRefresh();
        const delay = 1000;
        setTimeout(() => {
          this.openSnack({
            type: SNACK_TYPES.SUCCESS,
            text: this.deactivateSuccessText,
          });
        }, delay);
      } catch (e) {
        // eslint-disable-next-line
        console.log('error', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './chat-ui-info.scss';
</style>
