var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "questions" },
    [
      _c(
        "text-form-field",
        _vm._b(
          {
            ref: "mainQuestion",
            staticClass: "questions__main",
            attrs: {
              editingSelf: 0 === _vm.editingQuestionPos,
              editingItem: _vm.editingItem,
              initialText: _vm.questions[0].text,
              item: _vm.questions[0],
              header: "Question",
              label: "Text",
              cancelItem: _vm.cancelQuestion,
              activeUpArrow: false,
              activeDownArrow: false,
              handleDel: function() {},
              swapItems: function() {},
              position: 0,
              saveItem: _vm.setQuestion,
              startEditing: function() {
                return _vm.setEditingQuestionPos(0)
              }
            }
          },
          "text-form-field",
          _vm.$props,
          false
        )
      ),
      _vm._v(" "),
      _c("section", { staticClass: "buttons" }, [
        _c(
          "div",
          { staticClass: "add-content" },
          [
            _c("fab", {
              staticClass: "purple-fab",
              attrs: { disabled: _vm.editingItem, icon: "P" },
              on: {
                click: function($event) {
                  return _vm.addAndScroll("text")
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "fab-desc" }, [_vm._v("Phrase")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.phrases, function(phrase, index) {
        return _c(
          "text-form-field",
          _vm._b(
            {
              key: phrase.text + index,
              attrs: {
                id: _vm.determineScrollTarget(index),
                item: phrase,
                editingSelf: index + 1 === _vm.editingQuestionPos,
                editingItem: _vm.editingItem,
                initialText: phrase.text,
                header: "PHRASING",
                label: "Text",
                cancelItem: _vm.cancelQuestion,
                activeUpArrow: _vm.activeUpArrow(index),
                activeDownArrow: _vm.activeDownArrow(index),
                handleDel: _vm.deleteQuestion,
                swapItems: _vm.swapPhrases,
                position: index + 1,
                saveItem: _vm.setQuestion,
                startEditing: function() {
                  return _vm.setEditingQuestionPos(index + 1)
                }
              }
            },
            "text-form-field",
            _vm.$props,
            false
          )
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }