<template>
  <m-list class="full-width more-options">
    <m-list-item class="full-width option" @click="confirmDel">
      Delete
    </m-list-item>
  </m-list>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import { SNACK_TYPES } from '../../../../../constants/snackbar';

import Vue from 'vue';
import List from 'material-components-vue/dist/list';
Vue.use(List);

export default {
  methods: {
    ...mapMutations('modal', ['showModal']),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('contentList', ['triggerListRefresh']),
    ...mapActions('contentForm', [
      'deleteInitialContent',
      'handleCloseSideSheet',
    ]),
    async confirmDel(e) {
      const text =
        'Are you sure you want to delete this content item? This cannot be undone.';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        modalHeader: 'Delete Content',
        actionButton: 'Delete',
        cancelButton: 'Cancel',
        pendingAction: () => this.handleDelete(),
      });
    },
    async handleDelete() {
      try {
        await this.deleteInitialContent();
        this.$nextTick(() => {
          const SUCCESS_TEXT = 'Content successfully deleted';
          this.openSnack({ type: SNACK_TYPES.SUCCESS, text: SUCCESS_TEXT });
          this.handleCloseSideSheet();
          this.triggerListRefresh();
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log('error deleting content', e);
        this.openSnack({ type: SNACK_TYPES.ERROR });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'material-components-vue/dist/list/styles';
.more-options {
  height: auto;
}
.option {
  cursor: default;

  &.active {
    cursor: pointer;
  }
}
</style>
