var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSaveCancel
    ? _c(
        "section",
        { staticClass: "btn-wrap" },
        [
          _c(
            "btn",
            {
              staticClass: "form-action save",
              attrs: { disabled: _vm.disableSave, unelevated: true },
              on: { click: _vm.handleUpdate }
            },
            [_vm._v("\n    " + _vm._s(_vm.primaryActionCopy) + "\n  ")]
          ),
          _vm._v(" "),
          _c(
            "btn",
            {
              staticClass: "form-action cancel",
              on: { click: _vm.handleCancel }
            },
            [_vm._v(" Cancel ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }