<template>
  <div class="list-view">
    <list-headers />
    <m-list v-if="currentPageItems.length" class="full-width">
      <div class="list-view__rows">
        <m-list-item
          v-for="user in currentPageItems"
          @click="setSelectedUser(user)"
          class="full-width"
          :key="user.id"
        >
          <div class="title">
            <fab-static type="disabled">
              <icon icon="person" />
            </fab-static>
            <div>{{ trimId(user.id) }}</div>
            <span class="file-type">
              {{ user.channelViewable }}
            </span>
          </div>
          <div class="status">
            <status-chip
              class="status-chip--paused"
              :status="user.stateViewable"
            />
          </div>
          <div class="edited">
            {{ user.lastReplyDateViewable }}
            <locale-time :time="user.lastReplyTimeViewable" />
          </div>
          <div class="created">
            {{ user.submittedDateViewable }}
            <locale-time :time="user.submittedTimeViewable" />
          </div>
        </m-list-item>
      </div>
      <div class="scroll-pseudo-padding" />
      <slot />
    </m-list>
    <empty-message
      v-else-if="!items.length && !waitingForData"
      message="The combination of search and sort filters yielded no results. Please try another."
    />
    <pagination
      :loadMoreData="loadMoreData"
      :reachedLastPage="reachedLastPage"
      :waitingForData="waitingForData"
      :rowCountOnCurrentPage="rowCountOnCurrentPage"
    />
  </div>
</template>
<script>
import EmptyMessage from '../../../components/empty-message/empty-message.vue';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import FabStatic from '../../../components/fab-static/fab-static.vue';
import List from 'material-components-vue/dist/list';
import ListHeaders from './list-headers.vue';
import LocaleTime from '../../../components/locale-time/locale-time.vue';
import Pagination from './pagination/pagination.vue';
import StatusChip from '../../../components/status-chip/status-chip.vue';

import { mapGetters, mapMutations, mapState } from 'vuex';

import Vue from 'vue';
Vue.use(List);

export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    loadMoreData: {
      required: true,
      type: Function,
    },
    waitingForData: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  components: {
    EmptyMessage,
    FabStatic,
    Icon,
    ListHeaders,
    LocaleTime,
    Pagination,
    StatusChip,
  },
  computed: {
    ...mapState('humanHandoffList', ['itemsPerLoad', 'currentListPage']),
    ...mapGetters('humanHandoffList', [
      'firstRowIndex',
      'lastRowIndex',
      'reachedLastPage',
    ]),
    currentPageItems() {
      if (!this.items.length) return [];
      const currentPage = this.items.slice(
        this.firstRowIndex,
        this.lastRowIndex + 1
      );
      return currentPage;
    },
    rowCountOnCurrentPage() {
      return this.currentPageItems.length;
    },
  },
  methods: {
    ...mapMutations('humanHandoffConversation', ['setSelectedUser']),
    trimId(str) {
      const MAX_LEN = 12;
      if (str.length <= MAX_LEN) {
        return str;
      }
      return str.slice(-MAX_LEN);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'material-components-vue/dist/list/styles';
@import '../../../components/list-view/list-view.scss';
@import './list-view.scss';
</style>
