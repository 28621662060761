<template>
  <div
    class="go-carousel"
    :class="{ 'go-carousel--no-scroll': showNoButtons }"
    ref="container"
    @scroll="updateCarouselProperties"
  >
    <transition name="fade">
      <section v-show="showLeftButton" class="icon-bg icon--left" @click="left">
        <icon class="arrow" icon="keyboard_arrow_left" />
      </section>
    </transition>
    <slot></slot>
    <transition name="fade">
      <section
        v-show="showRightButton"
        class="icon-bg icon--right"
        @click="right"
      >
        <icon class="arrow" icon="keyboard_arrow_right" />
      </section>
    </transition>
  </div>
</template>

<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      scrollLeft: 0,
      totalScrollWidth: 0,
    };
  },
  computed: {
    showLeftButton() {
      try {
        return 0 < this.scrollLeft;
      } catch (e) {
        return false;
      }
    },
    showRightButton() {
      try {
        const prettyMuchTheEnd = 4;
        const containerWidth = 360;
        const endOfScroll =
          this.totalScrollWidth - this.scrollLeft - containerWidth <
          prettyMuchTheEnd;
        const minScrollWidth = 310;
        return this.totalScrollWidth > minScrollWidth && !endOfScroll;
      } catch (e) {
        return false;
      }
    },
    showNoButtons() {
      return !this.showLeftButton && !this.showRightButton;
    },
  },
  methods: {
    left() {
      this.$refs.container.scrollLeft -= 238;
      this.updateCarouselProperties();
    },
    right() {
      this.$refs.container.scrollLeft += 238;
      this.updateCarouselProperties();
    },
    updateCarouselProperties() {
      this.$nextTick(() => {
        if (this.$refs.container) {
          this.scrollLeft = this.$refs.container.scrollLeft;
          this.totalScrollWidth = this.$refs.container.scrollWidth;
        }
      });
    },
  },
  mounted() {
    this.updateCarouselProperties();
  },
};
</script>

<style lang="scss" scoped>
.go-carousel {
  display: flex;
  scroll-behavior: smooth;
  white-space: nowrap;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow: hidden;
  -ms-overflow-style: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.icon-bg {
  position: absolute;
  z-index: 9;
  background-color: #f3f3f3;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: calc(50% - 24px);

  &:hover .arrow {
    color: $black-high-emphasis;
  }
}

.icon--left {
  left: 0;
}

.icon--right {
  right: 0;
}

.arrow {
  color: $black-inactive;
  font-size: 36px;
}
.fade-enter {
  transition-delay: 0.5s;
}
.fade-enter-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
