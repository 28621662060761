<template>
  <div class="list-headers full-width">
    <div
      @click="handleHeaderClick('displayText')"
      class="list-header list-header--name list-header--sortable"
    >
      NAME
      <icon
        v-if="orderBy.includes('displayText')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'displayText.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
    <div class="list-header list-header--status">STATUS</div>
    <div
      @click="handleHeaderClick('sendDate')"
      class="list-header list-header--next list-header--sortable"
    >
      {{ dateTitle }}
      <icon
        v-if="orderBy.includes('sendDate')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'sendDate.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
    <div
      @click="handleHeaderClick('createdAt')"
      class="list-header list-header--created list-header--sortable"
    >
      CREATED
      <icon
        v-if="orderBy.includes('createdAt')"
        icon="arrow_upward"
        class="sort-icon"
        :class="{ 'sort-icon--downward': orderBy === 'createdAt.desc' }"
      />
      <icon v-else icon="arrow_upward" class="sort-icon sort-icon--hover" />
    </div>
  </div>
</template>
<script>
import Icon from 'gameon-components/src/components/icon/icon.vue';
import { mapMutations, mapState } from 'vuex';

import {
  ORDER_BY_VALUES,
  FILTER_BY_VALUES,
} from '../../../../constants/broadcasts';

export default {
  components: {
    Icon,
  },
  computed: {
    ...mapState('broadcastPage', ['orderBy', 'filterBy']),
    dateTitle() {
      return this.filterBy === FILTER_BY_VALUES.SCHEDULED
        ? 'SCHEDULED'
        : 'LAST SENT';
    },
  },
  methods: {
    ...mapMutations('broadcastPage', ['setOrderBy']),
    handleHeaderClick(updateOrderBy) {
      const prevOrderBy = this.orderBy;
      switch (updateOrderBy) {
        case 'sendDate':
          if (ORDER_BY_VALUES.SEND_DATE_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.SEND_DATE_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.SEND_DATE_ASC);
          }
          break;
        case 'updatedAt':
          if (ORDER_BY_VALUES.UPDATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.UPDATED_AT_ASC);
          }
          break;
        case 'createdAt':
          if (ORDER_BY_VALUES.CREATED_AT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.CREATED_AT_ASC);
          }
          break;
        case 'displayText':
          if (ORDER_BY_VALUES.DISPLAY_TEXT_ASC === prevOrderBy) {
            this.setOrderBy(ORDER_BY_VALUES.DISPLAY_TEXT_DESC);
          } else {
            this.setOrderBy(ORDER_BY_VALUES.DISPLAY_TEXT_ASC);
          }
          break;
        default:
          // eslint-disable-next-line
          console.log('invalid column order value');
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../components/list-headers/list-headers.scss';

.list-header--name {
  width: 30%;
}
.list-header--status {
  width: 17%;
  position: relative;
  left: -26px;
}
.list-header--next {
  width: 18%;
  color: $black-high-emphasis;
  position: relative;
  left: -51px;
}
.list-header--created,
.list-header--edited {
  width: 15%;
  color: $black-high-emphasis;
}
.list-header--edited {
  position: relative;
  left: -54px;
}
.list-header--created {
  position: relative;
  left: -60px;
}
</style>
