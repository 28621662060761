import { getField, updateField } from 'vuex-map-fields';
import linkBuilder from './link-builder/link-builder';

export default {
  namespaced: true,
  state: {
    itemData: {},
    prevItem: {},
    ...linkBuilder.state,
  },
  getters: {
    ...linkBuilder.getters,
    getField,
  },
  mutations: {
    ...linkBuilder.mutations,
    updateField,
    setPrevItem: (state, updatedData) => {
      state.prevItem = updatedData;
    },
    setItemData: (state, updatedData) => {
      state.itemData = updatedData;
    },
  },
  actions: {
    ...linkBuilder.actions,
  },
};
