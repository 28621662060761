var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "preview" },
    [
      _c(
        "div",
        { staticClass: "message-wrap" },
        [
          _vm._l(_vm.content, function(msgComponent, index) {
            return _c(
              "section",
              { key: index, staticClass: "bubble" },
              [
                _vm.isValidText(msgComponent)
                  ? _c("text-preview", { attrs: { text: msgComponent.text } })
                  : _vm.containsValidLink(msgComponent) &&
                    index === _vm.firstLinkIndex
                  ? _c("links-preview", {
                      key: _vm.linkChangeDetection,
                      attrs: {
                        links: _vm.links,
                        linksHorizontal: _vm.linksHorizontal,
                        position: index
                      }
                    })
                  : _vm.isValidMedia(msgComponent)
                  ? _c("media-preview", {
                      attrs: {
                        link: msgComponent.link,
                        thumbnail: msgComponent.thumbnail,
                        type: msgComponent.type
                      }
                    })
                  : msgComponent.buttons
                  ? _c("buttons-preview", {
                      attrs: {
                        buttons: msgComponent.buttons,
                        buttonsHorizontal: _vm.buttonsHorizontal
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.buttons.length
            ? _c("buttons-preview", {
                attrs: {
                  buttons: _vm.buttons,
                  buttonsHorizontal: _vm.buttonsHorizontal
                }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("toggle-layout", {
        attrs: {
          buttonsHorizontal: _vm.buttonsHorizontal,
          buttonsPresent: _vm.buttonsPresent,
          linksHorizontal: _vm.linksHorizontal,
          linksPresent: !!_vm.links.length,
          toggleButtonsPreview: _vm.toggleButtonsPreview,
          toggleLinksPreview: _vm.toggleLinksPreview
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }