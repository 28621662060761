<template>
  <section class="inline-metrics">
    <div class="inline-metrics__metric">
      <div class="inline-metrics__metric__title">Audience</div>
      <div class="inline-metrics__metric__stat">
        {{ formattedNum(latestMetric.count) }}
      </div>
    </div>
    <div class="inline-metrics__metric">
      <div class="inline-metrics__metric__title">Reach</div>
      <div class="inline-metrics__metric__stat">
        {{ formattedNum(successCount) }}
      </div>
      <div class="inline-metrics__metric__more-stats">
        {{ reachPercentage }}% of {{ formattedNum(latestMetric.count) }}
      </div>
    </div>
    <div
      class="inline-metrics__metric"
      :class="{ hidden: !latestMetric.linkSent }"
    >
      <div class="inline-metrics__metric__title">CTR</div>
      <div class="inline-metrics__metric__stat">{{ clickThroughRate }}%</div>
      <div class="inline-metrics__metric__more-stats">
        {{ formattedNum(correctedClicksNumerator) || 0 }} of
        {{ formattedNum(successCount) }}
      </div>
    </div>
  </section>
</template>
<script>
import { numberWithCommas } from '../../../../../helpers/conversions/numbers/numbers';

export default {
  props: {
    metrics: {
      required: true,
      type: Array,
    },
  },
  computed: {
    correctedClicksNumerator() {
      return Number(this.latestMetric.linkClick) || 0;
    },
    clickThroughRate() {
      if (!this.correctedClicksNumerator) return 0;
      const roundOff = 100;
      const percentage = 100;
      const clickPercentage =
        (this.correctedClicksNumerator / this.latestMetric.successCount) *
        percentage;
      return Math.round(roundOff * clickPercentage) / roundOff;
    },
    reachPercentage() {
      if (!this.successCount) return 0;
      const percentage = 100;
      const reachPercentage =
        (this.successCount / this.latestMetric.count) * percentage;
      return Math.round(reachPercentage);
    },
    latestMetric() {
      return this.metrics[0];
    },
    successCount() {
      return Number(this.latestMetric.successCount) || 0;
    },
  },
  methods: {
    formattedNum(num) {
      return numberWithCommas(num);
    },
  },
};
</script>
<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
.inline-metrics {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  &__metric {
    &:nth-child(odd) .inline-metrics__metric__title {
      opacity: 0.6;
    }
    &:nth-child(odd) .inline-metrics__metric__stat {
      opacity: 0.87;
      color: rgba(0, 0, 0, 0.87);
    }
    &:nth-child(even) .inline-metrics__metric__stat {
      font-weight: 500;
    }
    &__title {
      font-size: 14px;
      line-height: 24px;
    }
    &__stat {
      font-size: 18px;
      line-height: 140%;
      margin-top: 4px;
      margin-bottom: 8px;
    }
    &__more-stats {
      font-size: 12px;
      line-height: 16px;
      opacity: 0.38;
    }
  }
}
</style>
