<template>
  <div class="single-button">{{ text }}</div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.single-button {
  padding: 8px 16px;
  margin-left: 2px;
  border-radius: $border-radius-chat-render;
  border-style: solid;
  border-width: 1px;
  border-color: $color-chat-render-quick-reply-border;
  color: $color-chat-render-text;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
</style>
