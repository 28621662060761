<template>
  <div class="list-filters full-width">
    <search-filter
      :handleSearchInput="handleSearchInput"
      placeholder="placeholder"
    />
    <icon class="search-icon" icon="search" />
    <checklist-dropdown
      v-if="showCampaignsFilter && campaignsOptions && campaignsOptions.length"
      class="campaign-dropdown list-filter"
      :options="campaignsOptions"
      :handle-choice="setSelectedCampaigns"
      title="Campaigns"
    />
    <single-select
      class="filter-by list-filter"
      :handle-choice="setFilterBy"
      :options="filterByOptions"
      :upstream-val="filterBy"
    />
  </div>
</template>
<script>
import ChecklistDropdown from 'gameon-components/src/components/checklist-dropdown/checklist-dropdown.vue';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import SingleSelect from 'gameon-components/src/components/single-select/single-select.vue';
import SearchFilter from '../../../components/search-filter/search-filter.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { FILTER_BY_VALUES } from '../../../constants/broadcasts';

export default {
  components: {
    ChecklistDropdown,
    Icon,
    SingleSelect,
    SearchFilter,
  },
  computed: {
    ...mapState('broadcastPage', [
      'filterBy',
      'filterByOptions',
      'orderBy',
      'orderByOptions',
      'selectedCampaigns',
    ]),
    ...mapGetters('broadcastPage', ['campaignsOptions', 'selectedCampaignIds']),
    showCampaignsFilter() {
      if (
        this.filterBy === FILTER_BY_VALUES.FINISHED ||
        this.filterBy === FILTER_BY_VALUES.SCHEDULED
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations('broadcastPage', ['setFilterBy', 'setSelectedCampaigns']),
    ...mapActions('broadcastPage', ['handleSearchInput']),
  },
};
</script>
<style lang="scss" scoped>
@import '../../../components/list-filters/list-filters.scss';

.list-filter {
  margin-left: 16px;
}
.filter-by {
  margin-right: 12px;
  top: 5px;
}
.campaign-dropdown {
  top: 6px;
}

// Temp styles until search bar is implemented on backend
.search-filter {
  visibility: hidden;
}
.list-filters {
  margin-left: -512px;
}
</style>
