var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "links-editor",
      class: {
        "link-hover": !_vm.editingItem && _vm.hover,
        "editing-link": _vm.editingSelf
      },
      attrs: {
        id: _vm.editingPosition === _vm.position ? "scroll-target" : ""
      },
      on: {
        mouseover: function($event) {
          $event.stopPropagation()
          return _vm.handleChildHover($event)
        },
        mouseleave: _vm.handleChildHoverStop
      }
    },
    [
      _c(
        "section",
        { staticClass: "hover-bg" },
        [
          _c("div", { staticClass: "overline overline--subheader" }, [
            _vm._v("ARTICLE " + _vm._s(_vm.position + 1))
          ]),
          _vm._v(" "),
          _c("hover-buttons", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.editingItem && _vm.hover,
                expression: "!editingItem && hover"
              }
            ],
            staticClass: "child-hover-buttons",
            attrs: {
              activeUpArrow: _vm.activeUpArrow,
              activeDownArrow: _vm.activeDownArrow,
              handleDel: function() {
                return _vm.confirmDel(_vm.position)
              },
              onSwapUp: function() {
                return _vm.swapItems({
                  ind: _vm.position,
                  ind2: _vm.position - 1
                })
              },
              onSwapDown: function() {
                return _vm.swapItems({
                  ind: _vm.position,
                  ind2: _vm.position + 1
                })
              },
              startEditing: function() {
                return _vm.startEditing(_vm.position)
              }
            }
          }),
          _vm._v(" "),
          _c("text-field", {
            staticClass: "link-field",
            attrs: {
              disabled: !_vm.editingSelf,
              helper: "Paste or type link URL",
              label: "Link URL",
              valid: _vm.validLink,
              value: _vm.link,
              filled: ""
            },
            on: {
              "update:value": function($event) {
                _vm.link = $event
              }
            }
          }),
          _vm._v(" "),
          _c("linear-progress", {
            attrs: { open: "loading" === _vm.formState }
          }),
          _vm._v(" "),
          "linkChecked" === _vm.formState && _vm.editingSelf
            ? _c("text-field", {
                staticClass: "link-title",
                attrs: {
                  disabled: !_vm.editingSelf,
                  helper: "Customize link title",
                  label: "Title",
                  textarea: true,
                  valid: _vm.validTitle,
                  value: _vm.linkTitle,
                  filled: ""
                },
                on: {
                  "update:value": function($event) {
                    _vm.linkTitle = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editingSelf && !!("linkChecked" === _vm.formState)
            ? _c("text-field", {
                staticClass: "link-image",
                attrs: {
                  disabled: !_vm.editingSelf,
                  helper: "Customize link image",
                  label: "Image URL",
                  valid: _vm.validImage,
                  value: _vm.linkImage,
                  filled: ""
                },
                on: {
                  "update:value": function($event) {
                    _vm.linkImage = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editingSelf && !!("linkChecked" === _vm.formState)
            ? _c("text-field", {
                staticClass: "link-button",
                attrs: {
                  helper: "Customize link button",
                  label: "Button Label",
                  textarea: true,
                  valid: _vm.validButton,
                  value: _vm.readMoreButtonText,
                  filled: ""
                },
                on: {
                  "update:value": function($event) {
                    _vm.readMoreButtonText = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      "linkChecked" !== _vm.formState
        ? _c("save-cancel", {
            attrs: {
              "disable-save": !_vm.allowSave,
              "handle-cancel": _vm.handleCancel,
              "handle-update": _vm.processUrl,
              "old-val": _vm.oldLink,
              "show-save-cancel": _vm.editingSelf,
              primaryActionCopy: _vm.oldLink ? "Update" : "Add"
            }
          })
        : _c("save-cancel", {
            attrs: {
              "disable-save": !_vm.allowSave,
              "handle-cancel": _vm.handleCancel,
              "handle-update": _vm.checkMetaData,
              "old-val": _vm.oldLink,
              "show-save-cancel": _vm.editingSelf,
              primaryActionCopy: _vm.oldLink ? "Update" : "Add"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }