var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "links-preview",
      class: { "links-preview--vertical": !_vm.linksHorizontal }
    },
    [
      _vm.linksHorizontal
        ? _c(
            "carousel",
            {
              key: _vm.carouselChangeDetection,
              attrs: { onPlus: function() {} }
            },
            _vm._l(_vm.links, function(link) {
              return _c("link-slide", {
                key: link.link,
                attrs: {
                  link: link.link,
                  thumbnail: link.thumbnail,
                  title: link.title,
                  readMoreButtonText: link.readMoreButtonText
                }
              })
            }),
            1
          )
        : _vm._l(_vm.links, function(link) {
            return _c("link-slide", {
              key: _vm.getLinkChangeDetection(link),
              attrs: {
                link: link.link,
                thumbnail: link.thumbnail,
                title: link.title,
                readMoreButtonText: link.readMoreButtonText
              }
            })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }