<template>
  <section class="preview">
    <div class="message-wrap">
      <section
        class="bubble"
        v-for="(msgComponent, index) in content"
        :key="index"
      >
        <text-preview
          v-if="isValidText(msgComponent)"
          :text="msgComponent.text"
        />
        <links-preview
          v-else-if="
            containsValidLink(msgComponent) && index === firstLinkIndex
          "
          :key="linkChangeDetection"
          :links="links"
          :linksHorizontal="linksHorizontal"
          :position="index"
        />
        <media-preview
          v-else-if="isValidMedia(msgComponent)"
          :link="msgComponent.link"
          :thumbnail="msgComponent.thumbnail"
          :type="msgComponent.type"
        />
        <buttons-preview
          v-else-if="msgComponent.buttons"
          :buttons="msgComponent.buttons"
          :buttonsHorizontal="buttonsHorizontal"
        />
      </section>
      <buttons-preview
        v-if="buttons.length"
        :buttons="buttons"
        :buttonsHorizontal="buttonsHorizontal"
      />
    </div>
    <toggle-layout
      :buttonsHorizontal="buttonsHorizontal"
      :buttonsPresent="buttonsPresent"
      :linksHorizontal="linksHorizontal"
      :linksPresent="!!links.length"
      :toggleButtonsPreview="toggleButtonsPreview"
      :toggleLinksPreview="toggleLinksPreview"
    />
  </section>
</template>
<script>
import ButtonsPreview from './message-components/buttons-preview/buttons-preview.vue';
import LinksPreview from './message-components/links-preview/links-preview.vue';
import MediaPreview from './message-components/media-preview/media-preview.vue';
import TextPreview from './message-components/text-preview/text-preview.vue';
import ToggleLayout from './toggle-layout/toggle-layout.vue';

const MESSAGE_COMPONENT_TYPES = {
  TEXT: 'text',
  LINKS: 'links',
  MEDIA: 'media',
  BUTTONS: 'buttons',
};
export default {
  components: {
    ButtonsPreview,
    LinksPreview,
    MediaPreview,
    TextPreview,
    ToggleLayout,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
    buttonsHorizontal: {
      required: true,
      type: Boolean,
    },
    firstLinkIndex: {
      required: true,
      type: Number,
    },
    links: {
      required: true,
      type: Array,
    },
    linksHorizontal: {
      required: true,
      type: Boolean,
    },
    toggleButtonsPreview: {
      required: true,
      type: Function,
    },
    toggleLinksPreview: {
      required: true,
      type: Function,
    },
  },
  computed: {
    linkChangeDetection() {
      if (this.links.length) {
        return this.links
          .map((link) => `${link.link}${link.thumbnail}${link.title}`)
          .join('');
      } else {
        return null;
      }
    },
    buttonsPresent() {
      // form buttons
      if (this.buttons && !!this.buttons.length) return true;

      // read-only buttons in content
      for (let i = 0; i < this.content.length; i = i + 1) {
        if (this.content[i].mode === MESSAGE_COMPONENT_TYPES.BUTTONS)
          return true;
      }

      return false;
    },
  },
  methods: {
    // for triggering refresh of carousel slots
    isValidText(msgComponent) {
      const textMode = MESSAGE_COMPONENT_TYPES.TEXT === msgComponent.mode;
      const valid = !!msgComponent.text;
      return textMode && valid;
    },
    containsValidLink(msgComponent) {
      return (
        !!msgComponent.link && !!msgComponent.thumbnail && !!msgComponent.title
      );
    },
    isValidMedia(msgComponent) {
      const mediaMode = MESSAGE_COMPONENT_TYPES.MEDIA === msgComponent.mode;
      const valid = !!msgComponent.link;
      return mediaMode && valid;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './message-components/bubble.scss';
@import './message-preview.scss';
</style>
