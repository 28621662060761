import { UNAUTHORIZED } from '../../../server/statuses';
import axios from 'axios';

const scope = { store: { commit: () => {} } };

const registerStore = (store) => {
  scope.store = store;
};

const wrapper = async (method, ...args) => {
  try {
    const transport = axios.create({ withCredentials: true });
    const res = transport[method](...args);
    return res;
  } catch (e) {
    if (e.response) {
      switch (e.response.status) {
        case UNAUTHORIZED:
          scope.store.commit('user/authFailed');
          break;
      }
    } else {
      scope.store.commit('pipeFailed');
    }
    throw e;
  }
};

const del = async (...args) => {
  return wrapper('delete', ...args);
};

const get = async (...args) => {
  return wrapper('get', ...args);
};

const post = async (...args) => {
  return wrapper('post', ...args);
};

const put = async (...args) => {
  return wrapper('put', ...args);
};

export { del, get, post, put, registerStore };
