import { PREVIEW_MODES } from '../../../../constants/messages';

export default {
  state: {
    buttons: [],
    editingButtons: false,
  },
  getters: {
    buttonStrings: (state) => {
      if (!state.buttons.length) return [];
      return state.buttons.map((btn) => btn.title);
    },
    buttonsHorizontal: (state) => {
      return PREVIEW_MODES.HORIZONTAL === state.previewConfig.buttons.mode;
    },
  },
  mutations: {
    setButtons: (state, buttons) => {
      state.buttons = buttons;
    },
    deleteButtons: (state) => {
      state.buttons = [];
      state.editingButtons = false;
    },
    deleteButton: (state, { x }) => {
      state.buttons = state.buttons.filter((btn, ind) => x !== ind);
    },
    setEditingButtons: (state, bool) => {
      state.editingButtons = bool;
    },
    toggleButtonsPreview: (state) => {
      if (PREVIEW_MODES.HORIZONTAL === state.previewConfig.buttons.mode) {
        state.previewConfig.buttons.mode = PREVIEW_MODES.VERTICAL;
      } else {
        state.previewConfig.buttons.mode = PREVIEW_MODES.HORIZONTAL;
      }
    },
  },
  actions: {
    editButtons({ commit, dispatch, state }) {
      commit('setEditingButtons', true);
    },
  },
};
