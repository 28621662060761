<template>
  <div class="list-view">
    <list-headers />
    <m-list class="full-width" v-if="items.length">
      <div class="list-view__rows">
        <broadcast-row
          class="item-wrap full-width"
          v-for="broadcast in items"
          :key="broadcast.broadcastId"
          :group="broadcast"
        />
      </div>
      <div class="scroll-pseudo-padding" />
      <slot />
    </m-list>
    <empty-message
      v-else-if="!items.length && !waitingForData"
      message="The combination of search and sort filters yielded no results. Please try another."
    />
    <pagination
      :waitingForData="waitingForData"
      :rowCountOnCurrentPage="items.length"
    />
  </div>
</template>
<script>
import BroadcastRow from './broadcast-row/broadcast-row.vue';
import EmptyMessage from '../../../components/empty-message/empty-message.vue';
import List from 'material-components-vue/dist/list';
import ListHeaders from './list-headers/list-headers.vue';
import Pagination from './pagination/pagination.vue';
import { mapGetters, mapState } from 'vuex';

import Vue from 'vue';
Vue.use(List);

export default {
  components: {
    EmptyMessage,
    ListHeaders,
    Pagination,
    BroadcastRow,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    waitingForData: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState('broadcastList', [
      'broadcastsPerLoad',
      'currentBroadcastListPage',
    ]),
    ...mapGetters('broadcastList', ['firstRowIndex', 'lastRowIndex']),
  },
};
</script>
<style lang="scss">
@import 'material-components-vue/dist/list/styles';
@import '../../../components/list-view/list-view.scss';
@import './list-view.scss';
</style>
