<template>
  <div class="list-view">
    <list-headers />
    <m-list v-if="currentPageItems.length" class="full-width">
      <div class="list-view__rows">
        <m-list-item
          v-for="questionAnswerGroup in currentPageItems"
          @click="setInitialPair(questionAnswerGroup)"
          class="full-width"
          :key="questionAnswerGroup.id"
        >
          <section class="questions">
            <div
              v-for="(question, index) in questionAnswerGroup.questions"
              :key="index"
              class="question"
            >
              {{ question }}
            </div>
          </section>
          <section class="answer">
            <div
              v-for="descriptor in getContentDescriptors(
                questionAnswerGroup.answerContent
              )"
              :key="descriptor"
              class="answer__content-types"
            >
              {{ `${descriptor}` }}
            </div>
          </section>
          <section class="tags">
            {{ tagsToString(questionAnswerGroup.tags) }}
          </section>
          <section class="edited">
            {{ getLastEdited(questionAnswerGroup.lastUpdated) }}
          </section>
          <section class="score">
            {{
              questionAnswerGroup.score
                ? truncateScore(questionAnswerGroup.score)
                : ''
            }}
          </section>
        </m-list-item>
      </div>
      <div class="scroll-pseudo-padding" />
      <slot />
    </m-list>
    <empty-message
      v-else-if="!items.length && !waitingForData"
      message="The combination of search and sort filters yielded no results. Please try another."
    />
    <pagination
      :loadMoreData="() => {}"
      :reachedLastPage="reachedLastPage"
      :waitingForData="waitingForData"
      :rowCountOnCurrentPage="rowCountOnCurrentPage"
    />
  </div>
</template>
<script>
import EmptyMessage from '../../../components/empty-message/empty-message.vue';
import List from 'material-components-vue/dist/list';
import ListHeaders from './list-headers.vue';
// import LocaleTime from '../../../components/locale-time/locale-time.vue'
import Pagination from './pagination/pagination.vue';
// import StatusChip from '../../../components/status-chip/status-chip.vue'

import { mapGetters, mapMutations, mapState } from 'vuex';
import { epochToListView } from '../../../helpers/conversions/time/time';
import { contentToForm } from '../../../helpers/content/content';
import { CONTENT_TYPES } from '../../../constants/content';

import Vue from 'vue';
Vue.use(List);

export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    loadMoreData: {
      required: true,
      type: Function,
    },
    waitingForData: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  components: {
    EmptyMessage,
    ListHeaders,
    // LocaleTime,
    Pagination,
    // StatusChip,
  },
  computed: {
    ...mapState('qnaList', ['itemsPerLoad', 'currentListPage', 'endOfList']),
    ...mapGetters('qnaList', [
      'firstRowIndex',
      'lastRowIndex',
      'reachedLastPage',
    ]),
    currentPageItems() {
      if (!this.items.length) return [];
      const currentPage = this.items.slice(
        this.firstRowIndex,
        this.lastRowIndex + 1
      );
      return currentPage;
    },
    rowCountOnCurrentPage() {
      return this.currentPageItems.length;
    },
  },
  methods: {
    ...mapMutations('qnaForm', ['setInitialPair']),
    tagsToString(tagsArr) {
      if (tagsArr.length) {
        return tagsArr[0];
      } else {
        return tagsArr.join(',');
      }
    },
    getLastEdited(lastUpdated) {
      return epochToListView(lastUpdated);
    },
    truncateScore(score) {
      const percentage = 100;
      const nearestWholePercentage = 0;
      return (percentage * score).toFixed(nearestWholePercentage);
    },
    getContentDescriptors(answerContent) {
      const contentFormData = contentToForm(answerContent);
      const messageBody = contentFormData.content;
      const buttonsPresent = contentFormData.buttons.length;
      const messageBodyDescriptors = messageBody.map((messageItem) => {
        if (messageItem.mode === CONTENT_TYPES.text) return messageItem.text;
        if (messageItem.mode === CONTENT_TYPES.media) return '<media>';
        if (messageItem.mode === CONTENT_TYPES.link) return '<web pages>';
      });

      if (buttonsPresent) {
        return [...new Set(messageBodyDescriptors), '<buttons>'];
      } else {
        return [...new Set(messageBodyDescriptors)];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'material-components-vue/dist/list/styles';
@import '../../../components/list-view/list-view.scss';
@import './list-view.scss';
</style>
