<template>
  <section class="message-content">
    <section
      v-for="(content, index) in messageContents"
      :key="index"
      class="message-content__content"
    >
      <text-preview
        v-if="content.mode === 'text'"
        :left="message.id"
        :text="content.text"
      />
      <links-preview
        v-else-if="content.mode === 'links'"
        :key="index"
        :links="content.links"
        :linksHorizontal="linksHorizontal"
      />
      <media-preview
        v-else-if="content.mode === 'media'"
        :link="content.link"
        :thumbnail="content.thumbnail"
        :type="content.type"
      />
      <buttons-preview
        v-else-if="content.mode === 'buttons'"
        :buttons="content.buttons"
        :buttonsHorizontal="buttonsHorizontal"
      />
    </section>
  </section>
</template>
<script>
import ButtonsPreview from './message-content/buttons-preview/buttons-preview.vue';
import LinksPreview from './message-content/links-preview/links-preview.vue';
import MediaPreview from './message-content/media-preview/media-preview.vue';
import TextPreview from './message-content/text-preview/text-preview.vue';
export default {
  components: {
    ButtonsPreview,
    LinksPreview,
    MediaPreview,
    TextPreview,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    buttonsHorizontal: {
      required: true,
      type: Boolean,
    },
    linksHorizontal: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    messageContents() {
      return this.message.content;
    },
  },
};
</script>
<style lang="scss" scoped>
.message-content__content {
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
