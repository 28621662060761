<template>
  <div class="full-width pagination">
    <icon
      v-if="currentListPage === 1"
      class="pagination__arrow pagination__arrow--disabled"
      icon="keyboard_arrow_left"
    />
    <icon-btn
      v-else
      class="pagination__arrow"
      icon="keyboard_arrow_left"
      @click="handlePrevClick"
    />
    <div class="pagination__range">{{ beginRange }} - {{ endRange }}</div>
    <icon
      v-if="reachedLastPage || waitingForData"
      class="pagination__arrow pagination__arrow--disabled"
      icon="keyboard_arrow_right"
    />
    <icon-btn
      v-else
      class="pagination__arrow"
      icon="keyboard_arrow_right"
      @click="handleNextClick"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import Icon from 'gameon-components/src/components/icon/icon.vue';
import IconBtn from 'gameon-components/src/components/icon-btn/icon-btn.vue';

export default {
  components: {
    Icon,
    IconBtn,
  },
  props: {
    loadMoreData: {
      required: true,
      type: Function,
    },
    rowCountOnCurrentPage: {
      type: Number,
      required: true,
    },
    reachedLastPage: {
      required: true,
      type: Boolean,
    },
    waitingForData: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('contentList', ['currentListPage', 'endOfList']),
    ...mapGetters('contentList', ['firstRowIndex', 'lastRowIndex']),
    beginRange() {
      return this.firstRowIndex + 1;
    },
    endRange() {
      return this.firstRowIndex + this.rowCountOnCurrentPage;
    },
  },
  methods: {
    ...mapMutations('contentList', ['nextPage', 'prevPage']),
    async handleNextClick() {
      if (!this.endOfList) await this.loadMoreData();
      this.nextPage();
    },
    async handlePrevClick() {
      this.prevPage();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -12px;
  padding-right: 80px;
  background-color: #fafafa;
}
.pagination__range {
  font-size: 14px;
  padding: 0 12px;
}
.pagination__arrow--disabled {
  opacity: 0.38;
  padding: 12px;
  position: relative;
  top: 1px;
}
</style>
