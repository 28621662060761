<!-- eslint-disable max-lines -->
<template>
  <div class="media-form-field__media-link">
    <text-field
      :label="'Media URL*'"
      :disabled="!editingSelf"
      :value.sync="mutableLink"
      @keyup="() => updateFormData('link')"
    />
    <text-field
      v-if="editingSelf"
      :label="'Thumbnail'"
      :value.sync="mutableThumbnail"
      @keyup="() => updateFormData('thumbnail')"
      filled
    />
    <text-field
      v-if="editingSelf"
      :label="'Title'"
      :textarea="true"
      :value.sync="mutableTitle"
      @keyup="() => updateFormData('title')"
      filled
    />
  </div>
</template>
<script>
import TextField from 'gameon-components/src/components/text-field/text-field.vue';

export default {
  components: {
    TextField,
  },
  props: {
    // content item
    link: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    thumbnail: {
      default: '',
      required: false,
      type: String,
    },

    editingSelf: {
      required: true,
      type: Boolean,
    },
    updateFormData: {
      default: () => {},
      required: false,
      type: Function,
    },
  },
  data() {
    return {
      mutableLink: this.link || '',
      mutableTitle: this.title || '',
      mutableThumbnail: this.thumbnail || '',
    };
  },
  watch: {
    link() {
      this.mutableLink = this.link;
    },
    title() {
      this.mutableTitle = this.title;
    },
    thumbnail() {
      this.mutableThumbnail = this.thumbnail;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './media-link.scss';
</style>
