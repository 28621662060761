<template>
  <section class="empty-msg full-width">
    <p class="empty-text">{{ message }}</p>
  </section>
</template>
<script>
export default {
  props: {
    message: {
      required: true,
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
// Empty list view
.empty-msg {
  height: calc(100vh - 160px);
  color: $black-inactive;
  font-size: 14px;
  padding-top: 152px;
  justify-content: center;
}
</style>
