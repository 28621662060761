var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "toggle-layout" }, [
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.buttonsPresent,
            expression: "buttonsPresent"
          }
        ],
        staticClass: "toggle-clickable toggle-button",
        on: { click: _vm.toggleButtonsPreview }
      },
      [
        _vm._v("\n    View buttons as\n    "),
        _c("icon", {
          staticClass: "icon",
          class: { "icon--vertical": !this.buttonsHorizontal },
          attrs: { icon: _vm.buttonsIcon }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.linksPresent,
            expression: "linksPresent"
          }
        ],
        staticClass: "toggle-clickable toggle-links",
        on: { click: _vm.toggleLinksPreview }
      },
      [
        _vm._v("\n    View attachments as\n    "),
        _c("icon", {
          staticClass: "icon",
          class: { "icon--vertical": !this.linksHorizontal },
          attrs: { icon: _vm.linksIcon }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }