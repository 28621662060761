var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.editingSelf
    ? _c(
        "div",
        { staticClass: "full-width uploader" },
        [
          _c("upload-size-modal", {
            attrs: { show: _vm.modal === "UPLOAD_SIZE" }
          }),
          _vm._v(" "),
          _c("error-modal", { attrs: { show: _vm.modal === "ERROR" } }),
          _vm._v(" "),
          _c("div", { staticClass: "full-width" }, [
            _c("div", { staticClass: "go-input--file browse" }, [
              _vm.editingSelf
                ? _c(
                    "label",
                    {
                      staticClass: "clickable-file-label",
                      attrs: { for: "file" }
                    },
                    [_c("upload-icon")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "file",
                  id: "file",
                  accept: "image/*,video/*",
                  disabled: _vm.uploading
                },
                on: { click: _vm.nullify, change: _vm.handleFileInput }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "bar" }),
              _vm._v(" "),
              _c("div", { staticClass: "media-wrap" }, [
                _c(
                  "div",
                  { staticClass: "surface media-content" },
                  [
                    _vm.showEmptyState
                      ? _c("add-photo-alternate-icon")
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showEmptyState
                      ? _c("div", { staticClass: "tip" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.mediaTip) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("linear-progress", {
                      staticClass: "upload-progress",
                      attrs: {
                        indeterminate: false,
                        open: _vm.uploading,
                        progress: _vm.viewableProgress
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "media-clickable":
                        _vm.itemThumbnail || _vm.initialThumbnail
                    },
                    on: { click: _vm.openAsset }
                  },
                  [
                    _vm.editingSelf && !_vm.uploading
                      ? _c("media-content", {
                          staticClass: "media-content--uploader",
                          attrs: {
                            src: _vm.itemThumbnail || _vm.initialThumbnail,
                            "allow-preview": true,
                            video: "video" === _vm.fileType
                          },
                          on: { previewLoaded: _vm.handlePreviewLoaded }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }