var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "buttons-preview",
      class: { "buttons-preview--vertical": !_vm.buttonsHorizontal }
    },
    [
      _vm.buttonsHorizontal
        ? _c(
            "carousel",
            { attrs: { buttons: _vm.buttons } },
            _vm._l(_vm.buttons, function(button) {
              return _c("single-button", {
                key: button.title,
                staticClass: "button",
                attrs: { text: button.title }
              })
            }),
            1
          )
        : _vm._l(_vm.buttons, function(button) {
            return _c("single-button", {
              key: button.title,
              staticClass: "single-button--vertical",
              attrs: { text: button.title }
            })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }