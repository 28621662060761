<!-- eslint-disable max-lines -->
<template>
  <article
    class="media-form-field"
    :class="{
      'media-form-field--editing': editingSelf,
    }"
  >
    <div class="bg-wrap" :class="{ 'hover-bg': !editingItem }">
      <hover-buttons
        v-if="!editingItem"
        :activeUpArrow="activeUpArrow"
        :activeDownArrow="activeDownArrow"
        :handleDel="confirmDel"
        :startEditing="() => startEditing(position)"
        :onSwapUp="() => swapItems({ ind: position, ind2: position - 1 })"
        :onSwapDown="() => swapItems({ ind: position, ind2: position + 1 })"
      />
      <div class="overline">Media</div>
      <media-link
        v-if="mediaMode === mediaModes.link || initialItem.link"
        :title.sync="formData.title"
        :link.sync="formData.link"
        :thumbnail.sync="formData.thumbnail"
        :type="formData.type"
        :editingSelf="editingSelf"
        :updateFormData="updateFormData"
      />
      <media-upload v-else :updateFormData="updateFormData" />
    </div>
    <save-cancel
      :disable-save="!allowSave"
      :handle-cancel="cancelUpload"
      :handle-update="handleUpdate"
      :old-val="initialItem"
      :show-save-cancel="editingSelf"
      :primaryActionCopy="initialItem.link ? 'Update' : 'Add'"
    />
  </article>
</template>
<script>
import HoverButtons from '../../hover-buttons/hover-buttons.vue';
import MediaLink from './media-link/media-link.vue';
import MediaUpload from './media-upload/media-upload.vue';
import SaveCancel from '../../../save-cancel/save-cancel.vue';

import { FILTER_BY_OPTIONS } from '../../../../state/content/constants';
// import { validateByFilter, filterMediaListBySize } from '../../../../state/content/helpers'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { SNACK_TYPES, SNACK_CLASSES } from '../../../../constants/snackbar';

export default {
  components: {
    HoverButtons,
    MediaLink,
    MediaUpload,
    SaveCancel,
  },
  data() {
    const excludeTagFilters = 4;
    const mediaModes = {
      link: 'link',
      upload: 'upload',
      library: 'library',
    };
    return {
      endOfList: false,
      formData: {
        link: this.link || '',
        title: this.title || '',
        thumbnail: this.thumbnail || '',
        type: this.type || '',
      },
      filterBy: FILTER_BY_OPTIONS[0].value,
      filterByOptions: FILTER_BY_OPTIONS.slice(0, excludeTagFilters),
      mediaMode: mediaModes.upload,
      mediaModes,
      preloadSelected: '',
      previewLoaded: false,
      preloadedContent: [],
      uploaded: false,
      waitingForData: false,
    };
  },
  props: {
    // message item info
    link: {
      required: true,
      type: String,
    },
    position: {
      required: true,
      type: Number,
    },
    title: {
      default: '',
      required: false,
      type: String,
    },
    thumbnail: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },

    // hover buttons
    activeDownArrow: {
      type: Boolean,
      required: true,
    },
    activeUpArrow: {
      type: Boolean,
      required: true,
    },
    handleDel: {
      type: Function,
      required: true,
    },
    editingSelf: {
      required: true,
      type: Boolean,
    },
    editingItem: {
      required: true,
      type: Boolean,
    },
    swapItems: {
      required: true,
      type: Function,
    },
    startEditing: {
      required: true,
      type: Function,
    },

    // form buttons
    cancelItem: {
      required: true,
      type: Function,
    },
    finishEditingItem: {
      required: true,
      type: Function,
    },
    saveItem: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters('uploader', ['uploading', 'file']),
    ...mapState('uploader', ['file']),
    ...mapState('content', ['botTagOptions', 'contentBotsMeta']),
    allowSave() {
      // return this.updateAvailable && this.previewLoaded && !this.uploading
      return this.formData.link && this.changesMade;
    },
    bot() {
      return this.contentBotsMeta[0].displayName;
    },
    changesMade() {
      if (this.initialItem.link !== this.formData.link) return true;
      if (this.initialItem.title !== this.formData.title) return true;
      if (this.initialItem.thumbnail !== this.formData.thumbnail) return true;
      return false;
    },
    initialItem() {
      return {
        thumbnail: this.thumbnail || '',
        type: this.type,
        link: this.link || '',
        title: this.title || '',
      };
    },
    thumbnailOptions() {
      const MAX_THUMB_OPTIONS = 5;
      const thumbnailOptions = [];
      if (
        this.formData &&
        this.formData.thumbnail &&
        this.formData.type.includes('video')
      ) {
        for (let i = 0; i < MAX_THUMB_OPTIONS; i = i + 1) {
          const nextThumb = this.formData.thumbnail.replace(
            /(?:thumbnail\/)([0-9]+)/,
            `thumbnail/${i}`
          );
          thumbnailOptions.push(nextThumb);
        }
      }
      return thumbnailOptions;
    },
    // preloaded content
    // filteredContentOptions() {
    //   const CONTENT_OPTIONS = 6
    //   const selectedFilter = validateByFilter(this.filterBy)
    //   const filteredContent = this.preloadedContent.filter(contentItem => selectedFilter(contentItem))
    //   const sizeLimited = filterMediaListBySize(filteredContent)
    //   return sizeLimited.slice(0, CONTENT_OPTIONS)
    // },
    // showPreloadedContent() {
    //   const previousContent = !!(this.editingSelf && this.preloadedContent.length)
    //   return previousContent && !this.uploading && !this.uploaded
    // },
  },
  methods: {
    ...mapActions('uploader', ['handleCancel']),
    ...mapMutations('snackbar', ['openSnack']),
    ...mapMutations('uploader', ['clearStates', 'clearPreviousItem']),
    ...mapMutations('modal', ['showModal']),
    confirmDel() {
      const text = 'Are you sure you want to delete the selected text?';
      this.showModal({
        modalType: 'CONFIRM',
        modalText: text,
        actionButton: 'Delete',
        pendingAction: () => this.handleDel(this.position),
      });
    },
    updateFormData(property, val) {
      this.formData[property] = val || event.target.value;
    },

    // Uploader methods
    handleUpdate() {
      const getTypeFromLink = (url) => {
        if (url.includes('.gif')) {
          return 'image';
        } else if (url.includes('.mp4') || url.includes('.mov')) {
          return 'video';
        } else {
          return 'image';
        }
      };

      this.previewLoaded = false;
      const data = {
        link: this.formData.link,
        title: this.formData.title,
        type: this.formData.type || getTypeFromLink(this.formData.link),
        thumbnail: this.formData.thumbnail,
        mode: 'media',
      };
      this.saveItem({
        data,
        position: this.position,
      });
      this.finishEditingItem();
      const text = 'All updates saved';
      this.openSnack({
        customClass: SNACK_CLASSES.CUSTOM_SIDESHEET,
        type: SNACK_TYPES.SUCCESS,
        text,
      });
      this.clearPreviousItem();
      this.clearStates();
    },
    cancelUpload() {
      this.clearPreviousItem();
      this.formData = { ...this.initialItem };
      this.handleCancel();
      this.cancelItem();
    },
    handleThumbnailSelect(selectedUrl) {
      this.formData.thumbnail = selectedUrl;
    },
    async uploaderUpdatedItem(data) {
      this.uploaded = true;
      this.formData = { ...this.formData, ...data };
      if (!this.initialItem.link && this.botTagOptionsPresent) {
        this.addUploadToPreload(data);
        this.clearStates();
      }
    },

    // preloaded content
    // addUploadToPreload(data) {
    //   const unique = 0 === this.preloadedContent.filter(c => c.body.link === data.link).length
    //   if (unique) {
    //     const preloadData = {
    //       source: 'recentUpload',
    //       body: {
    //         mimeType: data.type,
    //         link: data.link,
    //         thumbnail: data.thumbnail,
    //       },
    //     }
    //     const preloadDataUpdated = [...this.preloadedContent]
    //     preloadDataUpdated.unshift(preloadData)
    //     this.preloadedContent = preloadDataUpdated
    //   }
    // },
    // async getPreloadedContent() {
    //   const CONTENT_OPTIONS = 6
    //   const RECURSIVE = true
    //   const nextContent = await getContent(
    //     this.contentBotsMeta[0].publishId,
    //     CONTENT_OPTIONS,
    //     RECURSIVE,
    //     this.preloadedContent.length,
    //   )
    //   if (CONTENT_OPTIONS > nextContent.length) this.endOfList = true
    //   this.preloadedContent.push(...nextContent)
    // },
    setFilterBy(option) {
      this.filterBy = option;
    },
    handlePreloadSelect(selected) {
      this.formData = {
        type: selected.body.mimeType,
        link: selected.body.link,
        thumbnail: selected.body.thumbnail || selected.body.link,
      };
      this.previewLoaded = true;
    },
    // async waitForData(reset) {
    //   if (this.libraryBotsMeta.length) {
    //     this.waitingForData = true
    //     await this.getPreloadedContent(reset)
    //     this.waitingForData = false
    //   }
    // },
  },
  watch: {
    editingSelf() {
      if (this.editingSelf) this.formData = { ...this.initialItem };
    },
    async filteredContentOptions() {
      if (this.endOfList) return;

      const minimum = 6;
      if (minimum > this.filteredContentOptions.length)
        await this.waitForData();
    },
    // async libraryBotsMeta() {
    //   if (this.loggedIn) {
    //     await this.waitForData(true)
    //   }
    // },
    uploading() {},
    initialItem() {
      this.formData = { ...this.formData, ...this.initialItem };
    },
  },
  async mounted() {
    // this.waitForData()
  },
};
</script>
<style lang="scss" scoped>
@import './media-form-field.scss';
@import './media-link/media-link.scss';
</style>
