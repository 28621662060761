var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "inline-metrics" }, [
    _c("div", { staticClass: "inline-metrics__metric" }, [
      _c("div", { staticClass: "inline-metrics__metric__title" }, [
        _vm._v("Audience")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "inline-metrics__metric__stat" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.formattedNum(_vm.latestMetric.count)) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "inline-metrics__metric" }, [
      _c("div", { staticClass: "inline-metrics__metric__title" }, [
        _vm._v("Reach")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "inline-metrics__metric__stat" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.formattedNum(_vm.successCount)) + "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "inline-metrics__metric__more-stats" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.reachPercentage) +
            "% of " +
            _vm._s(_vm.formattedNum(_vm.latestMetric.count)) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "inline-metrics__metric",
        class: { hidden: !_vm.latestMetric.linkSent }
      },
      [
        _c("div", { staticClass: "inline-metrics__metric__title" }, [
          _vm._v("CTR")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inline-metrics__metric__stat" }, [
          _vm._v(_vm._s(_vm.clickThroughRate) + "%")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inline-metrics__metric__more-stats" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.formattedNum(_vm.correctedClicksNumerator) || 0) +
              " of\n      " +
              _vm._s(_vm.formattedNum(_vm.successCount)) +
              "\n    "
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }