var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "more-menu",
      class: { "more-menu--side-sheet": _vm.button }
    },
    [
      _vm.button
        ? _c("icon-btn", {
            staticClass: "more-icon",
            attrs: { icon: "more_vert" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.handleClick($event)
              }
            }
          })
        : _c("icon", {
            staticClass: "more-icon more-icon--no-background",
            attrs: { icon: "more_vert" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.handleClick($event)
              }
            }
          }),
      _vm._v(" "),
      _c(
        "m-menu-anchor",
        { attrs: { "anchor-corner": "TOP_RIGHT" } },
        [
          _c(
            "m-menu",
            {
              attrs: { "default-focus-state": "0" },
              model: {
                value: _vm.showMoreMenu,
                callback: function($$v) {
                  _vm.showMoreMenu = $$v
                },
                expression: "showMoreMenu"
              }
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }